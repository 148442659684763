import React, { useEffect, useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import tvgConf from "@tvg/conf";
import { inspectURL } from "@tvg/formatter/url";
import { get, attempt } from "lodash";
import * as mediator from "@tvg/mediator-classic/src";
import mediatorChannels from "@tvg/mediator";
import NotificationBubble from "@tvg/atomic-ui/_atom/NotificationBubble";
import ContentCard from "@tvg/atomic-ui/_molecule/ContentCard";
import { envelope, email } from "@tvg/atomic-ui/_static/Icons/icons";
import ContentCardEmptyState from "@tvg/atomic-ui/_molecule/ContentCardEmptyState";
import {
  isBrazeInitialized,
  brazeReadContentCard,
  brazeClickContentCard
} from "@tvg/braze";

import style from "./style.css";

const conf = tvgConf();
const tvgDomains = conf.getDomains();
const acceptedCardTypes = (card) =>
  ["ab-image-only", "ab-captioned-image", "ab-classic-card"].includes(card.sc);

const ContentCards = (props) => {
  const { isOpen } = props;
  const nodeContentCards = useRef();
  const node = useRef();

  const [cardsLoaded, setCardsLoaded] = useState({});
  const intersactionObs = useRef(null);

  useEffect(() => {
    if (isOpen) {
      const intersactionOptions = {
        rootMargin: "0px",
        threshold: 1.0
      };

      const onCardEnterView = (entries, observer) => {
        const viewedCards = entries.reduce((acc, entry) => {
          if (entry.isIntersecting) {
            observer.unobserve(entry.target);

            let position = 0;
            const currentCard = props.brazeContentCards.cards.find(
              (card, index) => {
                position = index;
                return card.id === entry.target.id;
              }
            );

            if (currentCard) {
              acc.push(currentCard);
              mediatorChannels.base.dispatch({
                type: "BRAZE:CARD_IMPRESSION",
                payload: {
                  id: currentCard.id,
                  position: position + 1,
                  pinned: currentCard.pinned
                }
              });
            }
          }

          return acc;
        }, []);

        if (viewedCards.length) {
          brazeReadContentCard(viewedCards, true);
        }
      };

      intersactionObs.current = new IntersectionObserver(
        onCardEnterView,
        intersactionOptions
      );
    }

    // clean variables when modal is closed
    if (!isOpen) {
      if (Object.keys(cardsLoaded).length > 0) {
        setCardsLoaded({});
      }

      if (intersactionObs.current) {
        intersactionObs.current.disconnect();
      }
    }
    return () =>
      intersactionObs.current ? intersactionObs.current.disconnect() : null;
  }, [isOpen]);

  // start to observer once all cards are loaded
  useEffect(() => {
    const filteredCards =
      props.brazeContentCards.cards.filter(acceptedCardTypes);

    if ((isOpen, Object.keys(cardsLoaded).length === filteredCards.length)) {
      filteredCards.forEach(({ viewed, id }) => {
        return !viewed && Object.prototype.hasOwnProperty.call(cardsLoaded, id)
          ? intersactionObs.current.observe(cardsLoaded[id])
          : null;
      });
    }
  }, [cardsLoaded]);

  const onOutsideClick = (e) => {
    if (
      !!nodeContentCards.current &&
      !!node.current &&
      !nodeContentCards.current.contains(e.target) &&
      !node.current.contains(e.target) &&
      isBrazeInitialized()
    ) {
      if (!props.contentCardsCustom) {
        window.braze.hideContentCards();
      }
      window.braze.requestContentCardsRefresh();

      props.dispatch({
        type: "TOGGLE_CONTENT_CARDS_OPEN",
        payload: { brazeContentCardsOpen: false }
      });
    }
  };

  const getClassNames = (isOpenCards) =>
    classNames({
      [style.overlayArea]: true,
      [style.overlayAreaIn]: isOpenCards
    });

  const getButtonClassnames = (isOpenCards) =>
    classNames({
      [style.contentCardsButton]: true,
      [style.contentCardsActive]: isOpenCards
    });

  const toggleContentCards = () => {
    if (!isBrazeInitialized()) {
      return;
    }

    if (isOpen && !props.contentCardsCustom) {
      const parentNode = document.querySelector(".renderContentCards");
      if (parentNode) {
        window.braze.showContentCards(parentNode);
      }
    }

    props.dispatch({
      type: "TOGGLE_CONTENT_CARDS_OPEN",
      payload: { brazeContentCardsOpen: !isOpen }
    });

    mediator.dispatch("HEADER_DESKTOP_INBOX_SHOW_HIDE", {
      section: "TVG Inbox Notifications",
      value: isOpen ? "1" : "0",
      tag: props.brazeContentCards.unviewed
    });
  };

  const listContentCards = (cards) =>
    cards.map((card, index) => {
      const urlType =
        card.url && tvgDomains ? inspectURL(card.url, tvgDomains) : "";
      return (
        <ContentCard
          device="desktop"
          key={card.id}
          tvgDomains={tvgDomains}
          onClickContentCard={() => {
            brazeClickContentCard(card, true);
            mediatorChannels.base.dispatch({
              type: "BRAZE:CLICK_CARD",
              payload: {
                id: card.id,
                position: index + 1,
                pinned: card.pinned
              }
            });
          }}
          urlType={urlType}
          card={card}
          onCardLoaded={(cardRef) =>
            setCardsLoaded((prev) => ({ ...prev, [card.id]: cardRef }))
          }
          onDismissCard={() => {
            mediatorChannels.base.dispatch({
              type: "BRAZE:DISMISS_CARD",
              payload: {
                id: card.id,
                position: index + 1,
                pinned: card.pinned
              }
            });
          }}
        />
      );
    });

  const showEmptyState = () => (
    <ContentCardEmptyState
      qaLabel="empty-state-content-cards"
      title={get(props.brazeMessages, "emptyTitle", "")}
      message={get(props.brazeMessages, "emptyDescription", "")}
    />
  );

  useEffect(() => {
    if (isBrazeInitialized()) {
      document.addEventListener("mousedown", onOutsideClick);
    }
    return () => document.removeEventListener("mousedown", onOutsideClick);
  }, []);

  return useMemo(() => {
    const filteredCards =
      props.brazeContentCards?.cards?.filter(acceptedCardTypes) || [];
    const unreadFilteredCards =
      props.brazeContentCards?.cards?.filter(
        (card) => acceptedCardTypes(card) && !card.viewed
      ) || [];

    return (
      <div className={classNames(style.contentCardsContainer)}>
        <button
          type="button"
          ref={node}
          className={getButtonClassnames(isOpen)}
          onClick={toggleContentCards}
        >
          <NotificationBubble
            icon={props.tvgHeaderV2FeatureToggle ? envelope : email}
            iconSize={props.tvgHeaderV2FeatureToggle && 20}
            viewBoxSize={props.tvgHeaderV2FeatureToggle ? 20 : 1024}
            device="desktop"
            counter={unreadFilteredCards.length}
            qaLabel="content-cards-tvg4"
            to=""
            as="span"
            tvgHeaderV2FeatureToggle={props.tvgHeaderV2FeatureToggle}
          />
          <div className={classNames(style.notificationsTooltip)}>
            {props.tooltip}
          </div>
        </button>
        {isOpen && (
          <section ref={nodeContentCards} className={getClassNames(isOpen)}>
            <div className={classNames(style.contentCardsPanelHeader)}>
              Notifications
            </div>
            <div
              className={`${classNames(
                style.renderContentCards
              )} renderContentCards`}
            >
              {props.brazeContentCards.totalCards > 0 &&
              filteredCards.length > 0
                ? listContentCards(filteredCards)
                : showEmptyState()}
            </div>
          </section>
        )}
      </div>
    );
  }, [isOpen, JSON.stringify(props.brazeContentCards)]);
};

ContentCards.propTypes = {
  tooltip: PropTypes.string
};

ContentCards.defaultProps = {
  tooltip: "Notifications"
};

export default connect((store) => ({
  brazeMessages: attempt(JSON.parse, store.header.brazeMessages),
  brazeContentCards: get(store, "brazeData.brazeContentCards"),
  contentCardsCustom: get(store, "header.features.contentCardsCustom", false),
  isOpen: get(store, "header.brazeContentCardsOpen", false)
}))(ContentCards);
