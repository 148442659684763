import React from "react";
import {
  MessageBoxContainer,
  MessageBoxText,
  ArrowContainer
} from "./styled-components";
import Icon from "../../_static/Icons";
import { arrowRight } from "../../_static/Icons/icons";
import { handleVariants } from "./constants";

const NotificationBox = (props) => {
  const {
    text,
    hasArrowRight = true,
    hasRoundCorners = true,
    variant = "info"
  } = props;

  return (
    <MessageBoxContainer
      data-qa-label="message-box"
      hasRoundCorners={hasRoundCorners}
      borderColor={handleVariants(variant).borderColor}
      backgroundColor={handleVariants(variant).backgroundColor}
    >
      <Icon
        icon={handleVariants(variant).leftIcon.icon}
        size={16}
        viewBoxSize={16}
        color={handleVariants(variant).leftIcon.color}
        stroke={handleVariants(variant).leftIcon.strokeColor}
      />
      <MessageBoxText color={handleVariants(variant).textColor}>
        {text}
      </MessageBoxText>
      {hasArrowRight && (
        <ArrowContainer>
          <Icon
            icon={arrowRight}
            size={16}
            color={handleVariants(variant).rightIcon.color}
          />
        </ArrowContainer>
      )}
    </MessageBoxContainer>
  );
};

export default NotificationBox;
