import React from "react";
import { noop } from "lodash";

import {
  BetCardWrapper,
  BetCardRaceHeaderContainer,
  WagersList
} from "./styled-components";
import MyBetsCardHeader from "../../_molecule/MyBetsCardHeader";

const MyBetsCard = (props) => {
  const {
    children,
    dateDiff,
    closeModal,
    onWatchReplay,
    index,
    isReplayInsideMyBets,
    isMTPNewRules,
    intersectableRef,
    itemToRef,
    racePostTime,
    trackName,
    mtp,
    statusCode,
    legStatusCode,
    raceNumber,
    raceUrl,
    betsNumber,
    betAmount,
    winningsAmount,
    hasReplayVideo,
    hasLiveVideo,
    onVideoRedirect,
    showBetInfoHeader,
    isFutureBet,
    raceDate,
    qaLabel,
    showRaceLeg,
    legNumber,
    hasPastPerformance,
    isRaceNear,
    currentRaceNumber,
    showSeeResult,
    isMyBetsOpen,
    hasRepeatBet,
    onRepeatBet
  } = props;
  return (
    <BetCardWrapper
      data-qa-label={qaLabel}
      id={`activebet-${index}`}
      ref={index === itemToRef ? intersectableRef : null}
    >
      <BetCardRaceHeaderContainer data-qa-label={`${qaLabel}-header-container`}>
        <MyBetsCardHeader
          showBetInfo={showBetInfoHeader}
          racePostTime={racePostTime}
          trackName={trackName}
          mtp={mtp}
          statusCode={legStatusCode || statusCode}
          raceNumber={raceNumber}
          bets={betsNumber}
          betAmount={betAmount}
          dateDiff={dateDiff}
          winningsAmount={winningsAmount}
          raceUrl={raceUrl}
          closeModal={closeModal}
          onWatchReplay={onWatchReplay}
          isReplayInsideMyBets={isReplayInsideMyBets}
          isFutureBet={isFutureBet}
          raceDate={raceDate}
          isMTPNewRules={isMTPNewRules}
          hasReplayVideo={hasReplayVideo}
          hasLiveVideo={hasLiveVideo}
          onVideoRedirect={onVideoRedirect}
          showRaceLeg={showRaceLeg}
          legNumber={legNumber}
          hasPastPerformance={hasPastPerformance}
          isRaceNear={isRaceNear}
          currentRaceNumber={currentRaceNumber}
          showSeeResult={showSeeResult}
          isMyBetsOpen={isMyBetsOpen}
          hasRepeatBet={hasRepeatBet}
          onRepeatBet={onRepeatBet}
        />
      </BetCardRaceHeaderContainer>
      <WagersList>{children}</WagersList>
    </BetCardWrapper>
  );
};

MyBetsCard.defaultProps = {
  children: null,
  dateDiff: 0,
  closeModal: noop,
  onWatchReplay: noop,
  index: -1,
  isReplayInsideMyBets: true,
  isMTPNewRules: true,
  intersectableRef: null,
  itemToRef: 0,
  racePostTime: "",
  trackName: "",
  mtp: 60,
  statusCode: "O",
  raceNumber: 1,
  raceUrl: "",
  betsNumber: 0,
  betAmount: 0,
  winningsAmount: 0,
  hasReplayVideo: false,
  hasLiveVideo: false,
  onVideoRedirect: noop,
  showBetInfoHeader: false,
  isFutureBet: false,
  raceDate: "",
  qaLabel: "mybets-bets-race-card",
  showRaceLeg: false,
  legNumber: 0,
  hasPastPerformance: false,
  isRaceNear: false,
  currentRaceNumber: 1,
  legStatusCode: null,
  showSeeResult: false,
  isMyBetsOpen: false,
  hasRepeatBet: false,
  onRepeatBet: noop
};

export default MyBetsCard;
