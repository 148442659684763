import React from "react";
import { noop } from "lodash";
import { DialogMessage, Header } from "@tvg/design-system";
import { Button } from "@tvg/design-system/web";
import {
  ResultContainer,
  ContentWrapper,
  HeaderWrapper
} from "./styled-components";
import type { Props } from "./types";

const EmailReferralResult = ({
  qaLabel = "emailReferralResult",
  type = "error",
  title = "",
  description = "",
  isHeaderVisible = true,
  onNavigationBackClick = noop,
  onNavigationHomeClick = noop
}: Props) => (
  <ResultContainer data-qa-label={qaLabel}>
    {isHeaderVisible && (
      <HeaderWrapper>
        <Header
          qaLabel={`${qaLabel}HeaderTitle`}
          tag="h1"
          color="white.900"
          fontFamily="bold"
        >
          Email Referral
        </Header>
      </HeaderWrapper>
    )}
    <ContentWrapper>
      <DialogMessage
        variant={type}
        title={title}
        description={description}
        qaLabel={`${qaLabel}DialogMessage`}
      />
      <Button
        qaLabel={`${qaLabel}BackButton`}
        variant="primary"
        width={1}
        marginBottom="space-4"
        onClick={() => onNavigationBackClick && onNavigationBackClick()}
      >
        Back to referrals
      </Button>
      <Button
        qaLabel={`${qaLabel}HomeButton`}
        variant="secondary"
        width={1}
        onClick={() => onNavigationHomeClick && onNavigationHomeClick()}
      >
        Go to homepage
      </Button>
    </ContentWrapper>
  </ResultContainer>
);

export default EmailReferralResult;
