import React, { PureComponent } from "react";
import Loadable from "react-loadable";

import mediator from "@tvg/mediator";
import Containers from "@tvg/atomic-ui/_static/BaseComponents/Containers";
import ProcessingOverlay from "@tvg/atomic-ui/_static/ProcessingOverlay";

const Loading = ({ error }) => {
  if (error) {
    window.location.reload(true);
    return false;
  }

  return (
    <Containers.WagerRewardsContainer isTablet>
      <ProcessingOverlay isTransparent />
    </Containers.WagerRewardsContainer>
  );
};

const LoadableWagerRewards = Loadable({
  loader: () => import("@tvg/wager-rewards"),
  loading: Loading,
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props} />;
  }
});

export default class WagerRewards extends PureComponent {
  render() {
    if (this.props.isEnabled === false || this.props.hasProfileException()) {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: "/" }
      });

      return false;
    }

    return (
      <Containers.GridContainer>
        <LoadableWagerRewards shouldUpdate device="desktop" />
      </Containers.GridContainer>
    );
  }
}
