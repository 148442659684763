import styled, { css } from "styled-components";

import buildColor from "../../_static/ColorPalette";

const ResultsTable = styled.table`
  width: 100%;
  ${(props) =>
    props.border &&
    css`
      border-bottom: 1px solid ${buildColor("grey", "300")};
    `};
`;

export default ResultsTable;
