import { ForwardedRef } from "react";
import styled from "styled-components";

export const Container = styled.div<{
  ref: ForwardedRef<HTMLDivElement>;
}>`
  position: relative;
`;

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
`;
