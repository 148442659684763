import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import classnames from "classnames";

import style from "./style.css";

const animationTimes = {
  regular: 300,
  presentation: 300,
  fullscreen: 300
};

class Modal extends Component {
  static defaultProps = {
    type: "regular",
    qaLabel: ""
  };

  constructor(props) {
    super(props);
    this.animationTimes = animationTimes;

    this.state = {
      modalClass: classnames({
        [style.modal]: true,
        [style.enter]: true
      }),
      isClosing: false
    };
  }

  state;

  setCloseAnimation;
  setCloseAnimation = (cb) => {
    this.setState(
      {
        modalClass: classnames({
          [style.modal]: true,
          [style.leave]: true
        }),
        isClosing: true
      },
      cb
    );

    return this;
  }

  props;

  backgroundClick;
  backgroundClick = (e) => {
    if (!this.state.isClosing) {
      if (e.target === findDOMNode(this.component)) {
        this.props.closeModal();
      }
    }

    return this;
  }

  animationTimes;
  component;

  render() {
    return (
      <div
        role="button"
        tabIndex={0}
        className={this.state.modalClass}
        ref={(c) => {
          this.component = c;
        }}
        onClick={this.backgroundClick}
        data-qa-label={this.props.qaLabel.concat("Modal")}
      >
        <div
          className={classnames(style[this.props.type], this.props.classnames)}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Modal;
