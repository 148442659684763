import React from "react";

import { Container, Item, Slider } from "./styled-components";

const SegmentedControl = ({ tabs, qaLabel, className, withAnimation }) => {
  const selectedIndex = tabs.findIndex((tab) => tab.isActive);
  return (
    <Container className={className} data-qa-label={qaLabel}>
      {withAnimation && (
        <Slider tabsLength={tabs.length} selectedIndex={selectedIndex} />
      )}
      {tabs.map((tab, index) => (
        <Item
          key={`${tab.title}_${index.toString()}`}
          withAnimation={withAnimation}
          tabsLength={tabs.length}
          isActive={tab.isActive}
          counter={tab.counter}
          displayZero={tab.displayZero}
          onClick={tab.onClick}
          data-qa-label={tab.qaLabel}
        >
          {tab.title}
        </Item>
      ))}
    </Container>
  );
};

export default SegmentedControl;
