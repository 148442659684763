import React, { useContext, useEffect, Children, cloneElement } from "react";
import { View } from "react-native";
import { TabsContext } from "../../tabs";
import { TabSelectionIndicator } from "../tabSelectionIndicator";
import { StyledViewContainer } from "./styled-components";
import { TabListProps } from "./types";
import { ScrollViewFading } from "../../../scrollViewFading";

export const TabList = ({
  children,
  isStretched,
  tabSelectionIndicatorColor
}: TabListProps) => {
  const { tabsLayout, setTabsLayout, selectedValue } = useContext(TabsContext);

  const activeTabLayout = (tab: string | undefined) => {
    let result = { width: 0, x: 0 };
    Object.entries(tabsLayout).forEach((item) => {
      if (item[0] === tab) {
        result = {
          width: (item[1] as { width: number }).width,
          x: (item[1] as { x: number }).x
        };
      }
    });
    return result;
  };

  useEffect(() => {
    React.Children.forEach(children, (child) => {
      const state = {
        ...tabsLayout,
        [child.props.value]: { width: 0, x: 0 }
      };
      setTabsLayout(state);
    });
  }, []);

  return isStretched ? (
    <View>
      <StyledViewContainer accessibilityRole="tablist">
        {Children.map(children, (child) =>
          cloneElement(child, { isStretched })
        )}
      </StyledViewContainer>
      <TabSelectionIndicator
        width={activeTabLayout(selectedValue)?.width}
        x={activeTabLayout(selectedValue)?.x}
        tabSelectionIndicatorColor={tabSelectionIndicatorColor}
      />
    </View>
  ) : (
    <ScrollViewFading>
      <StyledViewContainer accessibilityRole="tablist">
        {Children.map(children, (child) =>
          cloneElement(child, { isStretched })
        )}
      </StyledViewContainer>
      <TabSelectionIndicator
        width={activeTabLayout(selectedValue)?.width}
        x={activeTabLayout(selectedValue)?.x}
        tabSelectionIndicatorColor={tabSelectionIndicatorColor}
      />
    </ScrollViewFading>
  );
};

export { TabListProps };
