import wtx from "@tvg/api/wtx";
import performanceUtils from "@tvg/utils/performanceUtils";

const cancelBet = ({ wager, accountNumber }) => {
  performanceUtils.register("cancel_bet");

  return wtx
    .cancelBet(wager.serialNumber, wager.wagerReference, accountNumber)
    .then((res) => {
      performanceUtils.measure("cancel_bet");
      return res;
    });
};

export default cancelBet;
