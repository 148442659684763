import React from "react";
import { useTheme } from "../../../../hooks";
import { Icon } from "../../../Icon";
import { Paragraph } from "../../../Typography";
import { Wrapper } from "./styled-components";

const OptedInTag = () => {
  const theme = useTheme();

  return (
    <Wrapper
      data-qa-label="myBets-betTicket-optedInTag-container"
      theme={theme}
    >
      <Icon
        name="optedIn"
        size="s"
        backgroundColor="myBets.win.trackingColor"
        lineColor="--fd-colors-system-positive-content-accent"
        mr="space-1"
      />
      <Paragraph
        qaLabel="myBets-betTicket-optedInTag-text"
        fontFamily="condensedRegular"
        textTransform="uppercase"
        color="--fd-colors-system-positive-content-onSubtle"
      >
        opted in
      </Paragraph>
    </Wrapper>
  );
};

export default OptedInTag;
