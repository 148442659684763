import React, { PureComponent } from "react";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import { connect } from "react-redux";
import mediator from "@tvg/mediator";
import Poller from "@tvg/poller";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import { attempt, get, isEqual, noop, orderBy } from "lodash";
import CredentialsRecovery from "@tvg/mobile-login/src/components/CredentialsRecovery";
import EmailRecovery from "@tvg/mobile-login/src/components/EmailRecovery";
import capiRequests from "@tvg/api/capi";
import TVGConf from "@tvg/conf";

import interceptRequests from "@tvg/perimeterx/src";
import isMobile from "@tvg/utils/mobileUtils";
import { isTvg5 } from "@tvg/utils/generalUtils";
import queryString from "query-string";
import {
  getPromoOnboardingPollerTime,
  getPromosOnboardingToggle
} from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import { startPromoOnboardingTimerPoller } from "@tvg/sh-lib-promos-onboarding/utils/promoOnboardingPollerTime";
import parseJSONCapiMessage from "@fdr/utils/parseJSONCapiMessage";
import initializeBraze from "@tvg/braze/src/userBraze";
import {
  successBrazePollerRequest,
  setBrazeContentCards
} from "@tvg/braze/src/store/actions";
import {
  getAccountNumber,
  getBalance,
  getEmail,
  getResidenceState
} from "@urp/store-selectors";
import fetchUserFavorites, {
  updateFavoriteTrack,
  updateUserFavoriteTracks
} from "@tvg/sh-lib-account-actions/src/utils/userFavorites";
import getUserPreferences from "@tvg/sh-lib-account-actions/src/utils/userPreferences";
import { getIsContentCardsOpen } from "@tvg/sh-lib-account-actions/src/selectors/modal";
import {
  closeLoginModal,
  openLoginModal,
  resetLoginFlowStatus
} from "@tvg/sh-lib-account-actions/src/actions/modal";
import {
  getUserData,
  setUserAccountNumber
} from "@tvg/sh-lib-account-actions/src/actions/login";

import { LOGIN_ACTIVE_FLOWS } from "@tvg/sh-lib-account-actions/src/reducers/modalReducer";
import { userPromoOnboarding } from "@tvg/sh-lib-account-actions/src/utils/userPromoOnboarding";
import {
  manageEquibaseId,
  promosSubscriber
} from "@tvg/sh-lib-account-actions/src/utils/controllerLogic";
import { clearOptedInPromos } from "@tvg/sh-lib-account-actions/src/services/optedInPromos";
import handleLogout from "./utils/userLogout";
import { updateBalanceRequest, requestBalanceTimer } from "./utils/userBalance";
import { buildModalProps } from "./utils/modal";
import {
  requestLocationWeb,
  handleRevalidateLocation,
  checkMobileLocation
} from "./utils/userLocation";

const BalancePollerTimer = new Poller();
const PromosPoller = new Poller();

const buildBaseProps = (device, product) => {
  const baseProps = {
    titleType: "ipp",
    device
  };

  switch (device) {
    case "mobile":
      return { ...baseProps, animation: "bottom" };
    case "tablet":
      return {
        ...baseProps,
        animation: "fade",
        hasOverlay: true,
        isFullWidth: false,
        isFullHeight: false,
        isFluidWidth: false,
        fixedWidth: "391px",
        offsetTop: 0,
        useFakeInput: product === "ios2"
      };
    default:
      return {
        ...baseProps,
        animation: "fade",
        hasOverlay: true,
        isFullWidth: false,
        isFullHeight: false,
        isFluidWidth: false,
        fixedWidth: "391px",
        offsetTop: 0,
        offsetLeft: 0,
        offsetRight: 0
      };
  }
};

export class LoginController extends PureComponent {
  loginCallback = null;

  Login = () => {};

  constructor(props) {
    super(props);
    this.state = {
      subscription: null,
      promoEligible: null,
      openLoginRetryCounter: 0
    };
  }

  componentDidMount() {
    if (!this.props.enableAWFlows) {
      this.Login = require("@tvg/mobile-login");
    }

    mediatorClassic.subscribe("TVG_LOGIN:OPEN_LOGIN_MODAL", (data) => {
      this.openLogin(data || { stateAbbr: "", callback: noop, mobile: true });
    });

    mediator.base.subscribe("CLOSE_LOGIN_MODAL", () => {
      this.props.dispatch(closeLoginModal());
    });

    if (TVGConf().product === "tvg5") {
      mediator.base.subscribe(
        "UPDATE_SESSION_FAVORITE_TRACKS",
        updateFavoriteTrack(this.props.dispatch)
      );
      mediatorClassic.subscribe(
        "NEW_FAVORITE_TRACKS",
        updateUserFavoriteTracks(this.props.dispatch)
      );
    }

    mediator.base.subscribe(
      "TVG_LOGIN:GET_USER_PROMOS",
      this.handlePromosSubscribe
    );

    mediator.base.subscribe("TVG_LOGIN:CLEAR_USER_PROMOS", () =>
      clearOptedInPromos(this.props.dispatch)
    );

    // currently used to restore modal on back arrow
    mediator.base.subscribe("RESET_LOGIN_FLOW_STATUS", () => {
      this.props.dispatch(resetLoginFlowStatus());
    });

    mediator.base.subscribe("TVG_LOGIN:OPEN_LOGIN_MODAL", (data) => {
      this.openLogin(
        data && data.payload
          ? data.payload
          : { stateAbbr: "", callback: noop, mobile: true }
      );
    });

    mediator.base.subscribe("OPEN_LOGIN_FLOW", (data) => {
      if (
        !isEqual(
          get(data, "payload.loginActiveFlow", null),
          get(this.props, "loginModal.loginActiveFlow", null)
        ) ||
        !isEqual(
          typeof data.payload.loginActiveFlowStatus === "string"
            ? data.payload.loginActiveFlowStatus
            : null,
          get(this.props, "loginModal.loginActiveFlowStatus", null)
        )
      ) {
        this.openLogin({
          stateAbbr: "",
          callback: noop,
          mobile: true,
          loginActiveFlow: data.payload.loginActiveFlow || null,
          loginActiveFlowStatus: data.payload.loginActiveFlowStatus || null
        });
      }
    });

    mediatorClassic.subscribe("OPEN_LOGIN_FLOW", (data) => {
      if (
        !isEqual(
          get(data, "loginActiveFlow", null),
          get(this.props, "loginModal.loginActiveFlow", null)
        ) ||
        !isEqual(
          typeof data.loginActiveFlowStatus === "string"
            ? data.loginActiveFlowStatus
            : null,
          get(this.props, "loginModal.loginActiveFlowStatus", null)
        )
      ) {
        this.openLogin({
          stateAbbr: "",
          callback: noop,
          mobile: true,
          loginActiveFlow: data.loginActiveFlow || null,
          loginActiveFlowStatus: data.loginActiveFlowStatus || null
        });
      }
    });

    mediator.base.subscribe("OPEN_LOGIN", (data) => {
      const payload =
        data && data.payload
          ? data.payload
          : { stateAbbr: "", callback: noop, mobile: true };
      checkMobileLocation(
        this.props.geolocation,
        payload,
        this.props.splashError,
        this.props.isLocationSplashOpen,
        this.props.isLocationRequired,
        this.props.dispatch,
        this.openLogin
      );
    });

    mediator.base
      .subscribe("TVG_LOGIN:USER_SESSION_UPDATE", (data) => {
        if (TVGConf().device === "desktop" || TVGConf().product === "tvg4") {
          // Let's TVG4 know that the user is logged
          mediatorClassic.dispatch("TVG_LOGIN:USER_SESSION_UPDATE", {
            ...data.payload
          });
        }

        capiRequests.fetchBrazePollerTime().then((response) => {
          this.props.dispatch(successBrazePollerRequest(+response.data.value));
        });

        const user = data.payload;
        this.props.dispatch(getUserData(user));
        if (user.logged) {
          // user made login: should generate and save uuid for equibase if not exists
          manageEquibaseId(true);
          // save if user ever loggin
          attempt(() => window.localStorage.setItem("userLoginOnce", true));

          fetchUserFavorites(user.user.accountNumber, this.props.dispatch);

          // Handle revalidate location
          handleRevalidateLocation(
            user,
            this.props.geopacketUsage,
            this.props.dispatch
          );
          // event for ios devices when user authentication is done
          this.handleIosLoginSuccess(user);

          this.props.dispatch(closeLoginModal());

          requestLocationWeb(user, this.props);
          // Request for device location and region data
        } else {
          handleLogout(user, manageEquibaseId, this.props.dispatch);
        }
      })
      .replay();

    // tries to get the user id (accountNumber) from localStorage and dispatch it to user data
    // this is useful because every component initialized will know if the user is logged, plus it's
    // account number beforehand
    attempt(() => {
      if (window.sessionStorage.getItem("userId")) {
        this.props.dispatch(
          setUserAccountNumber(window.sessionStorage.getItem("userId"))
        );
      }
    });

    // checks if user has account suspended based on session staorage IS_SUSPENDED key
    // this ensures the moneypak RG limits on the DMA
    // the user must
    attempt(() => {
      if (window.sessionStorage.getItem("IS_SUSPENDED")) {
        mediator.base.dispatch({ type: "TVG_LOGIN:DO_LOGOUT" });
        window.sessionStorage.removeItem("IS_SUSPENDED");
      }
    });

    // get 403 requests from perimeterx
    interceptRequests();

    // Used only got MEP. Other products are using the new Inbox button which is subscribing to this
    // packages/urp-comp-braze-inbox/src/components/InboxButton/index.tsx

    if (isMobile(TVGConf().product)) {
      mediator.ios.subscribe("SET_CONTENT_CARDS", (data) => {
        const { payload } = data;
        if (get(payload, "cards")) {
          const objCards = get(payload, "cards", []);
          this.props.dispatch(
            setBrazeContentCards({
              unviewed: get(payload, "unviewed", 0),
              totalCards: get(payload, "cards.length", 0),
              cards: orderBy(
                typeof objCards === "string" ? JSON.parse(objCards) : objCards,
                ["pinned", "createdAt"],
                ["desc", "desc"]
              )
            })
          );
        }
      });
    }

    if (this.props.promoOnboardingToggle) {
      startPromoOnboardingTimerPoller(this.props.dispatch);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.user.isLogged &&
      this.props.user.accountNumber &&
      this.props.user.accountNumber !== "" &&
      !this.props.promos.gotPromos &&
      !this.props.promos.loadingPromos
    ) {
      mediator.base.dispatch({
        type: "TVG_LOGIN:GET_USER_PROMOS"
      });

      if (!PromosPoller.isRunning() && isTvg5()) {
        PromosPoller.start(
          () =>
            mediator.base.dispatch({
              type: "TVG_LOGIN:GET_USER_PROMOS"
            }),
          60000
        );
      }
    }

    if (!this.props.user.isLogged && this.props.promos.gotPromos) {
      mediator.base.dispatch({
        type: "TVG_LOGIN:CLEAR_USER_PROMOS"
      });
    }

    if (
      (!prevProps.user.isLogged && this.props.user.isLogged) ||
      (this.props.user.isLogged &&
        prevProps.user.accountNumber !== this.props.user.accountNumber)
    ) {
      getUserPreferences(this.props.user.accountNumber, this.props.dispatch);
    }

    if (
      !isEqual(this.props.user.isLogged, prevProps.user.isLogged) ||
      !isEqual(this.props.user.accountNumber, prevProps.user.accountNumber) ||
      !isEqual(this.props.user.balancePoller, prevProps.user.balancePoller) ||
      (this.props.user.balance === undefined &&
        !!prevProps.user.balance &&
        this.props.user.isLogged)
    ) {
      updateBalanceRequest(
        this.props.user.isLogged,
        this.props.user.accountNumber,
        this.props.user.balancePoller,
        this.props.dispatch
      );
    }

    if (!BalancePollerTimer.isRunning()) {
      BalancePollerTimer.start(
        () => requestBalanceTimer(this.props.dispatch),
        300000
      );
    }

    // bind this context to setState to use on a regular function
    const setState = this.setState.bind(this);

    initializeBraze(
      prevProps,
      this.props,
      this.state.subscription,
      setState,
      this.props.dispatch,
      undefined,
      undefined,
      this.props.brazeRefreshExtraPollerTimer
    );

    userPromoOnboarding(this.props, prevProps, this.state, prevState, setState);
  }

  componentWillUnmount() {
    mediator.base.unsubscribe(
      "TVG_LOGIN:GET_USER_PROMOS",
      this.handlePromosSubscribe
    );
  }

  handlePromosSubscribe = () => {
    promosSubscriber({
      isLogged: this.props.user.isLogged,
      accountNumber: this.props.user.accountNumber,
      loadingPromos: this.props.promos.loadingPromos,
      dispatch: this.props.dispatch
    });
  };

  handleIosLoginSuccess = (user) => {
    const { product } = TVGConf();
    if (user.userLogin && isMobile(product)) {
      mediator.ios.dispatch({
        type: "LOGIN_SUCCESS",
        payload: {
          pushAccountId: user.user.accountNumber,
          accountId: user.loginPin
            ? user.user.accountNumber
            : user.user.userName,
          token: get(user, "user.pin"),
          tvgThreeToken: user.tvg3token
        }
      });
    }
  };

  openLogin = (loginData) => {
    const loginActiveFlow = get(loginData, "loginActiveFlow", null);
    const loginActiveFlowStatus = get(loginData, "loginActiveFlowStatus", null);

    if (this.props.loginModal.loginOpen && !loginActiveFlow) return;

    if (isMobile(TVGConf().product)) {
      // eslint-disable-next-line
      loginData.stateAbbr = get(this.props.geolocation, "state");
    }

    // get user from query parameter
    let initialUser;
    if (typeof window !== "undefined") {
      const queryParams = queryString.parse(
        window.location.search.toLowerCase()
      );
      initialUser = get(queryParams, "user", "");
    }
    const loginComponent =
      this.Login &&
      this.Login.default.getLoginModal(
        {
          ...loginData,
          user: initialUser,
          mobile: TVGConf().device !== "desktop",
          geopacketUsage: this.props.geopacketUsage
        },
        () => {
          this.props.dispatch(closeLoginModal());
        },
        this.props.history
      );

    const callback =
      typeof loginData.callback === "function" ? loginData.callback : noop;

    if (!loginData.loginOpen) {
      mediator.base.dispatch({
        type: "LOGIN_MODAL_OPEN_CLOSE",
        payload: {
          open: true
        }
      });
    }

    // call ios touch id authentication
    if (this.props.touchId.initEnabled || this.props.touchId.enabled) {
      mediator.ios.dispatch({
        type: "TOUCH_ID"
      });
    }

    this.props.dispatch(
      openLoginModal({
        loginComponent,
        callback,
        loginActiveFlow,
        loginActiveFlowStatus
      })
    );
  };

  renderContent = () => {
    // get user from query parameter
    let initialUser = null;
    if (typeof window !== "undefined") {
      const queryParams = queryString.parse(
        window.location.search.toLowerCase()
      );
      initialUser = get(queryParams, "user", "");
    }

    const loginActiveFlow = get(this.props, "loginModal.loginActiveFlow", null);

    switch (loginActiveFlow) {
      case LOGIN_ACTIVE_FLOWS["forgot-credentials"]: {
        return () => (
          <CredentialsRecovery
            mobile={TVGConf().device === "mobile"}
            history={this.props.history}
            initialValue={initialUser}
            onCloseModalCallback={() => this.props.dispatch(closeLoginModal())}
          />
        );
      }
      case LOGIN_ACTIVE_FLOWS["recover-email"]: {
        return () => (
          <EmailRecovery
            device={TVGConf().device}
            onCloseModalCallback={() => this.props.dispatch(closeLoginModal())}
          />
        );
      }
      default: {
        return () => this.props.loginModal.loginComponent;
      }
    }
  };

  render() {
    const { product, device } = TVGConf();
    const baseProps = buildBaseProps(device, product);

    const modalProps = buildModalProps(
      get(this.props, "loginModal.loginActiveFlow", null),
      get(this.props, "loginModal.loginActiveFlowStatus", null),
      baseProps,
      this.props,
      device,
      product
    );

    return <ModalV2 {...modalProps}>{this.renderContent()}</ModalV2>;
  }
}

export default connect((store) => ({
  user: {
    firstName: get(store, "userData.user.firstName"),
    lastName: get(store, "userData.user.lastName"),
    isLogged: get(store, "userData.logged"),
    returningUser: get(store, "userData.returningUser", false),
    balance: getBalance(store),
    balancePoller: get(store, "userData.balancePoller"),
    wagerProfile: get(store, "userData.user.profile"),
    accountNumber: getAccountNumber(store),
    userName: get(store, "userData.user.userName"),
    homeState: getResidenceState(store),
    emailAddress: getEmail(store)
  },
  androidGpsAllowedStates: get(
    store,
    "capi.messages.androidGpsAllowedStates",
    ""
  ),
  splashError: get(store, "locationSplash.error", null),
  isLocationSplashOpen: get(store, "locationSplash.isOpen", false),
  isLocationRequired: get(store, "locationSplash.isLocationRequired", false),
  onLocationGet: get(store, "locationSplash.onLocationGet", null),
  brazePoller: get(store, "userData.brazePoller", 60),
  touchId: {
    initEnabled: get(store, "ios.init.touchIdEnabled", false),
    enabled: get(store, "ios.touchId.touchIdEnabled", false),
    accountId: get(store, "ios.touchId.accountId", ""),
    userChangingTouchId: get("store.ios.userChangingTouchId", false),
    token: get(store, "ios.touchId.token", "")
  },
  geolocation: get(store, "geolocation"),
  contentCardsOpen: getIsContentCardsOpen(
    store,
    TVGConf().device === "desktop"
  ),
  loginModal: store.loginModal,
  geopacketUsage: get(
    store,
    "capi.featureToggles.geopacketUsage",
    get(store, "header.features.geopacketUsage", false)
  ),
  locationRequired: get(
    store,
    "capi.messages.statesWithLocationRequired",
    '["ca", "nj", "az"]'
  ),
  promos: {
    gotPromos: get(store, "userData.gotPromos", false),
    loadingPromos: get(store, "userData.loadingPromos", false)
  },
  useAndroidGpsAllowedStates: get(
    store,
    "capi.featureToggles.useAndroidGpsAllowedStates",
    false
  ),
  promoOnboardingToggle: getPromosOnboardingToggle(store),
  promoOnboardingPollerTime: getPromoOnboardingPollerTime(store),
  enableAWFlows: get(store, "capi.featureToggles.enableAWFlows", false),
  allowedStates: Object.keys(
    parseJSONCapiMessage(store, "capi.messages.stateSelectorListFdr", {})
  ),
  brazeRefreshExtraPollerTimer: get(
    store,
    "capi.messages.brazeRefreshExtraPollerTimer",
    0
  )
}))(LoginController);
