import { get, map } from "lodash";
import { getPortByBrand } from "@tvg/utils/generalUtils";

const POLL_INTERVAL = 60000;

export default {
  options: (props) => {
    const variables = {
      wagerProfile: get(props, "wagerProfile") || getPortByBrand()
    };

    return {
      client: get(props, "graphClient", null),
      pollInterval: POLL_INTERVAL,
      fetchPolicy: "cache-and-network",
      ssr: false,
      variables
    };
  },
  props: (result) => ({
    comingUpRaces: map(get(result, "data.tracks", []), "races"),
    comingUpRacesLoading: result.data.loading,
    refetch: (variables) => result.data.refetch(variables)
  })
};
