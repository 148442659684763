import { useEffect, useState } from "react";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

momentDurationFormatSetup(moment);

const updateTimerText = (scheduleDate) => {
  const currentDate = moment();
  const eventDate = moment(scheduleDate);
  const timeDifference = moment.duration(eventDate.diff(currentDate));
  const daysDiff = eventDate.diff(currentDate, "days");
  const hoursDiff = eventDate.diff(currentDate, "hours");
  const minutesDiff = eventDate.diff(currentDate, "minutes");
  const secondsDiff = eventDate.diff(currentDate, "seconds");

  if (daysDiff < 3 && daysDiff >= 1) {
    const dayText = daysDiff > 1 ? "days" : "day";

    return `in ${timeDifference.format(`dd [${dayText}] HH[h] mm[m]`)}`;
  }

  if (hoursDiff < 24 && hoursDiff >= 1) {
    return `in ${timeDifference.format(`HH[h] mm[m]`)}`;
  }

  if (minutesDiff < 60 && minutesDiff >= 1) {
    return `in ${timeDifference.format(`mm[m] ss[s]`)}`;
  }

  if (secondsDiff < 60) {
    return `in ${timeDifference.format(`ss[s]`)}`;
  }

  return moment(scheduleDate).format("dddd, MMM Do [at] h:mma");
};

const useCountDownTimer = (expirationTime) => {
  const currentTime = moment();
  const endTime = moment(expirationTime);
  const initTimeDifference = moment.duration(endTime.diff(currentTime));
  const initText =
    endTime.diff(currentTime) > 0 ? updateTimerText(expirationTime) : "";

  const [timeLeft, setTimeLeft] = useState(initTimeDifference);
  const [countDownText, setCountDownText] = useState(initText);

  const updateTimer = () => {
    setCountDownText(updateTimerText(expirationTime));
    setTimeLeft((prevTimeLeft) => prevTimeLeft - 1000);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeLeft > 0) {
        updateTimer();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expirationTime]);

  return { countDownText };
};

export default useCountDownTimer;
