import {
  setRaceAlerts,
  setUserFavoriteTracksWithId,
  removeFavoriteTrack,
  setUserFavoriteTrack
} from "@tvg/shared-actions/UserActions";
import { batch } from "react-redux";
import mediator from "@tvg/mediator";
import TVGConf from "@tvg/conf";
import { isMobile } from "@tvg/utils/mobileUtils";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import favorites from "../services/favorites";
import { setUserFavoriteTracks } from "../actions/favorites";

const fetchUserFavorites = (accountNumber, dispatch) => {
  favorites
    .getUserFavoriteTracks(+accountNumber)
    .then((favoriteTracks) => {
      const actualFavoriteTracks = Object.keys(favoriteTracks);
      dispatch(setUserFavoriteTracks(actualFavoriteTracks));
      dispatch(setUserFavoriteTracksWithId(favoriteTracks));
    })
    .then(() => mediator.base.dispatch({ type: "GOT_FAVORITE_TRACKS" }));
  if (isMobile(TVGConf().product)) {
    // get all race alerts subscriptions from ufc service
    favorites
      .getUserRaceAlerts(accountNumber, "race")
      .then((raceAlertsList) => {
        dispatch(setRaceAlerts(raceAlertsList));
      })
      .catch(() => {
        dispatch(setRaceAlerts([]));
      });
  }
};

export const updateUserFavoriteTracks = (dispatch) => (data) => {
  const { from, favoriteTracks, favoriteTracksWithId } = data;
  if (Array.isArray(favoriteTracks) && favoriteTracksWithId) {
    batch(() => {
      dispatch(setUserFavoriteTracks(favoriteTracks));
      dispatch(setUserFavoriteTracksWithId(favoriteTracksWithId));
    });
  } else if (from === "home" && typeof favoriteTracks === "string") {
    mediatorClassic.dispatch("NEW_FAVORITE_TRACKS", {
      favoriteTracks: favoriteTracks.split(",")
    });
  }
};

export const updateFavoriteTrack = (dispatch) => (data) => {
  const favoritesSession = (
    sessionStorage.getItem("favoriteTracks") || ""
  ).split(",");
  const { isAddingFavorite, trackCode, favoriteId } = data.payload;

  if (isAddingFavorite) {
    if (!favoritesSession.includes(trackCode)) {
      favoritesSession.push(trackCode);
    }
    dispatch(setUserFavoriteTrack(trackCode, favoriteId));
  } else {
    favoritesSession.splice(favoritesSession.indexOf(trackCode), 1);
    dispatch(removeFavoriteTrack(trackCode));
  }

  const favoritesToStore = favoritesSession.join(",");
  sessionStorage.setItem("favoriteTracks", favoritesToStore);

  // Send event to update favorites in LHN
  mediatorClassic.dispatch("NEW_FAVORITE_TRACKS", {
    favoriteTracks: favoritesToStore,
    from: "home"
  });

  // Send event to update favorites in TVG4
  mediatorClassic.dispatch("UPDATE_FAVORITE_TRACKS", {
    favorites: favoritesSession,
    trackCode,
    favoriteId
  });
};

export default fetchUserFavorites;
