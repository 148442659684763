import styled, { css } from "styled-components";
import type { TabsLayout } from "../../types";

export const StyledContainer = styled.div<{
  tabsLayout: TabsLayout;
}>`
  display: flex;
  flex-direction: row;
  height: 48px;
  border: none;
  position: relative;

  ${({ tabsLayout }) =>
    tabsLayout &&
    Object.entries(tabsLayout).map(
      ([key, value]) => css`
        #${key}.selected ~ .glider {
          width: ${value.width}px;
          left: ${value.x}px;
        }
      `
    )}
`;
