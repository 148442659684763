import React, { PureComponent } from "react";
import { map } from "lodash";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import BetSummary from "../../_molecule/BetSummary";
import { BetList, BetListItem } from "./styled-components";
import MessageBox from "../../_molecule/MessageBox";

export default class RaceBets extends PureComponent {
  static defaultProps = {
    bets: [],
    raceNumber: "1",
    hasPromosOptedIn: false
  };

  render() {
    return (
      <BetList>
        <TransitionGroup component="ul" data-qa-label="betlist">
          {map(this.props.bets, (bet) => (
            <CSSTransition
              timeout={4000}
              key={`bet-${bet.id}`}
              classNames="bet-list-item"
            >
              <BetListItem>
                {bet.isCancelable && (
                  <MessageBox
                    type="info"
                    hasIcon
                    message="Bet successfully deleted"
                    className="betSummaryMessage"
                  />
                )}
                <BetSummary
                  className="betSummary"
                  isCancelable={bet.isCancelable}
                  betTotal={bet.betTotal}
                  selectionTypeCode={bet.wagerType.code}
                  wagerAmount={bet.wagerAmount}
                  selections={bet.selections}
                  raceTypeCode={bet.raceTypeCode}
                  isKey={bet.isKey}
                  isLeg={bet.isLeg}
                  isVertical
                  onDelete={bet.onDelete}
                  isLost={bet.isLost}
                  isRepeatable={bet.isRepeatable}
                  isPromoQualifying={bet.isPromoQualifying}
                  hasRepeatButton={bet.hasRepeatButton}
                  hasRepeaterEnabled={bet.hasRepeaterEnabled}
                  onRepeatClick={bet.onRepeatClick}
                  repetitionsNr={bet.repetitionsNr}
                  isCancelled={bet.isCancelled}
                  isRefunded={bet.isRefunded}
                  totalWinAmount={bet.totalWinAmount}
                  raceNumber={this.props.raceNumber}
                  hasPromosOptedIn={this.props.hasPromosOptedIn}
                />
              </BetListItem>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </BetList>
    );
  }
}
