import styled, { keyframes } from "styled-components";
import type { ToastTypes, ToastVariants } from "./types";
import { toastContentMap } from "./constants";

export const toastAnimationDuration = 300;

const DEFAULT_STYLING_TOP = "98%";

const calculateTop = (offset: number) =>
  `calc(${DEFAULT_STYLING_TOP} - ${offset}px)`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(-50px);
  }
`;

const slideOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(-50px);
  }
  to {
    opacity: 0;
    transform: translateY(0);
  }
`;

const getAnimation = (visible: boolean, hasDismissAnimation: boolean) => {
  if (hasDismissAnimation) return "none";

  return visible
    ? `
        ${slideIn} ${toastAnimationDuration}ms forwards;
      `
    : `
        ${slideOut} ${toastAnimationDuration}ms forwards;
      `;
};

export const Wrapper = styled.div<{
  offset: number;
  hasDismissAnimation: boolean;
}>`
  display: flex;
  position: fixed;
  top: ${({ offset }) => calculateTop(offset)};
  justify-content: center;
  width: 100%;
  z-index: 1100;

  &[data-visible="true"] {
    animation: ${({ hasDismissAnimation }) =>
      getAnimation(true, hasDismissAnimation)};
  }

  &[data-visible="false"] {
    animation: ${({ hasDismissAnimation }) =>
      getAnimation(false, hasDismissAnimation)};
  }
`;

export const ContentWrapper = styled.div<{
  variant: ToastVariants;
  type: ToastTypes;
}>`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  padding: 12px;
  width: 351px;
  border-radius: 4px;
  background: ${({ variant }) => `var(${toastContentMap[variant].background})`};

  ${({ type }) =>
    type === "round" &&
    `
    border-radius: 48px;
    align-items: center;
    justify-content: center;
    width: fit-content;
  `}
`;

export const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  width: calc(100% - 48px);
  margin-right: 12px;
`;

export const ToastCloseButton = styled.div<{ type: ToastTypes }>`
  background: transparent;
  width: 16px;
  height: 100%;
  padding: 0;
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  ${({ type }) => type === "straight" && `align-items: start;`}
`;

export const Link = styled.a<{ color: string }>`
  color: ${({ color }) => `var(${color})`};
  cursor: pointer;
  font-family: "Roboto-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-decoration-line: none;
  display: flex;
  flex-direction: row;
`;

export const LinkWrapper = styled.span`
  display: flex;
  flex-direction: row;
  width: fit-content;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
`;
