import styled, { css } from "styled-components";
import { space } from "styled-system";
import { SaddleProps } from "./types";
import { Paragraph } from "../Typography";

export const StyledSaddle = styled.div<
  Pick<
    SaddleProps,
    | "all"
    | "saddleColor"
    | "fullHeight"
    | "isScratched"
    | "size"
    | "raceFinished"
    | "enableMyBetsBehavior"
    | "isWinner"
  >
>`
  align-items: center;
  background-color: ${({ saddleColor }) => saddleColor};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  position: relative;
  opacity: ${({ isScratched }) => (isScratched ? 0.4 : 1)};
  ${({ size, fullHeight, all }) => {
    if (fullHeight && size !== "s") {
      return css`
        flex-grow: 1;
        height: 100%;
        width: 32px;
      `;
    }
    if (all === true && size === "s") {
      return css`
        width: 44px;
        height: 20px;
      `;
    }

    return css`
      height: ${size === "s" ? "20px" : "44px"};
      width: ${size === "s" ? "20px" : "32px"};
    `;
  }}
  ${space};
  ${({ enableMyBetsBehavior, raceFinished }) =>
    enableMyBetsBehavior && `opacity: ${raceFinished ? 0.2 : 1}`};

  ${({ isWinner }) =>
    isWinner &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 12px;
        left: 12px;
        width: 12px;
        height: 12px;
        z-index: 2;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.438 6.00219C11.438 9.00537 9.00342 11.4399 6.00024 11.4399C2.99706 11.4399 0.5625 9.00537 0.5625 6.00219C0.5625 2.99901 2.99706 0.564453 6.00024 0.564453C9.00342 0.564453 11.438 2.99901 11.438 6.00219Z' fill='%23128000'/%3E%3Cpath d='M3.37527 6.0001L5.25027 7.8751L9.00027 4.1251M11.438 6.00219C11.438 9.00537 9.00342 11.4399 6.00024 11.4399C2.99706 11.4399 0.5625 9.00537 0.5625 6.00219C0.5625 2.99901 2.99706 0.564453 6.00024 0.564453C9.00342 0.564453 11.438 2.99901 11.438 6.00219Z' stroke='white'/%3E%3C/svg%3E ");
      }
    `};
`;

export const Scratcher = styled.div<{ size: string }>`
  position: absolute;
  height: 20px;
  width: 20px;
  border: 2px solid var(--fd-colors-core-grey-d-2);
  overflow: hidden;
  z-index: 2;
`;

export const DiagonalLine = styled.div`
  position: absolute;
  width: 141.42%;
  height: 2px;
  background-color: var(--fd-colors-core-grey-d-2);
  top: 50%;
  left: -22.85%;
  transform: rotate(135deg);
  opacity: 1;
`;

export const StyledParagraph = styled(Paragraph)<Pick<SaddleProps, "size">>`
  font-family: ${({ size }) =>
    size === "s"
      ? `${'"Roboto-Regular", sans-serif'}`
      : `${'"Roboto-Bold", sans-serif'}`};
  font-size: ${({ size }) => (size === "s" ? "12px" : "18px")};
  text-align: center;
  line-height: ${({ size }) => (size === "s" ? "15px" : "22px")};
`;
