import React, { FC } from "react";
import { noop } from "lodash";
import PickBetRebet from "@tvg/atomic-ui/_molecule/PickBetRebet";
import { Icon } from "@tvg/design-system";
import { Button } from "@tvg/design-system/web";

import { Props } from "./types";
import { ActionsWrapper } from "./styled-components";

const WagerActionButtons: FC<Props> = ({
  isWagerCancelable = false,
  onCancelWager = noop,
  shouldShowRebet = false,
  handleRebetClickEvent = noop,
  onRepeatBets = noop,
  showRepeatButton = false,
  rebetWagerTypeName = "",
  isBetActive = true
}) => (
  <ActionsWrapper>
    {showRepeatButton && (
      <Button
        qaLabel="myBets-betTicked-repeat-bet"
        variant="secondary"
        size="m"
        iconPosition="start"
        icon="repeat"
        isStretched
        mb="space-3"
        onClick={onRepeatBets}
      >
        {isBetActive ? "Repeat" : "Re-Bet"}
      </Button>
    )}
    {shouldShowRebet && (
      <PickBetRebet
        handleRebetClickEvent={handleRebetClickEvent}
        isTVG4
        bigLayout
        rebetWagerTypeName={rebetWagerTypeName}
      />
    )}
    {isWagerCancelable && (
      <Button
        qaLabel="myBets-betTicked-cancelButton"
        onClick={onCancelWager}
        variant="tertiaryDestructive"
        size="m"
        isStretched
        mb="space-3"
        iconPosition="start"
        icon={<Icon name="trash" size="s" />}
      >
        Cancel
      </Button>
    )}
  </ActionsWrapper>
);

export default WagerActionButtons;
