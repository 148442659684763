import React, { PureComponent } from "react";
import {
  HeaderRow,
  Position,
  Number,
  Runner,
  WagerType,
  BeatenDistance,
  Odds
} from "./styled-components";

export default class RaceResultsHeader extends PureComponent {
  static defaultProps = {
    hasRunner: true,
    hasWin: true,
    hasPlace: true,
    hasShow: true,
    hasDistance: false,
    hasOdds: false,
    isGreyhound: false
  };

  render() {
    return (
      <thead data-qa-label="raceResultsHeader">
        <HeaderRow>
          <Position>POS</Position>
          <Number>#</Number>
          <Runner data-qa-label="raceResultsHeader-runner">
            {this.props.hasRunner && (this.props.isGreyhound ? "DOG" : "HORSE")}
          </Runner>
          {this.props.hasWin && (
            <WagerType data-qa-label="raceResultsHeader-betType">WIN</WagerType>
          )}
          {this.props.hasPlace && (
            <WagerType data-qa-label="raceResultsHeader-betType">
              PLACE
            </WagerType>
          )}
          {this.props.hasShow && (
            <WagerType data-qa-label="raceResultsHeader-betType">
              SHOW
            </WagerType>
          )}
          {this.props.hasDistance && (
            <BeatenDistance data-qa-label="raceResultsHeader-beatenDistance">
              Beaten Dist.
            </BeatenDistance>
          )}
          {this.props.hasOdds && (
            <Odds data-qa-label="raceResultsHeader-Odds">Odds</Odds>
          )}
        </HeaderRow>
      </thead>
    );
  }
}
