/* eslint-disable no-console */
import type { Dispatch } from "redux";
import { get } from "lodash";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import { BinaryFn, NullaryFn, UnaryFn } from "@tvg/ts-types/Functional";
import type { GeolocationData } from "@tvg/sh-geolocation/src/types";
import { isMobile, isXSell } from "@tvg/sh-utils/mobileUtils";
import {
  createAuthTokenCookie,
  createWrapperAppConfigCookie,
  getAuthTokenCookie
} from "@tvg/sh-utils/sessionUtils";
import { isValidSessionByTokenXSell } from "@tvg/sh-utils/isValidSessionByToken";
import {
  EventConfigType,
  EventEmitterInterface,
  AuthDataXSell,
  LoginCompleteDataType,
  Props,
  EventType,
  Event
} from "../types";
import getAWPageTitle, { AWPath, awPathAction } from "./AWPages";
import { clearSession } from "../session/logout";
import { GetSessionHint, IAWFlows } from "../sdkWebTypes";
import { xSellConfig, hybridConfig, webConfig } from "../config";

// Utility

export const getEnvConfig = (
  type: EventType,
  event: Event
): EventConfigType | undefined => {
  let baseConfig = webConfig;

  if (isMobile()) {
    baseConfig = isXSell() ? xSellConfig : hybridConfig;
  }

  return get(baseConfig, [type, event]);
};

export const getMediatorChannel = (): EventEmitterInterface =>
  !isMobile() ? mediator.base : mediator.ios;

export const mediatorDispatch = (
  eventName: string,
  data?: Record<string, unknown>
) => {
  const mediatorChannel = getMediatorChannel();

  if (data) {
    mediatorChannel.dispatch({
      type: eventName,
      payload: { ...data }
    });
  } else {
    mediatorChannel.dispatch({ type: eventName });
  }
};

export const mediatorSubscribe = <T>(
  eventName: string,
  callback: UnaryFn<T, void>
) => {
  const mediatorChannel = getMediatorChannel();
  mediatorChannel.subscribe(eventName, callback);
};

export const setAWPostFlowRedirect = (pageUrl: string) =>
  createWrapperAppConfigCookie(JSON.stringify({ postFlowRedirect: pageUrl }));

export const mapperLoginCompletePayload = (data: LoginCompleteDataType) => {
  const mappedData: {
    authToken?: string;
    geolocationData?: GeolocationData;
  } = {
    authToken: get(data, "payload.authToken") || get(data, "payload.token"),
    geolocationData: get(data, "payload.geolocationData")
  };
  return mappedData;
};

// Triggers

export const onTriggerEvent = async (
  event: Event,
  awFlowsSDK?: IAWFlows,
  sessionHint?: GetSessionHint
) => {
  const eventConfig = getEnvConfig(EventType.Trigger, event);

  if (!eventConfig) {
    console.warn(`${event} Event not available`);
    return;
  }

  const { eventName, exceptionEventName } = eventConfig;

  if (awFlowsSDK) {
    try {
      const { authToken: token } = await awFlowsSDK.getSession(sessionHint);
      mediatorDispatch(eventName, { token });
    } catch (error) {
      if (exceptionEventName) {
        mediatorDispatch(exceptionEventName, { message: error });
      }
    }
  } else {
    mediatorDispatch(eventName);
  }
};

export const onTriggerLogin = (awFlowsSDK?: IAWFlows) => {
  onTriggerEvent(Event.Login, awFlowsSDK, "ForceNew");
};

export const onTriggerJoin = (awFlowsSDK?: IAWFlows) => {
  onTriggerEvent(Event.Join, awFlowsSDK, "NewUser");
};

export const onRefreshSession = (awFlowsSDK?: IAWFlows) =>
  onTriggerEvent(Event.OnRefreshSession, awFlowsSDK);

export const onTriggerLogout = (awFlowsSDK?: IAWFlows) => {
  const eventConfig = getEnvConfig(EventType.Trigger, Event.Logout);

  if (!eventConfig) {
    console.warn("onTriggerLogout not available");
    return;
  }

  const { eventName, exceptionEventName } = eventConfig;

  if (awFlowsSDK) {
    try {
      awFlowsSDK.clearSession();
      mediatorDispatch(eventName);
    } catch (error) {
      if (exceptionEventName) {
        mediatorDispatch(exceptionEventName, { message: error });
      }
    }
  } else {
    // TODO: maybe remove the workaround when Xsell is fixed
    // This is only to XSell since xSell is not allow to manipulate the user session
    if (isXSell() && isValidSessionByTokenXSell()) {
      console.warn(
        "onTriggerLogout skipped because the session still valid in xSell"
      );
      return;
    }

    mediatorDispatch(eventName);
  }
};

export const triggerRefreshSession = () =>
  mediator.base.dispatch({
    type: "TRIGGER_REFRESH_SESSION"
  });

export const onTriggerComplianceModal = (isVerified: boolean) => {
  if (typeof isVerified !== "undefined") {
    const configName = isVerified ? Event.AcceptTerms : Event.Verification;
    const eventConfig = getEnvConfig(EventType.Trigger, configName);

    if (!eventConfig) {
      console.warn("onTriggerComplianceModal not available");
      return;
    }
    const { eventName } = eventConfig;
    mediatorDispatch(eventName);
  }
};

export const onTriggerAccountSummary = (destinationUrl?: string) => {
  const eventConfig = getEnvConfig(EventType.Trigger, Event.AccountSummary);

  if (!eventConfig) {
    console.warn("onTriggerAccountSummary not available");
    return;
  }

  const { eventName } = eventConfig;

  if (isXSell() || typeof destinationUrl === "undefined") {
    mediatorDispatch(eventName);
  } else {
    mediatorDispatch(eventName, { destinationUrl });
  }
};

export const onTriggerDeposit = () => {
  const eventConfig = getEnvConfig(EventType.Trigger, Event.Deposit);

  if (!eventConfig) {
    console.warn("onTriggerDeposit not available");
    return;
  }
  const { eventName } = eventConfig;

  if (isMobile()) {
    mediatorDispatch(eventName);
  } else {
    mediatorDispatch(eventName, { path: "/account/deposit" });
  }
};

// TODO remove logic being used with this method (onOpenComplianceModal) inside atomic UI
export const onOpenComplianceModal = () =>
  mediator.base.dispatch({
    type: "OPEN_AW_COMPLIANCE_MODAL"
  });

export const onFirstLoadComplete = () => {
  const eventConfig = getEnvConfig(EventType.Trigger, Event.FirstLoadComplete);

  if (!eventConfig) {
    console.warn("onFirstLoadComplete not available");
    return;
  }

  const { eventName } = eventConfig;
  mediatorDispatch(eventName);
};

// Subscriptions

export const subscribeEvent = (
  event: Event,
  callback: UnaryFn<string, void>
) => {
  const eventConfig = getEnvConfig(EventType.Subscribe, event);

  if (!eventConfig) {
    console.warn(`${event} Event not available`);
    return;
  }

  const { eventName } = eventConfig;

  mediatorSubscribe(eventName, callback);
};

export const subscribeRefreshSession = (callback: UnaryFn<string, void>) => {
  const eventConfig = getEnvConfig(EventType.Subscribe, Event.RefreshSession);

  if (eventConfig) {
    const { eventName } = eventConfig;

    mediatorSubscribe(eventName, (data) => {
      callback(get(data, "payload.token"));
    });
  } else {
    console.warn("subscribeRefreshSession not available");
  }
};

export const subscribeLoginComplete = (callback: UnaryFn<string, void>) => {
  const eventConfig = getEnvConfig(EventType.Subscribe, Event.LoginComplete);
  if (!eventConfig) {
    console.warn("subscribeLoginComplete not available");
    return;
  }

  const { eventName, exceptionEventName } = eventConfig;

  mediatorSubscribe(eventName, (data: LoginCompleteDataType) => {
    if (get(data, "error", false)) {
      if (exceptionEventName) {
        mediatorDispatch(exceptionEventName, {
          message: get(data, "payload.message")
        });
      } else {
        console.warn(`${exceptionEventName} not available`);
      }
    } else {
      const { authToken } = mapperLoginCompletePayload(data);

      if (authToken) {
        createAuthTokenCookie(authToken);
        callback(authToken);
      }
    }
  });
};

export const subscribeLoginCancelled = (callback: NullaryFn<void>) => {
  subscribeEvent(Event.LoginCancelled, callback);
};

export const subscribeLogoutComplete = (callback: NullaryFn<void>) => {
  subscribeEvent(Event.LogoutComplete, callback);
};

export const subscribeAccountAndWalletClosed = (callback: NullaryFn<void>) => {
  subscribeEvent(Event.AccountAndWalletClosed, callback);
};

export const subscribeAuthDetailsUpdated = (
  callback: BinaryFn<string, Props, void>,
  getActualProps: NullaryFn<Props>
) => {
  const eventConfig = getEnvConfig(
    EventType.Subscribe,
    Event.AuthDetailsUpdated
  );

  if (!eventConfig) {
    console.warn("subscribeAuthDetailsUpdated not available");
    return;
  }

  const { eventName } = eventConfig;

  mediatorSubscribe(eventName, (data: { payload: AuthDataXSell }) => {
    const sessionProps = getActualProps();
    const { authToken } = data.payload;

    if (authToken) {
      createAuthTokenCookie(authToken);
      callback(authToken, sessionProps);
    } else if (getAuthTokenCookie()) {
      clearSession(sessionProps);
    }
  });
};

export const subscribeOpenAWPage = (
  getActualProps: NullaryFn<Props>,
  callback: BinaryFn<Dispatch, { title: string; path: string }, void>
) => {
  const eventConfig = getEnvConfig(EventType.Subscribe, Event.OpenAwPage);

  if (!eventConfig) {
    console.warn("subscribeOpenAWPage not available");
    return;
  }

  const { eventName } = eventConfig;

  mediator.base.subscribe(
    eventName,
    (data: { payload: { path: string; forceWeb?: boolean } }) => {
      const { path, forceWeb } = data.payload;

      if (!isMobile() || forceWeb) {
        const { dispatch } = getActualProps();
        const title = getAWPageTitle(path as AWPath);
        callback(dispatch, { title, path });
      } else {
        const triggerAction =
          awPathAction[path as AWPath] || awPathAction[AWPath.Account];
        if (triggerAction) {
          mediator.ios.dispatch({
            type: triggerAction
          });
        }
      }
    }
  );
};

export const subscribeOpenAWComplianceModal = (
  getActualProps: NullaryFn<Props>,
  callback: BinaryFn<Dispatch, { title: string; path: string }, void>
) => {
  mediator.base.subscribe("OPEN_AW_COMPLIANCE_MODAL", () => {
    const {
      dispatch,
      user: { isVerified, isTermsAccepted }
    } = getActualProps();

    if (isVerified && isTermsAccepted) {
      return;
    }

    if (isMobile()) {
      onTriggerComplianceModal(isVerified);
    } else {
      const path = !isVerified ? AWPath.Verification : AWPath.AcceptTerms;
      const title = getAWPageTitle(path);
      setAWPostFlowRedirect("/account");
      callback(dispatch, { title, path });
    }
  });
};

export const isFdrDesk =
  tvgConf().product === "tvg5" && tvgConf().brand === "fdr";
