import styled, { css, keyframes } from "styled-components";
import { get } from "lodash";

import {
  fontMedium,
  fontNormal,
  fontBold,
  fontCondensedNormal,
  defaultSize
} from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { iconStyles } from "./constant";

const textStyling = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
`;

const getIconStyle = (raceStatus, element) =>
  element
    ? get(iconStyles, `${raceStatus}.${element}`)
    : get(iconStyles, `${raceStatus}`);

/* Use z-index on runnerNumber when (ex: winner) to surpass these styles */
const MutedStyles = (color = "white", variation = "80") => css`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${buildColor(color, variation)};
    z-index: 1;
  }
`;

export const RunnerNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`;

export const RunnerNumberGroupMolecule = styled.ol`
  list-style-type: none;
  display: flex;
  width: 100%;
  ${({ isBetsDetailedView }) =>
    isBetsDetailedView
      ? css`
          flex-direction: column;
        `
      : css`
          align-items: center;
          flex-wrap: wrap;
        `}

  /* surpass muted styles in detailed view on these bet status */
  ${({ isBetsDetailedView, betStatusName, raceStatus }) =>
    isBetsDetailedView &&
    raceStatus !== "unknown" &&
    ["winner", "canceled", "loser", "refunded"].includes(betStatusName) &&
    css`
      z-index: 2;

      /* Text color for all bets status but not active */
      .runner-name {
        color: ${buildColor("grey", "700")};
      }
    `}

  ${({ raceStatus }) =>
    raceStatus === "unknown" &&
    css`
      z-index: 2;
    `}
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    ${({ isBetsDetailedView }) =>
      isBetsDetailedView
        ? css`
            margin-top: 2px;
          `
        : css`
            margin-left: 4px;
          `}
  }
`;

export const RunnerNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 8px;
  ${textStyling}

  /* Text styles for winner runners */
  ${({ isWinnerBI, isUnknownWinnerStatus }) =>
    isWinnerBI &&
    !isUnknownWinnerStatus &&
    css`
      & > .runner-name {
        font-family: ${fontMedium};
        color: ${buildColor("grey", "900")};
      }
    `}

  ${({ isWinnerBI, isUnknownWinnerStatus, isActiveMultiRaceLegOver }) =>
    !isWinnerBI &&
    !isUnknownWinnerStatus &&
    isActiveMultiRaceLegOver &&
    css`
      & > .runner-name {
        color: ${buildColor("grey", "700")};
      }
    `}
`;

export const RunnerName = styled.span`
  font-family: ${fontNormal};
  font-size: 14px;
  line-height: 18px;
  ${textStyling};
  color: ${({ isScratched }) =>
    buildColor("grey", isScratched ? "700" : "900")};
  ${({ isScratched }) =>
    isScratched &&
    css`
      text-decoration: line-through;
    `}
`;

export const DetailedViewInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
`;

export const Tag = styled.span`
  display: flex;
  margin-left: 6px;
`;

export const RaceOdds = styled.span`
  font-family: ${fontBold};
  font-size: 14px;
  line-height: 18px;
`;

export const WillPaysInfoContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 8px;
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      margin-left: 2px;
      margin-top: 4px;
    `}
`;

export const WillPaysInfoDesktopText = styled.span`
  font-family: ${fontNormal};
  font-size: 12px;
  color: ${buildColor("blue_accent", "700")};
`;

export const WillPays = styled.span`
  font-family: ${fontMedium};
  font-size: ${defaultSize};
  line-height: 18px;
  text-align: right;
  font-weight: 500;
  color: ${buildColor("grey", "900")};
  flex: 1;
  display: flex;
  align-self: center;
  justify-content: flex-end;
  ${({ hasMargin }) =>
    hasMargin &&
    `
    &:not(:last-child) {
      flex-grow: 2;
    }
  `}
`;

export const WillPaysLabel = styled.span`
  position: absolute;
  top: 6px;
  right: 26px;
  font-family: ${fontCondensedNormal};
  font-size: 12px;
  color: ${buildColor("grey", "800")};
`;

export const PickBetContainer = styled.div`
  position: relative;

  &:not(:first-child) {
    margin-top: 2px;
    ${({ showUpperHorizontalRule }) =>
      showUpperHorizontalRule &&
      css`
        margin-top: 15px;
      `};
  }

  &:not(:last-child) {
    ${({ isBetsDetailedView, isDesktop, showHorizontalRule }) => {
      if (isBetsDetailedView) {
        if (isDesktop) {
          if (!showHorizontalRule) {
            return "";
          }
          return css`
            padding-bottom: 13px;
          `;
        }

        return css`
          padding-bottom: 6px;
        `;
      }
      if (!isDesktop) {
        return css`
          padding-bottom: 8px;
        `;
      }
      return css``;
    }};
    ${({ isCanceled, isDesktop }) =>
      !isCanceled && !isDesktop
        ? css`
            &::after {
              content: "";
              width: 2px;
              height: calc(100% - 18px);
              background: ${({ raceStatus }) =>
                getIconStyle(raceStatus, "trackingColor")};
              border-radius: 1px;
              position: absolute;
              top: 20px;
              left: 9px;
              z-index: 2;
            }
          `
        : ""}
  }
`;

export const PickBetsWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const PickBetLabelIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ isDesktop, raceStatus }) =>
    isDesktop && (raceStatus === "open" || raceStatus === "raceOff")
      ? "right"
      : "center"};
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  margin-right: ${({ isDesktop }) => (isDesktop ? "6px" : "8px")};
  position: relative;
  z-index: 2;
`;

export const PickBetLabelRunnersWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  ${({ detailExpanded }) =>
    detailExpanded &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}
`;

export const PickBetHrContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  padding: ${({ isDesktop }) => (isDesktop ? "0 2px 0 26px" : "0 2px 0 28px")};
  z-index: 3;
  ${({ isTopHr, isDesktop }) => {
    if (isTopHr) {
      if (isDesktop) {
        return css`
          top: -9px;
        `;
      }

      return css`
        top: -6px;
      `;
    }

    if (isDesktop) {
      return css`
        bottom: 6px;
      `;
    }

    return css`
      bottom: 3px;
    `;
  }}
`;

export const ExoticBetContainer = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: ${({ isDesktop }) => (isDesktop ? "2px" : "10px")};
  }
`;

export const LabelItem = styled.div`
  height: 20px;
  margin-left: ${({ isDesktop }) => (isDesktop ? "0" : "2px")};
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  ${({ pickBetsDetailExpanded }) =>
    pickBetsDetailExpanded &&
    css`
      margin-bottom: 6px;
    `}
  ${({ isPickBets, pickBetsLabelLength, isDesktop }) =>
    isPickBets
      ? css`
          margin-right: 8px;
          justify-content: flex-start;
          ${pickBetsLabelLength === 11 &&
          css`
            min-width: 64px;
          `}
          ${pickBetsLabelLength === 12 &&
          css`
            min-width: 71px;
          `}
        `
      : css`
          margin-right: ${isDesktop ? "6px" : "14px"};
          justify-content: ${isDesktop ? "flex-start" : "flex-end"};
          min-width: ${isDesktop ? "20px" : "24px"};
        `}
`;

export const TextLabel = styled.div`
  font-family: ${({ isPickBets }) =>
    isPickBets ? fontCondensedNormal : fontNormal};
  font-size: ${({ isDesktop }) => (isDesktop ? "12px" : "14px")};
  line-height: 18px;
  color: ${({ raceStatus }) =>
    raceStatus
      ? getIconStyle(raceStatus, "textColor")
      : buildColor("grey", "800")};
`;

export const HorizontalRule = styled.hr.attrs({
  "data-qa-label": "myBets-horizontalRule"
})`
  height: 1px;
  background-color: ${buildColor("blue", "100")};
  width: calc(100% - 4px);
  border: none;
  position: relative;
  left: 2px;
  z-index: 2;
  margin: ${({ isPickBets, isDesktop }) => {
    if (isPickBets) {
      return "0";
    }
    if (isDesktop) {
      return "6px 0 6px";
    }
    return "10px 0 0";
  }};
  animation: ${fadeIn} 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;

export const LabelIconWrapper = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  ${({ raceStatus, isDesktop }) => {
    if (
      (["open", "raceOff"].includes(raceStatus) && !isDesktop) ||
      (["unknown"].includes(raceStatus) && isDesktop)
    ) {
      return css`
        border: 2px solid ${getIconStyle(raceStatus, "border")};
        background: ${getIconStyle(raceStatus, "background")};
        border-radius: 50%;
      `;
    }

    return "";
  }}
`;

export const LabelDot = styled.div`
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  top: calc(50% - 2px);
  left: calc(50% - 2px);
  background: ${({ raceStatus }) => getIconStyle(raceStatus, "dotColor")};
  border-radius: 50%;
`;

export const LabelIcon = styled(Icon)`
  ${({ raceStatus }) =>
    ["open", "raceOff"].includes(raceStatus) &&
    css`
      position: absolute;
      top: calc(50% - 5px);
      left: calc(50% - 5px);
      border-radius: 50%;
    `}
`;

export const LabelText = styled.span`
  font-family: ${({ boldText }) => (boldText ? fontBold : fontNormal)};
  font-size: 12px;
  align-self: flex-end;
  color: ${({ boldText }) =>
    boldText ? buildColor("grey", "900") : buildColor("grey", "800")};
`;

export const FavArrowIcon = styled(Icon)`
  margin: 0 4px;
  z-index: 4;
`;

export const PickBetsExpandButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 24px;
  outline: none;
  background-color: transparent;
  border-radius: 50%;
  position: relative;
  z-index: 4;
  left: 2px;

  &::before {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    height: 32px;
    width: 32px;
    background-color: transparent;
    border-radius: 50%;
    z-index: -1;
  }

  &:active {
    &::before {
      background-color: ${buildColor("grey", "200")};
    }

    svg {
      stroke: ${buildColor("grey", "900")};
    }
  }
`;

export const LegScratchNotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ isDesktop }) => (isDesktop ? "2px" : "-6px")};
  margin-bottom: ${({ isDesktop }) => (isDesktop ? "0" : "8px")};
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      margin-left: 2px;
    `}
`;

export const LegScratchNotification = styled.span`
  font-family: ${fontMedium};
  font-size: 12px;
  line-height: 17px;
  color: ${buildColor("orange", "700")};
`;

export const RunnerInfoContainer = styled.span`
  display: flex;
  align-items: center;
  ${({ isBetsDetailedView, cols }) =>
    isBetsDetailedView &&
    cols &&
    css`
      width: ${cols > 2 ? "105px" : "210px"};
    `}
  ${({ isBetsDetailedView, cols, isDesktop }) =>
    isBetsDetailedView &&
    cols &&
    isDesktop &&
    css`
      width: 105px;
    `}

  ${({ isBetsDetailedView, betStatusName, isDesktop }) =>
    !isBetsDetailedView &&
    isDesktop &&
    ["winner", "canceled", "loser", "refunded"].includes(betStatusName) &&
    MutedStyles()}
`;

export const PayoutHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  ::before {
    content: "";
    ${({ cols }) =>
      cols &&
      css`
        width: ${cols > 2 ? "105px" : "193px"};
      `};
    ${({ isDesktop }) =>
      isDesktop &&
      css`
        width: 105px;
      `};
  }
`;

export const PayoutHeaderItem = styled.span`
  text-align: right;
  text-transform: uppercase;
  font-family: ${fontCondensedNormal};
  font-size: 12px;
  font-weight: 400;
  color: ${buildColor("grey", "900")};
  flex: 1;
  ${({ hasTwoColumns }) =>
    hasTwoColumns &&
    `
    &:not(:last-child) {
      flex-grow: 2;
    }
  `}
`;

export const PayoutSeparator = styled.hr`
  width: calc(100% + 20px);
  margin-left: -10px;
  border: 0;
  border-bottom: 1px solid ${buildColor("blue", "100")};
`;

export const ListItemWrapper = styled.ul`
  display: flex;
  flex-direction: ${({ isBetsDetailedView }) =>
    isBetsDetailedView ? "column" : "row"};
  flex: 1;
`;

export const SelectionList = styled.div`
  display: flex;
  margin: 0 2px 2px;
  ${({ isBetsDetailedView, isDesktop }) =>
    isBetsDetailedView &&
    css`
      &:not(:only-child, :last-child) {
        margin-bottom: ${isDesktop ? "4px" : "12px"};
      }
    `}
`;

export const DetailAction = styled.div`
  position: absolute;
  width: 100%;
  height: 20px;
  z-index: 3;
`;
