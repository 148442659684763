import React, { useEffect, useRef, useState } from "react";

import { noop } from "lodash";
import { processURL } from "@tvg/formatter/url";
import { useDispatch } from "react-redux";
import { dismissContentCard } from "@tvg/braze";
import SwipeButton, { getTouchEvents } from "../SwipeButton";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { trash } from "../../_static/Icons/icons";
import { announcements } from "../../_static/Icons/iconsDuotone";
import {
  Card,
  CardContainer,
  Content,
  ImageContainer,
  Image,
  Title,
  Description,
  Link,
  Pinned,
  DismissDesktop,
  CardLink,
  CardLinkContainer
} from "./styled-components";

const ContentCard = (props) => {
  const {
    card,
    showDismiss,
    device,
    onClickContentCard,
    setIdToDismiss,
    onCardLoaded,
    onDismissCard,
    urlType
  } = props;

  const {
    id,
    title,
    description,
    imageUrl,
    linkText,
    url,
    dismissible,
    pinned,
    viewed,
    sc
  } = card;

  const [cardLoaded, setCardLoaded] = useState(!imageUrl);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isViewed, setIsViewed] = useState(viewed);
  const cardContRef = useRef(null);
  const cardRef = useRef(null);
  const threshold = 30;
  const dismissBtnWidth = 59;
  const dispatch = useDispatch();

  const handleEvents = getTouchEvents(
    id,
    cardContRef,
    dismissible,
    setIdToDismiss,
    dismissBtnWidth,
    threshold,
    !!showDismiss
  );

  useEffect(() => {
    if (cardLoaded && cardRef.current) {
      onCardLoaded(cardRef.current);
    }
  }, [cardLoaded]);

  const dismissCard = () => {
    setIsCollapsed(true);
    onDismissCard();
    dismissContentCard(card, dispatch);
  };

  const callbackSwipe = (event) => {
    event.stopPropagation();
    dismissCard();
  };

  const renderDismissDesktop = () => (
    <DismissDesktop
      onClick={(e) => {
        e.stopPropagation();
        dismissCard();
      }}
    >
      <Icon icon={trash} size={16} color={buildColor("red", "500")} />
    </DismissDesktop>
  );

  const renderCardContent = () => {
    return (
      <React.Fragment>
        <CardContainer
          {...(showDismiss ? { style: { transform: "translate(-59px)" } } : {})}
          ref={cardContRef}
          onClick={(e) => {
            if (url) {
              setIsViewed(true);
              e.stopPropagation();
              onClickContentCard();
            }
          }}
        >
          {imageUrl && (
            <ImageContainer>
              <Image src={imageUrl} onLoad={(evt) => setCardLoaded(!!evt)} />
            </ImageContainer>
          )}

          {sc !== "ab-image-only" && (
            <Content>
              <Title viewed={isViewed}>{title}</Title>
              <Description>{description}</Description>
              {!!url && <Link>{linkText}</Link>}
            </Content>
          )}

          {device === "desktop" &&
            sc === "ab-image-only" &&
            renderDismissDesktop()}

          {pinned && (
            <Pinned>
              <Icon
                icon={announcements}
                size={16}
                viewBoxSize={24}
                color="none"
                strokeColorList={[
                  buildColor(
                    "blue_accent",
                    sc === "ab-classic-card" ? "500" : "300"
                  )
                ]}
              />
            </Pinned>
          )}
        </CardContainer>
        {dismissible && device === "mobile" && (
          <SwipeButton
            dismissBtnWidth={dismissBtnWidth}
            dismissIconColor={buildColor("white", "100")}
            dismissIconSize={16}
            iconBtn={trash}
            callbackClick={callbackSwipe}
          />
        )}
      </React.Fragment>
    );
  };

  return device === "desktop" ? (
    <CardLinkContainer pinned={pinned}>
      <CardLink
        isDesktop
        viewed={isViewed}
        type={sc}
        id={id}
        isCollapsed={isCollapsed}
        hasUrl={!!url}
        ref={cardRef}
        {...(url
          ? {
              href: processURL(url)
            }
          : {})}
        {...(urlType === "isExternal" ? { target: "_blank" } : {})}
      >
        {renderCardContent()}
      </CardLink>
      {renderDismissDesktop()}
    </CardLinkContainer>
  ) : (
    <Card
      viewed={isViewed}
      type={sc}
      {...(!pinned ? handleEvents : {})}
      id={id}
      isCollapsed={isCollapsed}
      hasUrl={!!url}
      ref={cardRef}
    >
      {renderCardContent()}
    </Card>
  );
};

ContentCard.defaultProps = {
  card: {
    id: "",
    title: "",
    description: "",
    imageUrl: "",
    linkText: "",
    url: "",
    clicked: false,
    dismissed: false,
    dismissible: false,
    pinned: false,
    viewed: false,
    sc: ""
  },
  showDismiss: false,
  device: "mobile",
  setIdToDismiss: () => {},
  setRef: noop,
  urlType: ""
};

export default ContentCard;
