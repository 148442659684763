import React from "react";
import { IconPathWithBackgroundProps } from "../../types";

export const Pin = ({
  backgroundColor,
  lineColor,
  strokeWidth
}: IconPathWithBackgroundProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M9 0C12.3137 0 15 2.66867 15 5.96065C15 7.1848 14.6285 8.32277 13.9915 
    9.26937L9.39371 16.7796C9.26127 16.996 8.97736 17.0648 8.75956 
    16.9332C8.6965 16.8951 8.64355 16.8425 8.60516 16.7799L3.94032 
    9.17023C3.34643 8.24464 3 7.14284 3 5.96065C3 2.66867 5.68629 0 9 0ZM9 
    0.917023C6.19609 0.917023 3.92308 3.17513 3.92308 5.96065C3.92308 6.84849 
    4.15368 7.70185 4.59125 8.46656L8.99908 15.6591L13.2241 8.75969C13.7313 
    8.00606 14.0252 7.13229 14.0707 6.21257L14.0769 5.96065C14.0769 3.17513 
    11.8039 0.917023 9 0.917023ZM9 2.75107C10.7843 2.75107 12.2308 4.18805 
    12.2308 5.96065C12.2308 7.73325 10.7843 9.17023 9 9.17023C7.2157 9.17023 
    5.76923 7.73325 5.76923 5.96065C5.76923 4.18805 7.2157 2.75107 9 2.75107ZM9 
    3.66809C7.7255 3.66809 6.69231 4.69451 6.69231 5.96065C6.69231 7.22679 
    7.7255 8.25321 9 8.25321C10.2745 8.25321 11.3077 7.22679 11.3077 
    5.96065C11.3077 4.69451 10.2745 3.66809 9 3.66809Z"
    stroke={lineColor}
    strokeWidth={strokeWidth}
    strokeLinejoin="round"
    fill={backgroundColor}
  />
);
