import React from "react";
import { Paragraph, Header, InputText, IconButton } from "@tvg/design-system";
import { Button } from "@tvg/design-system/web";
import DynamicRefereeFields from "@tvg/atomic-ui/_organism/DynamicRefereeFields";
import {
  EmailReferralContainer,
  HeaderWrapper,
  ContentWrapper
} from "./styled-components";
import type { Props } from "./types";

const EmailReferral = ({
  qaLabel = "emailReferral",
  maxInvites,
  updatedReferredBy,
  defaultReferredBy,
  description,
  termsAndConditions,
  refereeFields,
  isSendInvitesEnable,
  isHeaderVisible,
  isLoading,
  isAddFieldEnable,
  onEmailCompletion,
  onReferredByCompletion,
  onNavigationBackClick,
  onChangeReferredBy,
  onAddEmailField,
  onRemoveEmailField,
  onUpdateEmailField,
  onSendInvitesClick
}: Props) => (
  <EmailReferralContainer data-qa-label={qaLabel}>
    {isHeaderVisible && (
      <HeaderWrapper>
        {!isLoading && onNavigationBackClick && (
          <IconButton
            qaLabel={`${qaLabel}BackButton`}
            variant="tertiary"
            iconName="arrowLeft"
            tertiaryColor="white.900"
            onPress={() => onNavigationBackClick()}
          />
        )}
        <Header
          qaLabel={`${qaLabel}HeaderTitle`}
          tag="h1"
          color="white.900"
          fontFamily="bold"
        >
          Email Referral
        </Header>
      </HeaderWrapper>
    )}
    <ContentWrapper>
      <Header
        qaLabel={`${qaLabel}InviteFriendsHeader`}
        tag="h2"
        fontFamily="bold"
        color="grey.900"
        marginBottom="space-3"
      >
        {`Invite up to ${maxInvites} friends`}
      </Header>
      <Paragraph
        qaLabel={`${qaLabel}InviteFriendsDescription`}
        color="grey.800"
      >
        {description}
      </Paragraph>
      <DynamicRefereeFields
        isAddFieldEnable={isAddFieldEnable}
        isEditFieldEnable={!isLoading}
        refereeFields={refereeFields}
        onAddField={onAddEmailField}
        onRemoveField={onRemoveEmailField}
        onUpdateField={onUpdateEmailField}
        onFieldBlur={onEmailCompletion}
      />
      <InputText
        qaLabel={`${qaLabel}ReferredBy`}
        label="Referred by"
        messageBellow="This name will appear on your invites"
        value={updatedReferredBy}
        placeholder={defaultReferredBy}
        isDisabled={isLoading}
        onChangeText={onChangeReferredBy}
        onBlur={onReferredByCompletion}
      />
      <Button
        qaLabel={`${qaLabel}SendInvites`}
        variant="primary"
        width={1}
        marginTop="space-8"
        marginBottom="space-4"
        onClick={onSendInvitesClick}
        isDisabled={!isSendInvitesEnable}
        isLoading={isLoading}
      >
        Send Invites
      </Button>
      <Paragraph
        qaLabel={`${qaLabel}TermsAndConditions`}
        color="grey.600"
        fontSize="xs"
      >
        {termsAndConditions}
      </Paragraph>
    </ContentWrapper>
  </EmailReferralContainer>
);

export default EmailReferral;
