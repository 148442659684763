import React, { useState } from "react";
import { has, noop } from "lodash";
import type {
  NativeSyntheticEvent,
  TextInputFocusEventData
} from "react-native";
import type { RefereeEmailField } from "@tvg/ts-types/ReferFriend";
import { Button } from "@tvg/design-system/web";
import RefereeInputEmail from "@tvg/atomic-ui/_molecule/RefereeInputEmail";
import { DynamicRefereeFieldsContainer } from "./styled-components";
import type { Props } from "./types";

const DynamicRefereeFields = ({
  qaLabel = "dynamicRefereeFields",
  refereeFields = [],
  isAddFieldEnable = false,
  isEditFieldEnable = false,
  onAddField = noop,
  onRemoveField = noop,
  onUpdateField = noop,
  onFieldBlur = noop
}: Props) => {
  const [currentFocus, setCurrentFocus] = useState("");
  const onFocusHandler = (
    e: NativeSyntheticEvent<TextInputFocusEventData>,
    fieldId: string
  ) => {
    if (has(e.target, "isFocused")) {
      setCurrentFocus(fieldId);
    }
  };
  const onBlurHandler = (
    e: NativeSyntheticEvent<TextInputFocusEventData>,
    field: RefereeEmailField
  ) => {
    if (typeof onFieldBlur === "function") {
      onFieldBlur(field);
    }
    if (has(e.target, "isFocused")) {
      setCurrentFocus("");
    }
  };
  return (
    <DynamicRefereeFieldsContainer data-qa-label={qaLabel}>
      {refereeFields.map((field, index) => (
        <RefereeInputEmail
          key={field.id}
          qaLabel={`${qaLabel}${field.id}InputEmail`}
          value={field.value}
          label={index === 0 ? "Enter your friends' email(s)" : undefined}
          placeholder="Enter email address"
          warningMessage={field.warningMessage}
          isInvalid={field.isInvalid}
          isDisabled={!isEditFieldEnable}
          isCloseButtonVisible={
            refereeFields.length > 1 &&
            currentFocus !== field.id &&
            !!isEditFieldEnable
          }
          onCloseClick={() => onRemoveField(index)}
          onChangeText={(value: string) => onUpdateField({ ...field, value })}
          onFocus={(e) => onFocusHandler(e, field.id)}
          onBlur={(e) => onBlurHandler(e, field)}
        />
      ))}
      <Button
        qaLabel={`${qaLabel}AddInputEmail`}
        variant="tertiary"
        icon="plus"
        width={1}
        marginTop="space-3"
        onClick={() => onAddField()}
        isDisabled={!isAddFieldEnable}
      >
        Add another email
      </Button>
    </DynamicRefereeFieldsContainer>
  );
};

export default DynamicRefereeFields;
