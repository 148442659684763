import React, { useCallback } from "react";
import { noop, get } from "lodash";
import { AlertInline } from "@tvg/design-system";

import MyBetsWagerHeader from "../../_molecule/MyBetsWagerHeader";
import MyBetsWagerFooter from "../../_molecule/MyBetsWagerFooter";
import PickBetRebet from "../../_molecule/PickBetRebet";
import { infoOutline } from "../../_static/Icons/iconsDuotone";
import buildColor from "../../_static/ColorPalette";
import BetNotificationScratch from "../../_molecule/BetNotification";
import OptedInTag from "../../_atom/OptedInTag";
import { BetCard, BetCardBody, AlertInlineAction } from "./styled-components";

const MyBetsWager = ({
  wager,
  probableValue,
  raceUrl,
  closeModal,
  onApproxPayout,
  showTrackRules,
  onHideShowLegsGTM = noop,
  showContent,
  setShowContent = noop,
  betCardRef,
  betCardBodyRef,
  isOptedIn,
  betStatus,
  isCanceled,
  showBetsDetailedView,
  betRefund,
  isBetWinner,
  shouldShowHideLegButton,
  shouldShowPotentialReturn,
  hasFooter,
  shouldShowRebet,
  isDetailsOpened,
  handleDetailsButton,
  shouldRenderFooter,
  isBetActive,
  isWagerCancelable,
  repeatButtonSearch,
  showRepeatButton,
  renderRunnerSelection,
  showScratchedNotification,
  shouldNotHideScratchNotification,
  setShouldNotHideScratchNotification,
  scratchedTitle,
  scratchedLegText,
  onCancelWager,
  handleRebetClickEvent,
  rebetWagerTypeName,
  rebetSearch,
  qaLabel,
  hasContentMaxHeight,
  isMyBetsOpen,
  betsNumber,
  isCardBodyScrollable,
  isDesktop,
  isScratchInfoDesign,
  showMinMaxWillPays,
  minWillPays,
  maxWillPays,
  renderPromoOnboarding,
  onShareWager,
  socialShareModalToggle,
  isFDR
}) => {
  if (!wager) {
    return null;
  }

  const wagerId = get(wager, "id", "unk");

  const getBetNotificationScratch = useCallback(
    () =>
      isScratchInfoDesign ? (
        <AlertInline
          message={scratchedLegText}
          title={scratchedTitle}
          type="fixed"
          variant="informational"
          qaLabel={`notification-scratch-${wagerId}`}
          marginBottom="space-5"
          onDismissPress={setShouldNotHideScratchNotification}
          actionLink={
            <AlertInlineAction
              qaLabel="notification-scratch-modal"
              onClick={showTrackRules}
            >
              See Rules
            </AlertInlineAction>
          }
        />
      ) : (
        <BetNotificationScratch
          qaLabel={`notification-scratch-${wagerId}`}
          title={scratchedTitle}
          text={scratchedLegText}
          textFontSize="small"
          color={buildColor("orange", "700")}
          colorList={[buildColor("orange", "200"), buildColor("orange", "700")]}
          colorBorder={buildColor("orange", "200")}
          colorBackground={buildColor("orange", "000")}
          iconElement={infoOutline}
          showCloseButton
          showNotificationFn={setShouldNotHideScratchNotification}
          showRulesLink={isMyBetsOpen}
          useLargerWidth
          showRulesFn={showTrackRules}
        />
      ),
    [
      wagerId,
      scratchedTitle,
      scratchedLegText,
      setShouldNotHideScratchNotification,
      isMyBetsOpen,
      showTrackRules,
      isScratchInfoDesign
    ]
  );

  return (
    <BetCard data-qa-label={qaLabel} ref={betCardRef}>
      <MyBetsWagerHeader
        isOptedIn={isOptedIn}
        betStatus={betStatus}
        isCanceled={isCanceled}
        wager={wager}
        probableValue={probableValue}
        onApproxPayout={onApproxPayout}
        onHideShowLegsGTM={onHideShowLegsGTM}
        showContent={showContent}
        setShowContent={setShowContent}
        betRefund={betRefund}
        isBetWinner={isBetWinner}
        shouldShowHideLegButton={shouldShowHideLegButton}
        shouldShowPotentialReturn={shouldShowPotentialReturn}
        shouldShowRebet={shouldShowRebet}
        betsNumber={betsNumber}
        showMinMaxWillPays={showMinMaxWillPays}
        minWillPays={minWillPays}
        maxWillPays={maxWillPays}
        isFDR={isFDR}
      />
      {isOptedIn && <OptedInTag />}
      {showScratchedNotification &&
        shouldNotHideScratchNotification &&
        getBetNotificationScratch()}
      {showContent && (
        <BetCardBody
          ref={betCardBodyRef}
          betStatus={betStatus}
          isScrollable={isCardBodyScrollable}
          isBetsDetailedView={
            showBetsDetailedView &&
            showBetsDetailedView.length > 0 &&
            showBetsDetailedView.every((value) => value)
          }
          hasContentMaxHeight={hasContentMaxHeight}
        >
          {renderRunnerSelection}
        </BetCardBody>
      )}
      {shouldShowRebet && (
        <PickBetRebet
          raceUrl={raceUrl}
          handleRebetClickEvent={handleRebetClickEvent}
          rebetWagerTypeName={rebetWagerTypeName}
          rebetSearch={rebetSearch}
          isContentDisplayed={showContent}
        />
      )}
      {renderPromoOnboarding}
      {hasFooter && (
        <MyBetsWagerFooter
          closeModal={closeModal}
          raceUrl={raceUrl}
          isDetailsOpened={isDetailsOpened}
          handleDetailsButton={handleDetailsButton}
          shouldRenderFooter={shouldRenderFooter && showContent}
          isBetActive={isBetActive}
          isWagerCancelable={isWagerCancelable}
          repeatButtonSearch={repeatButtonSearch}
          showRepeatButton={showRepeatButton}
          onCancelWager={onCancelWager}
          isContentDisplayed={showContent}
          isDesktop={isDesktop}
          onShareWager={onShareWager}
          socialShareModalToggle={socialShareModalToggle}
        />
      )}
    </BetCard>
  );
};

MyBetsWager.defaultProps = {
  wager: {},
  probableValue: null,
  raceUrl: "",
  closeModal: noop,
  onApproxPayout: noop,
  showTrackRules: false,
  onHideShowLegsGTM: noop,
  showContent: true,
  setShowContent: noop,
  betCardRef: null,
  isOptedIn: false,
  betStatus: "O",
  isCanceled: false,
  showBetsDetailedView: false,
  betRefund: 0,
  isBetWinner: false,
  shouldShowHideLegButton: false,
  shouldShowPotentialReturn: false,
  hasFooter: true,
  shouldShowRebet: false,
  isDetailsOpened: false,
  handleDetailsButton: noop,
  shouldRenderFooter: true,
  isBetActive: false,
  isWagerCancelable: false,
  repeatButtonSearch: "",
  showRepeatButton: false,
  renderRunnerSelection: null,
  showScratchedNotification: false,
  shouldNotHideScratchNotification: false,
  setShouldNotHideScratchNotification: noop,
  scratchedTitle: "",
  scratchedLegText: "",
  onCancelWager: noop,
  handleRebetClickEvent: noop,
  rebetWagerTypeName: "",
  rebetSearch: "",
  qaLabel: "my-bets-wager",
  hasContentMaxHeight: false,
  isMyBetsOpen: false,
  betsNumber: 1,
  isCardBodyScrollable: false,
  isScratchInfoDesign: false,
  showMinMaxWillPays: false,
  minWillPays: 0,
  maxWillPays: 0,
  renderPromoOnboarding: null,
  isFDR: false
};

export default MyBetsWager;
