import classNames from "classnames";
import React from "react";
import Button from "@tvg/ui-bootstrap/components/Buttons";
import {
  RobotMessage,
  PerimeterxContainer
} from "@tvg/atomic-ui/_templates/Login/styled-components";
import style from "./style.css";

export const closeAfterRedirect = (props) => {
  props.closeDropdownCallback && props.closeDropdownCallback();
  props.closeModal && props.closeModal();
};

const LoginFooter = (props) => {
  const loginBlockFooterStyle = classNames({
    [style.loginBlockFooter]: true,
    [style.loginBlockFooterMobile]: props.mobile
  });

  return (
    <div>
      <div className={loginBlockFooterStyle}>
        {props.isHumanChallengeEnabled && (
          <RobotMessage>{props.cmsMsgs.robotMessage}</RobotMessage>
        )}
        <PerimeterxContainer id={"pxElement"} />
        {!props.isHumanChallengeEnabled && (
          <Button
            onClick={props.validateForm}
            type="primary"
            content="log in"
            size="full-width"
            className={style.btnLoginFooter}
            isSelected={false}
            qaLabel="login"
          />
        )}
        <div className={style.loginBlockFooterLink}>
          <a
            className={style.forgotCredentials}
            data-qa-label="loginForgotCredentials"
            href="/forgot-credentials"
            onClick={() => closeAfterRedirect(props)}
          >
            Forgot your login credentials?
          </a>
        </div>
      </div>
    </div>
  );
};

LoginFooter.defaultProps = {
  mobile: false,
  isHumanChallengeEnabled: false
};

export default LoginFooter;
