import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { fontNormal, fontMedium, defaultSize } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import ButtonLink from "../Buttons/buttonLink";

export const AmountContainer = styled.div`
  width: 100%;
  height: ${({ isDesktop, tvgHeaderV2FeatureToggle }) =>
    isDesktop && tvgHeaderV2FeatureToggle ? "44px" : "auto"};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ isDesktop, tvgHeaderV2FeatureToggle, isBalanceShown }) =>
    isDesktop && !tvgHeaderV2FeatureToggle
      ? "0.4em 0.8em"
      : `0px ${isBalanceShown ? "16px" : "8px"} 0 8px`};
  padding: ${({ isDesktop }) => !isDesktop && "0"};
  ${({ isDesktop, tvgHeaderV2FeatureToggle, isLowBalance }) =>
    isDesktop && tvgHeaderV2FeatureToggle
      ? css`
          height: 44px;
          flex-direction: row-reverse;
          background-color: ${isLowBalance
            ? buildColor("green", "500")
            : buildColor("white", "10")};
          border-radius: 2px;

          &:hover {
            cursor: pointer;
            background-color: ${isLowBalance
              ? buildColor("green", "600")
              : `hsla(0, 0%, 100%, 0.2)`};
          }
        `
      : css`
          height: auto;
          flex-direction: row;
          background-color: transparent;
          border-radius: 0.5em;
        `}
`;

export const BalanceWrapperLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  text-decoration: none;
`;

export const BalanceAmount = styled.span`
  font-family: ${fontMedium};
  color: ${buildColor("white", "100")};
  ${({ isDesktop }) =>
    isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: ${defaultSize};
        `}
  font-size: ${({ tvgHeaderV2FeatureToggle }) =>
    tvgHeaderV2FeatureToggle && "14px"}
  ${({ newQuickDepositButton }) =>
    newQuickDepositButton &&
    css`
      margin-left: 9px;
      font-size: 14px;
      font-family: ${fontNormal};
      line-height: 19px;
      font-weight: 500;
    `}
`;

export const ButtonLinkContainer = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;

  &:hover {
    & span {
      visibility: visible;
    }
  }
`;

export const BalanceDescription = styled.span`
  text-transform: uppercase;
  font-family: ${fontMedium};
  font-size: ${({ isDesktop }) => (isDesktop ? defaultSize : "12px")};
  color: ${({ isLowBalance }) =>
    isLowBalance ? buildColor("green", "400") : buildColor("blue", "100")};
`;

export const AmountButton = styled(ButtonLink)`
  background-color: ${({ isDesktop }) =>
    isDesktop ? "transparent" : buildColor("white", "10")};
  outline: none;
  border-radius: 50%;
  position: relative;
  ${({ isDesktop }) =>
    !isDesktop &&
    css`
      border-radius: 50%;
    `}
  ${({ tvgHeaderV2FeatureToggle }) =>
    !tvgHeaderV2FeatureToggle &&
    css`
      margin-left: 10px;

      & svg:first-of-type {
        fill: ${buildColor("white", "100")};
      }
    `}

  ${({ isDesktop }) =>
    isDesktop
      ? css`
          width: 38px;
          height: 38px;
        `
      : css`
          width: 32px;
          height: 32px;
        `};

  &:hover,
  &:focus {
    background-color: ${buildColor("white", "100")};

    & svg:first-of-type {
      fill: ${buildColor("white", "100")};
    }
  }

  &:active {
    background-color: ${buildColor("white", "200")};
  }

  ${({ tvgHeaderV2FeatureToggle }) =>
    !tvgHeaderV2FeatureToggle
      ? css`
          &:hover,
          &:focus {
            background-color: ${buildColor("white", "10")};

            & svg:first-of-type {
              fill: ${buildColor("white", "100")};
            }
          }

          &:active {
            background-color: ${buildColor("white", "200")};
          }
        `
      : css`
          &:hover,
          &:focus {
            background-color: transparent;

            & svg:first-of-type {
              fill: ${buildColor("white", "100")};
            }
          }

          &:active {
            background-color: transparent;
          }
        `}
  &:hover {
    & span {
      visibility: visible;
    }
  }
`;

export const NewAmountButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ExclamationIcon = styled(Icon)`
  position: absolute;
  right: 0;
  top: 0;
`;

export const IconMask = styled.div`
  position: absolute;
  right: 5px;
  top: 2px;
  background-color: ${buildColor("white", "100")};
  height: 12px;
  width: 12px;
  border-radius: 50%;
`;

export const AmountButtonTooltip = styled.span`
  visibility: hidden;
  position: absolute;
  top: 44px;
  right: ${({ align }) => (align === "left" ? `0` : `-25px`)};
  z-index: 99;
  height: 30px;
  padding: 8px;
  color: ${buildColor("black", "100")};
  background: ${buildColor("white", "100")};
  box-shadow:
    0 4px 4px rgba(0, 0, 0, 0.1),
    0 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  overflow: hidden;
  white-space: nowrap;
  ${({ newQuickDepositButton }) =>
    newQuickDepositButton &&
    css`
      right: initial;
      top: 48px;
    `};
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  position: relative;
`;
