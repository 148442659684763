import styled, { css } from "styled-components";
import { space } from "styled-system";
import { animated } from "@react-spring/web";
import type {
  AlertInlineProps,
  AlertInlineVariants,
  AlertInlineTypes
} from "./types";

const alertInlineVariants = {
  success: css`
    background-color: var(--fd-colors-system-positive-background-subtle);
    border-color: var(--fd-colors-system-positive-border-default);
  `,
  informational: css`
    background-color: var(--fd-colors-system-info-background-subtle);
    border-color: var(--fd-colors-system-info-border-default);
  `,
  error: css`
    background-color: var(--fd-colors-system-important-background-subtle);
    border-color: var(--fd-colors-system-important-border-default);
  `,
  warning: css`
    background-color: var(--fd-colors-system-warning-background-subtle);
    border-color: var(--fd-colors-system-warning-border-default);
  `,
  promo: css`
    background-color: var(--fd-colors-system-alert-background-subtle);
    border-color: var(--fd-colors-system-alert-border-default);
  `
};

const alertInlineTypes = {
  floating: css`
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
  `,
  fixed: css`
    border-radius: 0;
    border-style: solid;
    border-width: 1px 0;
  `
};

export const AnimatedWrapper = styled(animated.div)`
  width: 100%;
  height: max-content;
`;

export const AlertInlineContainer = styled.div<
  Pick<AlertInlineProps, "variant" | "type">
>`
  box-sizing: content-box;
  display: flex;
  gap: 12px;
  padding: 12px;
  ${({ type }) => alertInlineTypes[type as AlertInlineTypes]}
  ${({ variant }) => alertInlineVariants[variant as AlertInlineVariants]}
  ${space}
`;

export const AlertInlineInfo = styled.div<{ flexDirection: string }>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex: 1;
  gap: 4px;
  justify-content: space-between;
`;

export const AlertInlineCloseButton = styled.button`
  background: transparent;
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
  border: 0;
`;

export const Link = styled.a<{ color: string }>`
  color: ${({ color }) => `var(${color})`};
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-decoration-line: underline;
  display: flex;
  flex-direction: row;
`;

export const LinkWrapper = styled.span`
  display: flex;
  flex-direction: row;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
`;
