import React from "react";
import { ApolloProvider } from "@apollo/client";
import { AppRegistry } from "react-native-web";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { TVGThemeProviderWeb, TvgConfProvider } from "@tvg/design-system";
import { FormationTokens } from "@tvg/design-system/web";
import GridFooterEvents from "@tvg/gtm/src/modules/GridFooter";
import MainEvents from "@tvg/gtm/src/modules/Main";
import PromosMainEvent from "@tvg/gtm/src/modules/PromosStoryblokMain";

import ApolloClient from "./apolloClient/ApolloClient";
import configureStore from "./configureStore";
import App from "./pages";

const preloadedState = window.__PROMOS_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle
window.promosPageRendered = true;

window.handleNativeMessages = (type, body) => {
  const message = {};
  const postMessage =
    window &&
    window.ReactNativeWebView &&
    window.ReactNativeWebView.postMessage;
  if (postMessage) {
    message.type = type;
    if (body) {
      message.body = body;
    }
    postMessage(JSON.stringify(message));
  }
};

GridFooterEvents();
MainEvents();
PromosMainEvent();

const store = configureStore(preloadedState);

const Main = () => (
  <TvgConfProvider>
    <TVGThemeProviderWeb isFanduelTheme={false}>
      <FormationTokens />
      <ApolloProvider client={ApolloClient.createClient(false)}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </ApolloProvider>
    </TVGThemeProviderWeb>
  </TvgConfProvider>
);

const Render = () => {
  AppRegistry.registerComponent("App", () => Main);

  AppRegistry.runApplication("App", {
    initialProps: {},
    // @TODO check if we can improve the hydration
    // hydrate: true,
    rootTag: document.getElementById("promos")
  });
};

export default Render();
