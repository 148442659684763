import React, { useMemo } from "react";
import { formatPeriod, formatPostTime, formatRaceDate } from "@tvg/formatter";
import { Header, Paragraph } from "@tvg/design-system";
import { Mtp } from "@tvg/design-system/web";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { DateWrapper, LegMtpWrapper } from "./styled-components";
import type { Props } from "./types";

const HeaderMTP = (props: Props) => {
  const {
    qaLabel = "mybets-card-header",
    showRaceLeg = false,
    legNumber = 1,
    isFutureBet = false,
    raceDate,
    racePostTime,
    mtp = 60,
    statusCode = RaceStatusEnum.OPEN,
    dateDiff = 0
  } = props;

  const colors = useColorTokens();
  const renderFullDate = useMemo(
    () =>
      dateDiff < 0 && (
        <Paragraph
          qaLabel={`${qaLabel}-full-date`}
          fontFamily="regular"
          color={colors.content.subtle}
        >
          {` - ${formatRaceDate(racePostTime)}`}
        </Paragraph>
      ),
    [dateDiff, racePostTime]
  );

  return (
    <LegMtpWrapper>
      {showRaceLeg && legNumber > 0 && (
        <Header
          tag="h3"
          fontSize="s"
          lineHeight="s"
        >{`Leg ${legNumber} -`}</Header>
      )}
      {isFutureBet ? (
        <DateWrapper>
          <Paragraph
            qaLabel={`${qaLabel}-mtp`}
            fontFamily="regular"
            color={colors.content.subtle}
          >
            {`${formatPostTime(racePostTime)} `}
          </Paragraph>
          <Paragraph
            qaLabel={`${qaLabel}-full-date`}
            fontFamily="regular"
            color={colors.content.subtle}
          >
            {`${formatPeriod(racePostTime)} - ${formatRaceDate(raceDate)}`}
          </Paragraph>
        </DateWrapper>
      ) : (
        <>
          <Mtp
            qaLabel={`${qaLabel}-mtp`}
            size="xs"
            mtp={mtp}
            status={statusCode}
            postTime={racePostTime}
            ml={showRaceLeg && legNumber > 0 ? "space-1" : undefined}
          />
          {renderFullDate}
        </>
      )}
    </LegMtpWrapper>
  );
};

export default HeaderMTP;
