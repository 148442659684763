import { object, string, array } from "yup";
import { isValid } from "date-fns";
import { invertDate } from "@tvg/rma/src/utils/testFns";

export const generateEmailRecoverySchema = (messages) =>
  object().shape({
    dateOfBirth: string().test(
      "is-required",
      messages.form.dateOfBirthErrorInvalid,
      (dateOfBirth) => {
        let validDate = false;
        if (
          dateOfBirth.length === 10 &&
          !dateOfBirth.includes("_") &&
          !isNaN(dateOfBirth.replace(/\s/g, "")) &&
          isValid(new Date(invertDate(dateOfBirth.replace(/\s/g, "-"))))
        ) {
          validDate = true;
        }
        return validDate;
      }
    ),
    last4Ssn: array().test(
      "check-required",
      messages.form.last4SsnErrorInvalid,
      (value) => {
        const unifiedSSN = value.join("");
        return unifiedSSN.length === 4;
      }
    )
  });
