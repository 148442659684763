import React, { PureComponent } from "react";
import { map } from "lodash";

import RaceResultsPayoff from "../../_molecule/RaceResultsPayoff";
import PayoffsTable from "./styled-components";
import RaceResultsPayoffHeader from "../../_molecule/RaceResultsPayoffHeader";

export default class RacePayoffs extends PureComponent {
  static defaultProps = {
    payoffs: [],
    raceNumber: "1",
    raceTypeCode: "T",
    enableRaceResultsReDesign: false
  };

  render() {
    const { payoffs, raceNumber, raceTypeCode, enableRaceResultsReDesign } =
      this.props;
    return (
      <PayoffsTable data-qa-label="racePayoffs">
        {enableRaceResultsReDesign && payoffs.length > 0 && (
          <RaceResultsPayoffHeader />
        )}
        <tbody>
          {map(payoffs, (payoff, index) => (
            <RaceResultsPayoff
              key={`${index}-${payoff.wagerType.code}-${payoff.selections[0].payoutAmount}-${payoff.selections[0].selection}-payoff`}
              payoff={payoff}
              raceNumber={raceNumber}
              raceTypeCode={raceTypeCode}
              enableRaceResultsReDesign={enableRaceResultsReDesign}
            />
          ))}
        </tbody>
      </PayoffsTable>
    );
  }
}
