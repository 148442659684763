import type { Actions, MtpStatus } from "./actions";

export type State = {
  mtpStatus: MtpStatus[];
  raceDate: string;
};

export const initialState = {
  mtpStatus: [],
  raceDate: ""
};

const updateMtpRaces = (raceList: MtpStatus[], races: MtpStatus[]) => {
  const newRaceList = [...raceList];
  races.forEach((race) => {
    const selectedRaceIndex = raceList.findIndex(
      (mtpRace) => mtpRace.tvgRaceId === race.tvgRaceId
    );
    if (selectedRaceIndex >= 0) {
      newRaceList[selectedRaceIndex] = {
        ...raceList[selectedRaceIndex],
        ...race
      };
    } else {
      newRaceList.push({ ...race });
    }
  });

  return newRaceList;
};

export default function reducer(
  /* @ts-ignore */ /* eslint-disable-next-line */
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "MTP_STATUS_UPDATE": {
      return {
        ...state,
        mtpStatus: action.payload.mtpStatus,
        raceDate: action.payload.raceDate
          ? action.payload.raceDate
          : state.raceDate
      };
    }
    case "UPDATE_MTP_RACES": {
      return {
        ...state,
        mtpStatus: updateMtpRaces(state.mtpStatus, action.payload.races)
      };
    }
    case "UPDATE_MTP_RACEDATE": {
      return {
        ...state,
        raceDate: action.payload.raceDate
      };
    }
    default: {
      return state;
    }
  }
}
