import React, { useEffect, useState } from "react";
import Text from "@tvg/atomic-ui/_atom/Text";

import {
  MenuBoxTitleWrapper,
  MenuBoxContainer,
  MenuBoxLinkItems,
  MenuBoxIcon
} from "./styled-components";
import Icon from "../../_static/Icons";
import MenuLinkItem from "../../_molecules/MenuLinkItem";
import { chevronDown, chevronUp } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";

const MenuBox = ({ links, title, isMobile = false, slug, qaLabel }) => {
  const [isOpen, setMenuOpen] = useState(!isMobile);

  useEffect(() => {
    setMenuOpen(!isMobile);
  }, [isMobile]);

  const toggleMenu = () => {
    setMenuOpen((prevIsOpen) => !prevIsOpen);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleWindowResize = () => setMenuOpen(window.innerWidth >= 1024);

      window.addEventListener("resize", handleWindowResize);

      return () => window.removeEventListener("resize", handleWindowResize);
    }

    return null;
  }, []);

  const renderMenuItems = () =>
    links.map((link) => (
      <MenuLinkItem
        key={link._uid}
        {...link}
        slug={slug}
        qaLabel={`${link._uid || ""}-linkItem`}
      />
    ));

  return (
    <MenuBoxContainer data-qa-label={qaLabel}>
      <MenuBoxTitleWrapper
        onClick={toggleMenu}
        data-qa-label={`${qaLabel}TitleWrapper`}
      >
        <Text
          tag="span"
          bold
          fontSize={18}
          color={buildColor("grey", "900")}
          qaLabel={`${qaLabel}Title`}
        >
          {title}
        </Text>
        <MenuBoxIcon data-qa-label={`${qaLabel}IconWrapper`}>
          <Icon
            icon={isOpen ? chevronDown : chevronUp}
            size={16}
            view="0 0 16 16"
            qaLabel={`${qaLabel}Icon`}
          />
        </MenuBoxIcon>
      </MenuBoxTitleWrapper>
      <MenuBoxLinkItems
        isOpen={isOpen}
        menuItemsQty={links.length > 0 ? links.length : 0}
        data-qa-label={`${qaLabel}LinkItems`}
      >
        {renderMenuItems()}
      </MenuBoxLinkItems>
    </MenuBoxContainer>
  );
};

MenuBox.defaultProps = {
  isMobile: false,
  links: [],
  title: "",
  slug: "",
  qaLabel: "menuBox"
};

export default MenuBox;
