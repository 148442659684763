import styled, { css } from "styled-components";
import { fontNormal } from "../../_static/Typography";
import { buildTextHighlighted, TYPES } from "../../_atom/TextHighlighted";
import MessageBox from "../../_molecule/MessageBox";

import buildColor from "../../_static/ColorPalette";

export const ResultsContainer = styled.section`
  background-color: ${buildColor("blue", "100")};
`;

export const NoBetsContainer = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${buildColor("white", "100")};
`;

export const RaceBetsContainer = styled.section`
  ${(props) => css`
    background-color: ${props.myBetsBody
      ? buildColor("blue", "000")
      : buildColor("white", "100")};
    padding: ${props.myBetsBody ? "0px" : "8px"};

    > div {
      margin-top: ${props.myBetsBody ? "8px" : "0px"};
    }
  `};
`;

export const Alerts = styled(MessageBox)`
  border-radius: 2px;
  min-height: 64px;
`;

export const TextHighlighted = styled(
  buildTextHighlighted(TYPES.pending, 11, true, true)
)`
  padding: 1px 2px;
  line-height: 1;
`;

export const ROWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  ul.tab-group {
    box-shadow: inset 0 -1px 0 0 ${buildColor("blue", "100")} !important;
  }
`;

export const Message = styled(MessageBox)`
  border-bottom: 1px solid ${buildColor("grey", "300")};
  ${(props) =>
    props.enableRaceResultsReDesign &&
    css`
      border-radius: 0 !important;
      border-bottom: 1px solid ${buildColor("blue", "100")};
      padding: 15px 12px !important;

      span {
        color: ${buildColor("blue_accent", "500")};
        font-family: ${fontNormal};
        font-size: 14px;
      }
    `};
`;
