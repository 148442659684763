import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { fontNormal, fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

// The login form story container is only meant to be used by ./story.jsx
export const LoginFormStoryContainer = styled.section`
  background-color: ${buildColor("white", "100")};
  padding: 24px;
`;

export const LoginFormComponent = styled.form`
  overflow: auto;
  flex: 1;
  ${({ device }) =>
    device === "mobile"
      ? css`
          padding: 16px 12px 12px;
        `
      : css`
          padding: 20px;
        `};
`;

export const MessageBoxContainer = styled.div`
  ${({ device }) =>
    device === "mobile"
      ? css`
          margin: 0 0 8px;
        `
      : css`
          margin: 20px 20px 0;
        `}
`;

export const AsideLabelLink = styled(Link)`
  text-decoration: none;
  color: ${buildColor("blue_accent", "500")};
  font-size: 12px;
  font-family: ${fontMedium};

  &:active {
    color: ${buildColor("blue_accent", "700")};
  }
`;

export const TextLink = styled(Link)`
  color: ${buildColor("red", "600")} !important;
  font-size: 14px;
  font-family: ${fontMedium};
  font-weight: 500;
  line-height: 16px;
  vertical-align: inherit;
`;

export const SelectWarnText = styled.p`
  color: ${buildColor("grey", "700")};
  font-weight: normal;
  line-height: 18px;
  margin-top: 8px;
  font-family: ${fontNormal};
`;

export const GoogleText = styled.p`
  color: ${buildColor("grey", "700")};
  font-weight: normal;
  line-height: 18px;
  margin-top: 16px;
  font-family: ${fontNormal};
`;

export const GoogleTextLink = styled.a`
  text-decoration: none;
  color: ${buildColor("blue_accent", "500")};
  font-family: ${fontMedium};
  line-height: 18px;
  margin-left: 2px;
  vertical-align: baseline;

  &:active {
    color: ${buildColor("blue_accent", "700")};
  }
`;
