import React, { PureComponent } from "react";

import classnames from "classnames";
import styles from "./index.css";

/**
 * TVG Button Component
 */
class Button extends PureComponent {
  static defaultProps = {
    behavior: "button",
    type: "primary",
    value: "",
    size: "normal",
    iconPosition: "left",
    className: "",
    children: [],
    isSelected: false,
    content: "",
    icon: false,
    disabled: false,
    qaLabel: ""
  };

  static getButtonProps(type) {
    const dict = {
      primary: styles.tvgBtnPrimary,
      secondary: styles.tvgBtnSecondary,
      tertiary: styles.tvgBtnTertiary,
      secondaryAlt: styles.tvgBtnSecondaryAlt,
      yellow: styles.tvgBtnMarketing,
      legacy: styles.tvgBtnLegacy,
      big: styles.big,
      "full-width": styles.fullWidth,
      normal: ""
    };

    return dict[type] || "";
  }

  props;

  render() {
    const classes = classnames({
      [Button.getButtonProps(this.props.type)]: true,
      [Button.getButtonProps(this.props.size)]: true,
      [this.props.className]: true,
      [styles.selected]: this.props.isSelected,
      [styles.icon]: this.props.icon,
      [styles.iconRight]: this.props.iconPosition === "right"
    });

    return (
      <button
        type={this.props.behavior}
        className={classes}
        onClick={() => this.props.onClick(this.props.value)}
        value={this.props.value}
        disabled={this.props.disabled}
        data-qa-label={this.props.qaLabel.concat("Button")}
      >
        {this.props.content || this.props.children}
      </button>
    );
  }
}

export default Button;
