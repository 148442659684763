import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { get, isEmpty, noop } from "lodash";
import { connect } from "react-redux";

import useScratchNotification from "@tvg/sh-lib-my-bets/hooks/useScratchNotification";
import {
  getFeatureUseTvgPotReturn,
  getIsMyBetsOpen,
  getPickBetRebetToggle,
  getSelectedSettledTab,
  getSelectedTab,
  getTodayActiveCounter,
  getTodaySettledCounter,
  getMyBetsTrackRulesToggle,
  getSocialShareToggle
} from "@tvg/sh-lib-my-bets/redux/selectors";
import { calculateShowBetsDetailedView } from "@tvg/sh-lib-my-bets/utils/betsBetCard";
import {
  getRaceStatus,
  shouldShowWillPays,
  isPickBetSub
} from "@tvg/sh-lib-my-bets/utils/raceDetails";
import {
  getFavoriteRunnerByLeg,
  getScratchedLegNumbersIndex,
  stringifySelections
} from "@tvg/sh-lib-my-bets/utils/pickBets";
import usePickBetRebet from "@tvg/sh-lib-my-bets/hooks/usePickBetRebet";
import {
  expandCollapseLegGtm,
  onApproxPayoutModalGtm,
  onHideShowLegsGTM,
  showTrackRulesGtm
} from "@tvg/sh-lib-my-bets/utils/gtm";

import {
  checkAllDetailedViewIsOpen,
  getSelectionLabel,
  getWagerProps,
  handleDetailsButton,
  setShowContent
} from "@tvg/sh-lib-my-bets/utils/general";
import MyBetsWagerOrganism from "@tvg/atomic-ui/_organism/MyBetsWager";
import {
  BetCard,
  BetCardBody
} from "@tvg/atomic-ui/_organism/MyBetsWager/styled-components";

import MyBetsRunnerNumberGroup from "@tvg/atomic-ui/_molecule/MybetsRunnerNumberGroup";
import PromosOnboarding from "@tvg/promos-onboarding";

import useSelections from "@tvg/sh-lib-my-bets/hooks/useSelections";
import useMinMaxWillPays from "@tvg/sh-lib-my-bets/hooks/useMinMaxWillPays";
import useResults from "@tvg/sh-lib-my-bets/hooks/useResults";
import {
  getStoryblokPromoByStep,
  getStoryblokPromos
} from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import { matchWagerPropositions } from "@tvg/sh-lib-promos-onboarding/utils/matchWagerPropositions";
import { matchPlacedWager } from "@tvg/sh-lib-promos-onboarding/utils/matchPlacedWager";
import { isFDR } from "@tvg/utils/generalUtils";

import { PromoStepType } from "@tvg/sh-lib-promos-onboarding/types/promoSteps";

export const MyBetsWager = (props) => {
  const {
    dispatch,
    wager,
    mainWagerDetails,
    index,
    useTvgPotReturnToggle,
    selectedTab,
    selectedSettledTab,
    races,
    raceNumber,
    statusCode,
    betAmount,
    bettingInterests,
    currentRaceDate,
    allRacesFromTrack,
    enablePickBetRebet,
    modalScrollableRef,
    activeBetsCounter,
    settledBetsCounter,
    closeModal,
    raceUrl,
    showContentObj,
    setShowContentObj,
    currentRace,
    currentOpenLeg,
    hasFooter,
    onCancelWager,
    hasDetails,
    legStatusCode,
    hasContentMaxHeight,
    isKey,
    isBox,
    isMyBetsOpen,
    isBetPlaced,
    isBetConfirmation,
    isInsideRaceOfficials,
    betsNumber,
    isDesktop,
    myBetsTrackRulesToggle,
    promosOnboardingObject,
    promosOnboardingStep,
    onShareWager,
    socialShareModalToggle
  } = props;

  const {
    wagerBetStatus,
    isCurrentRaceDate,
    isCanceled,
    isActive,
    currentWagerTypeCode,
    isWagerCancelable,
    isMultiRace,
    isPickBet,
    isExotic,
    isBetWinner,
    isOptedIn,
    isWheel
  } = getWagerProps(
    wager,
    legStatusCode || statusCode,
    currentRaceDate,
    get(mainWagerDetails, 0, ""),
    currentRace
  );
  const selectionSize = get(wager, "selections.selection.length", 0);
  const finalBettedLeg = currentRace
    ? +get(currentRace, "number", "0") + (selectionSize - 1)
    : 0;
  const betResults = [];
  const betRefund = get(wager, "betRefund", 0);
  const betStatusName = wagerBetStatus.name;
  const betStatusCode = wagerBetStatus.code;
  const betProbableValue = get(wager, "probable", "0");

  if (allRacesFromTrack && allRacesFromTrack.length > 0 && raceNumber) {
    for (let j = +raceNumber - 1; j < +raceNumber + selectionSize - 1; j += 1) {
      if (allRacesFromTrack[j] && allRacesFromTrack[j].results) {
        betResults.push(allRacesFromTrack[j].results);
      }
    }
  }

  const showWillPays =
    currentRace && allRacesFromTrack && isMultiRace && betResults && !isCanceled
      ? shouldShowWillPays(
          allRacesFromTrack,
          wager.selections.selection,
          betResults,
          finalBettedLeg
        )
      : false;

  const results = useResults({
    allRacesFromTrack
  });

  const selections = useSelections({
    wager,
    isMultiRace,
    raceNumber,
    currentRace,
    currentRaceDate,
    finalBettedLeg,
    showWillPays,
    allRacesFromTrack,
    currentWagerTypeCode,
    isActive,
    results,
    isExotic
  });

  const { minWillPays, maxWillPays, showMinMaxWillPays } =
    useMinMaxWillPays(selections);

  const betCardRef = useRef(null);
  const betCardBodyRef = useRef(null);

  const [showBetsDetailedView, setShowBetsDetailedView] = useState(
    calculateShowBetsDetailedView(selections, showWillPays)
  );

  const isCardBodyScrollable =
    betCardBodyRef.current &&
    betCardBodyRef.current.scrollHeight > betCardBodyRef.current.clientHeight;

  // Scroll betCard inToView
  const handleScrollPosition = useCallback(() => {
    if (
      betCardRef.current &&
      modalScrollableRef &&
      typeof betCardRef.current.scrollIntoView === "function" &&
      typeof betCardRef.current.getBoundingClientRect === "function"
    ) {
      setTimeout(() => {
        // this headerOffset is based on modal header height + plus Tab height and or plus the timeframe height
        const headerOffset = selectedTab === "SETTLED" ? 164 : 96;
        const betCardBoundingClientRect =
          betCardRef.current.getBoundingClientRect();
        const screenHeight =
          get(window, "innerHeight") ||
          get(document, "documentElement.clientHeight", 0);
        const isBetCardVisible =
          betCardBoundingClientRect.top >= headerOffset &&
          betCardBoundingClientRect.bottom <= screenHeight;

        if (!isBetCardVisible) {
          modalScrollableRef.scrollTo({
            top: betCardRef.current.offsetTop - headerOffset,
            behavior: "smooth"
          });
        }
      }, 0);
    }
  }, [showBetsDetailedView]);

  const [favoriteRunnerByLeg, setFavoriteRunnerByLeg] = useState([]);

  const [autoOpenedWillPays, setAutoOpenedWillPays] = useState(false);

  const hasLostOneLeg = useCallback(
    () =>
      selections.some(
        (leg) =>
          leg.some((sel) => sel.isWinner || sel.isWinner === null) === false
      ),
    [selections]
  );

  const shouldShowPotentialReturn =
    useTvgPotReturnToggle &&
    !showWillPays &&
    !hasLostOneLeg() &&
    betProbableValue !== "0";

  const shouldShowHideLegButton =
    isMultiRace &&
    hasLostOneLeg() &&
    selectedTab === "ACTIVE" &&
    !shouldShowPotentialReturn &&
    !isInsideRaceOfficials;

  const isDetailsOpened = checkAllDetailedViewIsOpen(showBetsDetailedView);
  const hadScratchedRunner = getScratchedLegNumbersIndex(selections);

  const [shouldRenderFooter, setShouldRenderFooter] = useState(true);

  const repeatButtonSearch = useCallback(() => {
    const newSelectionsWithoutScratch = selections.map((selection) =>
      selection.reduce((accSelection, currSelection) => {
        const runners = get(currSelection, "runners") || [];
        if (runners.some((runner) => !runner.isScratched)) {
          accSelection.push(currSelection);
        }
        return accSelection;
      }, [])
    );

    return `?race=${raceNumber}&wt=${currentWagerTypeCode}&bet=${
      wager.wagerAmount
    }${stringifySelections(newSelectionsWithoutScratch)}&type=Repeat`;
  }, [
    currentWagerTypeCode,
    raceNumber,
    JSON.stringify(selections),
    wager.wagerAmount
  ]);

  useEffect(() => {
    const willPaysFetched =
      currentOpenLeg &&
      !!get(
        allRacesFromTrack,
        `[${+currentOpenLeg.number - 1}].willPays`,
        false
      );
    if (
      !showBetsDetailedView.length ||
      !get(showBetsDetailedView, "[0].length", true)
    ) {
      setShowBetsDetailedView(
        calculateShowBetsDetailedView(selections, showWillPays)
      );
    } else if (showWillPays && willPaysFetched && !autoOpenedWillPays) {
      const newDetailedView = showBetsDetailedView.map(
        (leg, legIndex) => legIndex === showBetsDetailedView.length - 1 || leg
      );
      setAutoOpenedWillPays(true);
      setShowBetsDetailedView(newDetailedView);
    }
  }, [selectedTab, selections, showWillPays]);

  useEffect(() => {
    if (allRacesFromTrack) {
      setFavoriteRunnerByLeg(
        getFavoriteRunnerByLeg(
          selections,
          allRacesFromTrack,
          +raceNumber,
          wager.raceDate
        )
      );
    }
  }, [allRacesFromTrack, selections]);

  useEffect(() => {
    if (get(wager, "betStatus.code") === "R") {
      const isCancelDataOpen = statusCode === "O";
      const isCancelDataUpNext = statusCode === "IC";

      setShouldRenderFooter(isCancelDataOpen || isCancelDataUpNext);
    }
  }, []);

  useEffect(() => {
    setShowBetsDetailedView(Array(selections.length).fill(false));
  }, [showContentObj]);

  const [
    showScratchedNotification,
    shouldNotHideScratchNotification,
    setShouldNotHideScratchNotification,
    scratchedTitle,
    scratchedLegText
  ] = useScratchNotification({
    selections,
    isPickBet,
    betStatusCode,
    favoriteRunnerByLeg,
    currentWagerTypeCode,
    isCurrentRaceDate,
    betRefund
  });

  const defaultScratchedText = "Scratch occurred.";

  const [
    handleRebetClickEvent,
    rebetWagerTypeName,
    rebetSearch,
    shouldShowRebet
  ] = usePickBetRebet({
    allRacesFromTrack,
    mainWagerDetails,
    currentRace,
    currentOpenLeg,
    callback: closeModal,
    selections,
    enablePickBetRebet,
    isPickBet,
    isCurrentRaceDate,
    isSettledBet: selectedTab === "SETTLED"
  });

  const showRepeatButton =
    !shouldShowRebet &&
    !isWheel &&
    (wager.cancelable ||
      betStatusName === "canceled" ||
      betStatusName === "active") &&
    raceNumber &&
    closeModal &&
    raceUrl &&
    betAmount !== undefined &&
    (statusCode === "O" || statusCode === "IC") &&
    selectedTab !== "FUTURES" &&
    hadScratchedRunner.length === 0;

  const renderedRunnerGroup = useCallback(() => {
    return selections.map((selection, indexSelection, { length }) => {
      const { key, label, pickBetsLabelLength } = getSelectionLabel({
        selectionIndex: indexSelection,
        selectionLength: length,
        raceNumber: +raceNumber,
        selectionsModifier: get(wager, "selections.modifier"),
        isMultiRace,
        isKey,
        isBox,
        isDesktop
      });

      const isAllRunnersScratched = selection.every((runner) =>
        runner.runners.every((r) => r.isScratched)
      );
      const legContainsScratch = selection.some((runner) =>
        runner.runners.every((r) => r.isScratched)
      );

      const betBettingInterests = [];

      if (bettingInterests && bettingInterests.length > 0 && raceNumber) {
        if (!isMultiRace) {
          betBettingInterests.push(
            get(
              allRacesFromTrack.find((race) => +race.number === +raceNumber),
              "bettingInterests",
              bettingInterests[+raceNumber - 1]
            )
          );
        } else {
          for (
            let i = isBetConfirmation ? 0 : +raceNumber;
            i < +raceNumber + selectionSize;
            i += 1
          ) {
            const raceArrayIterator = isBetConfirmation ? i : i - 1;
            if (bettingInterests[raceArrayIterator]) {
              betBettingInterests.push(
                get(
                  allRacesFromTrack.find((race) => +race.number === i),
                  "bettingInterests",
                  bettingInterests[raceArrayIterator]
                )
              );
            }
          }
        }
      }

      const resultsIndex = isMultiRace ? indexSelection : 0;
      const currentBettingInterestsLength = get(
        betBettingInterests,
        `${resultsIndex}.length`,
        0
      );

      const allRunnersSelected =
        currentBettingInterestsLength === selection.length;

      const favoriteRunner = !isEmpty(favoriteRunnerByLeg)
        ? favoriteRunnerByLeg[indexSelection]
        : undefined;

      const allowsPickBetSub =
        currentWagerTypeCode && isPickBetSub(currentWagerTypeCode);

      const selectionRaceStatus = getRaceStatus({
        isAllRunnersScratched,
        legContainsScratch,
        selection,
        selectionIndex: indexSelection,
        races: allRacesFromTrack,
        raceNumber,
        selectionLength: length,
        isMultiRace,
        isCanceled,
        betStatusName,
        wagerType: currentWagerTypeCode,
        favoriteRunner,
        shouldShowReplacement: allowsPickBetSub
      });

      const isBetRefund =
        get(wager, "betRefund", 0) > 0 &&
        (betStatusName === "winner" || betStatusName === "refunded");

      const sortedSelection = selection.sort(
        (a, b) => +a.runnerNumber - +b.runnerNumber
      );

      return (
        <MyBetsRunnerNumberGroup
          key={`runner-group${indexSelection.toString()}${get(
            wager,
            "id",
            "unk"
          )}`}
          runners={sortedSelection}
          raceTypeCode={wager.raceTypeAbbreviation}
          isMultiRace={isMultiRace}
          isExotic={isExotic}
          isBetsStandalone
          isBetsDetailedView={get(showBetsDetailedView, indexSelection, false)}
          isKey={key}
          label={label}
          showHorizontalRule={length !== indexSelection + 1}
          isFirstRowOfRunners={indexSelection === 0}
          isLastRowOfRunners={indexSelection === length - 1}
          wagerTypeCode={currentWagerTypeCode}
          onDetailedView={() => {
            const isOpened = get(showBetsDetailedView, indexSelection, false);
            expandCollapseLegGtm(
              isOpened ? "Closed Leg" : "Opened Leg",
              indexSelection + 1,
              selectedTab,
              selectedSettledTab,
              activeBetsCounter,
              settledBetsCounter
            );
            setShowBetsDetailedView((prevShowedBets) => {
              const updatedShowedBet = [...prevShowedBets];
              updatedShowedBet[indexSelection] =
                !prevShowedBets[indexSelection];
              return updatedShowedBet;
            });
          }}
          allRunnersSelected={allRunnersSelected}
          hasDetails={hasDetails}
          raceStatus={selectionRaceStatus}
          betStatusName={betStatusName}
          isPickBet={isPickBet}
          isCanceled={isCanceled}
          pickBetsLabelLength={pickBetsLabelLength}
          favoriteRunner={favoriteRunner}
          isCurrentRaceDate={isCurrentRaceDate}
          isBetRefund={isBetRefund}
        />
      );
    });
  }, [
    selections,
    raceNumber,
    wager,
    isMultiRace,
    races,
    raceNumber,
    isCanceled,
    betStatusName,
    favoriteRunnerByLeg,
    showBetsDetailedView,
    currentWagerTypeCode,
    selectedTab,
    selectedSettledTab,
    activeBetsCounter,
    settledBetsCounter,
    isPickBet,
    isCurrentRaceDate,
    isKey,
    isBox
  ]);

  const promosOnboardingProps = useMemo(() => {
    const biNumbers = bettingInterests.reduce((previous, current) => {
      return [...previous, ...(current || []).map((bi) => bi.biNumber)];
    }, []);

    const wagerData = {
      bettingInterests: biNumbers,
      raceNumber: currentRace.number,
      breedTypeCode: get(wager, "raceTypeAbbreviation", ""),
      raceDate: get(currentRace, "raceDate", ""),
      trackCode: get(currentRace, "trackCode", ""),
      wagerTypeCode: get(wager, "wagerType.code", ""),
      serialNumber: get(wager, "serialNumber", "")
    };

    if (isBetConfirmation) {
      const placeToRender = isBetPlaced
        ? "betslip_placed"
        : "betslip_confirmation";

      const shouldShowPromoOnboarding = promosOnboardingStep
        ? matchWagerPropositions(promosOnboardingStep, wagerData)
        : false;

      return { placeToRender, shouldShowPromoOnboarding };
    }

    const shouldShowPromoOnboarding = promosOnboardingStep
      ? matchPlacedWager(promosOnboardingStep, wagerData)
      : false;

    return { placeToRender: "bet_ticket", shouldShowPromoOnboarding };
  }, [
    wager,
    currentRace,
    isBetPlaced,
    bettingInterests,
    isBetConfirmation,
    promosOnboardingStep
  ]);

  const renderPromoOnboarding = useCallback(() => {
    const wagerAmount = get(wager, "betTotal", 0);
    const rebateLimit = get(promosOnboardingObject, "rebateLimit", 0);

    const amountToShow = wagerAmount > rebateLimit ? rebateLimit : wagerAmount;
    const customMessage = `$${amountToShow} in wagering credit will be refunded if you don't win.`;

    const { placeToRender, shouldShowPromoOnboarding } = promosOnboardingProps;

    return (
      <PromosOnboarding
        placeToRender={placeToRender}
        customMessage={customMessage}
        isShown={shouldShowPromoOnboarding}
      />
    );
  }, [promosOnboardingProps]);

  return (
    <MyBetsWagerOrganism
      key={`wager-${get(wager, "id", index)}`}
      wager={wager}
      raceUrl={raceUrl}
      closeModal={closeModal}
      onApproxPayout={() =>
        onApproxPayoutModalGtm(dispatch, {
          selectedTab,
          selectedSettledTab,
          activeBetsCount: +activeBetsCounter,
          settledBetsCount: +settledBetsCounter
        })
      }
      showTrackRules={() =>
        showTrackRulesGtm(
          dispatch,
          selectedTab,
          selectedSettledTab,
          currentWagerTypeCode
        )
      }
      onHideShowLegsGTM={(isOpening) =>
        onHideShowLegsGTM({
          isOpening,
          activeBets: activeBetsCounter,
          settledBets: settledBetsCounter
        })
      }
      showContent={get(showContentObj, wager.id, true)}
      setShowContent={(value, id) =>
        setShowContent(value, id, showContentObj, setShowContentObj)
      }
      betCardRef={betCardRef}
      betCardBodyRef={betCardBodyRef}
      isOptedIn={isOptedIn}
      isBetActive={isActive}
      betStatus={betStatusName}
      isCanceled={isCanceled}
      showBetsDetailedView={showBetsDetailedView}
      betRefund={betRefund}
      isBetWinner={isBetWinner}
      probableValue={betProbableValue}
      shouldShowHideLegButton={shouldShowHideLegButton}
      shouldShowPotentialReturn={shouldShowPotentialReturn}
      isDetailsOpened={isDetailsOpened}
      handleDetailsButton={() =>
        handleDetailsButton(
          selectedTab,
          selectedSettledTab,
          isDetailsOpened,
          showBetsDetailedView,
          setShowBetsDetailedView,
          selections,
          handleScrollPosition
        )
      }
      shouldRenderFooter={shouldRenderFooter}
      isWagerCancelable={
        isPickBet && currentOpenLeg
          ? isWagerCancelable && +currentOpenLeg.number === +raceNumber
          : isWagerCancelable
      }
      repeatButtonSearch={repeatButtonSearch()}
      showRepeatButton={!!showRepeatButton}
      renderRunnerSelection={renderedRunnerGroup()}
      showScratchedNotification={showScratchedNotification}
      shouldNotHideScratchNotification={shouldNotHideScratchNotification}
      setShouldNotHideScratchNotification={setShouldNotHideScratchNotification}
      scratchedTitle={scratchedTitle}
      scratchedLegText={
        isInsideRaceOfficials ? defaultScratchedText : scratchedLegText
      }
      hasFooter={hasFooter}
      onCancelWager={() => {
        onCancelWager(wager);
      }}
      shouldShowRebet={shouldShowRebet && !isInsideRaceOfficials}
      handleRebetClickEvent={handleRebetClickEvent}
      rebetWagerTypeName={rebetWagerTypeName}
      rebetSearch={rebetSearch}
      hasContentMaxHeight={hasContentMaxHeight}
      isMyBetsOpen={isMyBetsOpen}
      betsNumber={betsNumber}
      isCardBodyScrollable={isCardBodyScrollable}
      showMinMaxWillPays={showMinMaxWillPays}
      minWillPays={minWillPays}
      maxWillPays={maxWillPays}
      isScratchInfoDesign={myBetsTrackRulesToggle}
      renderPromoOnboarding={renderPromoOnboarding()}
      onShareWager={onShareWager}
      socialShareModalToggle={socialShareModalToggle && !isInsideRaceOfficials}
      isFDR={isFDR()}
    />
  );
};

MyBetsWager.defaultProps = {
  dispatch: noop,
  wager: {},
  mainWagerDetails: [],
  index: -1,
  useTvgPotReturnToggle: false,
  selectedTab: "ACTIVE",
  selectedSettledTab: "TODAY",
  races: [],
  raceNumber: 1,
  statusCode: "O",
  betAmount: 0,
  bettingInterests: [],
  raceDate: "",
  currentRaceDate: "",
  allRacesFromTrack: [],
  enablePickBetRebet: false,
  modalScrollableRef: null,
  activeBetsCounter: 0,
  settledBetsCounter: 0,
  closeModal: noop,
  raceUrl: "",
  showContentObj: {},
  setShowContentObj: noop,
  currentRace: {},
  currentOpenLeg: null,
  hasFooter: false,
  onCancelWager: noop,
  hasDetails: false,
  hasContentMaxHeight: false,
  isKey: false,
  isBox: false,
  isMyBetsOpen: false,
  isBetPlaced: false,
  isBetConfirmation: false,
  isInsideRaceOfficials: false,
  betsNumber: 1,
  promosOnboardingStep: null,
  onShareWager: noop
};

export default connect(
  (store) => ({
    selectedTab: getSelectedTab(store),
    isMyBetsOpen: getIsMyBetsOpen(store),
    selectedSettledTab: getSelectedSettledTab(store),
    activeBetsCounter: getTodayActiveCounter(store),
    settledBetsCounter: getTodaySettledCounter(store),
    enablePickBetRebet: getPickBetRebetToggle(store),
    useTvgPotReturnToggle: getFeatureUseTvgPotReturn(store),
    myBetsTrackRulesToggle: getMyBetsTrackRulesToggle(store),
    promosOnboardingObject: getStoryblokPromos(store),
    promosOnboardingStep: getStoryblokPromoByStep(store, PromoStepType.WAGER),
    socialShareModalToggle: getSocialShareToggle(store)
  }),
  (dispatch) => ({ dispatch })
)(MyBetsWager);
