import React from "react";
import { GestureResponderEvent } from "react-native";
import { noop } from "lodash";
import { BinaryFn, NullaryFn } from "@tvg/ts-types/Functional";
import { getHasPromo } from "@tvg/utils/PromoUtils";
import { IconButton, useTheme, Theme } from "@tvg/design-system";
import { Tag, LinkCard, Icon } from "@tvg/design-system/web";
import {
  TrackInfo,
  ResultedTag,
  IconButtonContainer,
  LinkCardContainer,
  IconContainer
} from "./styled-components";

interface Props {
  qaLabel: string;
  trackCode: string;
  trackName: string;
  isFavorite: boolean;
  isSEORacetracks: boolean;
  handleFavoriteChange: BinaryFn<boolean, string, void>;
  handleTrackClick: NullaryFn<void>;
  hasPromo?: boolean;
  hasResultedState?: boolean;
  isPromoTagShown?: boolean;
  useIsPromoTagShownFlag?: boolean;
}

const renderFavoriteIcon = (
  isFavorite: boolean,
  qaLabel: string,
  isSEORacetracks: boolean,
  handleFavoriteChange: BinaryFn<boolean, string, void>,
  trackCode: string,
  theme: Theme
) => (
  <IconButtonContainer theme={theme} isFavorite={isFavorite}>
    <IconButton
      qaLabel={`${qaLabel}-${isFavorite ? `FavoriteIcon` : `StarOutlineIcon`}`}
      onPress={(e: GestureResponderEvent) =>
        onFavoriteClickHandler(e, {
          handleFavoriteChange,
          isFavorite,
          trackCode
        })
      }
      variant="tertiary"
      size="s"
      iconName="star"
      tertiaryColor={isSEORacetracks ? "grey.800" : "blue.200"}
    />
  </IconButtonContainer>
);

const renderTrackPromoTag = (
  hasPromo: boolean,
  qaLabel: string,
  isPromoTagShown: boolean,
  useIsPromoTagShownFlag: boolean
) => {
  const shouldRenderPromoTag = getHasPromo(
    useIsPromoTagShownFlag,
    hasPromo,
    isPromoTagShown
  );

  return (
    shouldRenderPromoTag && (
      <Tag
        qaLabel={`${qaLabel}-PromoTag`}
        label="Promo"
        iconName="promo"
        variant="alert"
        size="medium"
      />
    )
  );
};

const renderTrackState = (
  hasResultedState: boolean,
  hasPromo: boolean,
  qaLabel: string,
  isPromoTagShown: boolean,
  useIsPromoTagShownFlag: boolean
) =>
  hasResultedState ? (
    <ResultedTag data-qa-label={`${qaLabel}-Resulted`}>Resulted</ResultedTag>
  ) : (
    renderTrackPromoTag(
      hasPromo,
      qaLabel,
      isPromoTagShown,
      useIsPromoTagShownFlag
    )
  );

const onFavoriteClickHandler = (
  e: GestureResponderEvent,
  props: Pick<Props, "handleFavoriteChange" | "isFavorite" | "trackCode">
) => {
  const { handleFavoriteChange, isFavorite, trackCode } = props;
  e.stopPropagation();
  handleFavoriteChange(!isFavorite, trackCode);
};

const TrackLineHeader = ({
  qaLabel = "",
  trackCode = "",
  trackName = "",
  isFavorite = false,
  isSEORacetracks = false,
  handleFavoriteChange = noop,
  handleTrackClick = noop,
  hasPromo = false,
  hasResultedState = false,
  isPromoTagShown = false,
  useIsPromoTagShownFlag = false
}: Props) => {
  const theme = useTheme();

  const startContentRender = (
    <>
      {renderFavoriteIcon(
        isFavorite,
        qaLabel,
        isSEORacetracks,
        handleFavoriteChange,
        trackCode,
        theme
      )}
      {renderTrackState(
        hasResultedState,
        hasPromo,
        qaLabel,
        isPromoTagShown,
        useIsPromoTagShownFlag
      )}
    </>
  );

  return (
    <TrackInfo data-qa-label={qaLabel}>
      <LinkCardContainer>
        {}
        <LinkCard
          title={trackName}
          startContent={startContentRender}
          endContent={
            <IconContainer>
              <Icon
                name="chevronRight"
                size="s"
                lineColor="--fd-colors-content-default"
              />
            </IconContainer>
          }
          onPress={handleTrackClick}
          qaLabel={`${qaLabel}-LinkCard`}
          isLinkItem
        />
      </LinkCardContainer>
    </TrackInfo>
  );
};

export default TrackLineHeader;
