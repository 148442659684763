import mediator from "@tvg/mediator";
import { BinaryFn } from "@tvg/ts-types/Functional";
import { noop } from "lodash";
import { createAuthTokenCookie } from "./sessionUtils";
import { UpdatedToken, LaunchInRegionDataXSell } from "./types/generalTypes";
import { isValidSessionByTokenXSell } from "./isValidSessionByToken";

export const geolocationTokenUpdatedActionType =
  "x-sell/bridge/to-native/geolocationTokenUpdated";

const openExternalLink = (uri: string) => {
  mediator.ios.dispatch({
    type: "x-sell/bridge/to-react/onLoadExternalUrl",
    payload: { webViewUrl: uri }
  });
};

const askLocation = (reason: string) => {
  mediator.ios.dispatch({
    type: "x-sell/bridge/to-react/onTriggerGeolocationRefresh",
    payload: {
      reason
    }
  });
};

const onTriggerGeolocation = (reason: string) => {
  mediator.ios.dispatch({
    type: "x-sell/bridge/to-react/onTriggerGeolocationRefresh",
    payload: {
      reason
    }
  });
};

const onTriggerSBMyBets = () =>
  mediator.ios.dispatch({
    type: "x-sell/bridge/to-react/onTriggerSBMyBets"
  });

// XSell don't have way to understand if GeoComply is in progress
const subscribeGeolocationRequest = noop;

const subscribeGeolocationUpdate = (callback: Function) => {
  const subscribeCallback = (data: { payload: UpdatedToken }) =>
    callback(data.payload.geolocationData.geoToken);

  mediator.ios.subscribe(geolocationTokenUpdatedActionType, subscribeCallback);
  // trigged in subscribeLoginComplete
  mediator.base.subscribe(geolocationTokenUpdatedActionType, subscribeCallback);
};

const subscribeLaunchInRegion = (
  callback: BinaryFn<string | undefined, string, void>
) => {
  mediator.ios.subscribe(
    "x-sell/bridge/to-native/launchInRegion",
    (data: { payload: LaunchInRegionDataXSell }) => {
      const { authToken, sportsbookRegion } = data.payload;
      if (authToken) {
        createAuthTokenCookie(authToken);
      }
      callback(authToken, sportsbookRegion);
    }
  );
};

const subscribeWebViewForeground = () => {
  document.addEventListener("visibilitychange", () => {
    if (
      document.visibilityState === "visible" &&
      !isValidSessionByTokenXSell()
    ) {
      mediator.base.dispatch({ type: "TRIGGER_LOGOUT" });
    }
  });
};

export default {
  openExternalLink,
  askLocation,
  onTriggerGeolocation,
  onTriggerSBMyBets,
  subscribeGeolocationRequest,
  subscribeGeolocationUpdate,
  subscribeLaunchInRegion,
  subscribeWebViewForeground
};
