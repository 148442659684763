export const horsePastRaces = [
  {
    id: "GP-12",
    raceDate: "2017-01-28T00:00:00",
    date: "2017-01-28T00:00:00",
    number: "12",
    raceTypeCode: "T",
    track: {
      name: "Gulfstream Park",
      code: "GP"
    },
    hasReplays: true,
    replayFileName: "movie",
    distance: "21f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 12000000,
    numRunners: 17,
    finishPosition: 1,
    scratched: false,
    odds: { numerator: 4, denominator: 5 }
  },
  {
    id: "LRC-9",
    raceDate: "2016-12-17T00:00:00",
    date: "2016-12-17T00:00:00",
    number: "9",
    raceTypeCode: "T",
    replayFileName: "movie",
    track: {
      name: "Los Alamitos TBred",
      code: "LRC"
    },
    hasReplays: false,
    distance: "8.5f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 180000,
    numRunners: 17,
    finishPosition: 0,
    scratched: false,
    odds: { numerator: 4, denominator: null },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "SA-12",
    raceDate: "2016-11-05T00:00:00",
    date: "2016-11-05T00:00:00",
    number: "12",
    raceTypeCode: "T",
    replayFileName: "movie",
    track: {
      name: "Breeders Cup Santa Anita",
      code: "SA"
    },
    hasReplays: true,
    distance: "10f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 6000000,
    numRunners: 20,
    finishPosition: null,
    scratched: true
  },
  {
    id: "SA-7",
    raceDate: "2016-10-01T00:00:00",
    date: "2016-10-01T00:00:00",
    number: "7",
    raceTypeCode: "T",
    track: {
      name: "Santa Anita",
      code: "SA"
    },
    hasReplays: true,
    distance: "9f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 300000,
    numRunners: 17,
    finishPosition: 99,
    scratched: false,
    odds: { numerator: 4, denominator: null },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "DUB-9",
    raceDate: "2016-03-26T00:00:00",
    date: "2016-03-26T00:00:00",
    number: "9",
    raceTypeCode: "T",
    track: {
      name: "Dubai",
      code: "DUB"
    },
    hasReplays: true,
    distance: "10f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 10000000,
    numRunners: 17,
    finishPosition: 4,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "SA-8",
    raceDate: "2016-01-09T00:00:00",
    date: "2016-01-09T00:00:00",
    number: "8",
    raceTypeCode: "T",
    track: {
      name: "Santa Anita",
      code: "SA"
    },
    hasReplays: true,
    distance: "8.5f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 200000,
    numRunners: 17,
    finishPosition: 3,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "DUB-9",
    raceDate: "2015-03-28T00:00:00",
    date: "2015-03-28T00:00:00",
    number: "9",
    raceTypeCode: "T",
    track: {
      name: "Dubai",
      code: "DUB"
    },
    hasReplays: true,
    distance: "10f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 10000000,
    numRunners: 17,
    finishPosition: 2,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "SA-8",
    raceDate: "2015-02-07T00:00:00",
    date: "2015-02-07T00:00:00",
    number: "8",
    raceTypeCode: "T",
    track: {
      name: "Santa Anita",
      code: "SA"
    },
    hasReplays: true,
    distance: "9f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 500000,
    numRunners: 17,
    finishPosition: 1,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "SXC-4",
    raceDate: "2015-02-07T00:00:00",
    date: "2015-02-07T00:00:00",
    number: "4",
    raceTypeCode: "T",
    track: {
      name: "Cross Country Pick 4",
      code: "SXC"
    },
    hasReplays: true,
    distance: "9f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 500000,
    numRunners: 17,
    finishPosition: 1,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "DMR-8",
    raceDate: "2014-11-29T00:00:00",
    date: "2014-11-29T00:00:00",
    number: "8",
    raceTypeCode: "T",
    track: {
      name: "Del Mar",
      code: "DMR"
    },
    hasReplays: true,
    distance: "9f",
    surface: {
      shortName: "Turf",
      defaultCondition: "Good"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 300000,
    numRunners: 17,
    finishPosition: 1,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "SA-12",
    raceDate: "2014-11-01T00:00:00",
    date: "2014-11-01T00:00:00",
    number: "12",
    raceTypeCode: "T",
    track: {
      name: "Breeders Cup Santa Anita",
      code: "SA"
    },
    hasReplays: true,
    distance: "10f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 5000000,
    numRunners: 17,
    finishPosition: 10,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "PHI-12",
    raceDate: "2014-09-20T00:00:00",
    date: "2014-09-20T00:00:00",
    number: "12",
    raceTypeCode: "T",
    track: {
      name: "Parx Racing",
      code: "PHI"
    },
    hasReplays: true,
    distance: "9f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 1000000,
    numRunners: 17,
    finishPosition: 1,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "BEL-11",
    raceDate: "2014-06-07T00:00:00",
    date: "2014-06-07T00:00:00",
    number: "11",
    raceTypeCode: "T",
    track: {
      name: "Belmont",
      code: "BEL"
    },
    hasReplays: true,
    distance: "12f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 1500000,
    numRunners: 17,
    finishPosition: 1,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "PIM-12",
    raceDate: "2014-05-17T00:00:00",
    date: "2014-05-17T00:00:00",
    number: "12",
    raceTypeCode: "T",
    track: {
      name: "Pimlico",
      code: "PIM"
    },
    hasReplays: true,
    distance: "9.5f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 1500000,
    numRunners: 17,
    finishPosition: 1,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "CD-11",
    raceDate: "2014-05-03T00:00:00",
    date: "2014-05-03T00:00:00",
    number: "11",
    raceTypeCode: "T",
    track: {
      name: "Churchill Downs KY Derby",
      code: "CD"
    },
    hasReplays: true,
    distance: "10f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 2000000,
    numRunners: 17,
    finishPosition: 1,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "SA-8",
    raceDate: "2014-04-05T00:00:00",
    date: "2014-04-05T00:00:00",
    number: "8",
    raceTypeCode: "T",
    track: {
      name: "Santa Anita",
      code: "SA"
    },
    hasReplays: true,
    distance: "9f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 1000000,
    numRunners: 17,
    finishPosition: 1,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "SA-5",
    raceDate: "2014-03-08T00:00:00",
    date: "2014-03-08T00:00:00",
    number: "5",
    raceTypeCode: "T",
    track: {
      name: "Santa Anita",
      code: "SA"
    },
    hasReplays: true,
    distance: "8.5f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 300000,
    numRunners: 17,
    finishPosition: 1,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "SA-10",
    raceDate: "2014-01-25T00:00:00",
    date: "2014-01-25T00:00:00",
    number: "10",
    raceTypeCode: "T",
    track: {
      name: "Santa Anita",
      code: "SA"
    },
    hasReplays: true,
    distance: "8.5f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 250000,
    numRunners: 17,
    finishPosition: 1,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "SA-3",
    raceDate: "2013-11-01T00:00:00",
    date: "2013-11-01T00:00:00",
    number: "3",
    raceTypeCode: "T",
    track: {
      name: "Santa Anita",
      code: "SA"
    },
    hasReplays: true,
    distance: "8f",
    surface: {
      shortName: "Dirt",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 200000,
    numRunners: 17,
    finishPosition: 1,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "DMR-8",
    raceDate: "2013-09-04T00:00:00",
    date: "2013-09-04T00:00:00",
    number: "8",
    raceTypeCode: "T",
    track: {
      name: "Del Mar",
      code: "DMR"
    },
    hasReplays: true,
    distance: "7f",
    surface: {
      shortName: "Synth",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 300000,
    numRunners: 17,
    finishPosition: 1,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  },
  {
    id: "DMR-7",
    raceDate: "2013-07-31T00:00:00",
    date: "2013-07-31T00:00:00",
    number: "7",
    raceTypeCode: "T",
    track: {
      name: "Del Mar",
      code: "DMR"
    },
    hasReplays: true,
    distance: "5.5f",
    surface: {
      shortName: "Synth",
      defaultCondition: "Fast"
    },
    raceClass: {
      shortName: "Stakes"
    },
    purse: 100000,
    numRunners: 17,
    finishPosition: 1,
    scratched: false,
    odds: { numerator: 4, denominator: 5 },
    postRaceReport:
      "Dueled, short lead backstr, hustled hard late turn, clr top str, heldDueled, short lead backstr."
  }
];

export const runnerResults = [
  {
    runnerId: "1",
    runnerName: "Arrogate",
    trainer: "Baffert Bob",
    jockey: "Smith Mike E",
    scratched: false,
    finishPosition: 1,
    accBeatenDistance: "",
    accBeatenDistanceStatus: "",
    postRaceReport:
      "rail position, 3w, turn, mild pressure late, geared down 1/16",
    odds: { numerator: 4, denominator: 5 }
  },
  {
    runnerId: "7",
    runnerName: "Shaman Ghost",
    trainer: "Jerkens James A",
    jockey: "Ortiz Jose L",
    scratched: false,
    finishPosition: 2,
    accBeatenDistance: "4.75",
    accBeatenDistanceStatus: "4¾",
    postRaceReport:
      "allowed to settle, 2-3w rally, lug in 1/16, clearly 2nd best",
    odds: { numerator: 19, denominator: null }
  },
  {
    runnerId: "3",
    runnerName: "Neolithic",
    trainer: "Pletcher Todd A",
    jockey: "Velazquez John R",
    scratched: false,
    finishPosition: 3,
    accBeatenDistance: "3.50",
    accBeatenDistanceStatus: "3½",
    postRaceReport: "pressed pace two wide, led between calls turn, no match",
    odds: { numerator: 23, denominator: null }
  },
  {
    runnerId: "9",
    runnerName: "Keen Ice",
    trainer: "Pletcher Todd A",
    jockey: "Castellano Javier",
    scratched: false,
    finishPosition: 4,
    accBeatenDistance: "2.75",
    accBeatenDistanceStatus: "2¾",
    postRaceReport:
      "outrun for 6F three wide, four wide into stretch, late interest",
    odds: { numerator: 16, denominator: null }
  },
  {
    runnerId: "5",
    runnerName: "War Story",
    trainer: "Navarro Jorge",
    jockey: "Gallardo Antonio A",
    scratched: false,
    finishPosition: 5,
    accBeatenDistance: "2.50",
    accBeatenDistanceStatus: "2¼",
    postRaceReport:
      "rail both turns, clear down backstretch behind 1st flight, evenly",
    odds: { numerator: 45, denominator: null }
  },
  {
    runnerId: "4",
    runnerName: "Noble Bird",
    trainer: "Casse Mark E",
    jockey: "Leparoux Julien R",
    scratched: false,
    finishPosition: 6,
    accBeatenDistance: "1",
    accBeatenDistanceStatus: "1",
    postRaceReport:
      "slight bobble start, alert to lead inside, weakened far turn rail",
    odds: { numerator: 30, denominator: null }
  },
  {
    runnerId: "8",
    runnerName: "Semper Fortis",
    trainer: "O'neill Doug F",
    jockey: "Gaffalione Tyler",
    scratched: true,
    finishPosition: 7,
    accBeatenDistance: "9",
    accBeatenDistanceStatus: "9",
    postRaceReport: "two wide first turn, two to three wide far turn, tired",
    odds: { numerator: 99, denominator: null }
  },
  {
    runnerId: "10",
    runnerName: "Breaking Lucky",
    trainer: "Baker Reade",
    jockey: "Contreras Luis",
    scratched: false,
    finishPosition: 8,
    accBeatenDistance: "3.25",
    accBeatenDistanceStatus: "3¼",
    postRaceReport: "four wide first turn, three wide far turn, no factor",
    odds: { numerator: 70, denominator: null }
  },
  {
    runnerId: "12",
    runnerName: "California Chrome",
    trainer: "Sherman Art",
    jockey: "Espinoza Victor",
    scratched: false,
    finishPosition: 9,
    accBeatenDistance: "3",
    accBeatenDistanceStatus: "3",
    postRaceReport:
      "five wide to first turn, asked 4 wide near far turn, no response",
    odds: { numerator: 6, denominator: 5 }
  },
  {
    runnerId: "2",
    runnerName: "Prayer For Relief",
    trainer: "Romans Dale L",
    jockey: "Geroux Florent",
    scratched: false,
    finishPosition: 10,
    accBeatenDistance: "4.25",
    accBeatenDistanceStatus: "4¼",
    postRaceReport: "rail first turn, two wide far turn, never prominent",
    odds: { numerator: 99, denominator: null }
  },
  {
    runnerId: "6",
    runnerName: "War Envoy",
    trainer: "Ruis Mick",
    jockey: "Saez Luis",
    scratched: false,
    finishPosition: 11,
    accBeatenDistance: "10",
    accBeatenDistanceStatus: "10",
    postRaceReport: "bobbled start, rail turns, finished early",
    odds: { numerator: 99, denominator: null }
  },

  {
    runnerId: "11",
    runnerName: "Eragon (Arg)",
    trainer: "Wohlers Laura",
    jockey: "Prado Edgar S",
    scratched: false,
    finishPosition: 12,
    accBeatenDistance: "18",
    accBeatenDistanceStatus: "18",
    postRaceReport: "trailed throughout, eased in stretch",
    odds: { numerator: 80, denominator: null }
  },
  {
    runnerId: "41",
    runnerName: "California Hello",
    trainer: "Sherman Art 2",
    jockey: "Espinoza Victor 2",
    scratched: true,
    finishPosition: 99,
    accBeatenDistance: "8",
    accBeatenDistanceStatus: "8",
    postRaceReport:
      "five wide to first turn, asked 4 wide near far turn, no response",
    odds: { numerator: 6, denominator: 5 }
  }
];

export const runnerResultsBasic = [
  {
    runnerId: "1",
    runnerName: "Arrogate",
    trainer: "Baffert Bob",
    jockey: "Smith Mike E",
    scratched: false,
    finishPosition: 1,
    odds: { numerator: 4, denominator: 5 }
  },
  {
    runnerId: "7",
    runnerName: "Shaman Ghost",
    trainer: "Jerkens James A",
    jockey: "Ortiz Jose L",
    scratched: false,
    finishPosition: 2,
    odds: { numerator: 19, denominator: null }
  },
  {
    runnerId: "3",
    runnerName: "Neolithic",
    trainer: "Pletcher Todd A",
    jockey: "Velazquez John R",
    scratched: false,
    finishPosition: 3,
    odds: { numerator: 23, denominator: null }
  },
  {
    runnerId: "9",
    runnerName: "Keen Ice",
    trainer: "Pletcher Todd A",
    jockey: "Castellano Javier",
    scratched: false,
    finishPosition: 4,
    odds: { numerator: 16, denominator: null }
  },
  {
    runnerId: "5",
    runnerName: "War Story",
    trainer: "Navarro Jorge",
    jockey: "Gallardo Antonio A",
    scratched: false,
    finishPosition: 5,
    odds: { numerator: 45, denominator: null }
  }
];

export const pastRaceFullResults = [
  {
    runnerId: "1",
    runnerName: "Arrogate",
    trainer: "Baffert Bob",
    jockey: "Smith Mike E",
    scratched: false,
    finishPosition: 1,
    odds: {
      numerator: 4,
      denominator: 5
    },
    postRaceReport:
      "rail position, 3w, turn, mild pressure late, geared down 1/16",
    accBeatenDistance: ""
  },
  {
    runnerId: "7",
    runnerName: "Shaman Ghost",
    trainer: "Jerkens James A",
    jockey: "Ortiz Jose L",
    scratched: false,
    finishPosition: 2,
    odds: {
      numerator: 19,
      denominator: null
    },
    postRaceReport:
      "allowed to settle, 2-3w rally, lug in 1/16, clearly 2nd best",
    accBeatenDistance: "4¾"
  },
  {
    runnerId: "3",
    runnerName: "Neolithic",
    trainer: "Pletcher Todd A",
    jockey: "Velazquez John R",
    scratched: false,
    finishPosition: 3,
    odds: {
      numerator: 23,
      denominator: null
    },
    postRaceReport: "pressed pace two wide, led between calls turn, no match",
    accBeatenDistance: "3½"
  },
  {
    runnerId: "9",
    runnerName: "Keen Ice",
    trainer: "Pletcher Todd A",
    jockey: "Castellano Javier",
    scratched: false,
    finishPosition: 4,
    odds: {
      numerator: 16,
      denominator: null
    },
    postRaceReport:
      "outrun for 6F three wide, four wide into stretch, late interest",
    accBeatenDistance: "2¾"
  },
  {
    runnerId: "5",
    runnerName: "War Story",
    trainer: "Navarro Jorge",
    jockey: "Gallardo Antonio A",
    scratched: false,
    finishPosition: 5,
    odds: {
      numerator: 45,
      denominator: null
    },
    postRaceReport:
      "rail both turns, clear down backstretch behind 1st flight, evenly",
    accBeatenDistance: "2¼"
  },
  {
    runnerId: "4",
    runnerName: "Noble Bird",
    trainer: "Casse Mark E",
    jockey: "Leparoux Julien R",
    scratched: false,
    finishPosition: 6,
    odds: {
      numerator: 30,
      denominator: null
    },
    postRaceReport:
      "slight bobble start, alert to lead inside, weakened far turn rail",
    accBeatenDistance: "1"
  },
  {
    runnerId: "8",
    runnerName: "Semper Fortis",
    trainer: "O'neill Doug F",
    jockey: "Gaffalione Tyler",
    scratched: false,
    finishPosition: 7,
    odds: {
      numerator: 99,
      denominator: null
    },
    postRaceReport: "two wide first turn, two to three wide far turn, tired",
    accBeatenDistance: "9"
  },
  {
    runnerId: "10",
    runnerName: "Breaking Lucky",
    trainer: "Baker Reade",
    jockey: "Contreras Luis",
    scratched: false,
    finishPosition: 8,
    odds: {
      numerator: 70,
      denominator: null
    },
    postRaceReport: "four wide first turn, three wide far turn, no factor",
    accBeatenDistance: "3¼"
  },
  {
    runnerId: "12",
    runnerName: "California Chrome",
    trainer: "Sherman Art",
    jockey: "Espinoza Victor",
    scratched: false,
    finishPosition: 9,
    odds: {
      numerator: 6,
      denominator: 5
    },
    postRaceReport:
      "five wide to first turn, asked 4 wide near far turn, no response",
    accBeatenDistance: "3"
  },
  {
    runnerId: "2",
    runnerName: "Prayer For Relief",
    trainer: "Romans Dale L",
    jockey: "Geroux Florent",
    scratched: false,
    finishPosition: 10,
    odds: {
      numerator: 99,
      denominator: null
    },
    postRaceReport: "rail first turn, two wide far turn, never prominent",
    accBeatenDistance: "4¼"
  },
  {
    runnerId: "6",
    runnerName: "War Envoy",
    trainer: "Ruis Mick",
    jockey: "Saez Luis",
    scratched: false,
    finishPosition: 11,
    odds: {
      numerator: 99,
      denominator: null
    },
    postRaceReport: "bobbled start, rail turns, finished early",
    accBeatenDistance: "10"
  },
  {
    runnerId: "11",
    runnerName: "Eragon (Arg)",
    trainer: "Wohlers Laura",
    jockey: "Prado Edgar S",
    scratched: false,
    finishPosition: 12,
    odds: {
      numerator: 80,
      denominator: null
    },
    postRaceReport: "trailed throughout, eased in stretch",
    accBeatenDistance: "18"
  }
];

export const pastRaceBasicResults = [
  {
    runnerId: "1",
    runnerName: "Arrogate",
    trainer: "Baffert Bob",
    jockey: "Smith Mike E",
    scratched: false,
    finishPosition: 1
  },
  {
    runnerId: "7",
    runnerName: "Shaman Ghost",
    trainer: "Jerkens James A",
    jockey: "Ortiz Jose L",
    scratched: false,
    finishPosition: 2
  },
  {
    runnerId: "3",
    runnerName: "Neolithic",
    trainer: "Pletcher Todd A",
    jockey: "Velazquez John R",
    scratched: false,
    finishPosition: 3
  },
  {
    runnerId: "9",
    runnerName: "Keen Ice",
    trainer: "Pletcher Todd A",
    jockey: "Castellano Javier",
    scratched: false,
    finishPosition: 4
  }
];

export const ipprunners = [
  {
    biNumber: 1,
    currentOdds: { numerator: 5, denominator: 2 },
    morningLineOdds: { numerator: 7, denominator: 2 },
    isFavorite: true,
    runners: [
      {
        horseName: "Bonnin Margaret",
        runnerId: "1",
        scratched: false,
        hasJockeyChanges: false,
        timeform: {
          analystsComments: "Craze horse runne fast, bet now",
          silkUrlSvg: "silk.svg"
        },
        handicapping: [
          { key: "jockey", value: "David Allan" },
          { key: "trainer", value: "Tim Easterby" }
        ],
        masterPickNumber: 3,
        dob: 2017
      },
      {
        horseName: "Horsey Horsing",
        runnerId: "1A",
        scratched: true,
        hasJockeyChanges: false,
        handicapping: [
          { key: "jockey", value: "David Allan" },
          { key: "trainer", value: "Tim Easterby" }
        ],
        masterPickNumber: 3,
        dob: 2017
      }
    ]
  },
  {
    biNumber: 2,
    currentOdds: { numerator: 33, denominator: null },
    morningLineOdds: { numerator: 33, denominator: null },
    isFavorite: false,
    runners: [
      {
        horseName: "Ravenscar (Ire)",
        runnerId: "2",
        scratched: true,
        hasJockeyChanges: false,
        handicapping: [
          { key: "jockey", value: "Ben Curtis" },
          { key: "trainer", value: "Philip Kirby" }
        ],
        dob: 2016
      }
    ]
  },
  {
    biNumber: 3,
    currentOdds: { numerator: 7, denominator: 2 },
    morningLineOdds: { numerator: 5, denominator: null },
    isFavorite: false,
    runners: [
      {
        horseName: "Golden Hind",
        runnerId: "3",
        scratched: false,
        hasJockeyChanges: false,
        handicapping: [
          { key: "jockey", value: "Daniel Tudhope" },
          { key: "trainer", value: "David O'meara" }
        ],
        dob: 2017
      }
    ]
  },
  {
    biNumber: 4,
    currentOdds: { numerator: 7, denominator: 2 },
    morningLineOdds: { numerator: 10, denominator: null },
    isFavorite: false,
    runners: [
      {
        horseName: "Rainbow Jet (Ire)",
        runnerId: "4",
        scratched: false,
        hasJockeyChanges: false,
        handicapping: [
          { key: "jockey", value: "Graham Lee" },
          { key: "trainer", value: "John Mackie" }
        ],
        dob: 2017
      }
    ]
  },
  {
    biNumber: 5,
    currentOdds: { numerator: 7, denominator: 2 },
    morningLineOdds: { numerator: 9, denominator: 2 },
    isFavorite: false,
    runners: [
      {
        horseName: "Bellatrixsa (Ire)",
        runnerId: "5",
        scratched: false,
        hasJockeyChanges: false,
        handicapping: [
          { key: "jockey", value: "Connor Beasley" },
          { key: "trainer", value: "Michael Dods" }
        ],
        masterPickNumber: 1,
        dob: 2017
      }
    ]
  }
];

export const ippRunnersSorted = [
  {
    biNumber: 1,
    currentOdds: { numerator: 5, denominator: 2 },
    morningLineOdds: { numerator: 7, denominator: 2 },
    isFavorite: true,
    runners: [
      {
        horseName: "Bonnin Margaret",
        runnerId: "1",
        scratched: false,
        hasJockeyChanges: false,
        timeform: {
          analystsComments: "Craze horse runne fast, bet now",
          silkUrlSvg: "silk.svg"
        },
        handicapping: [
          { key: "jockey", value: "David Allan" },
          { key: "trainer", value: "Tim Easterby" }
        ],
        masterPickNumber: 3,
        dob: 2017
      }
    ]
  },
  {
    biNumber: 3,
    currentOdds: { numerator: 7, denominator: 2 },
    morningLineOdds: { numerator: 5, denominator: null },
    isFavorite: false,
    runners: [
      {
        horseName: "Golden Hind",
        runnerId: "3",
        scratched: false,
        hasJockeyChanges: false,
        handicapping: [
          { key: "jockey", value: "Daniel Tudhope" },
          { key: "trainer", value: "David O'meara" }
        ],
        dob: 2017
      }
    ]
  },
  {
    biNumber: 4,
    currentOdds: { numerator: 7, denominator: 2 },
    morningLineOdds: { numerator: 10, denominator: null },
    isFavorite: false,
    runners: [
      {
        horseName: "Rainbow Jet (Ire)",
        runnerId: "4",
        scratched: false,
        hasJockeyChanges: false,
        handicapping: [
          { key: "jockey", value: "Graham Lee" },
          { key: "trainer", value: "John Mackie" }
        ],
        dob: 2017
      }
    ]
  },
  {
    biNumber: 5,
    currentOdds: { numerator: 7, denominator: 2 },
    morningLineOdds: { numerator: 9, denominator: 2 },
    isFavorite: false,
    runners: [
      {
        horseName: "Bellatrixsa (Ire)",
        runnerId: "5",
        scratched: false,
        hasJockeyChanges: false,
        handicapping: [
          { key: "jockey", value: "Connor Beasley" },
          { key: "trainer", value: "Michael Dods" }
        ],
        masterPickNumber: 1,
        dob: 2017
      }
    ]
  },
  {
    biNumber: 1,
    currentOdds: { numerator: 5, denominator: 2 },
    morningLineOdds: { numerator: 7, denominator: 2 },
    isFavorite: true,
    runners: [
      {
        horseName: "Horsey Horsing",
        runnerId: "1A",
        scratched: true,
        hasJockeyChanges: false,
        handicapping: [
          { key: "jockey", value: "David Allan" },
          { key: "trainer", value: "Tim Easterby" }
        ],
        masterPickNumber: 3,
        dob: 2017
      }
    ]
  },
  {
    biNumber: 2,
    currentOdds: { numerator: 33, denominator: null },
    morningLineOdds: { numerator: 33, denominator: null },
    isFavorite: false,
    runners: [
      {
        horseName: "Ravenscar (Ire)",
        runnerId: "2",
        scratched: true,
        hasJockeyChanges: false,
        handicapping: [
          { key: "jockey", value: "Ben Curtis" },
          { key: "trainer", value: "Philip Kirby" }
        ],
        dob: 2016
      }
    ]
  }
];

export const activeHorse = {
  biNumber: 1,
  currentOdds: { numerator: 5, denominator: 2 },
  morningLineOdds: { numerator: 7, denominator: 2 },
  isFavorite: true,
  runners: [
    {
      horseName: "Horsey Horsing",
      runnerId: "1A",
      scratched: true,
      hasJockeyChanges: false,
      handicapping: [
        { key: "jockey", value: "David Allan" },
        { key: "trainer", value: "Tim Easterby" }
      ],
      masterPickNumber: 3,
      dob: 2017
    }
  ]
};

export const emptyMessagesDefault = {
  emptyMessageTitle: "",
  emptyMessageNone: "",
  emptyMessageWithFilterMultiple: "",
  emptyMessageWithFilterSingle: ""
};

export const mtpNotificationConfigDefault = {
  mtpBelowThresholdText: "",
  raceOffText: "",
  mtpThreshold: 3,
  showNotificationDelay: 5,
  hideNotificationDelay: 20
};

export const handicappingRunnerFlagsDefault = {
  title: "",
  subtitle: "",
  subtitleRunnerFlags: "",
  flags: []
};
