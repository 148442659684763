import styled from "styled-components";
import { fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const GeolocateInContainer = styled.section`
  background-color: ${buildColor("white", "100")};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 16px 12px;
`;

export const TextWrapper = styled.p`
  font-family: ${fontNormal};
  font-size: 14px;
  color: ${buildColor("grey", "900")};
  line-height: 18px;
  margin-bottom: 16px;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;

  a:first-child {
    margin-bottom: 8px;
  }
`;
