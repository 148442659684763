import React, { Component } from "react";
import classNames from "classnames";
import _ from "lodash";
import ArrowUp from "@tvg/ui-bootstrap/assets/svg/arrow-up.svg";
import ArrowDown from "@tvg/ui-bootstrap/assets/svg/down-arrow.svg";
import WarningIcon from "@tvg/ui-bootstrap/assets/svg/warning.svg";
import OkIcon from "@tvg/ui-bootstrap/assets/svg/success.svg";

import style from "./style.css";
import states from "./states.json";

const createStatesList = (statesObject) =>
  statesObject.states.map((state) => (
    <option key={state.name} value={state.abbreviation}>
      {state.name}
    </option>
  ));

const renderLocationDisclaimer = () => (
  <div className={style.disclaimer}>
    By entering this site, I certify that I am currently located in the state
    selected above.
  </div>
);

class StateSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    _.bindAll(this, ["renderLabel", "renderSelect", "onChangeSelectHolder"]);
  }

  state;

  onChangeSelectHolder(e) {
    const stateValue = e.target.value;
    this.props.stateSelect(stateValue);
    this.props.updateMainState({ stateValue });
    if (this.props.isStateValid === false && stateValue !== "") {
      this.props.updateMainState({
        isStateValid: true,
        isStateCorrected: true,
        stateValue
      });
    }
  }

  renderLabel() {
    const WarningFeedbackIcon = !this.props.isStateValid && (
      <WarningIcon
        className={classNames({
          [style.statusIcon]: true,
          [style.warningIcon]: true
        })}
      />
    );

    const OkFeedbackIcon = this.props.isStateCorrected &&
      this.props.isStateValid && (
        <OkIcon
          className={classNames({
            [style.statusIcon]: true,
            [style.warningIcon]: true
          })}
        />
      );

    return (
      <label htmlFor="stateSelector" className={style.label}>
        State{WarningFeedbackIcon || OkFeedbackIcon}
      </label>
    );
  }

  renderSelect;
  renderSelect() {
    const selectStyle = classNames({
      [style.inputSelect]: true,
      [style.inputSelectInvalid]: !this.props.isStateValid
    });

    const ArrowIcon = this.state.isOpen ? ArrowUp : ArrowDown;

    const WaningMessage = !this.props.isStateValid && (
      <div className={style.warningMessage}>Please select a State.</div>
    );

    return (
      <div className={style.inputSelectWrapper}>
        <select
          id="stateSelector"
          className={selectStyle}
          data-qa-label="stateSelector"
          onChange={this.onChangeSelectHolder}
          onFocus={this.props.firstTouchForGTM}
          onKeyPress={this.props.keyIsPressed}
        >
          <option value="" disabled selected>
            Select a State
          </option>
          {createStatesList(states)}
        </select>
        <ArrowIcon className={style.inputSelectArrow} />
        {WaningMessage}
      </div>
    );
  }

  render() {
    return (
      <div className={style.container}>
        {this.renderLabel()}
        {this.renderSelect()}
        {renderLocationDisclaimer()}
      </div>
    );
  }
}

export default StateSelector;
