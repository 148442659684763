import React, { PureComponent, Fragment } from "react";
import { bindAll, noop } from "lodash";

import Button from "../Buttons/button";
import { Text, Container, RedirectLink } from "./styled-components";

export default class LoginNotification extends PureComponent {
  static defaultProps = {
    type: "expired",
    targetDomain: "",
    redirectMessage: "",
    callback: noop
  };

  constructor(props) {
    super(props);
    bindAll(this, ["expiredContent", "redirectContent", "getContent"]);
  }

  getContent() {
    const expiredSessionMessage =
      "Your session has expired and you were automatically logged out. Please login again.";

    const appStoreMessage = `Sorry you are on the wrong app. From your log in details we see you are a ${this.props.targetDomain} account holder. We have an app dedicated just for ${this.props.targetDomain} players. Download it now from the App Store`;

    switch (this.props.type) {
      case "expired":
        return this.expiredContent(expiredSessionMessage, "ok");
      case "appStore":
        return this.expiredContent(appStoreMessage, "App Store");
      case "redirect":
      default:
        return this.redirectContent(this.props.redirectMessage);
    }
  }

  expiredContent(message, text) {
    return (
      <Fragment>
        <Text data-qa-label="loginNotifications-expiredContentText">
          {message}
        </Text>
        <Button
          onClick={this.props.callback}
          size="big"
          type="primary"
          isStretched
          data-qa-label="loginNotifications-expiredContentButton"
        >
          {text}
        </Button>
      </Fragment>
    );
  }

  redirectContent(message) {
    return (
      <Fragment>
        <Text data-qa-label="loginNotifications-redirectText">{message}</Text>
        <RedirectLink
          href={`${window.location.protocol}//${this.props.targetDomain}`}
          data-qa-label="loginNotifications-redirectButton"
        >
          Ok
        </RedirectLink>
      </Fragment>
    );
  }

  render() {
    return <Container>{this.getContent()}</Container>;
  }
}
