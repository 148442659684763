import React, { Component, Fragment } from "react";
import { bindAll, noop } from "lodash";

import Button from "../../_atom/Buttons/button";
import LoginForm from "../../_organism/LoginForm";
import ProcessingOverlay from "../../_static/ProcessingOverlay";
import { fontMedium } from "../../_static/Typography";
import { successOutline } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";

import {
  LoginResponsibly,
  LoginFooterContainer,
  LoginContainer,
  Content,
  ResetCreateLinksContainer,
  ResetCredButton,
  CreateAccountButton,
  PerimeterxContainer,
  RobotMessage,
  FooterText,
  LoginMessageWrapper,
  LoginMessageTitle,
  LoginMessage
} from "./styled-components";

export const renderLoginResponsibly = (
  isMobile,
  noStateSelector,
  message,
  hasMarginTop
) => (
  <LoginResponsibly
    isMobile={isMobile}
    data-qa-label="loginResponsible"
    hasMarginTop={hasMarginTop}
  >
    {message}
  </LoginResponsibly>
);

export const renderSuccessMessage = (message, device, isLoginError) => {
  return (
    <LoginMessageWrapper device={device} isLoginError={isLoginError}>
      <LoginMessageTitle>
        <Icon icon={successOutline} color={buildColor("green", "600")} />
        {message.title}
      </LoginMessageTitle>
      <LoginMessage>{message.message}</LoginMessage>
    </LoginMessageWrapper>
  );
};

const queryParams = typeof window !== "undefined" ? window.location.search : "";

export default class Login extends Component {
  static defaultProps = {
    redirectToPRF: noop,
    mobile: false,
    isLoginError: false,
    errorTitle: "",
    errorMessage: null,
    usernameStatus: null,
    usernameCallback: noop,
    username: "",
    passwordStatus: null,
    passwordCallback: noop,
    password: "",
    selectCallback: noop,
    stateSelectorStatus: null,
    onLoginCallback: noop,
    signUpCallback: noop,
    onBlurHandler: noop,
    enterKeyHandler: noop,
    closeModal: noop,
    states: [],
    isProcessing: false,
    noStateSelector: false,
    awareMessage:
      "AWARE - Always Wager Responsible - National Program Gamblers Helpline: 1-800-522-4700. Age restrictions apply and you must live in a location where TVG offers wagering services. Void where prohibited. Other restrictions apply.",
    hasAwareMessageMarginTop: true,
    isHumanChallengeEnabled: false,
    robotMessage: "",
    recoverLink: `${queryParams}#forgot-credentials`,
    hasSuccessMessage: false,
    device: "mobile",
    isWagerpad: false
  };

  constructor(props) {
    super(props);
    bindAll(this, ["renderForm", "renderLoginFooter"]);
  }

  renderForm() {
    return (
      <Fragment>
        <LoginForm
          passwordPlaceholder={this.props.passwordPlaceholder}
          mobile={this.props.mobile}
          username={this.props.username}
          usernameCallback={this.props.usernameCallback}
          errorTitle={this.props.errorTitle}
          errorMessage={this.props.errorMessage}
          password={this.props.password}
          passwordCallback={this.props.passwordCallback}
          states={this.props.states}
          selectCallback={this.props.selectCallback}
          noStateSelector={this.props.noStateSelector}
          isLoginError={this.props.isLoginError}
          usernameStatus={this.props.usernameStatus}
          passwordStatus={this.props.passwordStatus}
          stateSelectorStatus={this.props.stateSelectorStatus}
          onBlurHandler={this.props.onBlurHandler}
          onEnterKeyHandler={(e) => this.props.enterKeyHandler(e)}
          redirectToPRF={this.props.redirectToPRF}
          recoverLink={this.props.recoverLink}
          closeModal={this.props.closeModal}
          device={this.props.device}
        />
      </Fragment>
    );
  }

  renderLoginFooter() {
    const { device } = this.props;

    return (
      <LoginFooterContainer data-qa-label="loginFooter" device={device}>
        {this.props.isHumanChallengeEnabled && (
          <RobotMessage>{this.props.robotMessage}</RobotMessage>
        )}
        <PerimeterxContainer id="pxElement" />
        {!this.props.isHumanChallengeEnabled && (
          <Button
            isStretched
            onClick={this.props.onLoginCallback}
            size="bigger"
            isUppercase={false}
            textTransform="capitalize"
            fontFamily={fontMedium}
            qaLabel="loginBtn"
            isBold={false}
          >
            log in
          </Button>
        )}
        <Fragment>
          <ResetCreateLinksContainer>
            <FooterText data-qa-label="signup-description">
              Don’t have an account?&nbsp;
            </FooterText>
            <CreateAccountButton
              qaLabel="createAccountBtn"
              onClick={(e) => {
                if (!this.props.isWagerpad) {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.signUpCallback();
                }
              }}
              target="_blank"
              href="/registration"
            >
              Sign up now
            </CreateAccountButton>
          </ResetCreateLinksContainer>
          {device !== "desktop" &&
            renderLoginResponsibly(
              true,
              this.props.noStateSelector,
              this.props.awareMessage,
              this.props.hasAwareMessageMarginTop
            )}
        </Fragment>
      </LoginFooterContainer>
    );
  }

  render() {
    return (
      <Fragment>
        {this.props.isProcessing && <ProcessingOverlay />}
        <LoginContainer
          data-qa-label="loginSignUpBlock"
          isMobile={this.props.mobile}
        >
          {this.props.hasSuccessMessage &&
            this.props.successMessage &&
            renderSuccessMessage(
              this.props.successMessage,
              this.props.device,
              this.props.isLoginError
            )}
          <Content data-qa-label="loginBlock">{this.renderForm()}</Content>
          {this.renderLoginFooter()}
        </LoginContainer>
      </Fragment>
    );
  }
}
