import styled from "styled-components";

import { fontBold } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const BackButtonContainer = styled.div`
  background-color: ${buildColor("blue_accent", "000")};
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  padding-left: 12px;

  & > button {
    display: flex;
    align-items: center;
    background-color: ${buildColor("blue_accent", "000")};
  }
`;

export const BackButtonText = styled.span`
  font-size: 16px;
  font-family: ${fontBold};
  font-weight: bold;
  color: ${buildColor("grey", "900")};
  margin-left: 12px;
`;
