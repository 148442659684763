import type { Dispatch } from "redux";
import { isEmpty, isUndefined } from "lodash";
import { History } from "@tvg/ts-types/History";
import { GeolocationError } from "@tvg/sh-geolocation/src/types";
import { resetProgramPageState } from "@tvg/pp/src/store/actions";
import { logoutUser, setUserData } from "@fdr/shared-actions/UserActions";
import { geolocationStatusClear } from "@tvg/sh-geolocation/src/redux/actions";
import mediator from "@tvg/mediator";
// @ts-ignore
import { resetGeoLocation } from "@tvg/sh-geolocation/src/utils/requestGeolocation";
import {
  clearAuthTokenCookie,
  clearLoginTokenCookie
} from "@tvg/sh-utils/sessionUtils";
// @ts-ignore
import * as mediatorClassic from "@tvg/mediator-classic/src";
import { UserInfo } from "@fdr/types/ts/User";
import { clearDatadogRumUser } from "@fdr/datadog-rum";

const userInitialState = {
  accountNumber: "",
  emailAddress: "",
  firstName: "",
  userName: "",
  profile: "FDR-Generic",
  homeAddress: {
    streetNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    stateAbbr: ""
  },
  lastName: "",
  mailingAddress: {
    streetNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    stateAbbr: ""
  }
};

export const logout = () => mediator.base.dispatch({ type: "TRIGGER_LOGOUT" });

export type ClearSessionProps = {
  history: History;
  dispatch: Dispatch;
  geolocationError: GeolocationError;
  allowRedirect?: boolean;
  user?: Partial<UserInfo>;
};

export const clearSession = (props: ClearSessionProps) => {
  const { history, dispatch, geolocationError, allowRedirect } = props;

  dispatch(logoutUser());
  dispatch(
    setUserData({ logged: false, userLogout: true, user: userInitialState })
  );
  if (props.user) {
    mediatorClassic.dispatch("TVG_LOGIN:USER_SESSION_UPDATE", {
      ...props.user
    });
  }
  clearAuthTokenCookie();
  clearLoginTokenCookie();
  clearDatadogRumUser();
  if (!isEmpty(geolocationError)) {
    dispatch(resetGeoLocation());
  }
  dispatch(geolocationStatusClear());
  dispatch(resetProgramPageState());
  if (typeof window !== "undefined") {
    window.sessionStorage.removeItem("userId");
  }

  const isWagerpad = history && history.location.pathname.includes("/wagerpad");

  if (!isWagerpad && (isUndefined(allowRedirect) || allowRedirect)) {
    history.push("/");
  }
};

export default logout;
