export const initialState = {
  isLoading: false,
  isEnrolled: false,
  userDidEnroll: false,
  totalRewards: 0,
  historyList: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "WAR_SET_LOADING": {
      return { ...state, isLoading: action.payload.isLoading };
    }

    case "WAR_SET_ENROLLED": {
      return { ...state, isEnrolled: action.payload.isEnrolled };
    }

    case "WAR_SET_TOTAL_REWARDS": {
      return { ...state, totalRewards: action.payload.totalRewards };
    }

    case "WAR_SET_USER_DID_ENROLL": {
      return { ...state, userDidEnroll: action.payload.userDidEnroll };
    }

    case "WAR_SET_TERMS_N_CONDITIONS_OPEN": {
      return {
        ...state,
        termsNConditionsOpen: action.payload.termsNConditionsOpen
      };
    }

    case "WAR_SET_HISTORY_LIST": {
      return { ...state, historyList: action.payload.historyList };
    }

    case "WAR_SET_INFO_WARNING": {
      return { ...state, infoWarning: action.payload.infoWarning };
    }

    default: {
      return state;
    }
  }
}
