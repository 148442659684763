import { get, has, map, omit, forEach, pickBy } from "lodash";
import { formatPurse } from "@tvg/formatter/currency";
import { differenceInMinutes } from "date-fns";

const createRaceDataForHeader = (race, enableRaceChanges = false) => {
  const keysRace = [
    "numRunners",
    "distance",
    "surface.shortName",
    "surface.defaultCondition",
    "raceClass.shortName",
    "type.shortName",
    "purse"
  ];

  const changesKeyMap = {
    distance: "distance",
    "surface.shortName": "course",
    "surface.defaultCondition": "condition"
  };

  const keyGreyhoundsRace = ["distance", "raceClass.name"];
  const dataArr = get(race, "isGreyhound") ? keyGreyhoundsRace : keysRace;
  const surfaceChanges = pickBy(get(race, "changes.surface"));
  const resArr = [];

  forEach(dataArr, (item) => {
    const defRes = {
      item,
      changed:
        enableRaceChanges &&
        Object.keys(surfaceChanges).indexOf(changesKeyMap[item]) !== -1
    };
    if (has(race, item)) {
      if (item === "raceClass.shortName" && get(race, "claimingPrice") > 0) {
        resArr.push({
          ...defRes,
          value: `${formatPurse(get(race, "claimingPrice"))} ${get(race, item)}`
        });
      } else if (item === "purse") {
        resArr.push({
          ...defRes,
          value: formatPurse(get(race, "purse"))
        });
      } else {
        const raceItem = get(race, item);
        if (raceItem) {
          resArr.push({
            ...defRes,
            value: item === "numRunners" ? `${raceItem} runners` : raceItem
          });
        }
      }
    }
  });

  return map(resArr, (e) => omit(e, "item"));
};

export const getVideoFeedback = (
  currentRace,
  firstRace,
  lastRace,
  enableReplays
) => {
  let replayValue = false;
  if (
    enableReplays &&
    get(currentRace, "video.hasReplay", false) &&
    get(currentRace, "video.replayFileName") &&
    get(currentRace, "video.mobileAvailable")
  ) {
    replayValue = true;
  }

  if (!get(currentRace, "video.liveStreaming")) {
    return replayValue ? "NOT_AVAILABLE_REPLAY" : "NOT_AVAILABLE_NO_REPLAY";
  }

  const firstRaceDate = new Date(get(firstRace, "postTime"));
  const lastRaceDate = new Date(get(lastRace, "postTime"));
  const dateNow = new Date();
  const firstRaceDiff = differenceInMinutes(dateNow, firstRaceDate);
  const lastRaceDiff = differenceInMinutes(lastRaceDate, dateNow);

  // one hour and a half before the first race
  if (firstRaceDiff < -90) {
    return replayValue ? "NOT_AVAILABLE_REPLAY" : "NOT_AVAILABLE_NO_REPLAY";
  }

  // between 1 hour and a half and 1 hour before the first race
  if (firstRaceDiff >= -90 && firstRaceDiff < -60) {
    return replayValue ? "STARTING_SOON_REPLAY" : "STARTING_SOON_NO_REPLAY";
  }

  // half an hour after the last race
  if (lastRaceDiff < -30) {
    return replayValue ? "FINISHED_REPLAY" : "FINISHED_NO_REPLAY";
  }

  return replayValue ? "AVAILABLE_REPLAY" : "AVAILABLE_NO_REPLAY";
};

const getImportanceLevel = (level) => (level === "Normal" ? "0" : "1");

const RaceHeaderUtils = {
  createRaceDataForHeader,
  getVideoFeedback,
  getImportanceLevel
};

export default RaceHeaderUtils;
