import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontMedium, fontNormal } from "../../_static/Typography";
import { buildText } from "../../_atom/Text";
import { generateTransition, easeInOut } from "../../_static/Transitions";
import Button from "../../_atom/Buttons/button";

const computeMaxHeight = (runnersNumber) => {
  const averageHeight = 225;
  const height = runnersNumber * averageHeight;
  if (height < 1000) return "max-height: 1000px";
  return `max-height: ${height}px`;
};

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  padding-bottom: 12px;
  background-color: ${buildColor("white", "100")};
  border-top: 1px solid ${buildColor("blue", "100")};
`;

export const InfoItem = styled.div`
  padding-top: 12px;
  display: inline-flex;
`;

export const InfoLabel = styled(
  buildText({
    tag: "span",
    fontSize: 14,
    color: buildColor("grey", "900")
  })
)`
  font-family: ${fontMedium};
  margin-right: ${(props) => (props.isScratched ? "32px" : "8px")};
`;

export const InfoMessage = styled(
  buildText({
    tag: "span",
    fontSize: 14,
    color: buildColor("grey", "800")
  })
)`
  font-family: ${fontNormal};
`;

export const RunnerNumberContainer = styled.div`
  margin-right: 4px;
  min-width: 16px;
  height: 16px;
`;

export const AnimateContainer = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: ${generateTransition(easeInOut, "max-height")};
`;

export const RunnersRestContainer = styled.div`
  width: 100%;

  & .animatedContainer {
    ${({ runnersNumber }) => computeMaxHeight(runnersNumber)}
  }

  &.expand-enter .animatedContainer {
    max-height: 0;
  }

  &.expand-enter-active .animatedContainer {
    ${({ runnersNumber }) => computeMaxHeight(runnersNumber)}
  }

  &.expand-enter-done .animatedContainer {
    ${({ runnersNumber }) => computeMaxHeight(runnersNumber)}
  }

  &.expand-exit-active .animatedContainer {
    max-height: 0;
  }

  &.expand-exit-done .animatedContainer {
    max-height: 0;
  }
`;

export const ShowMoreRunnersButton = styled(Button)`
  height: 44px;
  background-color: ${buildColor("white", "100")};
  border-top: 1px solid ${buildColor("blue", "100")};

  && > span {
    font-size: 14px;
    color: ${buildColor("blue_accent", "500")};
    font-family: ${fontMedium};
    text-transform: capitalize;
    font-weight: normal;
  }
`;

export const ShowMoreRunnersArrow = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;
