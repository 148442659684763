import React from "react";
import classNames from "classnames";
import Button from "@tvg/ui-bootstrap/components/Buttons";
import style from "./style.css";

export const changeLink = () => {
  window.location.href = "/registration";
};

const SignupFooter = (props) => {
  const signupBlockFooter = classNames({
    [style.signupBlockFooter]: true,
    [style.signupBlockFooterMobile]: props.mobile
  });
  return (
    <div className={signupBlockFooter}>
      <Button
        type="tertiary"
        content="sign up"
        size="full-width"
        className={style.btnSignupFooter}
        isSelected={false}
        onClick={changeLink}
      />
    </div>
  );
};

SignupFooter.defaultProps = {
  mobile: false
};

export default SignupFooter;
