import styled from "styled-components";
import type { AnimatedDropdownProps } from "./types";

export const Container = styled.div<
  Pick<AnimatedDropdownProps, "top" | "left" | "width" | "isTop">
>`
  display: flex;
  left: ${({ left }) => left}px;
  padding-top: 8px;
  position: ${({ isTop }) => (isTop ? "fixed" : "absolute")};
  top: ${({ top }) => top}px;
  width: ${({ width }) => width}px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000;
  background-color: var(--fd-colors-background-surface);
  border: 1px solid var(--fd-colors-border-default);
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
`;
