import React from "react";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import tvgConf from "@tvg/conf";
import { Container, Text } from "./styled-components";

const TrackRulesModal = (props) => {
  const { isOpen, onClose, subtitle, messages, layerOffset = 1 } = props;
  const { device } = tvgConf();

  const baseModalProps = {
    title: "Scratch Rules",
    subtitle,
    titleType: "ipp",
    isOpen,
    onClose,
    qaLabel: "track-rules",
    hasShadow: true,
    hasOverlay: true,
    isFullWidth: false,
    isContentTransparent: false,
    isFullHeight: false,
    layerOffset
  };

  const modalProps =
    device === "mobile"
      ? {
          ...baseModalProps,
          animation: "bottomFloating",
          offsetTop: 200,
          offsetBottom: 200,
          offsetLeft: 8,
          offsetRight: 8
        }
      : {
          ...baseModalProps,
          animation: "fade",
          offsetTop: 40,
          offsetBottom: 0,
          offsetLeft: 40,
          offsetRight: 40
        };
  return (
    <ModalV2 {...modalProps} hasBiggerHeader>
      {() => (
        <Container>
          {messages.map((message) => (
            <Text key={message.toString()}>{message}</Text>
          ))}
        </Container>
      )}
    </ModalV2>
  );
};

export default TrackRulesModal;
