import React, { useState, useRef, createRef, useEffect, Fragment } from "react";
import { get } from "lodash";

import { useField } from "@unform/core";
import tvgConf from "@tvg/conf";
import { useDebouncedStringDiff } from "@tvg/custom-hooks";
import {
  Dash,
  InputWrapper,
  InputValue,
  IconWrapper,
  Label,
  LabelDescription,
  SectionTitle
} from "./styled-components";
import { Container, ErrorText } from "../InputV2/styled-components";
import Icon from "../../_static/Icons";
import { warning } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";

const SSN_THIRD_PART_LENGTH = 4;
const SSN_FUL_LENGTH = 9;

// handle dots sizes in safari
export const isSafariBrowser = () => {
  if (
    typeof window !== "undefined" &&
    window.navigator &&
    window.navigator.userAgent
  ) {
    const ua = window.navigator.userAgent;
    // TODO: revisit this!!!
    return (
      ua.match(/WebKit/i) ||
      (ua.match(/Safari/i) && !ua.match(/CriOS/i) && !ua.match(/Chrome/i))
    );
  }
  return false;
};

export const inputChangeHandler = (
  e,
  elRefs,
  index,
  SSNValue,
  setSSNValue,
  customHandleChange
) => {
  const checkCurrentRefAlreadyContainsChar =
    elRefs.current[index].current.value.length > 1;

  if (!checkCurrentRefAlreadyContainsChar) {
    const inputValue = get(e, "target.value").replace(/\D/g, "");

    setSSNValue((prevSSNValue) => {
      const newSSNValue = [...prevSSNValue];
      newSSNValue[index] = inputValue;
      return newSSNValue;
    });

    if (typeof customHandleChange === "function") {
      const newSSNValue = [...SSNValue];
      newSSNValue[index] = inputValue;
      customHandleChange(newSSNValue);
    }

    const nextRef = elRefs && elRefs.current[index + 1];
    if (nextRef && nextRef.current && inputValue !== "") {
      nextRef.current.focus();
    }
  }
};

const onKeyDownHandler = (
  e,
  elRefs,
  index,
  SSNValue,
  setSSNValue,
  customHandleChange
) => {
  if (e.key === "e" || e.key === ".") {
    e.preventDefault();
    return;
  }

  const previousRef = elRefs.current[index - 1];
  const nextRef = elRefs.current[index + 1];

  const checkCurrentRefAlreadyContainsChar =
    elRefs.current[index].current.value.length >= 1;

  if (e.key === "ArrowLeft") {
    e.preventDefault();
    if (previousRef) {
      previousRef.current.focus();
    }
    return;
  }

  if (e.key === "ArrowRight") {
    e.preventDefault();
    if (nextRef) {
      nextRef.current.focus();
    }
    return;
  }

  if (previousRef && previousRef.current && e.key === "Backspace") {
    let newSSNValue;
    if (checkCurrentRefAlreadyContainsChar) {
      newSSNValue = [...SSNValue];
      // Change current value
      newSSNValue[index] = "";

      setSSNValue((prevSSNValue) => {
        const newSSNValueState = [...prevSSNValue];
        newSSNValueState[index] = "";
        return newSSNValueState;
      });
    } else {
      previousRef.current.focus();
      newSSNValue = [...SSNValue];
      // Change previous value
      newSSNValue[index - 1] = "";

      setSSNValue((prevSSNValue) => {
        const newSSNValueState = [...prevSSNValue];
        newSSNValueState[index - 1] = "";
        return newSSNValueState;
      });
    }

    if (typeof customHandleChange === "function") {
      customHandleChange(newSSNValue);
    }
  }
};

const onClickHandler = (
  elRefs,
  SSNValue,
  setSSNValue,
  isValidInput,
  setIsFocused,
  SSNLength
) => {
  setIsFocused(true);
  if (!isValidInput) {
    const currentFilled = SSNValue.findIndex((el) => !el || el === "");
    const ref = elRefs.current[currentFilled];
    if (ref.current) ref.current.focus();
    return;
  }

  let focusIndex = SSNValue.findIndex((el) => !el || el === "");
  if (focusIndex === -1) {
    focusIndex =
      SSNValue.length === SSNLength ? SSNLength - 1 : SSNValue.length;
  }

  if (elRefs.current[focusIndex].current) {
    elRefs.current[focusIndex].current.focus();
  }
};

export const renderInputValue = (
  elRefs,
  index,
  SSNValue,
  setSSNValue,
  isVisible,
  size,
  setIsFocused,
  isFullSsn,
  customHandleChange,
  isIOS,
  autoFocus
) => (
  <Fragment key={`SSNInputValue_${index}`}>
    <InputValue
      data-qa-label={`ssn-input-${index}`}
      ref={elRefs.current[index]}
      placeholder="_"
      onChange={(e) =>
        inputChangeHandler(
          e,
          elRefs,
          index,
          SSNValue,
          setSSNValue,
          customHandleChange
        )
      }
      onKeyDown={(e) =>
        onKeyDownHandler(
          e,
          elRefs,
          index,
          SSNValue,
          setSSNValue,
          customHandleChange
        )
      }
      onFocus={() => setIsFocused(true)}
      value={SSNValue[index]}
      type={isVisible ? "text" : "password"}
      isVisible={isVisible}
      inputMode="numeric"
      pattern="^\d{1}$"
      maxLength={1}
      size={size}
      isIOS={isIOS}
      autoFocus={autoFocus}
      autoComplete="off"
    />
    {isFullSsn && (index === 2 || index === 4) && (
      <Fragment>
        <Dash />
      </Fragment>
    )}
  </Fragment>
);

const ssnFulfilled = (SSNValue, SSNLength) =>
  SSNValue.length === SSNLength &&
  SSNValue.findIndex((el) => !el || el === "") === -1;

export const InputSSN = ({
  name,
  label,
  labelDescription,
  isVisible,
  icon,
  handleIconClick,
  customHandleOnBlur,
  customHandleChange,
  marginTop,
  marginBottom,
  align,
  size,
  onBlur,
  isFullSsn = false,
  sectionTitle,
  borderTop,
  paddingTop,
  autoFocus,
  debouncedValueCallback
}) => {
  const SSNLength = isFullSsn ? SSN_FUL_LENGTH : SSN_THIRD_PART_LENGTH;
  const [SSNValue, setSSNValue] = useState(new Array(SSNLength).fill(""));
  const [isValid, setIsValid] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const isInputsEmptyRef = useRef(false);
  const elRefs = useRef(
    Array(SSNLength)
      .fill()
      .map(() => createRef())
  );

  const { fieldName, registerField, error } = useField(name);
  const { product } = tvgConf();
  // hammer time
  const isSafari = isSafariBrowser();
  const isIOS = product === "ios2" || product === "iosnative";

  useEffect(() => {
    registerField({
      name: fieldName,
      clearValue() {
        setSSNValue(new Array(SSNLength).fill(""));
      },
      setValue(ref, value) {
        setSSNValue(value);
        isInputsEmptyRef.current = !ssnFulfilled(value, SSNLength);
      },
      getValue() {
        return SSNValue;
      }
    });
  }, [fieldName, registerField, SSNValue]);

  useEffect(() => {
    if (SSNValue && ssnFulfilled(SSNValue, SSNLength)) {
      setIsValid(true);

      // In order to not send GTM events when going back and forward,
      // this is to prevent that and only dispatch GTM after we have updated inputs
      isInputsEmptyRef.current = true;

      // Here because the approach for this component is not possible to look for the real onBlur
      if (typeof customHandleOnBlur === "function") {
        customHandleOnBlur(SSNValue);
      }
    } else {
      setIsValid(false);
    }
  }, [SSNValue]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    // ignore use effect on initial 'isFocused' state
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (!isFocused && typeof onBlur === "function") {
      onBlur(SSNValue);
    }
  }, [isFocused]);

  useEffect(() => {
    if (autoFocus) {
      setIsFocused(true);
    }
  }, [autoFocus]);

  useDebouncedStringDiff(
    SSNValue.join(""),
    debouncedValueCallback,
    isInputsEmptyRef.current
  );

  return (
    <Container
      data-qa-label="ssn-container"
      marginTop={marginTop}
      marginBottom={marginBottom}
      borderTop={borderTop}
      paddingTop={paddingTop}
    >
      {sectionTitle && isFullSsn && <SectionTitle>{sectionTitle}</SectionTitle>}
      <Label htmlFor={fieldName} marginBottom={8}>
        {label}
        {labelDescription && (
          <LabelDescription>{labelDescription}</LabelDescription>
        )}
      </Label>
      <InputWrapper
        data-qa-label="ssn-section-ssn-input"
        error={error}
        isFocused={isFocused}
        onClick={() =>
          onClickHandler(
            elRefs,
            SSNValue,
            setSSNValue,
            isValid,
            setIsFocused,
            SSNLength
          )
        }
        onBlur={() => {
          setIsFocused(false);
        }}
        align={align}
        size={size}
      >
        {SSNValue &&
          SSNValue.map((_, index) =>
            renderInputValue(
              elRefs,
              index,
              SSNValue,
              setSSNValue,
              isVisible,
              size,
              setIsFocused,
              isFullSsn,
              customHandleChange,
              isIOS || isSafari,
              index === 0 && !!autoFocus
            )
          )}
        {icon && (
          <IconWrapper
            data-qa-label="input-icon-wrapper"
            onClick={handleIconClick}
            type="button"
            size={size}
          >
            {icon}
          </IconWrapper>
        )}
      </InputWrapper>
      {error && (
        <ErrorText data-qa-label="input-error-message">
          <Icon
            qalabel="input-error-icon"
            icon={warning}
            color={buildColor("orange", "600")}
          />
          {error}
        </ErrorText>
      )}
    </Container>
  );
};

InputSSN.defaultProps = {
  enableSSNPassword: false,
  align: "left",
  size: "medium",
  isFullSsn: false
};

export default InputSSN;
