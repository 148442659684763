import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import fetch from "node-fetch";

import TVGConf from "@tvg/conf";

const getClientURI = (graphClient) => {
  let uri = "";

  switch (graphClient) {
    case "fcp":
      uri = TVGConf().config().service.fcp;
      break;
    case "rda":
      uri = TVGConf().config().service.rda;
      break;
    default:
      uri = TVGConf().config().service.graph;
      break;
  }
  return uri;
};

export default {
  getClient(graphClient = "graph") {
    switch (graphClient) {
      case "rda":
        return this.rdaClient;
      case "fcp":
        return this.fcpClient;
      default:
        return this.graphClient;
    }
  },

  setClient(client, type) {
    switch (type) {
      case "rda":
        this.rdaClient = client;
        break;
      case "fcp":
        this.fcpClient = client;
        break;
      default:
        this.graphClient = client;
        break;
    }
  },

  createClient(ssrMode = false, graphClient = "graph") {
    const HttpLink = createHttpLink({
      fetch,
      uri: getClientURI(graphClient),
      credentials: "include"
    });

    const retryLink = new RetryLink({
      attempts: () => true,
      delay: (count) => {
        if (count < 25) {
          return 2000 * Math.random();
        }
        return 15000;
      }
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          // eslint-disable-next-line no-console
          console.error(
            `[GraphQL error]: Operation: ${operation.operationName}, Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );

      if (networkError) {
        console.error(`[Network error]: ${networkError}`); // eslint-disable-line no-console
      }
    });

    let link;
    if (typeof window === "undefined") {
      link = errorLink.concat(HttpLink);
    } else {
      link = retryLink.concat(errorLink).concat(HttpLink);
    }

    const cache = new InMemoryCache();

    if (typeof window !== "undefined") {
      cache.restore(window.__APOLLO_STATE__); // eslint-disable-line no-underscore-dangle
    }

    const client = new ApolloClient({
      link,
      cache,
      addTypename: true,
      ssrMode
    });

    this.setClient(client, graphClient);

    return client;
  }
};
