export const errors = {
  ACCOUNT_DISABLED: "loginFailureAccountDisabled",
  ACCOUNT_LOCKED: "loginFailureAccountLocked",
  ACCOUNT_UPDATE_REQUIRED: "loginFailureAccountUpdateRequired",
  BLACK_LISTED_LOCATION: "blackListedLocation",
  BLOCKED_SERVICE: "geoLocationBlockedService",
  BLOCKED_SOFTWARE: "geoLocationBlockedSoftware",
  GEO_SERVICE_FAILURE: "loginFailureGeneric",
  INVALID_CREDENTIALS: "loginFailureInvalidCredentials",
  INVALID_GEO: "geoLocationUnconfirmedOrInvalid",
  INVALID_GEOPACKET: "geoLocationUnconfirmedOrInvalid",
  INVALID_WAGERING_STATE: "loginFailureNonLegalState",
  LOGIN_FAILED: "loginFailureGeneric",
  LOGIN_REDIRECT: "loginSiteRedirect",
  MISSING_TOKEN: "apiMissingUserSessionToken",
  NON_LEGAL_STATE: "loginFailureNonLegalState",
  OUT_OF_BOUNDARY: "geoLocationOutOfBoundary",
  SESSION_NOT_FOUND: "apiUserSessionNotFound",
  SESSION_TIMEOUT: "apiUserSessionTimedOut",
  UNCONFIRMED_BOUNDARY: "geoLocationUnconfirmedOrInvalid",
  USER_SESSION_LIMIT_REACHED: "loginFailureUserSessionLimitReached",
  ACCOUNT_IN_TIMEOUT: "loginFailureRGTimeout",
  ACCOUNT_SUSPENDED: "loginFailureRGSuspendedOrClose",
  ACCOUNT_SELF_EXCLUDED: "loginFailureRGSuspendedOrClose",
  InvalidPinException: "invalidPinException",
  "com.tvg.pxp.exceptions.UserCompromisedCredentialsException":
    "userCompromisedCredentials"
};

export const CONSTANTS = {
  // REDUCERS CONSTANTS
  SET_SESSION_SUCCESS: "SET_SESSION_SUCCESS",
  SET_SESSION_ERROR: "SET_SESSION_ERROR",
  CLEAR_SESSION: "CLEAR_SESSION",
  SET_USER: "SET_USER",
  CMS_LOGIN_MESSAGES: "CMS_LOGIN_MESSAGES",

  // MEDIATOR CONSTANTS
  "TVG_LOGIN:DO_LOGIN": "TVG_LOGIN:DO_LOGIN",
  "TVG_LOGIN:DO_LOGIN_FORM": "TVG_LOGIN:DO_LOGIN_FORM",
  "TVG_LOGIN:DO_LOGOUT": "TVG_LOGIN:DO_LOGOUT",
  "TVG_LOGIN:USER_SESSION_UPDATE": "TVG_LOGIN:USER_SESSION_UPDATE",
  "TVG_LOGIN:OPEN_LOGIN_MODAL": "TVG_LOGIN:OPEN_LOGIN_MODAL",
  "TVG_LOGIN:CLOSE_LOGIN_MODAL": "TVG_LOGIN:CLOSE_LOGIN_MODAL",
  "TVG_LOGIN:VALIDATE_SESSION": "TVG_LOGIN:VALIDATE_SESSION",
  "TVG_LOGIN:TOUCH_ID_ERROR": "TVG_LOGIN:TOUCH_ID_ERROR",

  // EXTERNAL REDUCERS
  UPDATE_USER_DATA: "UPDATE_USER_DATA",

  // METRICS
  METRIC_USER_LOGIN: "user_login"
};
