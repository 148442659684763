import mediator from "@tvg/mediator";
import { EventData, MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.WAGERPAD_CLICK, () => {
    track<BaseEventProps>(EVENT_NAME.BETSLIP_OPENED, {
      [BaseEventProps.EVENT_CATEGORY]: "Betslip",
      [BaseEventProps.MODULE]: "Global Header"
    });
  });

  mediator.base.subscribe(MediatorEventType.WAGERPAD_CTA_CLICKED, () => {
    track<BaseEventProps>(EVENT_NAME.WAGERPAD_CTA_CLICKED);
  });

  mediator.base.subscribe(
    MediatorEventType.WAGERPAD_PAGE_VIEWED,
    (data: EventData) => {
      track<BaseEventProps>(EVENT_NAME.WAGERPAD_PAGE_VIEWED, {
        [BaseEventProps.TRACK_NAME]: data.payload.trackName,
        [BaseEventProps.RACE_NUMBER]: data.payload.raceNumber,
        [BaseEventProps.PAGE_NAME]: "Wagerpad"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.WAGERPAD_DROPDOWN_SELECTED,
    (data: EventData) => {
      track<BaseEventProps>(EVENT_NAME.WAGERPAD_DROPDOWN_SELECTED, {
        [BaseEventProps.MODULE]: "wagerpad_dropdown",
        [BaseEventProps.FIELD_NAME]: data.payload.fieldName,
        [BaseEventProps.TRACK_NAME]: data.payload.trackName,
        [BaseEventProps.RACE_NUMBER]: data.payload.raceNumber
      });
    }
  );
};
