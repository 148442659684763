import { Store } from "redux";
import jwt_decode from "jwt-decode";
import { get } from "lodash";
import { fromUnixTime, isAfter } from "date-fns";
import Cookies from "universal-cookie";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import { PRODUCTS_FDR } from "@tvg/conf/src/product";

interface AuthToken {
  ses: number;
  sub: number;
  usn: string;
  prd: string;
  crt: number;
  eml: string;
  src: number;
  mfa: boolean;
  typ: number;
  exp: number;
}
export const cookies = new Cookies();

export const AUTH_TOKEN_COOKIE = "X-Auth-Token";
export const LOGIN_TOKEN_COOKIE = "X-Login-Token";
export const GEO_TOKEN_COOKIE = "X-Geo-Token";
export const GEO_LICENSE_COOKIE = "X-Geo-License";
export const SPORTSBOOK_REGION_COOKIE = "X-Sportsbook-Region";
export const WRAPPER_CONFIG_COOKIE = "wrapperAppConfig";
export const GEO_RELOCATE_PROCESS_ID = "geoRelocateProcessId";

export const isTvg5 = () => tvgConf().product === "tvg5";

const isFdrProduct = () =>
  PRODUCTS_FDR.some((product) => product === tvgConf().product);

export const decodeToken = (token?: string): AuthToken | {} => {
  if (token) {
    return jwt_decode(token);
  }
  return {};
};

export const getUserSessionData = (authToken: string) => {
  const tokenDecoded = decodeToken(authToken);

  return {
    authToken,
    sessionId: get(tokenDecoded, "ses", ""),
    sessionExpireDate: get(tokenDecoded, "exp", ""),
    fdUserId: get(tokenDecoded, "sub", ""),
    userName: get(tokenDecoded, "usn", ""),
    userEmail: get(tokenDecoded, "eml", "")
  };
};

export const getUserSessionStartDate = (
  authToken?: string
): Date | undefined => {
  const tokenDecoded: AuthToken | {} = decodeToken(authToken);
  return authToken
    ? fromUnixTime(get(tokenDecoded, "crt", new Date()))
    : undefined;
};

export const getDomainForCookie = () => {
  let cookieDomain: string | undefined;
  if (typeof window !== "undefined") {
    const domain = window.location.hostname;
    const environment = tvgConf().environment as string;
    cookieDomain = ["local", environment].some((env) => domain.startsWith(env))
      ? domain.substring(domain.indexOf("."))
      : `.${domain}`;
  }

  return cookieDomain;
};

export const getCookieOptions = () => ({
  domain: getDomainForCookie(),
  path: "/",
  doNotParse: false
});

export const addCookie = (
  cookieName: string,
  cookieValue: string,
  maxAge?: number,
  domain?: string
): void => {
  if (getCookieValue(cookieName)) {
    removeCookie(cookieName);
  }

  if (domain) {
    cookies.set(cookieName, cookieValue, {
      ...getCookieOptions(),
      maxAge,
      domain
    });
  } else {
    cookies.set(cookieName, cookieValue, { ...getCookieOptions(), maxAge });
  }
};

export const getCookieValue = <T = string>(
  cookieName: string,
  defaultValue?: T,
  forceParse = true
): T =>
  cookies.get(cookieName, {
    ...getCookieOptions(),
    doNotParse: !forceParse
  }) || defaultValue;

export const removeCookie = (cookieName: string): void => {
  cookies.remove(cookieName, getCookieOptions());
};

export const createAuthTokenCookie = (token: string) => {
  addCookie(AUTH_TOKEN_COOKIE, token);
};

export const getAuthTokenCookie = () => getCookieValue(AUTH_TOKEN_COOKIE, "");

export const clearAuthTokenCookie = () => removeCookie(AUTH_TOKEN_COOKIE);

export const clearLoginTokenCookie = () => removeCookie(LOGIN_TOKEN_COOKIE);

export const getGeoTokenCookie = () => {
  let geoToken = "";
  if (typeof window !== "undefined") {
    geoToken = localStorage.getItem(GEO_TOKEN_COOKIE) || "";
  }

  return geoToken;
};

export const createGeoTokenCookie = (token: string) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(GEO_TOKEN_COOKIE, token);
  }
};

export const clearGeoTokenCookie = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(GEO_TOKEN_COOKIE);
  }
};

export const getLicenseCached = () => {
  let license = "";
  if (typeof window !== "undefined") {
    license = localStorage.getItem(GEO_LICENSE_COOKIE) || "";
  }

  return license;
};

export const createLicenseCached = (token: string) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(GEO_LICENSE_COOKIE, token);
  }
};

export const clearLicenseCached = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(GEO_LICENSE_COOKIE);
  }
};

export const getTemporaryRelocateUserProcessId = () => {
  let processId = "";
  if (typeof window !== "undefined") {
    processId = sessionStorage.getItem(GEO_RELOCATE_PROCESS_ID) || "";
  }

  return processId;
};

export const createTemporaryRelocateUserProcessId = (processId: string) => {
  if (typeof window !== "undefined") {
    sessionStorage.setItem(GEO_RELOCATE_PROCESS_ID, processId);
  }
};

export const clearTemporaryRelocateUserProcessId = () => {
  if (typeof window !== "undefined") {
    sessionStorage.removeItem(GEO_RELOCATE_PROCESS_ID);
  }
};

export const createSportsbookRegionCookie = (region: string) => {
  addCookie(SPORTSBOOK_REGION_COOKIE, region);
};

export const getSportsbookRegionCookie = () =>
  getCookieValue(SPORTSBOOK_REGION_COOKIE);

export const clearSportsbookRegionCookie = () => {
  removeCookie(SPORTSBOOK_REGION_COOKIE);
};

export const getLoginAuthToken = () => getCookieValue(LOGIN_TOKEN_COOKIE);

export const isSessionExpired = (authToken: string) => {
  const { sessionExpireDate } = getUserSessionData(authToken);
  return isAfter(new Date(), fromUnixTime(sessionExpireDate));
};

export const isAccountCompliantSelector = (store: Store): boolean => {
  if (
    (isTvg5() && get(store, "capi.featureToggles.enableAWFlows", false)) ||
    isFdrProduct()
  ) {
    return (
      get(store, "userData.isVerified") &&
      get(store, "userData.jurisdiction.accepted")
    );
  }

  return get(store, "userData.logged");
};

export const checkValidWageringState = (
  selectedState: string,
  allowedStates: Array<string>
): boolean =>
  !!selectedState && allowedStates && allowedStates.includes(selectedState);

export const createWrapperAppConfigCookie = (token: string) => {
  addCookie(WRAPPER_CONFIG_COOKIE, token);
};

export const removeWrapperAppConfigCookie = () => {
  removeCookie(WRAPPER_CONFIG_COOKIE);
};

export const getWrapperAppConfigCookie = () =>
  getCookieValue<string | undefined>(WRAPPER_CONFIG_COOKIE, undefined, false);

export const openLoginWall = (
  loginSuccessCallback: Function,
  loginFailCallback?: Function
) => {
  if (isFdrProduct()) {
    mediator.base.dispatch({
      type: "OPEN_LOGIN",
      payload: {
        callback: loginSuccessCallback
      }
    });
  } else {
    mediator.base.dispatch({
      type: "TVG_LOGIN:OPEN_LOGIN_MODAL",
      payload: {
        callback: (data?: { status: string }) => {
          if (
            !data ||
            data.status !== "error" ||
            typeof loginSuccessCallback === "function"
          ) {
            loginSuccessCallback();
          } else if (typeof loginFailCallback === "function") {
            loginFailCallback();
          }
        }
      }
    });
  }
};
