import React from "react";

import Icon from "../../_static/Icons";
import { trash } from "../../_static/Icons/icons";

import { Dismiss } from "./styled-components";

const { min, abs } = Math;
const getClient = (event) => {
  const { clientX, clientY } = event.changedTouches
    ? event.changedTouches[0]
    : event;
  return [clientX, clientY];
};
let isOpen = false;

export const getTouchEvents = (
  targetId,
  targetRef,
  dismissible,
  fnDismiss,
  targetWidth,
  threshold = targetWidth / 2,
  showDismiss
) => {
  let startPointX = 0;
  let startPointY = 0;
  const getEl = () => targetRef;
  const { current } = getEl();

  const toggleCardDismiss = (value) => {
    if (dismissible) {
      fnDismiss(value);
    }
  };
  const handleTouchStart = (event) => {
    [startPointX, startPointY] = getClient(event);
  };
  const handleTouchMove = (event) => {
    if (dismissible) {
      const [currentPointX, currentPointY] = getClient(event);
      const difX = currentPointX - startPointX;
      const difY = currentPointY - startPointY;
      if (currentPointX < startPointX) {
        const offset = abs(difX) > targetWidth ? -targetWidth : difX;
        current.style.setProperty("transform", `translate(${offset}px)`);
        isOpen = true;
      } else if (showDismiss && difY * -1 < difX && isOpen) {
        const dist = min(abs(targetWidth - difX), 59);
        const offset = dist > threshold ? dist : 0;
        isOpen = !!offset;
        current.style.setProperty("transform", `translate(-${offset}px)`);
      }
    }
  };
  const handleTouchEnd = (event) => {
    const [endPoint] = getClient(event);
    const showButton = startPointX > endPoint + threshold;

    if (showButton) {
      toggleCardDismiss(targetId);
      current.style.setProperty("transform", `translate(-${targetWidth}px)`);
    } else {
      if (showDismiss) {
        toggleCardDismiss("");
      }
      current.style.setProperty("transform", "translate(0px)");
    }
  };

  return {
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onTouchEnd: handleTouchEnd
  };
};

const SwipeButton = ({
  dismissBtnWidth = 59,
  dismissIconSize = 16,
  dismissIconColor,
  callbackClick,
  iconBtn = trash
}) => {
  const handleClick = (event) => {
    event.stopPropagation();
    callbackClick(event);
  };

  return (
    <Dismiss onClick={handleClick} width={dismissBtnWidth}>
      <Icon icon={iconBtn} size={dismissIconSize} color={dismissIconColor} />
    </Dismiss>
  );
};
export default React.memo(SwipeButton);
