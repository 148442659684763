import styled, { css } from "styled-components";
import {
  layout,
  space,
  color,
  margin,
  padding,
  width,
  maxWidth,
  height,
  maxHeight
} from "styled-system";

import type { BorderToShow } from "./types";

const createBorder = (bordersToShow: BorderToShow) =>
  Object.entries(bordersToShow).map(([key, value]) => {
    if (value) {
      return css`border-${key}: 1px solid var(--fd-colors-border-subtle);`;
    }

    return "";
  });

export const ShadowWrapper = styled.div`
  overflow: hidden;

  &:not([data-scroll-position="top"])[data-hide-shadow="false"]::before {
    content: "";
    box-shadow: inset 3px 3px 3px rgba(17, 43, 68, 0.25);
    display: flex;
    height: 16px;
    left: -6px;
    pointer-events: none;
    position: relative;
    margin-bottom: -16px;
    width: calc(100% - 10px);
    z-index: 1000;
  }

  &:not([data-scroll-position="bottom"])[data-hide-shadow="false"]::after {
    content: "";
    box-shadow: inset 3px -3px 3px rgba(17, 43, 68, 0.25);
    display: flex;
    height: 16px;
    left: -6px;
    margin-top: -16px;
    pointer-events: none;
    width: calc(100% - 10px);
    z-index: 1000;
    position: relative;
  }

  ${width}
  ${maxWidth}
  ${height}
  ${maxHeight}
  ${margin}
`;

export const ScrollContainer = styled.div<{
  hasShadow: boolean;
  borderToShow: BorderToShow;
}>`
  overflow: auto;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    width: 16px;
    ${({ borderToShow }) => createBorder(borderToShow)}
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    overflow: auto;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--fd-colors-component-data-input-icon-base);
    border-radius: 16px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--fd-colors-component-data-input-content-base);
    border-radius: 16px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  ${color}
  ${({ hasShadow }) => (hasShadow ? padding : space)} 
  ${layout}
`;
