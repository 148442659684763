import styled, { css } from "styled-components";

import buildColor from "../../_static/ColorPalette";
import { fontBold, fontNormal } from "../../_static/Typography";

const textStyling = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const verticalLayout = css`
  h2 {
    max-height: 48px;
  }

  span {
    max-height: 36px;
  }
`;

export const AcqOfferContainer = styled.div`
  display: flex;
  background-color: ${buildColor("blue_accent", "000")};

  @media only screen and (width <= 1023px) {
    border-bottom: 1px solid ${buildColor("blue", "000")};
  }

  @media only screen and (width <= 767px) {
    flex-direction: column;
    padding: 12px;
  }

  @media only screen and (width >= 768px) and (width <= 1023px) {
    padding: 16px 24px;
    border-radius: 4px 4px 0 0;
    align-items: center;
  }

  @media only screen and (width >= 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media only screen and (width >= 1024px) {
    flex-direction: column;
    width: 325px;
    padding: 12px;
    border-radius: 4px;
  }
`;

export const AcqOfferContent = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-family: ${fontBold};
    line-height: 24px;
  }

  span {
    font-family: ${fontNormal};
    line-height: 18px;
    margin-top: 8px;
  }

  @media only screen and (width >= 1024px) {
    ${verticalLayout}
    span {
      margin: 4px 0 16px;
    }
  }

  @media only screen and (width >= 768px) and (width <= 1023px) {
    margin-right: 24px;

    h2,
    span {
      max-width: 439px;
      ${textStyling}
    }
  }

  @media only screen and (width <= 767px) {
    margin-bottom: 16px;
    ${verticalLayout}
  }
`;

export const AcqLink = styled.div`
  height: 44px;
  border-radius: 2px;
  background-color: ${buildColor("blue_accent", "500")};
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: ${buildColor("blue_accent", "000")};
    text-decoration: none;
  }

  &:hover {
    background-color: ${buildColor("blue_accent", "600")};
  }

  &:active {
    background-color: ${buildColor("blue_accent", "700")};
  }

  @media only screen and (width >= 1024px) {
    width: 100%;
  }

  @media only screen and (width <= 1023px) and (width >= 768px) {
    width: 289px;
  }

  @media only screen and (width <= 767px) {
    width: 100%;
  }
`;
