import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontMedium, fontBold } from "../../_static/Typography";
import { buildText } from "../../_atom/Text";

export const RunnerRow = styled.article`
  background-color: ${(props) =>
    props.isActive
      ? buildColor("blue_accent", "000")
      : buildColor("white", "100")};
  font-size: 14px;
  padding: 12px;
  border-top: 1px ${(props) => (props.hasDashedBorder ? "dashed" : "solid")}
    ${buildColor("blue", "100")};
  font-family: ${fontMedium};
  border-left: ${(props) =>
    props.isActive ? `2px solid ${buildColor("blue_accent", "500")}` : "0px"};
`;

export const ResultRow = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(3, 55px);
  grid-column-gap: 20px;
`;

export const Position = styled(
  buildText({
    tag: "div",
    fontSize: 14,
    color: buildColor("grey", "900")
  })
)`
  font-family: ${fontBold};
  color: ${(props) =>
    props.isFirst ? buildColor("green", "600") : buildColor("grey", "900")};
  display: inline-flex;
`;

export const MoneyWon = styled.div`
  font-family: ${fontMedium};
  font-size: 14px;
  color: ${buildColor("grey", "800")};
  text-align: right;
  ${({ gridPosition }) =>
    gridPosition &&
    css`
      grid-column-start: ${gridPosition};
    `}
`;

export const RunnerInfo = styled.div`
  padding-top: 8px;
  display: flex;
  align-items: center;
`;

export const Number = styled.div`
  min-width: 16px;
  height: 16px;
  margin-right: 8px;
`;

export const RunnerName = styled.div`
  color: ${buildColor("grey", "900")};
  font-size: 16px;
  font-family: ${fontBold};
`;

export const Comment = styled.p`
  font-family: ${fontNormal};
  font-size: 14px;
  color: ${buildColor("grey", "800")};
  line-height: 18px;
  margin-top: 4px;

  &::first-letter {
    text-transform: uppercase;
  }

  &::before {
    content: '"';
  }

  &::after {
    content: '"';
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-family: ${fontNormal};
  font-size: 14px;
  color: ${buildColor("grey", "800")};
`;
export const InfoItem = styled.div`
  &:not(:last-child) {
    &::after {
      content: "|";
      margin: 0 4px;
    }
  }
`;

export const DNF = styled.span`
  width: 30px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  border-radius: 2px;
  border: 1px solid ${buildColor("grey", "700")};
  color: ${buildColor("grey", "700")};
  font-family: ${fontMedium};
  font-size: 12px;

  &::before {
    content: "DNF";
  }
`;
