import { get } from "lodash";
import {
  BettingInterest,
  FullResultRunner,
  Results,
  RaceInfoMyBetsResults,
  Runner,
  Payoffs,
  ResultRunner
} from "@tvg/ts-types/Race";
import { formatSecondsToDuration } from "@tvg/formatter/dates";

const payoffBets = ["WN", "PL", "SH"];

type ResultsTemplateProps = {
  label: string;
  show: boolean;
};

type ResultsTemplate = {
  winningTime: ResultsTemplateProps;
};

export const RESULTS_TEMPLATE = {
  emptyWinningTime: {
    winningTime: {
      label: "n/d",
      show: true
    }
  }
};

export const mapRunnersToFullResults = (
  bettingInterests: BettingInterest[],
  runners: Runner[]
): FullResultRunner[] =>
  (runners || []).map((runner) => {
    const bi = bettingInterests[get(runner, "biNumber", 1) - 1];
    const runnerBI = bettingInterests[
      get(runner, "biNumber", 1) - 1
    ].runners.find((r) => +r.runnerId === runner.runnerNumber);

    const runnerResult = {
      runnerId: `${get(runner, "runnerNumber", "")}`,
      runnerName: get(runner, "runnerName", ""),
      jockey: get(runnerBI, "jockey", ""),
      trainer: get(runnerBI, "trainer", ""),
      scratched: get(runnerBI, "scratched", false),
      finishPosition: get(runner, "finishPosition", 0)
    };

    return get(runner, "timeform")
      ? {
          ...runnerResult,
          odds: get(bi, "currentOdds"),
          accBeatenDistance: get(runner, "timeform.accBeatenDistance", ""),
          accBeatenDistanceStatus: get(
            runner,
            "timeform.accBeatenDistanceStatus",
            ""
          ),
          postRaceReport: get(runner, "timeform.postRaceReport", "")
        }
      : runnerResult;
  });

export const checkTimeformData = (
  results: Results | RaceInfoMyBetsResults
): boolean => !!get(results, "runners[0].timeform", false);

export const buildResults = (
  results?: Results,
  defaultValues: ResultsTemplate = {
    winningTime: {
      label: "",
      show: false
    }
  }
) => {
  const formattedResult: {
    payoffs: Array<Payoffs>;
    runners: Array<ResultRunner>;
    winningTime: string | undefined;
  } = {
    payoffs: (get(results, "payoffs") || []).filter(
      (payoff) => payoffBets.indexOf(payoff.wagerType.code) === -1
    ),
    runners: get(results, "runners") || [],
    winningTime: undefined
  };

  const { winningTime } = defaultValues;
  const resultsWinningTime = +get(results, "winningTime", "0");

  if (winningTime.show) {
    formattedResult.winningTime =
      resultsWinningTime > 0
        ? formatSecondsToDuration(resultsWinningTime)
        : winningTime.label;
  }

  return formattedResult;
};
