import React, { useEffect, memo, useRef, useState } from "react";
import { useField } from "@unform/core";

import { get, toString } from "lodash";
import {
  StyledSelect,
  IndividualSelectWrapper,
  SelectLabel,
  SelectWrapper,
  IconWrapper,
  SelectContainer
} from "./styled-components";
import { arrowDown, warning } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { ErrorText } from "../InputV2/styled-components";

/**
 * Month, Day & Year generator for default value.
 * Month field should have all months available to select;
 * Day field should have 31 days available to select;
 * Year field should have all years from 1900 available to select.
 *
 * @param {number} start
 * @param {number} end
 * @param {number} step
 * @returns {{key: string, value: string}[]}
 */
export function makeRange(start = 1, end = 100, step = 1) {
  const listOfResults = [];
  for (let i = start; i <= end; i += step) {
    const numStr = String(i);
    const formatedNum = numStr.length > 1 ? numStr : 0 + numStr;
    listOfResults.push({
      key: formatedNum,
      value: formatedNum
    });
  }
  return listOfResults;
}

const defaultOptions = [];
const currentYear = new Date().getFullYear();
const arrayMonths = makeRange(1, 12);
const arrayDays = makeRange(1, 31);
const arrayYears = makeRange(1900, currentYear).reverse();
defaultOptions.push(arrayMonths, arrayDays, arrayYears);

export const DateOfBirthMolecule = ({
  handleCustomChange,
  options,
  name,
  label,
  defaultValue,
  baseYear,
  shouldValidate,
  handleSelectChange,
  optionDisableCallback,
  validateSelectChange,
  qaLabel
}) => {
  const [valuesDOB, setValuesDOB] = useState(defaultValue);
  const { fieldName, registerField, error } = useField(name);

  // used to provide a ref to each select from the list just for styling purposes.
  const refMap = defaultValue.reduce(
    (refs, kind) => ({ ...refs, [kind]: useRef(null) }),
    {}
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      clearValue() {
        setValuesDOB(["", "", ""]);
      },
      setValue(_, val) {
        setValuesDOB(val);
      },
      getValue() {
        return valuesDOB;
      }
    });
  }, [fieldName, registerField, valuesDOB]);

  /**
   * Called for every change occured at the selects
   * @param {React.SyntheticEvent} event
   * @param {KindEnum} kind - defining if is month, day or year
   */
  const handleOnChange = (event, kind) => {
    const selectedValue = get(event, "target.value");
    const selectedIndex = get(event, "target.selectedIndex");
    const selectOptions = get(event, "target.options");

    let [month, day, year] = valuesDOB;

    switch (kind) {
      case "MM":
        month = selectedValue;
        break;
      case "DD":
        day = selectedValue;
        break;
      case "YYYY":
        year = selectedValue;
        break;
      default:
        break;
    }

    refMap[kind].current.classList.add("selected-item");
    if (shouldValidate) {
      const validDay = get(
        validateSelectChange(month, day, year, baseYear),
        "validDay"
      );

      if (validDay && +day !== validDay) {
        day = validDay;
        // Workaround necessary for Firefox and Safari
        refMap.DD.current.value = validDay;
      }
    }

    setValuesDOB([month, day, year]);

    if (typeof handleCustomChange === "function") {
      handleCustomChange({
        selectOptions,
        val: selectedValue,
        index: selectedIndex
      });
    }
  };

  return (
    <SelectContainer>
      <SelectLabel>{label}</SelectLabel>
      <SelectWrapper
        onChange={() =>
          handleSelectChange(
            Object.values(refMap)
              .map((ref) => ref.current.value)
              .join(" ")
          )
        }
      >
        {defaultValue.map((valueItem, index) => {
          const keyItem = `${valueItem}-${index}`;
          return (
            <IndividualSelectWrapper key={keyItem}>
              <StyledSelect
                name={name}
                error={error}
                onChange={(event) => handleOnChange(event, valueItem)}
                ref={refMap[valueItem]}
                qaLabel={`${qaLabel}date-of-birth-${valueItem}`}
              >
                {valueItem && (
                  <option value="" disabled selected>
                    {valueItem}
                  </option>
                )}
                {options[index].map(({ key, value }, _, { length }) => (
                  <option
                    key={`${valueItem}-${key}`}
                    selected={value === toString(valuesDOB[index])}
                    disabled={optionDisableCallback(value, valueItem, length)}
                  >
                    {key}
                  </option>
                ))}
              </StyledSelect>
              <IconWrapper>
                <Icon
                  color={buildColor("blue_accent", "500")}
                  icon={arrowDown}
                />
              </IconWrapper>
            </IndividualSelectWrapper>
          );
        })}
      </SelectWrapper>
      {error && (
        <ErrorText>
          <Icon icon={warning} color={buildColor("orange", "600")} />
          {error}
        </ErrorText>
      )}
    </SelectContainer>
  );
};

DateOfBirthMolecule.defaultProps = {
  options: defaultOptions,
  defaultValue: ["MM", "DD", "YYYY"],
  baseYear: 2020,
  qaLabel: "generic-"
};

export default memo(DateOfBirthMolecule);
