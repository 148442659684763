import mediator from "@tvg/mediator";

import { createStore, applyMiddleware } from "redux";
import React from "react";
import { bindAll, get, noop } from "lodash";

import LoginService from "@tvg/login-service";
import { CONSTANTS } from "@tvg/login-service/services/static";

import { isFDR } from "@tvg/utils/generalUtils";

import tvgConf from "@tvg/conf";

import { formatDateToMMDDYYYYhhmm } from "@tvg/formatter/dates";

import Login from "./components/LoginComponent";
import reducers from "./reducers";

export class TVGLogin {
  store;

  tvgConf;

  constructor() {
    this.tvgConf = tvgConf();
    this.store = createStore(reducers, applyMiddleware());

    bindAll(this, [
      "triggerLogin",
      "triggerLogout",
      "validateSession",
      "watchSession",
      "getLoginModal"
    ]);

    // can keep it to validate session from TP tool
    mediator.base.subscribe(CONSTANTS["TVG_LOGIN:DO_LOGIN"], (data) =>
      this.triggerLogin(data.payload || {})
    );
    mediator.base.subscribe(CONSTANTS["TVG_LOGIN:DO_LOGOUT"], (data) =>
      this.triggerLogout(data.payload || {})
    );
    mediator.base.subscribe(CONSTANTS["TVG_LOGIN:VALIDATE_SESSION"], () =>
      this.validateSession()
    );
    this.watchSession();
    return this;
  }

  showRGException = (type, endDate) => {
    mediator.base.dispatch({
      type: "TVG_LOGIN:RG_EXCEPTION",
      payload: {
        type,
        endDate
      }
    });
  };

  triggerLogin(payload) {
    return LoginService.doLogin(payload).then((response) => {
      const type = response.error
        ? CONSTANTS.SET_SESSION_ERROR
        : CONSTANTS.SET_SESSION_SUCCESS;

      if (
        type === CONSTANTS.SET_SESSION_SUCCESS &&
        typeof window !== "undefined"
      ) {
        window._pxParam5 = get(
          response,
          "success.data.userDetails.accountNumber"
        );

        mediator.base.dispatch({
          type: "TVG_LOGIN:LOGIN_SUCCESS",
          userData: {
            ...get(response, "success.data.userDetails"),
            currentLocationByState: payload.stateAbbr
          }
        });
      }

      const loginCheck = {
        userLogin: !payload.notFromLogin,
        loginPin: !!Number(payload.pin)
      };

      const RGException = get(response, "error.responsibleGamingExclusion", {});

      this.store.dispatch({
        type,
        payload: {
          ...loginCheck,
          ...(response.error || response.success)
        }
      });

      if (RGException.type) {
        this.showRGException(
          RGException.type,
          formatDateToMMDDYYYYhhmm(RGException.endDate)
        );
      }

      return response;
    });
  }

  triggerLogout(payload) {
    return LoginService.doLogout(payload).then((response) => {
      const type = response.success ? CONSTANTS.CLEAR_SESSION : null;

      if (type) {
        this.store.dispatch({
          type,
          payload: {
            userLogout: true,
            ...(response.error || response.success)
          }
        });
      }

      return response;
    });
  }

  watchSession() {
    if (typeof window !== "undefined" && !isFDR()) {
      this.validateSession();
      return setInterval(() => this.validateSession(), 60000);
    }
    return true;
  }

  validateSession(logged = this.store.getState().logged) {
    return (
      !logged &&
      LoginService.validateSession().then((response) => {
        if (response.success) {
          this.store.dispatch({
            type: CONSTANTS.SET_USER,
            payload: get(response, "success.data", {})
          });
        } else {
          this.store.dispatch({
            type: CONSTANTS.CLEAR_SESSION,
            payload: { userLogout: false }
          });
        }
      })
    );
  }

  getLoginModal(payload, closeModal = noop, history) {
    return (
      <Login
        mobile={payload.mobile}
        callback={payload.callback}
        geopacketUsage={payload.geopacketUsage}
        triggerLogin={this.triggerLogin}
        stateAbbr={payload.stateAbbr}
        closeModal={closeModal}
        history={history}
        hasSuccessMessage={payload.hasSuccessMessage}
        successMessage={payload.successMessage}
        initialUsername={
          !payload.user && payload.successMessage
            ? payload.successMessage.user
            : payload.user
        }
      />
    );
  }
}

const instance = new TVGLogin();
export default instance;
