import mediator from "@tvg/mediator";

export default (data) => {
  switch (data.type) {
    case "attempt":
      mediator.base.dispatch({
        type: "LOGIN_MODAL_LOGIN_ATTEMPT"
      });
      break;
    case "success":
      mediator.base.dispatch({
        type: "LOGIN_MODAL_SUCCESS",
        payload: {
          accountId: data.accountId,
          balance: data.balance
        }
      });
      break;
    case "error":
      mediator.base.dispatch({
        type: "LOGIN_MODAL_LOGIN_ERROR",
        payload: {
          error: data.error
        }
      });
      break;
    case "input-complete":
      mediator.base.dispatch({
        type: "LOGIN_MODAL_FIELD_COMPLETE",
        payload: {
          field: data.field
        }
      });
      break;
    case "state-selected":
      mediator.base.dispatch({
        type: "LOGIN_MODAL_SELECT_STATE",
        payload: {
          state: data.state
        }
      });
      break;
    case "login-start":
    default:
      mediator.base.dispatch({
        type: "LOGIN_MODAL_START"
      });
      break;
  }
};
