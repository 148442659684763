import styled from "styled-components";

export const BetSelectionsContainer = styled.div<{
  isDark?: boolean;
}>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  flex-direction: row;
  height: 42px;
  width: fit-content;
  padding: 0 11px;
  background-color: ${({ isDark }) =>
    isDark ? "var(--fd-colors-core-navy-d-4)" : "transparent"};
  border: 1px solid
    ${({ isDark }) =>
      isDark
        ? "var(--fd-colors-core-navy-d-4)"
        : "var(--fd-colors-border-default)"};
`;
