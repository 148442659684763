import React from "react";
import { noop } from "lodash";
import formatCurrency from "@tvg/formatter/currency";

import {
  HeaderCanceledContainer,
  HeaderColumn,
  HeaderContainer,
  HeaderPayoutButton,
  HeaderRefundedLink,
  HeaderRefundText,
  HeaderRow,
  HeaderText,
  HeaderTitle
} from "./styled-components";
import Icon from "../../_static/Icons";
import { betCanceled, info, question } from "../../_static/Icons/iconsDuotone";
import buildColor from "../../_static/ColorPalette";
import WonIllustration from "../../_atom/WonIllustration";

const renderRefundStatus = (betRefund, qaLabel, addMargin) => (
  <HeaderColumn
    data-qa-label={`${qaLabel}-status-list`}
    textRight
    addMargin={addMargin}
  >
    <HeaderText qaLabel={`${qaLabel}-status-text`} isRefund>
      {formatCurrency(betRefund || 0)}
    </HeaderText>
    {/* There will be a modal explaining this */}
    <HeaderRefundedLink data-qa-label={`${qaLabel}-status-link`} to="/">
      <Icon
        qaLabel={`${qaLabel}-status-icon`}
        icon={info}
        viewBoxSize={16}
        size={16}
        color={buildColor("blue_accent", "100")}
        stroke={buildColor("blue_accent", "500")}
      />
      <HeaderTitle qaLabel={`${qaLabel}-status-title`}>refunded</HeaderTitle>
    </HeaderRefundedLink>
  </HeaderColumn>
);

export const WagerHeaderStatus = (props) => {
  const {
    betStatus,
    winningsAmount,
    probableValue,
    onApproxPayout,
    betRefund,
    isBetWinner,
    shouldShowPotentialReturn,
    qaLabel,
    showMinMaxWillPays,
    minWillPays,
    maxWillPays,
    isFDR
  } = props;

  const renderPotentialPayout = () => (
    <HeaderColumn data-qa-label={`${qaLabel}-status-list`} textRight>
      <HeaderText qaLabel={`${qaLabel}-status-text`}>
        {probableValue}
      </HeaderText>
      <HeaderPayoutButton onClick={onApproxPayout}>
        <Icon
          icon={question}
          viewBoxSize={16}
          size={16}
          color={buildColor("blue_accent", "100")}
          stroke={buildColor("blue_accent", "500")}
        />
        <HeaderTitle qaLabel={`${qaLabel}-status-title`}>
          approx. payout
        </HeaderTitle>
      </HeaderPayoutButton>
    </HeaderColumn>
  );

  const renderWillPays = () => (
    <HeaderColumn>
      <HeaderText data-qa-label="my-bets-payout-value">
        {`${formatCurrency(minWillPays, "USD")} - ${formatCurrency(
          maxWillPays,
          "USD"
        )}`}
      </HeaderText>
      <HeaderTitle data-qa-label="my-bets-payout-button-text">
        Will Pays
      </HeaderTitle>
    </HeaderColumn>
  );

  switch (betStatus) {
    case "canceled":
      return (
        <HeaderColumn data-qa-label={`${qaLabel}-status-list`} textRight>
          <HeaderCanceledContainer data-qa-label={`${qaLabel}-status-wrapper`}>
            <Icon
              qaLabel={`${qaLabel}-status-icon`}
              icon={betCanceled}
              viewBoxSize={16}
              size={16}
              color={buildColor("white", "10")}
              stroke={buildColor("grey", "800")}
            />
            <HeaderText qaLabel={`${qaLabel}-status-text`} isCanceled>
              Cancelled
            </HeaderText>
          </HeaderCanceledContainer>
        </HeaderColumn>
      );
    case "winner":
      return isBetWinner ? (
        <HeaderRow>
          <HeaderContainer>
            <HeaderColumn data-qa-label={`${qaLabel}-status-list`}>
              <WonIllustration
                winAmount={winningsAmount || 0}
                hideLogo={isFDR}
              />
              {!!betRefund && (
                <HeaderRow data-qa-label={`${qaLabel}-status-list`} center>
                  <HeaderRefundText qaLabel={`${qaLabel}-status-text`}>
                    +{formatCurrency(betRefund || 0)}
                  </HeaderRefundText>
                  {/* There will be a modal explaining this */}
                  <HeaderRefundedLink
                    smallText
                    data-qa-label={`${qaLabel}-status-link`}
                    to="/"
                  >
                    <HeaderTitle qaLabel={`${qaLabel}-status-title`}>
                      refund
                    </HeaderTitle>
                  </HeaderRefundedLink>
                </HeaderRow>
              )}
            </HeaderColumn>
          </HeaderContainer>
        </HeaderRow>
      ) : (
        <HeaderRow>
          {!!betRefund && (
            <HeaderColumn data-qa-label={`${qaLabel}-status-list`} textRight>
              <HeaderText qaLabel={`${qaLabel}-status-text`}>
                {formatCurrency(betRefund || 0)}
              </HeaderText>
              {/* There will be a modal explaining this */}
              <HeaderRefundedLink
                data-qa-label={`${qaLabel}-status-link`}
                to="/"
              >
                <HeaderTitle qaLabel={`${qaLabel}-status-title`}>
                  refund
                </HeaderTitle>
              </HeaderRefundedLink>
            </HeaderColumn>
          )}
          <HeaderContainer>
            <HeaderColumn data-qa-label={`${qaLabel}-status-list`} textRight>
              <HeaderText
                qaLabel={`${qaLabel}-status-text`}
                isWinner={isBetWinner}
              >
                {formatCurrency(winningsAmount || 0)}
              </HeaderText>
              <HeaderTitle
                qaLabel={`${qaLabel}-status-title`}
                isWinner={isBetWinner}
              >
                return
              </HeaderTitle>
            </HeaderColumn>
          </HeaderContainer>
        </HeaderRow>
      );
    case "loser":
      return (
        <HeaderContainer>
          {betRefund > 0 && renderRefundStatus(betRefund, qaLabel, true)}
          <HeaderColumn data-qa-label={`${qaLabel}-status-list`} textRight>
            <HeaderText qaLabel={`${qaLabel}-status-text`}>
              {formatCurrency(0)}
            </HeaderText>
            <HeaderTitle qaLabel={`${qaLabel}-status-title`}>
              return
            </HeaderTitle>
          </HeaderColumn>
        </HeaderContainer>
      );
    case "refunded":
      return (
        <React.Fragment>
          {renderRefundStatus(betRefund, qaLabel, false)}
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>
          {shouldShowPotentialReturn && renderPotentialPayout()}
          {showMinMaxWillPays && renderWillPays()}
        </React.Fragment>
      );
  }
};

WagerHeaderStatus.defaultProps = {
  betStatus: "active",
  winningsAmount: 0,
  probableValue: "",
  onApproxPayout: noop,
  betRefund: 0,
  isBetWinner: false,
  shouldShowPotentialReturn: false,
  qaLabel: "wager-header-status",
  showMinMaxWillPays: false,
  minWillPays: 0,
  maxWillPays: 0,
  isFDR: false
};
export default WagerHeaderStatus;
