import React, { Component } from "react"; //eslint-disable-line
import classNames from "classnames";
import styles from "./index.css";

/**
 * TVG Loading Component
 */
class Loading extends Component {
  static defaultProps = {
    size: "medium",
    show: true
  };

  static getLoadingProps(type) {
    const dict = {
      big: styles.loadingBig,
      small: styles.loadingSmall,
      medium: styles.loadingMedium
    };

    return dict[type];
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    const size = this.props.size || "medium";

    return (
      <div
        className={classNames(styles.loading, Loading.getLoadingProps(size))}
        data-qa-label="Loading"
      />
    );
  }
}

export default Loading;
