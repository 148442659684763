import React, { useEffect, useRef, useState } from "react";
import { noop } from "lodash";
import { useField } from "@unform/core";

import { useDebouncedStringDiff } from "@tvg/custom-hooks";
import Icon from "../../_static/Icons";
import buildColor from "../../_static/ColorPalette";
import { warning } from "../../_static/Icons/icons";
import {
  Container,
  Label,
  OptionalLabel,
  InputMaskStyled,
  ErrorText,
  IconWrapper,
  InputWrapper
} from "../InputV2/styled-components";

const InputMask = ({
  name,
  label,
  optionalLabel,
  qaLabel,
  type,
  inputMode,
  isMono,
  placeholder,
  customHandleChange,
  customHandleOnBlur,
  icon,
  handleIconClick,
  letterSpacing,
  marginTop,
  marginBottom,
  alignCenter,
  mask,
  maskChar,
  alwaysShowMask,
  debouncedValueCallback,
  hasPreloadedInputs,
  ...inputProps
}) => {
  const [trackOfInputValue, setTrackOfInputValue] = useState("");
  const inputRef = useRef(null);

  useDebouncedStringDiff(
    trackOfInputValue,
    debouncedValueCallback,
    hasPreloadedInputs
  );

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      setValue(ref, value = "") {
        if (ref && typeof ref.setInputValue && typeof value === "string") {
          ref.setInputValue(value);
          setTrackOfInputValue(value);
        }
      },
      clearValue(ref) {
        if (ref) {
          ref.setInputValue("");
          setTrackOfInputValue("");
        }
      }
    });
  }, [fieldName, registerField]);

  return (
    <Container
      data-qa-label={qaLabel}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <Label htmlFor={fieldName}>
        {label}
        {optionalLabel && <OptionalLabel>({optionalLabel})</OptionalLabel>}
      </Label>
      <InputWrapper>
        <InputMaskStyled
          id={fieldName}
          data-qa-label={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          type={type}
          $isMono={isMono}
          inputMode={inputMode}
          onBlur={(event) => {
            const { value } = event.target;
            if (typeof customHandleOnBlur === "function") {
              customHandleOnBlur(value);
            }
          }}
          onChange={(event) => {
            const { value } = event.target;

            if (typeof customHandleChange === "function") {
              customHandleChange(value, fieldName);
            }

            setTrackOfInputValue(value);
          }}
          error={error}
          mask={mask}
          maskChar={maskChar}
          placeholder={placeholder}
          alwaysShowMask={alwaysShowMask}
          letterSpacing={letterSpacing}
          $alignCenter={alignCenter}
          {...inputProps}
        />
        {icon && (
          <IconWrapper onClick={handleIconClick} type="button">
            {icon}
          </IconWrapper>
        )}
      </InputWrapper>
      {error && (
        <ErrorText>
          <Icon icon={warning} color={buildColor("orange", "600")} />
          {error}
        </ErrorText>
      )}
    </Container>
  );
};

InputMask.defaultProps = {
  optionalLabel: "",
  type: "text",
  inputMode: "",
  placeholder: "",
  customHandleChange: noop,
  icon: null,
  handleIconClick: noop,
  letterSpacing: 0,
  marginTop: 0,
  marginBottom: 0,
  alignCenter: false
};

export default InputMask;
