import styled from "styled-components";
import { fontMedium, fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const ContentCardEmpty = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: ${buildColor("white", "100")};
`;

export const ContentCardEmptyContainer = styled.div`
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: ${buildColor("grey", "100")};
  border-radius: 50%;
`;

export const Strong = styled.p`
  display: block;
  margin-top: 20px;
  font-size: 14px;
  font-family: ${fontMedium};
  text-align: center;
  color: ${buildColor("grey", "900")};
`;

export const P = styled.p`
  display: block;
  margin-top: 5px;
  font-size: 14px;
  font-family: ${fontNormal};
  text-align: center;
  color: ${buildColor("grey", "800")};
`;
