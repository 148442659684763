export const addMessage = (message) => ({
  type: "MESSENGER_ADD_MESSAGE",
  payload: { message }
});

export const removeMessage = (key) => ({
  type: "MESSENGER_REMOVE_MESSAGE",
  payload: { key }
});

export const removeAllMessages = () => ({
  type: "MESSENGER_REMOVE_ALL_MESSAGES"
});
