import * as mediator from "@tvg/mediator-classic/src";
import _, { get } from "lodash";
import CONSTANTS from "./constants";

export const initialState = {
  hasRequested: false,
  logging: false,
  logged: false,
  error: null,
  wasLogin: false,
  userLogin: false,
  userLogout: false,
  geolocateIn: 0,
  user: null
};

export const mediatorSessionUpdateMiddleware = (oldState, newState) => {
  if (
    !oldState.hasRequest ||
    !_.isEqual(oldState.logged, newState.logged) ||
    !_.isEqual(oldState.user, newState.user) ||
    !!newState.forceSessionUpdate
  ) {
    mediator.dispatch(CONSTANTS["TVG_LOGIN:USER_SESSION_UPDATE"], newState);
  }

  return newState;
};

const reducers = {
  [CONSTANTS.SET_SESSION_SUCCESS]: (state, payload) =>
    mediatorSessionUpdateMiddleware(state, {
      ...state,
      ...{
        user: payload.data ? payload.data.userDetails : null,
        hasRequested: true,
        logged: true,
        logging: false,
        wasLogin: payload.fromLogin,
        userLogin: payload.userLogin,
        geolocateIn: get(payload.data, "geoDetails.geolocateIn", 1),
        error: null
      }
    }),

  [CONSTANTS.SET_SESSION_ERROR]: (state, payload) =>
    mediatorSessionUpdateMiddleware(state, {
      ...state,
      ...{
        user: null,
        hasRequested: true,
        logged: false,
        logging: false,
        wasLogin: payload.fromLogin,
        userLogin: payload.userLogin,
        error: payload.message
      }
    }),
  [CONSTANTS.SET_USER]: (state, payload) =>
    mediatorSessionUpdateMiddleware(state, {
      ...state,
      ...{
        user: payload.user,
        hasRequested: true,
        forceSessionUpdate: payload.forceSessionUpdate || false,
        logged: true,
        logging: false,
        wasLogin: payload.fromLogin,
        userLogin: payload.userLogin,
        geolocateIn: get(payload.data, "geoDetails.geolocateIn", 1),
        error: null
      }
    }),
  [CONSTANTS.CLEAR_SESSION]: (state, payload) =>
    mediatorSessionUpdateMiddleware(state, {
      ...initialState,
      ...{
        hasRequested: true,
        userLogout: payload.userLogout
      }
    })
};

export default (state = initialState, action) => {
  const validAction = action || { type: "" };

  return validAction.type && reducers[validAction.type]
    ? reducers[validAction.type](state, validAction.payload)
    : state;
};
