import styled, { css } from "styled-components";

import Button from "../../_atom/Buttons/default";
import { titleFont } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const RaceButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 8px 8px 8px 6px;
  outline: none;
  ${(props) =>
    props.isSelected
      ? css`
          background-color: ${buildColor("blue", "000")};
          position: relative;

          &::before {
            content: "";
            position: absolute;
            width: 2px;
            top: 0;
            left: 0;
            bottom: 0;
            background-color: ${buildColor("blue_accent", "500")};
          }
        `
      : css`
          background-color: ${buildColor("white", "0")};
        `};

  & > * {
    flex-shrink: 0;
  }
`;

export const RaceSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 8px 0;
  background-color: ${buildColor("white", "100")};

  & > * {
    flex-shrink: 0;
  }
`;

export const Date = styled.time`
  width: 42px;
  min-height: 32px;
  display: flex;
  flex: 0 0 auto;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  color: ${buildColor("grey", "800")};
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1.4;
  border-right: 1px solid ${buildColor("blue", "100")};

  strong {
    color: ${buildColor("grey", "900")};
  }
`;

export const RaceInfo = styled.div`
  flex: 1 1 auto;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
`;

export const TrackRace = styled.h3`
  display: flex;
  text-align: left;
  font-family: ${titleFont};
  text-transform: uppercase;
  color: ${buildColor("grey", "900")};
`;

export const TrackDate = styled.time`
  display: flex;
  text-align: left;
  height: 12px;
  width: 55px;
  font-size: 11px;
  color: ${buildColor("grey", "800")};
  font-weight: bold;
  margin-bottom: 2px;
`;

export const RaceDataList = styled.ul`
  list-style: none;
  text-align: left;
`;

export const RaceDataListItem = styled.li`
  font-size: 12px;
  color: ${buildColor("grey", "800")};
  display: inline;
  text-align: left;

  &:not(:last-child) {
    &::after {
      content: "";
      border-right: 1px solid ${buildColor("grey", "600")};
      padding-right: 4px;
      margin-right: 4px;
    }
  }
`;

export const Position = styled.strong`
  border-radius: 2px;
  display: flex;
  height: 15px;
  align-items: center;
  padding: 0 2px;
  margin: 0 4px;
  color: ${buildColor("grey", "900")};
  background-color: ${buildColor("blue", "100")};

  & > sup {
    display: inline-block;
    font-size: 8px;
    transform: translate(0, -2px);
    margin-right: 4px;
    vertical-align: inherit;
  }
`;

export const RaceArrowLink = styled(Button)`
  background: ${buildColor("white", "0")};
  width: 40px;

  &:active {
    background-color: ${buildColor("white", "50")};
  }
`;

export const RaceArrowContent = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
`;

export const RaceArrowNavigation = styled.div`
  display: flex;
  flex: 0 0 72px;
  flex-direction: row;
  align-items: stretch;
  margin-left: 8px;
`;
