import React from "react";
import style from "./style.css";

const SignupHeader = (props) => {
  const firstTextLine = "come join america's biggest horse";
  const secondTextLine = "racing site";

  return (
    <div className={style.signupHeader}>
      {!props.mobile ? (
        <h3 className={style.signupHeaderTitle}>new to tvg?</h3>
      ) : null}
      <p className={style.firstTextLine}>{firstTextLine}</p>
      <p className={style.secondTextLine}>{secondTextLine}</p>
    </div>
  );
};

SignupHeader.defaultProps = {
  mobile: false
};

export default SignupHeader;
