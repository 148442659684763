import { ColorMapperAggregate } from "./types";

export const toastContentMap: ColorMapperAggregate = {
  success: {
    icon: {
      name: "success",
      lineColor: "--fd-colors-system-positive-content-on-default",
      backgroundColor: "transparent"
    },
    text: "--fd-colors-system-positive-content-on-default",
    background: "--fd-colors-system-positive-background-default",
    link: "--fd-colors-system-positive-link-on-default"
  },
  info: {
    icon: {
      name: "info",
      lineColor: "--fd-colors-system-info-content-on-default",
      backgroundColor: "transparent"
    },
    text: "--fd-colors-system-info-content-on-default",
    background: "--fd-colors-system-info-background-default",
    link: "--fd-colors-system-info-link-on-default"
  },
  error: {
    icon: {
      name: "exclamation",
      lineColor: "--fd-colors-system-important-content-on-default",
      backgroundColor: "transparent"
    },
    text: "--fd-colors-system-important-content-on-default",
    background: "--fd-colors-system-important-background-default",
    link: "--fd-colors-system-important-link-on-default"
  },
  warning: {
    icon: {
      name: "warning",
      lineColor: "--fd-colors-system-warning-content-on-default",
      backgroundColor: "transparent"
    },
    text: "--fd-colors-system-warning-content-on-default",
    background: "--fd-colors-system-warning-background-default",
    link: "--fd-colors-system-warning-link-on-default"
  },
  promo: {
    icon: {
      name: "promo",
      lineColor: "--fd-colors-system-alert-content-on-default",
      backgroundColor: "transparent"
    },
    text: "--fd-colors-system-alert-content-on-default",
    background: "--fd-colors-system-alert-background-default",
    link: "--fd-colors-system-alert-link-on-default"
  }
};
