export const openLocationSplash = () => ({
  type: "OPEN_LOCATION_SPLASH"
});

export const clearSplashErrors = () => ({
  type: "CLEAR_ERROR_SPLASH"
});

export const setBlockedCountry = (isLocationRequired) => ({
  type: "SET_BLOCKED_COUNTRY",
  payload: isLocationRequired
});

export const setAndroidBlockedState = () => ({
  type: "ANDROID_BLOCKED_STATE"
});

export const closeLocationSplash = () => ({
  type: "CLOSE_LOCATION_SPLASH"
});

export const setLocationRequired = (locationRequired) => ({
  type: "SET_LOCATION_REQUIRED",
  payload: locationRequired
});

export const setLocationLoading = (loading) => ({
  type: "SET_LOCATION_LOADING",
  payload: loading
});

export const setLocationDenied = () => ({
  type: "SET_LOCATION_DENIED"
});

export const setLocationUnsupported = () => ({
  type: "SET_LOCATION_UNSUPPORTED"
});

export const setLocationUnknownError = (showClose) => ({
  type: "SET_LOCATION_UNKNOWN_ERROR",
  payload: showClose
});

export const setLocationUnavailable = () => ({
  type: "SET_LOCATION_UNAVAILABLE"
});

export const setLocationTimedOut = () => ({
  type: "SET_LOCATION_TIMED_OUT"
});

export const setLocationUnauthorized = () => ({
  type: "SET_LOCATION_UNAUTHORIZED"
});

export const getGeoLocation = (locationData) => ({
  type: "GEO_LOCATION_GET",
  payload: locationData
});

export const setOnLocationGet = (callback) => ({
  type: "SET_ON_LOCATION_GET",
  payload: callback
});

export const rgTimeoutExclusionSplash = (type, endDate) => ({
  type: "RG_TIMEOUT_EXCLUSION_SPLASH",
  payload: {
    type,
    endDate
  }
});
