import { gql } from "@apollo/client";

export const RESULTS = gql`
  fragment Results on Race {
    results {
      runners {
        betAmount
        biNumber
        finishPosition
        placePayoff
        runnerName
        showPayoff
        winPayoff
        runnerNumber
        timeform {
          postRaceReport
          accBeatenDistance
          accBeatenDistanceStatus
        }
      }
      payoffs {
        selections {
          payoutAmount
          selection
        }
        wagerAmount
        wagerType {
          code
          name
        }
      }
      winningTime
    }
  }
`;

export default RESULTS;
