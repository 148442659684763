import tvgConf from "@tvg/conf";
import mediatorChannels from "@tvg/mediator";
import pushToDataLayer, { getSiteVersion, getProduct } from "../gtmUtils";

export default () => {
  const productVersion = getProduct(tvgConf().product);
  const siteVersion = getSiteVersion(tvgConf().product);

  mediatorChannels.base.subscribe("WAR_OPTIN", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "OPT-IN TO WAGER REWARDS",
      tag: undefined,
      gaEventLabel: undefined,
      module: "Wager Rewards",
      microApp: undefined,
      productVersion,
      siteVersion
    });
  });

  mediatorChannels.base.subscribe("WAR_OPTIN_SUCCESS", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Opt-In Success",
      tag: undefined,
      gaEventLabel: "Wager Rewards",
      module: "Wager Rewards",
      microApp: undefined,
      productVersion,
      siteVersion
    });
  });

  mediatorChannels.base.subscribe("WAR_OPTIN_ERROR", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Opt-In Error",
      tag: data.payload.message,
      gaEventLabel: "Wager Rewards",
      module: "Wager Rewards",
      microApp: undefined,
      productVersion,
      siteVersion
    });
  });

  mediatorChannels.base.subscribe("WAR_FIND_RACE", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Find a Race",
      tag: undefined,
      gaEventLabel: undefined,
      module: "Wager Rewards",
      microApp: undefined,
      productVersion,
      siteVersion
    });
  });

  mediatorChannels.base.subscribe("WAR_CASH_IN", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Cash In",
      tag: data.payload,
      gaEventLabel: undefined,
      module: "Wager Rewards",
      microApp: undefined,
      productVersion,
      siteVersion
    });
  });
  mediatorChannels.base.subscribe("WAR_FILTER", (data) => {
    pushToDataLayer({
      event: "filter",
      gaEventCategory: "Filters",
      gaEventAction: "Select Filter",
      gaEventLabel: data.payload.filter,
      gaEventValue: undefined,
      preChecked: data.payload.preCheckedFilter,
      module: "Rewards History Filter",
      microApp: "non-microApp",
      upNextTracks: undefined,
      favTracks: undefined,
      atozTracks: undefined,
      productVersion,
      siteVersion
    });
  });

  mediatorChannels.base.subscribe("WAR_SEE_ALL_HISTORY", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "See All History",
      tag: data.payload.points,
      gaEventLabel: undefined,
      module: "Wager Rewards",
      microApp: undefined,
      productVersion,
      siteVersion
    });
  });
};
