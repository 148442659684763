import React from "react";

import Text from "@tvg/atomic-ui/_atom/Text";

import {
  OfferItemContainer,
  OfferItemLink,
  EventTime,
  EventPlace,
  EventLocation,
  EventText,
  OfferItemContent,
  OfferItemText
} from "./styled-components";
import ExternalLink from "../../_atoms/ExternalLink";
import buildColor from "../../_static/ColorPalette";
import InternalLink from "../../_atoms/InternalLink";
import Icon from "../../_static/Icons";
import { arrowRight, popOut } from "../../_static/Icons/icons";

// Render was extracted outside of component because it fails during build time due to span
const renderContent = (text, description, qaLabel = "offerItem") => (
  <OfferItemContent data-qa-label={`${qaLabel}Content`}>
    <OfferItemText itemProp="name" data-qa-label={`${qaLabel}Text`}>
      {text}
    </OfferItemText>
    <Text
      tag="span"
      normal
      fontSize={14}
      color={buildColor("grey", "800")}
      qaLabel={`${qaLabel}Description`}
    >
      {description}
    </Text>
  </OfferItemContent>
);

const OfferItem = ({
  text,
  path,
  eventEndDate,
  eventStartDate,
  addressLocality,
  addressRegion,
  locationName,
  description,
  qaLabel
}) => {
  const renderExternalLink = () => (
    <ExternalLink
      url={path.url}
      itemProp="url"
      qaLabel={`${qaLabel}ExternalLink`}
    >
      {renderContent(text, description, qaLabel)}
      <Icon
        icon={popOut}
        size={16}
        view="0 0 16 16"
        qaLabel={`${qaLabel}ExternalLinkIcon`}
      />
    </ExternalLink>
  );

  const renderInternalLink = () => (
    <InternalLink
      pathName={path.linktype === "story" ? path.cached_url : path.url}
      linkType={path.linktype}
      target="_self"
      itemProp="url"
      qaLabel={`${qaLabel}InternalLink`}
    >
      {renderContent(text, description, qaLabel)}
      <Icon
        icon={arrowRight}
        size={16}
        view="0 0 16 16"
        qaLabel={`${qaLabel}InternalLinkIcon`}
      />
    </InternalLink>
  );

  return (
    <OfferItemContainer
      itemScope
      itemType="http://schema.org/Event"
      data-qa-label={qaLabel}
    >
      <EventTime
        itemProp="startDate"
        dateTime={eventStartDate}
        data-qa-label={`${qaLabel}EventStartDate`}
      />
      <EventTime
        itemProp="endDate"
        dateTime={eventEndDate}
        data-qa-label={`${qaLabel}EventEndDate`}
      />
      <OfferItemLink data-qa-label={`${qaLabel}Link`}>
        {path.linktype === "story" ||
        (path.url.indexOf("http") < 0 && path.linktype === "url")
          ? renderInternalLink()
          : renderExternalLink()}
      </OfferItemLink>
      <EventPlace
        itemScope
        itemProp="location"
        itemType="http://schema.org/Place"
        data-qa-label={`${qaLabel}EventPlace`}
      >
        <EventText itemProp="name">{locationName}</EventText>
        <EventLocation
          itemScope
          itemProp="address"
          itemType="http://schema.org/PostalAddress"
          data-qa-label={`${qaLabel}EventLocation`}
        >
          <EventText
            itemProp="addressLocality"
            data-qa-label={`${qaLabel}EventLocality`}
          >
            {addressLocality}
          </EventText>
          <EventText
            itemProp="addressRegion"
            data-qa-label={`${qaLabel}EventRegion`}
          >
            {addressRegion}
          </EventText>
        </EventLocation>
      </EventPlace>
    </OfferItemContainer>
  );
};

OfferItem.defaultProps = {
  _uid: "12345",
  component: "offer_item",
  text: "",
  description: "",
  path: {
    url: "",
    cached_url: "",
    linkType: "story"
  },
  eventStartDate: "",
  eventEndDate: "",
  addressLocality: "",
  addressRegion: "",
  locationName: "",
  qaLabel: "offerItem"
};

export default React.memo(OfferItem);
