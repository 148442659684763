import styled, { css } from "styled-components";
import { margin } from "styled-system";
import type { MtpCellProps } from "./types";

export const Container = styled.div<
  Pick<MtpCellProps, "withBackground" | "align"> & {
    bgColor: string;
    lineColor: string;
  }
>`
  background-color: ${({ withBackground, bgColor }) =>
    withBackground ? `var(${bgColor})` : "transparent"};
  border-top-left-radius: 4px;
  display: inline-flex;
  flex-direction: column;
  height: 60px;
  min-width: 94px;
  max-width: 102px;
  justify-content: center;
  padding: 0 10px 0 12px;
  align-items: flex-start;
  ${({ align, lineColor, withBackground }) =>
    align === "left"
      ? css`
          border-right: solid ${withBackground ? 1 : 0}px var(${lineColor});
          border-bottom: solid ${withBackground ? 1 : 0}px var(${lineColor});
        `
      : css`
          border-left: solid ${withBackground ? 1 : 0}px var(${lineColor});
          border-bottom: solid ${withBackground ? 1 : 0}px var(${lineColor});
        `};

  ${({ align }) =>
    align !== "left" &&
    css`
      align-items: flex-end;
    `}
  ${margin}
`;
