import React from "react";
import { noop } from "lodash";
import { Link } from "react-router-dom";
import { formatPastRaceDate } from "@tvg/formatter/dates";
import formatCurrency from "@tvg/formatter/currency";

import ButtonLink from "../../_atom/Buttons/buttonLink";
import Icon from "../../_static/Icons";
import { play } from "../../_static/Icons/iconsDuotone";
import { hasReplay } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import {
  RaceHeaderContainer,
  RaceHeaderWrapper,
  RaceWatchReplayWrapper,
  RaceHeaderInfo,
  RaceTrackNameWrapper,
  RaceTrackName,
  RaceTrackNumber,
  RaceFeaturedEventName,
  RaceBetsInfo,
  RaceHeaderActions,
  VideoButtonIconWrapper,
  RepeatBetButton
} from "./styled-components";
import MyBetsMTP from "../MyBetsMTP";
import Button from "../../_atom/Buttons/button";

const getBetsInfo = (betAmount, bets) => {
  const betTitle = `Bet${bets > 1 ? "s" : ""}`;
  return `${bets} ${betTitle} • ${formatCurrency(betAmount)} staked`;
};

const getButtonProps = (hasReplayVideo) =>
  hasReplayVideo
    ? {
        text: "Watch Replay",
        type: "primary",
        hasIcon: true,
        hasShadow: false
      }
    : {
        text: "See Result",
        type: "secondary",
        hasIcon: false,
        hasShadow: true
      };

const renderVideoButton = (
  raceUrl,
  currentRaceNumber,
  showRaceNumber,
  isRaceNear,
  onVideoRedirect
) => {
  const [racePathname] = raceUrl.split("?");
  const iconColor = isRaceNear
    ? buildColor("white", "100")
    : buildColor("blue_accent", "500");
  return (
    <ButtonLink
      url={{
        pathname: racePathname,
        search: `?race=${currentRaceNumber}`,
        state: {
          openVideo: true
        }
      }}
      onClick={onVideoRedirect}
      qaLabel="watch-video-button"
      size="bigger"
      isBold={false}
      isUppercase={false}
      type={isRaceNear ? "primary" : "secondary"}
      hasShadow={!isRaceNear}
    >
      <VideoButtonIconWrapper>
        <Icon
          icon={play}
          size={14}
          viewBoxSize={20}
          color={buildColor("white", "0")}
          stroke={iconColor}
        />
      </VideoButtonIconWrapper>
      Watch {showRaceNumber ? `R${currentRaceNumber}` : "Live"}
    </ButtonLink>
  );
};

const renderRedirectReplayButton = (
  raceUrl,
  hasReplayVideo,
  onWatchReplay,
  closeModal
) => {
  const [racePathname, raceSearch] = raceUrl.split("?");
  const buttonProps = getButtonProps(hasReplayVideo);

  return (
    <RaceWatchReplayWrapper>
      <ButtonLink
        isStretched
        size="bigger"
        isBold={false}
        isUppercase={false}
        url={{
          pathname: racePathname,
          search: raceSearch,
          state: {
            openVideo: true
          }
        }}
        onClick={() => {
          onWatchReplay(false);
          closeModal();
        }}
        qaLabel="watch-video-button"
        hasRoundedCorners
        type={buttonProps.type}
        hasShadow={buttonProps.hasShadow}
      >
        {buttonProps.hasIcon && (
          <VideoButtonIconWrapper>
            <Icon
              icon={hasReplay}
              size={15}
              color={buildColor("white", "100")}
            />
          </VideoButtonIconWrapper>
        )}
        {buttonProps.text}
      </ButtonLink>
    </RaceWatchReplayWrapper>
  );
};

const renderReplayButton = (
  onWatchReplay,
  isReplayInsideMyBets,
  hasReplayVideo,
  raceUrl,
  showSeeResult,
  closeModal
  // eslint-disable-next-line consistent-return
) => {
  if (!isReplayInsideMyBets && hasReplayVideo) {
    return renderRedirectReplayButton(
      raceUrl,
      hasReplayVideo,
      onWatchReplay,
      closeModal
    );
  }
  if (showSeeResult || isReplayInsideMyBets) {
    const buttonProps = getButtonProps(hasReplayVideo);

    return (
      <RaceWatchReplayWrapper>
        <Button
          isStretched
          size="bigger"
          isBold={false}
          isUppercase={false}
          onClick={onWatchReplay}
          qaLabel="watch-video-button"
          hasRoundedCorners
          type={buttonProps.type}
          hasShadow={buttonProps.hasShadow}
        >
          {buttonProps.hasIcon && (
            <VideoButtonIconWrapper>
              <Icon
                icon={hasReplay}
                size={15}
                color={buildColor("white", "100")}
              />
            </VideoButtonIconWrapper>
          )}
          {buttonProps.text}
        </Button>
      </RaceWatchReplayWrapper>
    );
  }
};

const MyBetsCardHeader = (props) => {
  const {
    racePostTime,
    statusCode,
    trackName,
    eventName,
    raceNumber,
    currentRaceNumber,
    mtp,
    bets,
    betAmount,
    dateDiff,
    winningsAmount,
    raceUrl,
    closeModal,
    onWatchReplay,
    isFutureBet,
    raceDate,
    isMTPNewRules,
    hasReplayVideo,
    hasLiveVideo,
    postTime,
    showRaceLeg,
    legNumber,
    onVideoRedirect,
    isReplayInsideMyBets,
    showBetInfo,
    qaLabel,
    hasPastPerformance,
    isRaceNear,
    showSeeResult,
    isMyBetsOpen,
    hasRepeatBet,
    onRepeatBet
  } = props;

  return (
    <RaceHeaderContainer data-qa-label={qaLabel}>
      <RaceHeaderWrapper
        data-qa-label={`${qaLabel}-details`}
        as={dateDiff === 0 && isMyBetsOpen ? Link : "div"}
        to={raceUrl}
        onClick={closeModal}
      >
        <RaceHeaderInfo data-qa-label={`${qaLabel}-info`}>
          <MyBetsMTP
            showRaceLeg={showRaceLeg}
            legNumber={legNumber}
            isFutureBet={isFutureBet}
            raceDate={raceDate}
            postTime={postTime}
            currentDate={formatPastRaceDate(postTime || "")}
            raceMtp={mtp}
            racePostTime={racePostTime}
            raceStatusCode={statusCode}
            raceDateDiff={dateDiff}
            isMTPNewRules={isMTPNewRules}
            qaLabel={`${qaLabel}-mtp`}
          />
          {eventName && (
            <RaceFeaturedEventName data-qa-label={`${qaLabel}-event-name`}>
              {eventName}
            </RaceFeaturedEventName>
          )}
          <RaceTrackNameWrapper data-qa-label={`${qaLabel}-track-name`}>
            <RaceTrackName>{trackName}</RaceTrackName>
            {raceNumber && (
              <RaceTrackNumber>{` R${raceNumber}`}</RaceTrackNumber>
            )}
          </RaceTrackNameWrapper>
          {showBetInfo && (
            <RaceBetsInfo data-qa-label={`${qaLabel}-bets-info`}>
              {getBetsInfo(betAmount, bets)}
            </RaceBetsInfo>
          )}
        </RaceHeaderInfo>
        <RaceHeaderActions data-qa-label={`${qaLabel}-header-actions`}>
          {hasLiveVideo &&
            renderVideoButton(
              raceUrl,
              currentRaceNumber,
              !!legNumber,
              isRaceNear,
              onVideoRedirect
            )}
          {hasRepeatBet && (
            <RepeatBetButton onClick={onRepeatBet} qaLabel="repeat-bet-link">
              Repeat bet
            </RepeatBetButton>
          )}
        </RaceHeaderActions>
      </RaceHeaderWrapper>
      {hasPastPerformance &&
        renderReplayButton(
          onWatchReplay,
          isReplayInsideMyBets,
          hasReplayVideo,
          raceUrl,
          showSeeResult,
          closeModal
        )}
    </RaceHeaderContainer>
  );
};

MyBetsCardHeader.defaultProps = {
  racePostTime: "2020-11-04T17:00:00Z",
  statusCode: "O",
  trackName: "",
  eventName: "",
  raceNumber: 1,
  mtp: 60,
  bets: 0,
  betAmount: 0,
  dateDiff: 0,
  winningsAmount: 0,
  raceUrl: "",
  closeModal: noop,
  onWatchReplay: noop,
  isFutureBet: false,
  raceDate: "",
  isMTPNewRules: true,
  hasReplayVideo: false,
  hasLiveVideo: false,
  postTime: "",
  showRaceLeg: false,
  legNumber: 1,
  onVideoRedirect: noop,
  isReplayInsideMyBets: true,
  showBetInfo: false,
  qaLabel: "mybets-card-header",
  hasPastPerformance: true,
  isRaceNear: false,
  currentRaceNumber: 1,
  showSeeResult: false,
  isMyBetsOpen: false,
  hasRepeatBet: false,
  onRepeatBet: noop
};

export default MyBetsCardHeader;
