import React, { useCallback, useMemo } from "react";
import formatCurrency from "@tvg/formatter/currency";
import { get } from "lodash";
import { Saddle } from "../Saddle";
import { Icon } from "../Icon";
import { Paragraph } from "../Typography";
import { Tag } from "../Tag";
import { rangeSelections } from "./utils";
import type { SaddleSelectionsCompProps, Selection } from "./types";
import {
  SelectionsContainer,
  SaddleSelectionContainer,
  RowContainer,
  IconContainer,
  SaddleDetailsParagraph
} from "./styled-components";

export const SaddleSelections = ({
  numWagerableRunners,
  selections,
  isDark,
  showBetType,
  scratches,
  wagerDetails = false,
  shouldShowRefundTag,
  breed = "thoroughbred",
  qaLabel = "saddle-selections-container",
  onHandicaping,
  hideWillPaysTag = false,
  enableMyBetsBehavior = false,
  showOdds,
  favoriteRunner,
  showReplacedRunners,
  raceStatus
}: SaddleSelectionsCompProps) => {
  const saddleSelections =
    selections?.length > 0
      ? selections.sort(
          (a, b) =>
            parseInt(a.number.toString(), 10) -
            parseInt(b.number.toString(), 10)
        )
      : [];
  const hasScratches = scratches && scratches.length > 0;
  const hasWinner = saddleSelections.some((selection) => selection.winner);
  const hasWillPays = saddleSelections.some((selection) => selection.willPay);

  const checkSaddleSelection = useCallback(
    (runnerNumber: string) =>
      saddleSelections.filter(
        (saddleSelection) => saddleSelection.number === runnerNumber
      ),
    [saddleSelections, hasWinner, hasScratches, hasWillPays]
  );

  const isAllSelections =
    numWagerableRunners &&
    Number(numWagerableRunners) === saddleSelections.length &&
    !wagerDetails;

  const isSaddleNumber = !isAllSelections && saddleSelections.length > 0;

  const rangedSelections: string[] = useMemo(
    () =>
      rangeSelections(
        saddleSelections,
        scratches,
        wagerDetails,
        enableMyBetsBehavior
      )
        .join()
        .split(/(-)/)
        .join()
        .split(","),
    [wagerDetails, saddleSelections, scratches]
  );

  const renderAllSelections = () =>
    isAllSelections ? (
      <Saddle all={isAllSelections} number="All" size="s" breed={breed} />
    ) : (
      // empty Selections
      <Saddle
        number="?"
        size="s"
        saddleColor="--fd-colors-content-default"
        numberColor="--fd-colors-core-white"
        mr="4px"
        enableMyBetsBehavior={enableMyBetsBehavior}
      />
    );

  return (
    <SelectionsContainer data-qa-label={qaLabel}>
      {!isSaddleNumber &&
      !hasScratches &&
      !onHandicaping &&
      !wagerDetails &&
      !hasWinner ? (
        renderAllSelections()
      ) : (
        <SaddleSelectionContainer isDetailView={wagerDetails}>
          {rangedSelections.map((item, index) => {
            const currentSaddleSelection = checkSaddleSelection(item)[0];

            return (
              <RowContainer
                isDetailView={wagerDetails}
                showReplacedRunners={
                  showReplacedRunners && scratches?.includes(item)
                }
                key={`sanddle-arrow-${index.toString()}`}
              >
                {item.includes("-") ? (
                  <IconContainer>
                    <Icon
                      size="m"
                      name="arrowRight"
                      lineColor={
                        isDark
                          ? "--fd-colors-content-on-dark"
                          : "--fd-colors-content-default"
                      }
                      qaLabel="runner-number-range-arrow"
                      mr="4px"
                    />
                  </IconContainer>
                ) : (
                  currentSaddleSelection && (
                    <>
                      <Saddle
                        isScratched={
                          scratches?.includes(item) ||
                          (!enableMyBetsBehavior &&
                            hasWinner &&
                            currentSaddleSelection.winner === false)
                        }
                        raceFinished={
                          saddleSelections.length === scratches?.length ||
                          (hasWinner &&
                            currentSaddleSelection.winner === false) ||
                          (enableMyBetsBehavior &&
                            ["lost", "won", "attention"].includes(
                              raceStatus ?? ""
                            ))
                        }
                        key={`Saddle-num-${currentSaddleSelection.number}`}
                        number={currentSaddleSelection.number}
                        numberColor={currentSaddleSelection.numberColor}
                        saddleColor={currentSaddleSelection.saddleColor}
                        all={currentSaddleSelection.number === "ALL"}
                        isWinner={currentSaddleSelection.winner}
                        breed={breed}
                        enableMyBetsBehavior={enableMyBetsBehavior}
                        size="s"
                        mr={
                          !showBetType && index === rangedSelections.length
                            ? 0
                            : "4px"
                        }
                      />

                      {showReplacedRunners &&
                        scratches?.includes(item) &&
                        enableMyBetsBehavior && (
                          <>
                            <IconContainer
                              key={`sanddle-arrow-${index.toString()}`}
                            >
                              <Icon
                                size="m"
                                name="arrowRight"
                                lineColor="--fd-colors-system-warning-background-default"
                                qaLabel="runner-number-range-arrow"
                                mr="4px"
                              />
                            </IconContainer>
                            <Saddle
                              isScratched={
                                scratches?.includes(item) ||
                                (!enableMyBetsBehavior &&
                                  hasWinner &&
                                  currentSaddleSelection.winner === false)
                              }
                              raceFinished={
                                saddleSelections.length === scratches?.length ||
                                (hasWinner &&
                                  currentSaddleSelection.winner === false) ||
                                (enableMyBetsBehavior &&
                                  ["lost", "won", "attention"].includes(
                                    raceStatus ?? ""
                                  ))
                              }
                              key={`Saddle-num-${currentSaddleSelection.number}`}
                              number={
                                (
                                  favoriteRunner as unknown as Record<
                                    string,
                                    string
                                  >
                                )?.runnerNumber
                              }
                              size="s"
                              mr={
                                !showBetType &&
                                index === rangedSelections.length
                                  ? 0
                                  : "4px"
                              }
                            />
                          </>
                        )}
                      {wagerDetails && (
                        <SaddleDetailsParagraph
                          qaLabel="details-runner-name"
                          fontSize="14px"
                          fontFamily={
                            currentSaddleSelection?.winner &&
                            !currentSaddleSelection?.scratched
                              ? "medium"
                              : "regular"
                          }
                          color={
                            currentSaddleSelection?.isSettled ||
                            scratches?.includes(item)
                              ? "--fd-colors-content-subtle"
                              : "--fd-colors-content-default"
                          }
                          scratched={
                            scratches?.includes(item) && !showReplacedRunners
                          }
                          m={0}
                        >
                          {showReplacedRunners && scratches?.includes(item)
                            ? (
                                favoriteRunner as unknown as Record<
                                  string,
                                  string
                                >
                              )?.runnerName
                            : (currentSaddleSelection.runnerName as string)}
                        </SaddleDetailsParagraph>
                      )}

                      {wagerDetails &&
                        !!currentSaddleSelection.willPay &&
                        !hideWillPaysTag && (
                          <Paragraph
                            qaLabel="details-will-pay-amount"
                            textAlign="center"
                            fontSize="14px"
                            fontFamily="medium"
                            color={
                              currentSaddleSelection?.isSettled
                                ? "--fd-colors-content-subtle"
                                : "--fd-colors-content-default"
                            }
                          >
                            {formatCurrency(currentSaddleSelection.willPay)}
                          </Paragraph>
                        )}

                      {wagerDetails &&
                        !!shouldShowRefundTag &&
                        !!currentSaddleSelection.scratched && (
                          <Tag label="REFUND" size="small" variant="warning" />
                        )}

                      {wagerDetails &&
                        currentSaddleSelection.winner &&
                        currentSaddleSelection.currentOdds &&
                        showOdds && (
                          <Paragraph
                            qaLabel="details-will-pay-amount"
                            textAlign="center"
                            fontWeight={
                              currentSaddleSelection.winner ? "bold" : undefined
                            }
                          >
                            <>
                              {get(
                                currentSaddleSelection,
                                "currentOdds.numerator"
                              )}
                              {get(
                                currentSaddleSelection,
                                "currentOdds.denominator"
                              )
                                ? `/${get(
                                    currentSaddleSelection,
                                    "currentOdds.denominator"
                                  )}`
                                : ""}
                            </>
                          </Paragraph>
                        )}
                    </>
                  )
                )}
              </RowContainer>
            );
          })}
        </SaddleSelectionContainer>
      )}
    </SelectionsContainer>
  );
};

export type { SaddleSelectionsCompProps, Selection };
