import TVGConf from "@tvg/conf";
import cookie from "react-cookie";
import { errors } from "./static";

export const tvgHeaders = () => ({
  "content-type": "application/json",
  "x-tvg-context": TVGConf().context(),
  "x-requested-with": "XMLHttpRequest" // we need this some the request works on old request
});

const timeDiff = [];

export const TimeDiffService = {
  markStart: (mark) => {
    timeDiff[mark] = Date.now();
  },
  markEnd: (mark) => Date.now() - timeDiff[mark],
  getMark: (mark) => timeDiff[mark]
};

export const errorParser = (error) =>
  error in errors ? errors[error] : "Fail to login.";

/**
 * Builds a redirect title and a redirect message to show on the redirect popup box when a customer is trying to login on a different brand
 * @param redirectUrl - the redirect url the user needs to go
 * @param capiRedirectMessage - the capi message that has both redirect title and redirect message
 * @returns {{redirectTitle: string, redirectMessage: string}}
 */
export const buildRedirectMessage = (redirectUrl, capiRedirectMessage) => {
  const capiMessage = JSON.parse(capiRedirectMessage);

  const {
    loginRedirectionTitle = "<brand> CUSTOMER",
    loginRedirectionMessage = "Looks like you’re from <state>! Due to state regulation, we need to redirect you to <brand>"
  } = capiMessage;

  let brand = "tvg";

  if (redirectUrl.indexOf("4njbets") > -1) {
    brand = "4njbets";
  } else if (
    redirectUrl.indexOf("ia-") > -1 ||
    redirectUrl.indexOf("ia.tvg") > -1
  ) {
    brand = "iowa";
  } else if (redirectUrl.indexOf("pabets") > -1) {
    brand = "pabets";
  }

  const stateMap = {
    "4njbets": {
      brand: "4NJBets",
      state: "New Jersey"
    },
    iowa: {
      brand: "TVG - IA",
      state: "Iowa"
    },
    pabets: {
      brand: "PABets",
      state: "Pennsylvania"
    },
    tvg: {
      brand: "TVG",
      state: "TVG"
    }
  };

  return {
    redirectMessage: loginRedirectionMessage
      .replace(/<state>/g, stateMap[brand].state)
      .replace(/<brand>/g, stateMap[brand].brand),
    redirectTitle: loginRedirectionTitle.replace(
      /<brand>/g,
      stateMap[brand].brand
    )
  };
};
