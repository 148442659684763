import CONSTANTS from "./constants";

export const initialState = {
  logging: false,
  logged: false,
  error: null,
  user: null,
  userLogin: false,
  userLogout: false
};

const reducers = {
  [CONSTANTS.UPDATE_USER_DATA]: (state, payload) => payload
};

const reducer = (state = initialState, action) => {
  const validAction = action || { type: "" };

  return validAction.type && reducers[validAction.type]
    ? reducers[validAction.type](state, validAction.payload)
    : state;
};

export default { userData: reducer };
