import styled, { css } from "styled-components";
import { Paragraph } from "../Typography";

export const SelectionsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  row-gap: 4px;
  flex-shrink: 0;
`;

export const SaddleSelectionContainer = styled.div<{
  isDetailView: boolean;
}>`
  display: flex;
  ${({ isDetailView }) =>
    isDetailView ? "flex-direction: column" : "flex-direction: row"};
  row-gap: 12px;
  width: 100%;
`;

export const RowContainer = styled.div<{
  isDetailView: boolean;
  showReplacedRunners?: boolean;
}>`
  align-items: center;
  ${({ isDetailView }) =>
    isDetailView
      ? `display: grid;
        column-gap: 8px;
        row-gap: 12px;
        grid-template-columns: min-content 1fr min-content;`
      : "display: flex; flex-direction: row;"}

  ${({ isDetailView, showReplacedRunners }) =>
    isDetailView &&
    showReplacedRunners &&
    css`
      display: flex;
      flex-direction: row;
      column-gap: 8px;
    `}
`;

export const IconContainer = styled.div`
  align-items: center;
  flex-direction: row;
  height: 20px;
  margin-right: 4px;
  width: 20px;
`;

export const SaddleDetailsParagraph = styled(Paragraph)<{
  scratched?: boolean;
}>`
  text-decoration: ${({ scratched }) => (scratched ? "line-through" : "none")};
`;
