import React, { useContext, useMemo, useState, useEffect } from "react";
import ApolloContext from "@tvg/utils/apolloContext";
import ApolloContextClients from "@tvg/utils/types/ApolloContext";
// Store
import {
  getBetGroupStatus,
  getErrorBets,
  getProcessingBets,
  getSuccessBets
} from "@tvg/desktop-bet/src/store/selectors";
import { useSelector } from "react-redux";
// DS
import {
  BetTypeCell,
  breakpoints,
  Breed,
  Modal,
  useMediaQuery
} from "@tvg/design-system";
import { Mtp, Button, Paragraph, Header } from "@tvg/design-system/web";
// Types
import { BetBasicInfo, BetRepeatInfo } from "@tvg/ts-types/Bet";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { BetStatus, BetStatusType } from "@tvg/desktop-bet/src/types";
// Utils
import { get } from "lodash";
import BetUtils from "@tvg/utils/betSelection";
import formatCurrency from "@tvg/formatter/currency";
import { getCurrentProbableValue } from "@tvg/sh-lib-my-bets/utils/potentialReturn";
import { RaceProgram, RaceStatusEnum } from "@tvg/ts-types/Race";
import { NullaryFn, UnaryFn } from "@tvg/ts-types/Functional";
import { isRaceOff } from "@tvg/sh-lib-my-bets/utils/raceDetails";
import calculateTotals from "@tvg/api/wtx/BetHelper";
import { getBettingInfo } from "@tvg/desktop-bet/src/utils/betUtils";
import { getAccountNumber, getHasPreviousWagered } from "@urp/store-selectors";
import { isFDR } from "@tvg/utils/generalUtils";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import { getSelectionsRunnerNbr } from "../../utils/raceUtils";
// Hooks
import { useBetBlockedByState, usePlaceBet } from "../../hooks";
import { useCancelLimits } from "../ConfirmBetModal/hooks/useCancelLimits";
import { useGeocomplyBetDisabled } from "../ConfirmBetModal/hooks/useGeocomplyBetDisabled";
// Components
import { RepeatBetContainer } from "./styled-components";
import RepeaterSelector from "./components/RepeaterSelector";
import Alerts from "./components/Alerts";
import RepeatBetModalHeader from "./components/RepeatBetModalHeader";
// Relative path Store
import {
  getBetConfirmationMessages,
  getBetConfirmModalTexts,
  getCancelLimitBetSlipEnabled,
  getCancelLimitsBetSlipConf,
  getEnableUrpConfirmModalRepeatBet,
  getRepeatBetConfig,
  getUserBalanceIsShown
} from "../../store/selectors";
import onRepeatBetSuccessfullyEvent from "./utils/gtm/onRepeatBetSuccesfully";
import { confirmBetGtmEvent } from "../../utils/gtm/betConfirmJourney";

interface RepeatBetModalProps {
  isRepeatBetModalOpen: boolean;
  race: RaceProgram;
  races: RaceProgram[];
  raceWager: BetBasicInfo | BetRepeatInfo;
  userBalance: number;
  setRepeatBetModalOpen: UnaryFn<boolean, void>;
  onResponsibleGamingError?: NullaryFn<void>;
}

const RepeatBetModal: React.FC<RepeatBetModalProps> = ({
  isRepeatBetModalOpen,
  race,
  races,
  raceWager,
  userBalance,
  setRepeatBetModalOpen,
  onResponsibleGamingError
}) => {
  const { behgClient } = useContext<ApolloContextClients>(ApolloContext);
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const [isWagerableState, setWagerableState] = useState(true);
  const [isInvalidBet, setIsInvalidBet] = useState(false);

  const {
    handleSubmitBet,
    resetBetting,
    errorMessages,
    betDisable,
    clearErrorMessages
  } = usePlaceBet();

  if (isRaceOff(race.status?.code || RaceStatusEnum.OPEN)) {
    setRepeatBetModalOpen(false);
  }

  const MAX_REPETITIONS = 20;
  const [selectedRepetition, setSelectedRepetition] = useState(1);
  let typeFromRace = BetUtils.getOriginalBetTypeFromRace(
    race,
    raceWager.wagerType.id as unknown as WagerTypeCodesEnum
  );

  // ====== hack because program page way of identify WPS ======
  // Some functions like bet amount calculation it uses the columnCount to identify the type of calculation required to be done,
  // and for example Win/Place/Show in those functions required the columnCount === 1 to enter in the correct formula.
  // but some other fuctions like how the buttons should appear in the programPage required this to have the correct value of 3 (Ex: WPS bet type)
  // so here we are disconnecting the object from the one used in the parents components so we can change the value withotu interfering in anything.
  typeFromRace = typeFromRace?.specialGroup
    ? { ...typeFromRace, columnCount: 1 }
    : typeFromRace;
  const [isBlockedByState, blockedByStateErrorMessage] = useBetBlockedByState();
  const { isBetDisabled: isGeoComplyBetDisabled } = useGeocomplyBetDisabled();
  const isPlacingBetError = useSelector(getErrorBets);
  const betGroupStatus = useSelector(getBetGroupStatus);
  const betConfirmationMessages = useSelector(getBetConfirmationMessages);
  const confirmModalMessages = useSelector(getBetConfirmModalTexts);
  const cancelLimitConfig = useSelector(getCancelLimitsBetSlipConf);
  const cancelLimitFeedbackEnabled = useSelector(getCancelLimitBetSlipEnabled);
  const accountNumber = useSelector(getAccountNumber);
  const repeatBetConfig = useSelector(getRepeatBetConfig);
  const userBalanceIsShown = useSelector(getUserBalanceIsShown);
  const isPlacingBetSuccess = useSelector(getSuccessBets);
  const isPlacingBet = useSelector(getProcessingBets);
  const selectionsRunnerNbr = getSelectionsRunnerNbr(raceWager);
  const enableUrpConfirmModalRepeatBet = useSelector(
    getEnableUrpConfirmModalRepeatBet
  );
  const hasMadePreviousWager = useSelector(getHasPreviousWagered);

  const { numberOfWagerableRunners, fullSelections } = getBettingInfo(
    selectionsRunnerNbr,
    raceWager.wagerAmount,
    race,
    races,
    typeFromRace
  );
  const scratches = BetUtils.getSelectionsRunnerNbrScratched(fullSelections);
  const handleNrOfScratches = Object.values(scratches).filter(
    (value) => value.length
  );
  const placeBetError =
    isPlacingBetError > 0 && isPlacingBet === 0 && errorMessages.length > 0;
  const isLoading = isPlacingBet > 0;
  const placeBetSuccess =
    isPlacingBetSuccess > 0 && isPlacingBet === 0 && !placeBetError;

  const type = get(typeFromRace, "type.code", WagerTypeCodesEnum.WIN);
  const mtp = get(race, "mtp", "0");
  const status = get(race, "status.code");
  const postTime = get(race, "postTime");
  const trackName = get(race, "track.trackName");
  const betRaceNumber = get(race, "raceNumber");
  const trackCode = get(race, "track.trackCode");
  const trackCountry = get(race, "track.trackLocation.country");
  const isRaceWagerable = get(race, "wagerable", false);

  useEffect(() => {
    clearErrorMessages();
  }, [selectedRepetition]);

  useEffect(() => {
    const wagerableState =
      status !== RaceStatusEnum.RACE_OFF &&
      status !== RaceStatusEnum.RACE_OFFICIAL &&
      status !== "C";
    setWagerableState(wagerableState);
  }, [status]);

  useEffect(() => {
    const isBetInvalid = fullSelections.some((selection) =>
      selection.every((runner) => runner.scratched)
    );
    if (isBetInvalid) {
      setIsInvalidBet(true);
    }
  }, [JSON.stringify(scratches), JSON.stringify(fullSelections)]);

  useEffect(() => {
    if (isRepeatBetModalOpen) clearErrorMessages();
  }, [isRepeatBetModalOpen]);

  const approximatedPayout = useMemo(
    () =>
      getCurrentProbableValue(
        get(race, "probables", []),
        get(raceWager, "wagerType"),
        get(raceWager, "wagerAmount", 0),
        selectionsRunnerNbr
      ),
    [race, raceWager, selectedRepetition]
  );

  const handleCloseModal = () => {
    setRepeatBetModalOpen(false);
    setSelectedRepetition(1);
    clearErrorMessages();
  };

  const handleRepeatBet = () => {
    confirmBetGtmEvent({
      betAmount: +raceWager.wagerAmount,
      betType: raceWager.wagerType.name,
      raceType: race.type?.name
    });
    handleSubmitBet({
      betAmount: +raceWager.wagerAmount,
      betSelectionsGroup: completeSelections(),
      race,
      selectedRepetition,
      forceWagerType: get(typeFromRace, "type.id"),
      hasMadePreviousWager
    });
    onRepeatBetSuccessfullyEvent({
      betAmount: +raceWager.wagerAmount,
      betType: raceWager.wagerType.name,
      raceType: race.type?.name
    });
  };

  const completeSelections = () => {
    const validSelections = Object.values(fullSelections).map((selection) =>
      selection.filter((obj) => !obj.scratched)
    );
    return handleNrOfScratches.length ? validSelections : fullSelections;
  };

  const handleSelections = () =>
    Object.values(completeSelections()).map((arr) =>
      arr.map((selection) => Number(selection.number.replace(/\D/g, "")))
    );

  // filter repeated if array has coupled runners
  const uniqueSelectionsHandled = (handledSelections: Array<Array<number>>) =>
    handledSelections.map((sel: Array<number>) =>
      sel.filter((item, index) => sel.indexOf(item) === index)
    );

  const totals = typeFromRace
    ? calculateTotals(
        raceWager.wagerAmount,
        typeFromRace,
        uniqueSelectionsHandled(handleSelections())
      )
    : 0;

  const totalAmount =
    selectedRepetition *
    (typeof totals === "object" ? +totals.betCost : totals);

  const { feedback } = useCancelLimits({
    needsFetch: isRepeatBetModalOpen && cancelLimitFeedbackEnabled,
    accountNumber,
    behgClient,
    config: cancelLimitConfig,
    feedbackExtraValues: {
      forceFeedback: placeBetSuccess ? "placed" : undefined,
      prediction: {
        amount: typeof totals === "object" ? +totals.betCost : totals,
        count: selectedRepetition
      },
      track: {
        code: trackCode,
        name: trackName,
        country: trackCountry
      }
    },
    currentRace: trackCode
  });

  const isWagerableRace = isXSell() ? isWagerableState : isRaceWagerable;

  const placeBetNotAllowed =
    isBlockedByState ||
    betDisable ||
    isGeoComplyBetDisabled ||
    userBalance < totalAmount ||
    !isWagerableRace ||
    placeBetSuccess;

  const betsStatus = Object.values<BetStatus>(betGroupStatus).map(
    (betType) => betType && betType.status
  );

  const showRaceOffAlert =
    (status === RaceStatusEnum.RACE_OFF &&
      Object.keys(betGroupStatus).length !== 0 &&
      betsStatus.includes(BetStatusType.ERROR)) ||
    (status === RaceStatusEnum.RACE_OFF &&
      Object.keys(betGroupStatus).length === 0);

  const isBetRepeatable = useMemo(
    () =>
      placeBetSuccess &&
      !fullSelections.some((entry) => entry.some((value) => value.scratched)),
    [placeBetSuccess, fullSelections]
  );

  const isResponsibleGamingError = !!(
    errorMessages?.some(({ message }) => message.buttonType === "rgLimits") &&
    isFDR() &&
    onResponsibleGamingError
  );

  return (
    <Modal
      isOpen={isRepeatBetModalOpen}
      title={
        <RepeatBetModalHeader
          userBalance={userBalance}
          placeBetSuccess={placeBetSuccess}
          repetitions={selectedRepetition}
          showBalance={Boolean(+userBalanceIsShown)}
        />
      }
      padding={0}
      onClose={handleCloseModal}
      onOverlayClick={handleCloseModal}
      type={isMobile ? "flexible" : "lightbox"}
      onAfterClose={() => resetBetting(false)}
    >
      <>
        <Alerts
          isRaceWagerable={isRaceWagerable}
          showRaceOffAlert={showRaceOffAlert}
          hasScratches={handleNrOfScratches.length > 0}
          errorMessages={errorMessages}
          placeBetSuccess={placeBetSuccess}
          placeBetError={placeBetError}
          isBlockedByState={isBlockedByState}
          userBalance={userBalance}
          totalAmount={totalAmount}
          blockedByStateErrorMessage={blockedByStateErrorMessage}
          feedback={feedback}
          betConfirmationMessages={betConfirmationMessages}
          confirmModalMessages={confirmModalMessages}
        />
        <RepeatBetContainer>
          <Mtp
            mtp={+mtp}
            status={status}
            postTime={postTime as string}
            qaLabel="confirm-bet-modal-mtp"
            size="xs"
          />
          <Header
            tag="h3"
            fontFamily="bold"
            color="grey.900"
            qaLabel="confirm-bet-modal-race-name"
            mb="space-1"
          >
            {`${trackName} R${betRaceNumber}`}
          </Header>
          <BetTypeCell
            mt={24}
            qaLabel="rbm-bet-cell"
            completeSelection={fullSelections}
            selections={get(fullSelections, 0)}
            scratches={scratches}
            approximatedPayout={approximatedPayout}
            numWagerableRunners={numberOfWagerableRunners}
            betAmount={formatCurrency(raceWager.wagerAmount)}
            betTicket={formatCurrency(
              typeof totals === "object" ? +totals.betCost : totals
            )}
            type={type}
            breed={
              get(race, "type.name", "Thoroughbred").toLowerCase() as Breed
            }
            isKey={get(typeFromRace, "isKey", false)}
            isBox={get(typeFromRace, "isBox", false)}
            isWheel={get(typeFromRace, "isWheel", false)}
            betStatus="NONE"
            betTypeName={get(typeFromRace, "type.name", WagerTypeCodesEnum.WIN)}
            isLeg={get(typeFromRace, "legCount", 1) > 1}
            raceNumber={betRaceNumber}
            isRepeatable={isBetRepeatable && enableUrpConfirmModalRepeatBet}
            onRepeatBetHandler={() => resetBetting()}
          />
          {!placeBetSuccess && !isInvalidBet && (
            <>
              <RepeaterSelector
                isDisabled={isLoading || placeBetSuccess}
                repetitions={repeatBetConfig.maxAllowed || MAX_REPETITIONS}
                selectedRepetition={selectedRepetition}
                setSelectedRepetition={setSelectedRepetition}
              />
              {isResponsibleGamingError ? (
                <Button
                  variant="primary"
                  onClick={onResponsibleGamingError}
                  qaLabel="betConfirmation-rgLimitsBtn"
                  mt="space-7"
                  isStretched
                >
                  View Limits
                </Button>
              ) : (
                <Button
                  variant="betting"
                  onClick={handleRepeatBet}
                  qaLabel="confirm-bet-modal-sucess-button"
                  mt="space-7"
                  isDisabled={placeBetNotAllowed}
                  isLoading={isLoading}
                  isStretched
                >
                  <Paragraph
                    qaLabel=""
                    fontFamily="medium"
                    color={
                      placeBetNotAllowed
                        ? "--fd-colors-component-button-primary-content-disabled"
                        : "--fd-colors-component-button-primary-content-base"
                    }
                  >
                    <>
                      Confirm x{selectedRepetition} Repeat |{" "}
                      {formatCurrency(totalAmount)} Bet
                    </>
                  </Paragraph>
                </Button>
              )}
            </>
          )}
          <Button
            variant={placeBetSuccess ? "primary" : "secondary"}
            onClick={handleCloseModal}
            qaLabel="confirm-bet-modal-close-button"
            mt="space-4"
            isStretched
          >
            Close
          </Button>
        </RepeatBetContainer>
      </>
    </Modal>
  );
};

export default RepeatBetModal;
