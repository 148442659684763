import React from "react";
import { get } from "lodash";
import style from "./style.css";

const LoginDisclaimer = (props) => (
  <div className={style.loginResponsibly}>
    {get(props, "cmsMsgs.LoginAware") ||
      "AWARE – Always Wager Responsibly National Gambling Helpline: (800) 522-4700."}{" "}
    Must be 21 years of age or older. Residency restrictions apply. Void where
    prohibited.
  </div>
);

export default LoginDisclaimer;
