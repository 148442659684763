import { get } from "lodash";

export const initialState = {
  isOpen: false,
  isLocationRequired: false,
  error: null,
  isLoading: false,
  onLocationGet: null,
  showClose: false
};

const locationReducer = (state = initialState, action) => {
  let rgTimeoutExclusionType;
  switch (action.type) {
    case "OPEN_LOCATION_SPLASH": {
      return { ...state, isOpen: true };
    }
    case "SET_BLOCKED_COUNTRY": {
      return {
        ...state,
        isOpen: action.payload,
        isLocationRequired: action.payload,
        error: "BLOCKED_COUNTRY"
      };
    }
    case "ANDROID_BLOCKED_STATE": {
      return {
        ...state,
        isOpen: true,
        isLocationRequired: true,
        error: "ANDROID_BLOCKED_STATE"
      };
    }
    case "CLEAR_ERROR_SPLASH": {
      return {
        ...state,
        isOpen: false,
        isLocationRequired: false,
        error: null
      };
    }
    case "CLOSE_LOCATION_SPLASH": {
      return { ...state, isOpen: false };
    }
    case "SET_LOCATION_REQUIRED": {
      return { ...state, isLocationRequired: action.payload };
    }
    case "SET_LOCATION_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    case "SET_LOCATION_DENIED": {
      return { ...state, error: "PERMISSION_DENIED" };
    }
    case "SET_LOCATION_UNSUPPORTED": {
      return { ...state, error: "UNSUPPORTED" };
    }
    case "SET_LOCATION_UNKNOWN_ERROR": {
      return { ...state, error: "UNKNOWN_ERROR", showClose: action.payload };
    }
    case "SET_LOCATION_UNAVAILABLE": {
      return { ...state, error: "POSITION_UNAVAILABLE" };
    }
    case "SET_LOCATION_TIMED_OUT": {
      return { ...state, error: "TIMEOUT" };
    }
    case "SET_LOCATION_UNAUTHORIZED": {
      return { ...state, error: "UNAUTHORIZED" };
    }
    case "SET_ON_LOCATION_GET": {
      return { ...state, onLocationGet: action.payload };
    }
    case "RG_TIMEOUT_EXCLUSION_SPLASH": {
      switch (action.payload.type) {
        case "TIMEOUT":
          rgTimeoutExclusionType = "RG_TIMEOUT";
          break;
        case "SELFEXCLUSION":
        case "SELF_EXCLUSION":
          rgTimeoutExclusionType = "RG_SELF_EXCLUSION";
          break;
        default:
          rgTimeoutExclusionType = "RG_SUSPEND";
      }
      return {
        ...state,
        isOpen: true,
        error: rgTimeoutExclusionType,
        isLocationRequired: true,
        timeoutEndDate: get(action, "payload.endDate")
      };
    }
    default: {
      return state;
    }
  }
};

export default locationReducer;
