import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const Dismiss = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${buildColor("red", "600")};
  width: ${({ width = 59 }) => width}px;
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
`;

export const DismissDesktop = styled.div`
  display: none;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: ${buildColor("red", "100")};
  cursor: pointer;

  &:hover {
    background-color: ${buildColor("red", "200")};
  }
`;
