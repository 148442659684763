import React, { Component } from "react";
import { isEqual, noop } from "lodash";

import {
  ResultsTable,
  AllRunnersButton,
  IconContainer,
  SeeAllRunnersSection,
  OtherInfo,
  WinningTime,
  WinningTimeLabel,
  Scratches,
  ScratchesLabel,
  Number
} from "./styled-components";

import RunnerNumber from "../../_atom/RunnerNumber";
import RaceResultsHeader from "../../_molecule/RaceResultsHeader";
import RaceResultsRunner from "../../_molecule/RaceResultsRunner";
import Icon from "../../_static/Icons";
import { arrowDown } from "../../_static/Icons/icons";

import buildColor from "../../_static/ColorPalette";

export default class RaceResults extends Component {
  static defaultProps = {
    runnerName: "",
    runners: [],
    scratchedRunners: [],
    raceTypeCode: "T",
    resultsType: "full",
    winningTime: "",
    seeAllHorsesGTM: noop
  };

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false
    };
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (!isEqual(this.props, prevProps) && this.state.isExpanded === true) {
      this.setState({ isExpanded: false });
    }
  }

  getRunnersForFullResults = (runners, runnerName) =>
    runners.reduce((resultRunner, runner, index) => {
      if (
        index < 3 ||
        runner.runnerName.toLowerCase() === runnerName.toLowerCase()
      ) {
        resultRunner.push(runner);
      }

      return resultRunner;
    }, []);

  render() {
    const {
      runners,
      runnerName,
      raceTypeCode,
      resultsType,
      winningTime,
      scratchedRunners
    } = this.props;
    const hasRunners = runners.length > 0;
    const hasTimeformData = resultsType === "full";
    const runnersToDisplay =
      this.state.isExpanded || !hasTimeformData
        ? runners
        : this.getRunnersForFullResults([...runners], runnerName);

    return (
      hasRunners && (
        <section data-qa-label="raceResults">
          <ResultsTable>
            <RaceResultsHeader
              data-qa-label="raceResults-header"
              isGreyhound={raceTypeCode === "G"}
              hasWin={false}
              hasPlace={false}
              hasShow={false}
              hasDistance={hasTimeformData}
              hasOdds={hasTimeformData}
            />
            <tbody>
              {runnersToDisplay.map((runner) => (
                <RaceResultsRunner
                  key={`${runner.runnerId}-results-position-${runner.finishPosition}`}
                  {...runner}
                  accBeatenDistance={runner.accBeatenDistanceStatus}
                  hasTimeformData={hasTimeformData}
                  isHighlighted={runner.runnerName === runnerName}
                  raceTypeCode={raceTypeCode}
                />
              ))}
            </tbody>
          </ResultsTable>
          {!this.state.isExpanded && hasTimeformData && (
            <SeeAllRunnersSection>
              <AllRunnersButton
                size="big"
                type="tertiary"
                onClick={() => {
                  this.props.seeAllHorsesGTM();
                  this.setState({ isExpanded: true });
                }}
                qaLabel="hpp-all-runners"
              >
                See all runners
                <IconContainer>
                  <Icon
                    icon={arrowDown}
                    color={buildColor("blue_accent", "600")}
                  />
                </IconContainer>
              </AllRunnersButton>
            </SeeAllRunnersSection>
          )}
          <OtherInfo>
            {hasTimeformData && (
              <WinningTime>
                <WinningTimeLabel>Winning time</WinningTimeLabel>
                {winningTime}
              </WinningTime>
            )}
            <Scratches>
              <ScratchesLabel>Scratches</ScratchesLabel>
              {scratchedRunners.length === 0
                ? "No Changes"
                : scratchedRunners.map((runner) => (
                    <Number key={runner}>
                      <RunnerNumber
                        key={`${runner}-number`}
                        runnerId={runner}
                        raceTypeCode={this.props.raceTypeCode}
                      />
                    </Number>
                  ))}
            </Scratches>
          </OtherInfo>
        </section>
      )
    );
  }
}
