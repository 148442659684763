import React, { useState } from "react";

import { Icon, Paragraph, useTheme } from "@tvg/design-system";
import { Button as ButtonDS } from "@tvg/design-system/web";
import { UnaryFn } from "@tvg/ts-types/Functional";
import Dropdown from "../Dropdown";
import {
  Button,
  ButtonContent,
  RepeaterSelectorWrapper,
  SelectContainer
} from "./styled-component";

export interface RepeaterSelectorProps {
  isDisabled: boolean;
  repetitions: number;
  selectedRepetition: number;
  setSelectedRepetition: UnaryFn<number, void>;
}

const RepeaterSelector: React.FC<RepeaterSelectorProps> = ({
  isDisabled,
  selectedRepetition,
  setSelectedRepetition,
  repetitions
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { colors } = useTheme();

  return (
    <RepeaterSelectorWrapper color={colors.blue[800]}>
      <Paragraph
        qaLabel="rbm-selector-title"
        color={colors.white[900]}
        fontFamily="medium"
        mb="space-3"
      >
        <>Repeat bet (x{repetitions} max)</>
      </Paragraph>
      <SelectContainer>
        <Button
          data-qa-label="repetition-selector-button"
          disabled={isDisabled}
          onClick={() => {
            setIsVisible((prevIsVisible) => !prevIsVisible);
          }}
        >
          <ButtonContent
            data-qa-label="repetition-selector-button-content"
            onClick={() => setIsVisible((prevIsVisible) => !prevIsVisible)}
          >
            <Paragraph
              fontFamily="bold"
              fontSize="s"
              color={isDisabled ? "blue.300" : "grey.900"}
              qaLabel="repetition-selector-text"
            >
              {`x${selectedRepetition}`}
            </Paragraph>
            <Icon
              ml="auto"
              name={isVisible ? "chevronUp" : "chevronDown"}
              size="s"
              lineColor="blue_accent.500"
            />
          </ButtonContent>
        </Button>
        <Dropdown
          values={Array.from(Array(repetitions).keys()).map((elem) => ({
            label: `x${elem + 1}`,
            value: elem + 1
          }))}
          isVisible={isVisible}
          selectedValue={selectedRepetition}
          onSelectValue={(val) => {
            setIsVisible(false);
            setSelectedRepetition(val);
          }}
          onClickAway={() => setIsVisible(false)}
        />
        <ButtonDS
          qaLabel="rbm-set-toMax"
          variant="tertiary"
          ml="space-4"
          onClick={() => setSelectedRepetition(repetitions)}
          icon={
            <Icon
              name="refresh"
              size="s"
              lineColor={colors.blue_accent[500]}
              backgroundColor="transparent"
            />
          }
          isDisabled={isDisabled}
        >
          Set to max
        </ButtonDS>
      </SelectContainer>
    </RepeaterSelectorWrapper>
  );
};

export default RepeaterSelector;
