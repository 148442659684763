import { PRODUCTS_FDR_X_SELL } from "@tvg/conf/src/product";
import TvgConfig from "@tvg/conf";
import { getAuthTokenCookie, isSessionExpired } from "./sessionUtils";

export const isXSell = () => PRODUCTS_FDR_X_SELL.includes(TvgConfig().product);

export const isValidSessionByToken = () =>
  !isSessionExpired(getAuthTokenCookie());

export const isValidSessionByTokenXSell = () =>
  isXSell() && isValidSessionByToken();
