import React from "react";
import { SaddleSelections } from "../SaddleSelections";
import { Paragraph } from "../Typography";
import { BetSelectionsContainer } from "./styled-components";
import type { BetSelectionsProps } from "./types";

export const BetSelections = ({
  numWagerableRunners,
  type,
  breed = "thoroughbred",
  selections,
  isDark = true,
  qaLabel = "bet-selection",
  showBetType = true,
  scratches,
  wagerDetails,
  shouldShowRefundTag,
  onHandicaping,
  hideWillPaysTag,
  enableMyBetsBehavior = false,
  showOdds,
  favoriteRunner,
  showReplacedRunners,
  raceStatus
}: BetSelectionsProps) =>
  showBetType ? (
    <BetSelectionsContainer data-qa-label={qaLabel} isDark>
      <Paragraph
        color={
          isDark ? "--fd-colors-content-on-dark" : "--fd-colors-content-default"
        }
        fontFamily="regular"
        fontSize="14px"
        paddingRight="6px"
      >
        {type}
      </Paragraph>
      <SaddleSelections
        selections={selections}
        isDark={isDark}
        showBetType={showBetType}
        scratches={scratches}
        breed={breed}
        hideWillPaysTag={hideWillPaysTag}
        enableMyBetsBehavior={enableMyBetsBehavior}
        showOdds={showOdds}
        favoriteRunner={favoriteRunner}
        showReplacedRunners={showReplacedRunners}
        raceStatus={raceStatus}
      />
    </BetSelectionsContainer>
  ) : (
    <SaddleSelections
      numWagerableRunners={numWagerableRunners}
      selections={selections}
      isDark={isDark}
      showBetType={showBetType}
      scratches={scratches}
      breed={breed}
      wagerDetails={wagerDetails}
      shouldShowRefundTag={shouldShowRefundTag}
      onHandicaping={onHandicaping}
      hideWillPaysTag={hideWillPaysTag}
      enableMyBetsBehavior={enableMyBetsBehavior}
      favoriteRunner={favoriteRunner}
      showReplacedRunners={showReplacedRunners}
      raceStatus={raceStatus}
      showOdds={showOdds}
    />
  );

export type { BetSelectionsProps };
