import React, { FC } from "react";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import type { IconSizeVariants } from "../../theme";
import { useTheme } from "../../hooks";
import {
  Svg,
  AnimatedCircleOne,
  AnimatedCircleTwo,
  Circle
} from "./styled-components";
import type { LoadingSpinnerProps } from "./types";

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  size = "s",
  color = "--fd-colors-component-button-tertiary-content-active",
  bgColor = "--fd-colors-core-white-tint-020",
  qaLabel = "loading-spinner",
  ...rest
}) => {
  const { iconSizes } = useTheme();
  const { iconSize, strokeWidth } = iconSizes[size as IconSizeVariants];

  const filterColor = (receivedColor: string | undefined) =>
    receivedColor && (receivedColor.includes("#") || color.includes("rgba"))
      ? receivedColor
      : useColorTokens(receivedColor);

  const filteredColor = color.startsWith("--")
    ? `var(${color})`
    : filterColor(color);

  const filterBgColor = bgColor.startsWith("--")
    ? `var(${bgColor})`
    : filterColor(bgColor);

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={iconSize}
      height={iconSize}
      data-qa-label={qaLabel}
      {...rest}
    >
      <Circle
        rx="11"
        ry="11"
        fill="none"
        stroke={filterBgColor}
        strokeWidth={strokeWidth}
        strokeDashoffset="0"
        strokeLinecap="round"
        transform="translate(12,12)"
      />
      <AnimatedCircleOne
        rx="11"
        ry="11"
        fill="none"
        stroke={filteredColor}
        strokeWidth={strokeWidth}
        strokeDashoffset="67.69"
        strokeDasharray="68.69"
        strokeLinecap="round"
        transform="translate(8,8) rotate(-90) translate(-4,4)"
      />
      <AnimatedCircleTwo
        rx="11"
        ry="11"
        fill="none"
        stroke={filteredColor}
        strokeWidth={strokeWidth}
        strokeDashoffset="68.69"
        strokeDasharray="68.69"
        strokeLinecap="round"
        transform="translate(8,8) rotate(-90) translate(-4,4)"
      />
    </Svg>
  );
};

export type { LoadingSpinnerProps };
