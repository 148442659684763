import React from "react";
import { Link } from "react-router-dom";
import { first, noop } from "lodash";
import { Icon as DSIcon } from "@tvg/design-system";

import Icon from "../../_static/Icons";
import {
  chevronDown,
  chevronUp,
  repeat,
  trash
} from "../../_static/Icons/iconsDuotone";
import buildColor from "../../_static/ColorPalette";
import { BetCardFooter, FooterButton } from "./styled-components";

const parseSelectionsUrl = (url) => {
  if (!url) return "";

  const urlParsed = url.split("&");
  if (urlParsed.length) {
    urlParsed.shift();
    urlParsed.pop();
    return urlParsed.join("&");
  }
  return "";
};

const MyBetsWagerFooter = ({
  closeModal,
  raceUrl,
  isDetailsOpened,
  handleDetailsButton,
  shouldRenderFooter,
  isBetActive,
  isWagerCancelable,
  repeatButtonSearch,
  showRepeatButton,
  onCancelWager,
  isContentDisplayed,
  isDesktop,
  onShareWager,
  socialShareModalToggle
}) =>
  shouldRenderFooter && (
    <BetCardFooter hasTopBorder={isContentDisplayed} isDesktop={isDesktop}>
      {socialShareModalToggle && (
        <FooterButton
          data-qa-label="myBets-social-share"
          onClick={() => onShareWager(parseSelectionsUrl(repeatButtonSearch))}
        >
          <DSIcon
            name="popout"
            size="s"
            lineColor={buildColor("blue", "900")}
          />
        </FooterButton>
      )}
      {showRepeatButton && (
        <FooterButton
          data-qa-label="myBets-betTicked-repeatButton"
          as={Link}
          onClick={closeModal}
          isDesktop={isDesktop}
          to={{
            pathname: first(raceUrl.split("?")),
            search: repeatButtonSearch.substring(1)
          }}
        >
          <Icon
            icon={repeat}
            viewBoxSize={16}
            size={16}
            color={buildColor("grey", "100")}
            stroke={buildColor("blue", "900")}
          />
          {isBetActive ? "Repeat bet" : "Re-Bet"}
        </FooterButton>
      )}
      {isWagerCancelable && (
        <FooterButton
          qaLabel="myBets-betTicked-cancelButton"
          isCancelButton
          isDesktop={isDesktop}
          onClick={onCancelWager}
        >
          <Icon
            icon={trash}
            viewBoxSize={16}
            size={16}
            color={buildColor("red", "000")}
            stroke={
              isDesktop
                ? buildColor("blue_accent", "500")
                : buildColor("red", "600")
            }
          />
          Cancel
        </FooterButton>
      )}
      <FooterButton
        qaLabel="myBets-betTicked-detailsButton"
        isDetailsButton
        isDesktop={isDesktop}
        showDetails={!isDetailsOpened}
        onClick={handleDetailsButton}
      >
        {isDetailsOpened ? "Hide details" : "Show details"}
        <Icon
          icon={!isDetailsOpened ? chevronDown : chevronUp}
          viewBoxSize={16}
          size={16}
          color="transparent"
          stroke={
            isDesktop
              ? buildColor("blue_accent", "500")
              : buildColor("grey", "900")
          }
        />
      </FooterButton>
    </BetCardFooter>
  );

MyBetsWagerFooter.defaultProps = {
  closeModal: noop,
  raceUrl: "",
  isDetailsOpened: false,
  handleDetailsButton: noop,
  shouldRenderFooter: false,
  isBetActive: false,
  isWagerCancelable: false,
  repeatButtonSearch: "",
  showRepeatButton: false,
  onCancelWager: noop,
  isDesktop: false
};
export default MyBetsWagerFooter;
