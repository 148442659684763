import React from "react";
import { get } from "lodash";
import { Form } from "@unform/web";
import { ValidationError } from "yup";
import { InputSSN } from "@tvg/atomic-ui/_molecule/InputSSN";
import mediator from "@tvg/mediator";
import InputMask from "@tvg/atomic-ui/_molecule/InputMask";
import DateOfBirth from "@tvg/atomic-ui/_molecule/DateOfBirthSelect";
import Icon from "@tvg/atomic-ui/_static/Icons";
import { eyeLineShow, eyeLineHide } from "@tvg/atomic-ui/_static/Icons/icons";
import Button from "@tvg/atomic-ui/_atom/Buttons/button";
import RecoverEmail, {
  messagesDefault
} from "@tvg/atomic-ui/_templates/RecoverEmail";

import { invertDate } from "@tvg/rma/src/utils/testFns";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { connect } from "react-redux";
import parseCapiMessage from "@tvg/utils/capiUtils";
import { postRecoveryUsername } from "@tvg/api/crf";
import { handleOnTheFlyChange } from "../../utils/handlers";
import { useEmailRecovery, useDateOfBirth } from "../../utils/hooks";

import { ACTION } from "../../reducers/emailRecoveryReducer";

const ATTEMPTS_MAX = 3;
const DATE_OF_BIRTH_PLACEHOLDER = "__ __ ____";

const EmailRecovery = ({ device, onCloseModalCallback, messages }) => {
  const isDesktop = device === "desktop";
  const [
    { formRef, isSSNVisible, startedBirthTyping, state, isValid, schema },
    { setIsSSNVisible, setStartedBirthTyping, dispatch }
  ] = useEmailRecovery(messages);
  const { success, warning, failure, contact, tryAgain, error } = messages;

  const [dateValidator, predicate] = useDateOfBirth();
  const predicateCorrectDay = (
    (target) => (value, valueItem, length) =>
      predicate(target, value, valueItem, length)
  )("DD");

  const sendRecoveryEmail = async (values) => {
    try {
      dispatch({ type: ACTION.RECOVERY_REQUEST_START });
      if (state.attempts >= ATTEMPTS_MAX) {
        dispatch({ type: ACTION.RECOVERY_REQUEST_FAILURE });
        return;
      }

      const dateOfBirthFormatted = invertDate(
        values.dateOfBirth.replace(/\s/g, "-")
      );

      const res = await postRecoveryUsername(
        dateOfBirthFormatted,
        values.last4Ssn.join("")
      );

      dispatch({
        type: ACTION.RECOVERY_REQUEST_SUCCESS,
        payload: { values, maskedEmail: res.data.maskedEmail }
      });
    } catch (err) {
      mediator.base.dispatch({
        type: "FORGOT_DETAILS_RECOVER_EMAIL_ERROR",
        payload: {
          message: get(
            err,
            "response.data.message",
            "Request Failure or Error for Recovery Email"
          )
        }
      });
      mediator.base.dispatch({
        type: "FORGOT_CREDENTIALS_RESET_SUBMIT_ERROR",
        payload: {
          field: get(
            err,
            "response.data.message",
            "Request Failure or Error for Reset Submit"
          )
        }
      });

      if (get(err, "response.status") === 400) {
        dispatch({ type: ACTION.RECOVERY_REQUEST_FAILURE });
      } else if (get(err, "response.status") === 500) {
        dispatch({ type: ACTION.RECOVERY_REQUEST_INTERNAL_ERROR });
      } else {
        dispatch({ type: ACTION.RECOVERY_REQUEST_ERROR });
      }
    }
  };

  const handleSubmit = async (data) => {
    try {
      mediator.base.dispatch({
        type: "FORGOT_DETAILS_RECOVER_EMAIL_SUBMIT"
      });

      /** In desktop case, the Date of Birth data came from the multiple selects */
      if (isDesktop && data.dob instanceof Array) {
        const dob = data.dob.join(" ");
        Object.assign(data, { dateOfBirth: dob });
      }
      await schema.validate(data, {
        abortEarly: false
      });

      // Valid so lets remove all previous errors
      if (formRef.current) formRef.current.setErrors({});

      await sendRecoveryEmail(data);
    } catch (err) {
      if (err instanceof ValidationError) {
        const validationErrors = err.inner.reduce((errors, fieldError) => {
          if (fieldError.path in errors) return errors;
          return {
            ...errors,
            [fieldError.path]: fieldError.message
          };
        }, {});
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <RecoverEmail
      device={device}
      onCloseModalCallback={onCloseModalCallback}
      onResubmitHandler={() => sendRecoveryEmail(state.values)}
      hasSentEmail={state.hasSentEmail}
      attempts={state.attempts}
      attemptsMax={ATTEMPTS_MAX}
      hasError={state.hasError}
      hasErrorAttempt={state.hasErrorAttempt}
      hasTryAgainLink={!state.hasErrorInternal}
      isLoading={state.isLoading}
      info={messages.info}
      messages={{ success, warning, failure, contact, tryAgain, error }}
      maskedEmail={state.maskedEmail}
      dateOfBirthPlaceholder={DATE_OF_BIRTH_PLACEHOLDER}
      dateOfBirthValue={state.values.dateOfBirth}
    >
      <Form
        data-qa-label="recover-email-form"
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={state.values}
        className="email-recovery-form"
      >
        {isDesktop ? (
          <DateOfBirth
            name="dob"
            qaLabel="recover-email"
            label="Date of Birth"
            handleSelectChange={(data) => {
              dispatch({
                type: ACTION.SET_VALUES,
                payload: { dateOfBirth: data }
              });
              handleOnTheFlyChange(true, data, "dateOfBirth", schema, formRef);
            }}
            shouldValidate
            optionDisableCallback={predicateCorrectDay}
            validateSelectChange={dateValidator}
          />
        ) : (
          <InputMask
            name="dateOfBirth"
            label={messages.form.dateOfBirth}
            qaLabel="email-recovery-birth"
            optionalLabel="MM DD YYYY"
            inputMode="numeric"
            marginBottom={20}
            letterSpacing={startedBirthTyping ? 0.5 : 2}
            customHandleChange={(value) => {
              dispatch({
                type: ACTION.SET_VALUES,
                payload: { dateOfBirth: value }
              });
              setStartedBirthTyping(/\d/.test(value));
              handleOnTheFlyChange(
                state.touched.dateOfBirth,
                value,
                "dateOfBirth",
                schema,
                formRef
              );
            }}
            onBlur={() => {
              if (!state.touched.dateOfBirth) {
                dispatch({
                  type: ACTION.SET_TOUCHED,
                  payload: { dateOfBirth: true }
                });
                mediator.base.dispatch({
                  type: "FORGOT_DETAILS_VALIDATED_FIELD",
                  payload: { field: "Date of Birth" }
                });
                handleOnTheFlyChange(
                  true,
                  state.values.dateOfBirth,
                  "dateOfBirth",
                  schema,
                  formRef
                );
              }
            }}
            mask="99 99 9999"
            placeholder={DATE_OF_BIRTH_PLACEHOLDER}
            alwaysShowMask
            isOnlyMobile
          />
        )}
        <InputSSN
          name="last4Ssn"
          label={messages.form.last4Ssn}
          labelDescription={messages.form.last4SsnDescription}
          isVisible={isSSNVisible}
          icon={
            <Icon
              icon={isSSNVisible ? eyeLineHide : eyeLineShow}
              size={16}
              viewBoxSize={16}
              color={buildColor("blue_accent", "500")}
            />
          }
          handleIconClick={() => setIsSSNVisible(!isSSNVisible)}
          marginBottom={20}
          customHandleChange={(value) => {
            dispatch({ type: ACTION.SET_VALUES, payload: { last4Ssn: value } });
            handleOnTheFlyChange(
              state.touched.last4Ssn,
              value,
              "last4Ssn",
              schema,
              formRef
            );
          }}
          onBlur={() => {
            if (!state.touched.last4Ssn) {
              dispatch({
                type: ACTION.SET_TOUCHED,
                payload: { last4Ssn: true }
              });
              mediator.base.dispatch({
                type: "FORGOT_DETAILS_VALIDATED_FIELD",
                payload: { field: "SSN" }
              });
              handleOnTheFlyChange(
                true,
                state.values.last4Ssn,
                "last4Ssn",
                schema,
                formRef
              );
            }
          }}
        />
        <Button
          isStretched
          size="huge"
          isUppercase={false}
          qaLabel="recover-email-submit"
          isDisabled={!isValid}
        >
          {messages.form.submit}
        </Button>
      </Form>
    </RecoverEmail>
  );
};

EmailRecovery.defaultProps = {};

export default connect(
  (store) => ({
    messages: parseCapiMessage(
      store,
      "capi.messages.emailRecoveryModal",
      parseCapiMessage(store, "header.emailRecoveryModal", messagesDefault)
    )
  }),
  () => ({})
)(EmailRecovery);
