export const initialState = {
  isOpen: false,
  error: null
};

const revalidateLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_LOCATION_REVALIDATE": {
      return { isOpen: true, error: "WARNING" };
    }

    case "SET_LOCATION_REVALIDATE_FAILURE": {
      return { isOpen: true, error: "FAILURE" };
    }

    case "CLOSE_LOCATION_REVALIDATE": {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default revalidateLocationReducer;
