import mediator from "@tvg/mediator";

export const AppsFlyerEvents = {
  AfPlaceWager: "af_place_wager",
  AfPlaceFirstWager: "af_place_first_wager",
  AfAppLunch: "af_app_launch",
  AfLogin: "af_login",
  AfBetCost: "af_bet_cost",
  AfCompleteRegistration: "af_complete_registration"
};

const APPS_FLYER_TYPE = "APPSFLYER_EVENT";

const APP_FLYER_EMPTY_VALUES = { values: { "": "" } };

const sendToAppsFlyer = (payload) => {
  const appsFlyerPayload = {
    type: APPS_FLYER_TYPE,
    payload: {
      appsflyer: {
        ...APP_FLYER_EMPTY_VALUES,
        ...payload
      }
    }
  };
  if (
    window &&
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.APPSFLYER_EVENT
  ) {
    window.webkit.messageHandlers.APPSFLYER_EVENT.postMessage(appsFlyerPayload);
  } else {
    mediator.ios.dispatch(appsFlyerPayload);
  }
};

export default sendToAppsFlyer;
