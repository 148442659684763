export const balanceShownAction = (isBalanceShown) => ({
  type: "USER_PREFS_BALANCE_SHOWN",
  payload: isBalanceShown
});

export const setBetConfirmationPreference = (isBetConfirmationEnabled) => ({
  type: "USER_PREFS_BET_CONFIRMATION",
  payload: isBetConfirmationEnabled
});

export const setTutorialsListAction = (tutorialsList) => ({
  type: "SET_TUTORIALS_LIST",
  payload: tutorialsList
});

export const iOSTouchIdToggle = (payload) => ({
  type: "IOS_TOUCH_ID_TOGGLE",
  payload
});

export const setWithdrawals = (withdrawals) => ({
  type: "WITHDRAWALS_SET_WITHDRAWS",
  payload: { withdrawals }
});

export const iOSTouchIdToggleChange = (isChanging) => ({
  type: "IOS_TOUCH_ID_CHANGE",
  payload: { isChanging }
});
