import React, { Fragment } from "react";

import { isFullURL } from "@tvg/formatter/url";

import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import * as allIcons from "../../_static/Icons/icons";
import {
  ListItemWrapper,
  ListItemContent,
  ListItemContentWrapper,
  TitleContainer,
  NewTag,
  IconWrapper,
  GoArrow,
  ListItemLink,
  ListItemRef,
  ListItemDescription,
  NormalTitle
} from "./styled-components";

const renderItem = (
  title,
  description,
  isNew,
  isNewTagSLim,
  showGoArrow,
  icon
) => (
  <Fragment>
    {icon && allIcons && (
      <IconWrapper data-qa-label="menu_option_icon">
        <Icon
          icon={allIcons[icon] || allIcons.arrowRight}
          color={buildColor("blue_accent", "500")}
          size={14}
          qaLabel="icon"
        />
      </IconWrapper>
    )}
    <ListItemContent>
      <TitleContainer isNewTagSLim={isNewTagSLim}>
        <NormalTitle
          tag="h3"
          uppercase={false}
          fontSize={14}
          color={buildColor("grey", "900")}
          qaLabel={title.replace(/ /g, "")}
          fontFamilySecondary
        >
          {title}
        </NormalTitle>
        {isNew && (
          <NewTag fontSize={12} name="NEW" isNewTagSLim={isNewTagSLim} />
        )}
      </TitleContainer>
      <ListItemDescription
        data-qa-label={`${title.replace(/ /g, "")}_Description`}
      >
        {description}
      </ListItemDescription>
    </ListItemContent>
    {showGoArrow && (
      <GoArrow>
        <Icon
          icon={allIcons.arrowRight}
          color={buildColor("blue", "200")}
          qaLabel={`${title.replace(/ /g, "")}_icon`}
        />
      </GoArrow>
    )}
  </Fragment>
);

const ListItem = (props) => {
  const { id, title, description, isNew, url, icon, promptClick } = props.item;
  const { showGoArrow } = props;
  const { isNewTagSLim } = props;

  return (
    <ListItemWrapper key={`listItem_${id}`}>
      {url &&
        (isFullURL(url) ? (
          <ListItemRef
            href={url}
            onClick={promptClick}
            data-qa-label={`${title.replace(/ /g, "")}_ListItem-FindOutMoreBtn`}
          >
            {renderItem(
              title,
              description,
              isNew,
              isNewTagSLim,
              showGoArrow,
              icon
            )}
          </ListItemRef>
        ) : (
          <ListItemLink
            to={(url || "").startsWith("/") ? url : `/${url}`}
            onClick={promptClick}
            data-qa-label={`${title.replace(/ /g, "")}_ListItem-FindOutMoreBtn`}
          >
            {renderItem(
              title,
              description,
              isNew,
              isNewTagSLim,
              showGoArrow,
              icon
            )}
          </ListItemLink>
        ))}
      {!url && (
        <ListItemContentWrapper
          onClick={promptClick}
          data-qa-label="listItem-findOutMoreBtn"
        >
          {renderItem(
            title,
            description,
            isNew,
            isNewTagSLim,
            showGoArrow,
            icon
          )}
        </ListItemContentWrapper>
      )}
    </ListItemWrapper>
  );
};

ListItem.defaultProps = { item: {}, showGoArrow: true, isNewTagSLim: false };

export default ListItem;
