import type { MtpTheme } from "./types";

export const sizeVariantStyles: MtpTheme = {
  xs: {
    mtpText: {
      fontFamily: "medium",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "125%"
    },
    mtpExtraDates: {
      fontFamily: "regular",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "125%"
    }
  },
  s: {
    mtpText: {
      fontFamily: "medium",
      fontSize: "18px",
      fontWeight: "500",
      lineHeight: "125%"
    },
    mtpExtraDates: {
      fontFamily: "condensedRegular",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "110%"
    }
  },
  m: {
    mtpText: {
      fontFamily: "medium",
      fontSize: "24px",
      fontWeight: "500",
      lineHeight: "125%"
    },
    mtpExtraDates: {
      fontFamily: "condensedRegular",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "100%"
    }
  },
  xxs: {
    mtpText: {
      fontFamily: "medium",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "125%"
    },
    mtpExtraDates: {
      fontFamily: "regular",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "100%"
    }
  },
  l: {
    mtpText: {
      fontFamily: "regular",
      fontSize: "28px",
      fontWeight: "500",
      lineHeight: "125%"
    },
    mtpExtraDates: {
      fontFamily: "condensedRegular",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "100%"
    }
  }
};
