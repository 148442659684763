import React from "react";
import { RouteComponentProps } from "react-router";
import PromosOnboarding from "@tvg/promos-onboarding";

export const PromosOnboardingPreview = (props) => {
  const { contentId } = props.match.params;

  return (
    <PromosOnboarding
      isInternalComponent
      componentName="preview"
      previewContentId={contentId}
    />
  );
};

export default PromosOnboardingPreview;
