import { createContext } from "react";
import { noop } from "lodash";
import type { SortContextProps } from "./types";

const initialState: SortContextProps = {
  isDropdownOpen: false,
  onChange: noop,
  selectedValue: "",
  layer: 999
};

export const SortContext = createContext<SortContextProps>(initialState);
SortContext.displayName = "SortContext";
