import React from "react";
import { noop } from "lodash";

import Icon from "../../_static/Icons";

import buildColor from "../../_static/ColorPalette";
import { arrowRight } from "../../_static/Icons/icons";

import {
  AccountLinkGroup,
  LinkText,
  AccountExternalLink,
  AccountHighlightedText
} from "./styled-components";

const AccountMenuLink = ({
  id,
  qaLabel,
  to,
  onClick,
  linkText,
  highlightedText,
  leftIcon,
  rightIcon,
  isExternalLink,
  hideLeftIcon,
  hideRightIcon,
  leftIconQaLabel
}) => {
  const AccountLinkComp = isExternalLink
    ? AccountExternalLink
    : AccountLinkGroup;

  return (
    <AccountLinkComp
      id={id}
      onTouchStart={noop}
      data-qa-label={qaLabel}
      to={to}
      onClick={onClick}
    >
      {!hideLeftIcon && leftIcon && (
        <Icon
          icon={leftIcon.icon}
          color={leftIcon.color}
          size={leftIcon.size}
          qaLabel={leftIconQaLabel}
        />
      )}
      <LinkText>
        {linkText}
        {highlightedText && (
          <AccountHighlightedText>{highlightedText}</AccountHighlightedText>
        )}
      </LinkText>
      {!hideRightIcon && rightIcon && (
        <Icon
          icon={rightIcon.icon}
          color={rightIcon.color}
          size={rightIcon.size}
        />
      )}
    </AccountLinkComp>
  );
};

AccountMenuLink.defaultProps = {
  id: null,
  qaLabel: "account-nav-default",
  to: "/",
  onClick: noop,
  linkText: "Default",
  highlightedText: "",
  leftIcon: {
    icon: arrowRight,
    color: buildColor("grey", "900"),
    size: 17
  },
  rightIcon: {
    icon: arrowRight,
    color: buildColor("blue", "500"),
    size: 24
  },
  isExternalLink: false,
  hideLeftIcon: false,
  hideRightIcon: false
};

export default AccountMenuLink;
