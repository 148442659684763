import { combineReducers } from "redux";

import promosReducer, {
  initialState as initialPromosState
} from "@tvg/promos/src/reducers";

import capiReducer, {
  initialState as initialCapiState
} from "./App/capiReducer";

import appReducer, { initialState as initialAppState } from "./App/appReducer";

import userDataReducer, {
  initialState as initialUserState
} from "./App/userDataReducer";

export const initialState = {
  capi: initialCapiState,
  app: initialAppState,
  userData: initialUserState,
  promos: initialPromosState
};

export default combineReducers({
  capi: capiReducer,
  app: appReducer,
  userData: userDataReducer,
  promos: promosReducer
});
