import styled from "styled-components";
import {
  space,
  compose,
  typography,
  system,
  color,
  fontFamily,
  fontSize,
  layout,
  flex,
  alignItems,
  flexDirection,
  justifyContent
} from "styled-system";
import { ParagraphProps } from "../Typography";

const transformText = system({
  whiteSpace: {
    property: "whiteSpace"
  }
});

const styles = compose(fontFamily, fontSize, transformText, typography, color);

export const DecoratedResulted = styled.span<ParagraphProps>`
  ${styles}
  ${space}
  ${layout}
  ${flex}
  ${alignItems}
  ${flexDirection}
  ${justifyContent}
`;

export const MtpWrapper = styled.div`
  display: flex;
  align-items: center;
  ${styles}
  ${space}
`;

export const ProExtensionWrapper = styled.span`
  letter-spacing: -0.5px;
`;
