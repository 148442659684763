import styled from "styled-components";
import colorBuilder from "../../_static/ColorPalette";
import { fontNormal, defaultSize } from "../../_static/Typography";

export const IconContainer = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: ${(props) => props.iconMargin};
  color: ${colorBuilder("grey", "800")};
  font-size: ${(props) =>
    props.fontSize ? `${props.fontSize}px` : defaultSize};
  font-weight: normal;
  font-family: ${fontNormal};
  letter-spacing: 0;
`;

export const DescriptionText = styled.span`
  color: ${(props) => props.color};
`;
