import React, { useEffect, useRef } from "react";
import { animated, config, useTransition } from "@react-spring/web";
import { Paragraph } from "@tvg/design-system";
import { Scroll } from "@tvg/design-system/web";
import { Button, Container, List, ListItem } from "./styled-components";

export interface DropdownProps {
  onSelectValue: (value: number) => void;
  values: Array<{ label: string; value: number }>;
  selectedValue: number;
  isVisible: boolean;
  onClickAway: () => void;
}

const AnimatedContainer = animated(Container);

const Dropdown = ({
  onSelectValue,
  values,
  selectedValue,
  isVisible,
  onClickAway
}: DropdownProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const transition = useTransition(isVisible, {
    config: isVisible ? { ...config.stiff } : { duration: 1 },
    from: { opacity: 0, transform: `translate3d(0px, 1px, 0px)` },
    enter: { opacity: 1, transform: `translate3d(0px, 0px, 0px)` },
    leave: { opacity: 0, transform: `translate3d(0px, 1px, 0px)` }
  });

  useEffect(() => {
    const element = document.getElementById(`dropdown-button-${selectedValue}`);
    if (element && element.scrollIntoView && isVisible) {
      element.scrollIntoView({ behavior: "auto", block: "start" });
    }
  }, [isVisible]);

  useEffect(() => {
    const handleClickAway = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isSelectorButton =
        target?.getAttribute("data-qa-label") &&
        target?.getAttribute("data-qa-label")?.toString() ===
          "repetition-selector-button";
      if (ref.current && !ref.current.contains(target) && !isSelectorButton) {
        onClickAway();
      }
    };
    document.addEventListener("click", handleClickAway, true);
    return () => {
      document.removeEventListener("click", handleClickAway, true);
    };
  }, [ref, onClickAway]);

  return (
    <>
      {transition(
        (styles, isOpen) =>
          isOpen &&
          values.length > 0 && (
            <AnimatedContainer
              data-qa-label="repetition-selector-dropdown"
              ref={ref}
              style={styles}
            >
              <Scroll
                height="142px"
                width="170px"
                overflowY="scroll"
                overflowX="hidden"
              >
                <List>
                  {values.map((item, index) => (
                    <ListItem key={index.toString()}>
                      <Button
                        onClick={() => {
                          onSelectValue(item.value);
                        }}
                        data-qa-label={`dropdown-button-${item.value}`}
                        id={`dropdown-button-${item.value}`}
                      >
                        <Paragraph
                          fontSize="s"
                          color="grey.900"
                          qaLabel="dropdown-button-text"
                        >
                          {item.label}
                        </Paragraph>
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Scroll>
            </AnimatedContainer>
          )
      )}
    </>
  );
};

export default Dropdown;
