import { attempt, get, isError } from "lodash";

import cookie from "react-cookie";
import tvgConf from "@tvg/conf";
import mediatorChannels from "@tvg/mediator";
import pushToDataLayer, { getSiteVersion, getProduct } from "../gtmUtils";

export default () => {
  const siteVersion = getSiteVersion(tvgConf().product);
  const productVersion = getProduct(tvgConf().product);
  const hasLoginOnce = cookie.load("hasLoginOnce");

  let privateBrowser = attempt(() =>
    localStorage.getItem("privateMode") === "true" ? "Yes" : "No"
  );

  if (isError(privateBrowser)) {
    privateBrowser = "No";
  }

  mediatorChannels.base.subscribe("RACETRACKS:PAGE_VIEW", (data) => {
    pushToDataLayer({
      event: "ga_pageload",
      graphVersion: "v2",
      loginStatus: get(data, "payload.loginStatus", "Logged out"),
      page: get(data, "payload.location.pathname"),
      privateBrowser,
      productVersion,
      registrationStatus: hasLoginOnce ? "Registered" : "Unregistered",
      residenceState: get(data, "payload.residenceState", ""),
      screenName: "Track Page",
      sectionName: "Track List",
      siteVersion,
      balance: get(data, "payload.balance", ""),
      accountId: get(data, "payload.accountId", ""),
      customerStatus: hasLoginOnce ? "Returning" : "First Time"
      // sport: get(data, "payload.runnerType", "")
    });
  });

  mediatorChannels.base.subscribe("RACETRACKS:TRACK_CLICK", (data) => {
    pushToDataLayer({
      event: "navigation",
      gaEventAction: "Navigated To",
      gaEventCategory: "Navigation",
      gaEventLabel: get(data, "payload.trackName", ""),
      module: "Track List",
      menu: "Track List",
      tag: undefined,
      destinationUrl: get(data, "payload.destinationUrl", "")
    });
  });

  mediatorChannels.base.subscribe("RACETRACKS:RACE_CLICK", (data) => {
    pushToDataLayer({
      event: "navigation",
      gaEventAction: "Navigated To",
      gaEventCategory: "Navigation",
      gaEventLabel: `${get(data, "payload.trackName", "")} - R${get(
        data,
        "payload.raceNumber",
        ""
      )} - ${get(data, "payload.mtp", "")}`,
      module: "Track Page",
      menu: "Races",
      tag: undefined,
      destinationUrl: get(data, "payload.destinationUrl", "")
    });
  });

  mediatorChannels.base.subscribe("RACETRACKS:ADD_FAVORITE_CLICK", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventAction: "Add To Favorites Clicked",
      gaEventCategory: "Site Click",
      gaEventLabel: get(data, "payload.trackName", ""),
      module: "Track Page",
      tag: undefined
    });
  });

  mediatorChannels.base.subscribe("RACETRACKS:ADD_FAVORITE_SUCCESS", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventAction: "Add To Favorites Success",
      gaEventCategory: "Site Click",
      gaEventLabel: get(data, "payload.trackName", ""),
      module: "Track Page",
      tag: undefined
    });
  });

  mediatorChannels.base.subscribe(
    "RACETRACKS:SEE_ALL_TODAYS_RACING",
    (data) => {
      pushToDataLayer({
        event: "navigation",
        gaEventAction: "Navigated To",
        gaEventCategory: "Navigation",
        gaEventLabel: "See All Todays Racing",
        module: "Track Page",
        menu: "Races",
        tag: undefined,
        destinationUrl: get(data, "payload.destinationUrl", "")
      });
    }
  );

  mediatorChannels.base.subscribe("RACETRACKS:RETURN_TO_RACETRACKS", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventAction: "Back Arrow Clicked",
      gaEventCategory: "Site Click",
      gaEventLabel: "Return To Track List",
      module: "Track Page",
      tag: undefined
    });
  });
};
