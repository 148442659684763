import React, { PureComponent } from "react";
import { Route, Routes } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";

import { connect } from "react-redux";

import { attempt, get, isEmpty, bindAll, find } from "lodash";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import mediator from "@tvg/mediator";
import WagerRewardsEvents from "@tvg/gtm/src/modules/WagerRewards";
import tvgConf from "@tvg/conf";
import calculateFeatureOverride from "@tvg/utils/featuresUtils";

import WagerRewards from "./WagerRewards";

import {
  failFeaturesRequest,
  failMessagesRequest,
  successFeaturesRequest,
  successMessagesRequest
} from "../actions/capi";
import { setUserAccountNumber, getUserData } from "../actions/user";

export class Main extends PureComponent {
  constructor(props) {
    super(props);
    bindAll(this, ["getFeatures", "getMessages"]);

    this.tvg = tvgConf();
  }

  componentWillMount() {
    // tries to get the user id (accountNumber) from localStorage and dispatch it to user data
    // this is useful because every component initialized by tvg-mobile will know if the user is logged, plus it's
    // account number beforehand
    attempt(() => {
      if (window.sessionStorage.getItem("userId")) {
        this.props.dispatch(
          setUserAccountNumber(window.sessionStorage.getItem("userId"))
        );
      }
    });
  }

  componentDidMount() {
    this.getFeatures();
    this.getMessages();

    mediatorClassic.subscribe("UPDATE_ROUTER", () => {
      const pathWithParameters = window.location.href.replace(
        window.location.origin,
        ""
      );
      if (this.props.location.pathname !== pathWithParameters) {
        this.props.history.replace(pathWithParameters);
      }
    });

    mediatorClassic.subscribeWithPast(
      "TVG_LOGIN:USER_SESSION_UPDATE",
      (data) => {
        this.props.dispatch(getUserData(data));
      }
    );

    // Bridge open login event between new mediator and old
    mediator.base.subscribe("OPEN_LOGIN", (data) => {
      mediatorClassic.dispatch(
        "TVG_LOGIN:OPEN_LOGIN_MODAL",
        data && data.payload ? data.payload : {}
      );
    });

    mediator.base.subscribe("TVG4_NAVIGATION", (data) => {
      mediatorClassic.dispatch(
        "TVG4_NAVIGATION",
        data && data.payload ? data.payload : {}
      );
    });

    mediator.base.subscribe("ACCOUNT_BALANCE_CHANGED", (data) => {
      mediatorClassic.dispatch(
        "ACCOUNT_BALANCE_CHANGED",
        data && data.payload ? data.payload : {}
      );
    });

    WagerRewardsEvents();
  }

  getFeaturesOverrides = () =>
    attempt(
      () => JSON.parse(window.localStorage.getItem("featureOverrides")),
      false
    );

  setFeaturesOverrides = (features) =>
    attempt(() =>
      window.localStorage.setItem("featureOverrides", JSON.stringify(features))
    );

  getFeatures = () =>
    this.tvg
      .getFeatures()
      .then((response) => {
        const featureToggles = {};
        let featureOverrides = this.getFeaturesOverrides() || {};
        const hasFeaturesOverrides = !!this.getFeaturesOverrides();

        if (response && Array.isArray(response.featureToggles)) {
          response.featureToggles.forEach((toggle) => {
            let { enabled } = toggle;
            featureOverrides = calculateFeatureOverride(
              hasFeaturesOverrides,
              featureOverrides,
              toggle
            );
            if (get(featureOverrides, toggle.name)) {
              enabled = featureOverrides[toggle.name].enabled;
            }

            featureToggles[toggle.name] = enabled;
          });
        }

        if (!isEmpty(featureOverrides)) {
          this.setFeaturesOverrides(featureOverrides);
        }
        this.props.dispatch(successFeaturesRequest(featureToggles));
      })
      .catch((err) => this.props.dispatch(failFeaturesRequest(err)));

  getMessages = () => {
    const messageNamespaces = ["WagerRewards", "Global"];

    return this.tvg
      .getMessages(messageNamespaces)
      .then((response) => this.props.dispatch(successMessagesRequest(response)))
      .catch((err) => this.props.dispatch(failMessagesRequest(err)));
  };

  hasProfileException = () => {
    try {
      const userProfileType = this.props.user.user.profile;
      const tvgMenus = JSON.parse(this.props.messages.globalTVG4MenuItems);

      let wagerMenu;
      tvgMenus.forEach((menu) => {
        if (!wagerMenu) {
          wagerMenu = find(menu.subMenus, { subQaLabel: "wagerRewardsButton" });
        }
      });

      const profileTypeException = get(
        wagerMenu,
        "options.profileTypeException",
        []
      );
      return profileTypeException.includes(userProfileType);
    } catch (error) {
      return false;
    }
  };

  tvg;

  render() {
    return (
      <Routes location={this.props.location}>
        <Route
          path="/wager-rewards"
          element={
            <WagerRewards
              history={this.props.history}
              isEnabled={get(this.props.featureToggles, "wagerRewardsApp")}
              isLogged={this.props.isLogged}
              profile={this.props.profile}
              hasProfileException={this.hasProfileException}
            />
          }
        />
      </Routes>
    );
  }
}

export default connect(
  (store) => ({
    isLogged: store.userData.logged,
    profile: get(store.userData, "user.profile", ""),
    user: store.userData,
    messages: store.capi.messages,
    featureToggles: store.capi.featureToggles
  }),
  (dispatch) => ({ dispatch })
)(withRouter(Main));
