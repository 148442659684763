import mediator from "@tvg/mediator";
import { isEqual, get } from "lodash";
import { CONSTANTS } from "@tvg/login-service/services/static";

export const initialState = {
  hasRequest: false,
  forceSessionUpdate: false,
  hasRequested: false,
  logging: false,
  logged: false,
  error: null,
  wasLogin: false,
  userLogin: false,
  userLogout: false,
  loginPin: false,
  geolocateIn: 0,
  user: null
};

export const mediatorSessionUpdateMiddleware = (
  oldState,
  newState,
  tvg3token
) => {
  if (
    !oldState.hasRequest ||
    !isEqual(oldState.logged, newState.logged) ||
    !isEqual(oldState.user, newState.user) ||
    !!newState.forceSessionUpdate
  ) {
    mediator.base.dispatch({
      type: CONSTANTS["TVG_LOGIN:USER_SESSION_UPDATE"],
      payload: tvg3token ? { ...newState, tvg3token } : newState
    });
  }

  return newState;
};

const reducers = {
  [CONSTANTS.SET_SESSION_SUCCESS]: (state, payload) =>
    mediatorSessionUpdateMiddleware(
      state,
      {
        ...state,
        ...{
          user: payload.data ? payload.data.userDetails : null,
          hasRequested: true,
          logged: true,
          logging: false,
          wasLogin: payload.fromLogin,
          userLogin: payload.userLogin,
          loginPin: payload.loginPin,
          geolocateIn: get(payload.data, "geoDetails.geolocateIn", 0),
          error: null
        }
      },
      get(payload, "data.session.tvg3token")
    ),

  [CONSTANTS.SET_SESSION_ERROR]: (state, payload) =>
    mediatorSessionUpdateMiddleware(state, {
      ...state,
      ...{
        user: null,
        hasRequested: true,
        logged: false,
        logging: false,
        wasLogin: payload.fromLogin,
        userLogin: payload.userLogin,
        loginPin: payload.loginPin,
        error: payload.message
      }
    }),
  [CONSTANTS.SET_USER]: (state, payload) =>
    mediatorSessionUpdateMiddleware(state, {
      ...state,
      ...{
        user: payload.user,
        hasRequested: true,
        forceSessionUpdate: payload.forceSessionUpdate || false,
        logged: true,
        logging: false,
        wasLogin: payload.fromLogin,
        userLogin: payload.userLogin,
        geolocateIn: get(payload.data, "geoDetails.geolocateIn", 1),
        error: null
      }
    }),
  [CONSTANTS.CLEAR_SESSION]: (state, payload) =>
    mediatorSessionUpdateMiddleware(state, {
      ...initialState,
      hasRequested: true,
      userLogout: payload.userLogout
    })
};

export default (state = initialState, action) => {
  const validAction = action || { type: "" };

  return validAction.type && reducers[validAction.type]
    ? reducers[validAction.type](state, validAction.payload)
    : state;
};
