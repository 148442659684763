import { gql } from "@apollo/client";

export const TRACKS_WITH_METADATA = gql`
  query tracksWithMetadata($wagerProfile: String) {
    tracks(profile: $wagerProfile) {
      id
      races {
        trackCode
        trackName
        number
        mtp
        postTime
        status {
          code
        }
      }
    }
  }
`;
export default TRACKS_WITH_METADATA;
