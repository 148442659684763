/* eslint-disable security/detect-object-injection */
import React from "react";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import moment from "moment";
import type { MtpAlign, MtpSizes } from "./types";
import { Paragraph, ParagraphFonts } from "../Typography";
import { sizeVariantStyles } from "./constants";
import { DecoratedResulted, ProExtensionWrapper } from "./styled-components";

// Formatter depending on alignment
const formatter: Record<MtpAlign, Record<string, string>> = {
  right: {
    extraSmall: "DD MMM YYYY -",
    default: "hh:mm A - DD MMM YYYY"
  },
  left: {
    extraSmall: " - DD MMM YYYY",
    default: "  hh:mm A - DD MMM YYYY"
  },
  center: {
    extraSmall: " - DD MMM YYYY",
    default: "  hh:mm A - DD MMM YYYY"
  }
};

export const getResultedFontFamily = (
  size: MtpSizes,
  value: ParagraphFonts
) => {
  if (size === "l" || size === "m") {
    return "bold";
  }

  return value;
};

// Race off computation
export const GetRaceOffAndOfficialText = (
  status: RaceStatusEnum,
  size: MtpSizes,
  postTime: string,
  align: MtpAlign,
  qaLabel: string,
  mode: "dark" | "light"
) => {
  if (status === RaceStatusEnum.RACE_OFF) {
    return (
      <Paragraph
        color={getRaceOffColorContent(mode)}
        fontFamily={sizeVariantStyles[size].mtpText.fontFamily}
        fontSize={sizeVariantStyles[size].mtpText.fontSize}
        fontWeight={sizeVariantStyles[size].mtpText.fontWeight}
        lineHeight={sizeVariantStyles[size].mtpText.lineHeight}
        textAlign={align}
        qaLabel={qaLabel}
        margin={0}
      >
        {size === "xxs" ? "OFF" : "Race Off"}
      </Paragraph>
    );
  }

  // Compute format of the race resulted
  const hours = moment().diff(moment(postTime), "hours");

  if (hours > 24 && size !== "xxs") {
    const formatParam =
      formatter[align][size === "xs" ? "extraSmall" : "default"];
    const extraData = moment(postTime).format(formatParam);
    return (
      <Paragraph
        color={getResultedTextColor(mode)}
        fontFamily={sizeVariantStyles[size].mtpExtraDates.fontFamily}
        fontSize={sizeVariantStyles[size].mtpExtraDates.fontSize}
        fontWeight={sizeVariantStyles[size].mtpExtraDates.fontWeight}
        lineHeight={sizeVariantStyles[size].mtpExtraDates.lineHeight}
        textAlign={align}
        qaLabel={qaLabel}
        margin={0}
      >
        {align === "right" && (
          <>
            <>{extraData}</>&nbsp;
          </>
        )}
        <DecoratedResulted
          color={`var(${getResultedTextColor(mode)})`}
          fontFamily={
            ["m", "l"].includes(size)
              ? "bold"
              : sizeVariantStyles[size].mtpText.fontFamily
          }
          fontSize={sizeVariantStyles[size].mtpText.fontSize}
          fontWeight={sizeVariantStyles[size].mtpText.fontWeight}
          lineHeight={sizeVariantStyles[size].mtpText.lineHeight}
          textAlign={align}
          margin={0}
        >
          Resulted
        </DecoratedResulted>
        {align !== "right" && (
          <>
            &nbsp;<>{extraData}</>
          </>
        )}
      </Paragraph>
    );
  }

  return (
    <Paragraph
      color={getResultedTextColor(mode)}
      fontFamily={getResultedFontFamily(
        size,
        sizeVariantStyles[size].mtpText.fontFamily
      )}
      fontSize={sizeVariantStyles[size].mtpText.fontSize}
      fontWeight={sizeVariantStyles[size].mtpText.fontWeight}
      lineHeight={sizeVariantStyles[size].mtpText.lineHeight}
      textAlign={align}
      margin={0}
    >
      {size === "xxs" ? "RES" : "Resulted"}
    </Paragraph>
  );
};

// More than 60m computation
export const getPostTimeMoreThanSixtyMinutes = (
  size: MtpSizes,
  postTime: string
) => {
  if (size === "xxs") {
    const output = moment(postTime).format("hh:mm A");
    const parts = output.split(" ");

    return (
      <>
        {parts[0]}
        <div>{parts[1]}</div>
      </>
    );
  }

  const hours = moment().diff(moment(postTime), "hours");

  if (hours < -24) {
    return moment(postTime).format("MMM DD, hh:mm A");
  }
  return moment(postTime).format("hh:mm A");
};

// compute font weight special cases
export const getWeight = (mtp: number, size: MtpSizes) => {
  if ((size === "s" && mtp < 6) || size === "xs" || size === "xxs") {
    return 500;
  }

  return 700;
};

const getFontSize = (size: MtpSizes) => {
  if (size === "l") {
    return "16px";
  }

  if (size === "m") {
    return "14px";
  }

  return sizeVariantStyles[size].mtpExtraDates.fontSize;
};

// Less than 60m computation
export const getPostTimeLessThanSixtyMinutes = (
  size: MtpSizes,
  mtp: number,
  align: MtpAlign,
  color: string,
  isMtpPro = false
) => {
  if (size === "xxs") {
    return `${mtp}m`;
  }

  if (["xs", "s"].includes(size)) {
    return `${mtp} MTP`;
  }

  let formatMtp = `${mtp}`;

  if (mtp < 9) {
    formatMtp = `0${mtp}`;
  }

  let props = {};

  if (isMtpPro) {
    props = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontSize: getFontSize(size)
    };
  }

  return (
    <>
      {formatMtp}&nbsp;
      <DecoratedResulted
        color={`var(${color})`}
        fontFamily={sizeVariantStyles[size].mtpExtraDates.fontFamily}
        fontSize={sizeVariantStyles[size].mtpExtraDates.fontSize}
        fontWeight={sizeVariantStyles[size].mtpExtraDates.fontWeight}
        lineHeight={sizeVariantStyles[size].mtpExtraDates.lineHeight}
        textAlign={align}
        {...props}
      >
        {isMtpPro ? (
          <>
            MINUTE{mtp > 1 ? "S" : ""}
            <ProExtensionWrapper>TILL POST</ProExtensionWrapper>
          </>
        ) : (
          <>MINUTE{mtp > 1 ? "S" : ""} TILL POST</>
        )}
      </DecoratedResulted>
    </>
  );
};

// Get variants styles
export const getSizeVariantsStyles = (
  size: MtpSizes,
  mtp: number,
  status: RaceStatusEnum
) => {
  const defaultValue = sizeVariantStyles;

  if ((size === "s" && mtp < 6) || status === RaceStatusEnum.RACE_OFF) {
    defaultValue[size as keyof typeof defaultValue].mtpText.fontFamily = "bold";
    defaultValue[size as keyof typeof defaultValue].mtpText.fontWeight = "700";
  }

  return defaultValue;
};

// Get MTP texts color
export const getMtpColorTextColor = (mtp: number, mode: "dark" | "light") => {
  if (mtp < 6) {
    return mode === "dark"
      ? `--fd-colors-system-component-mtp-less-than-six-minutes-dark`
      : "--fd-colors-system-component-mtp-less-than-six-minutes";
  }

  if (mtp >= 6) {
    return mode === "dark"
      ? `--fd-colors-system-component-mtp-more-than-six-minutes-dark`
      : "--fd-colors-system-component-mtp-more-than-six-minutes";
  }

  return "";
};

export const getResultedTextColor = (mode: "dark" | "light") =>
  mode === "dark"
    ? `--fd-colors-system-component-mtp-content-resulted-dark`
    : "--fd-colors-system-component-mtp-content-resulted";

export const getRaceOffTextColor = (mode: "dark" | "light") =>
  mode === "dark"
    ? "--fd-colors-system-component-mtp-content-dark"
    : "--fd-colors-system-component-mtp-content";

export const getMoreThanSixMinutesTextColor = (mode: "dark" | "light") =>
  mode === "dark"
    ? "--fd-colors-system-component-mtp-more-than-six-minutes-dark"
    : "--fd-colors-system-component-mtp-more-than-six-minutes";

export const getRaceOffColorContent = (mode: "dark" | "light") =>
  mode === "dark"
    ? "--fd-colors-system-component-mtp-content-race-off-dark"
    : "--fd-colors-system-component-mtp-content-race-off";
