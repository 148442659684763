import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import colorBuilder from "../../_static/ColorPalette";
import { buildText } from "../../_atom/Text";
import { buildTextHighlighted, TYPES } from "../../_atom/TextHighlighted";
import { fontMedium } from "../../_static/Typography";

export const AccountLinkBaseStyles = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 100%;

  > svg {
    &:first-child {
      margin-right: 12px;
    }

    &:last-child {
      margin-left: auto;
      margin-right: 5px;
    }
  }
`;

export const AccountLinkGroup = styled(Link).attrs({
  "data-qa-label": "account-link"
})`
  ${AccountLinkBaseStyles};
`;

export const AccountExternalLink = styled.a`
  ${AccountLinkBaseStyles};
`;

const LinkTextStyles = buildText({
  tag: "span",
  fontSize: 14,
  color: colorBuilder("grey", "900"),
  qaLabel: "account-link-text"
});

export const LinkText = styled(LinkTextStyles)`
  line-height: 1;
  display: flex;
  align-items: center;
  font-family: ${fontMedium};
`;

const HighlightedTextStyles = buildTextHighlighted(
  TYPES.marketing,
  11,
  true,
  true
);

export const AccountHighlightedText = styled(HighlightedTextStyles)`
  margin-left: 10px;
  height: 14px;
`;
