import React from "react";
import { Button } from "@tvg/design-system/web";
import { Container, EmptyTitle } from "./styled-components";

interface Props {
  title: string;
  actionText: string;
  onActionClick: () => void;
}

const EmptyRaceList = ({ title, actionText, onActionClick }: Props) => (
  <Container>
    <EmptyTitle>{title}</EmptyTitle>
    <Button
      variant="tertiary"
      qaLabel="todays-racing-link"
      iconPosition="end"
      icon="arrowRight"
      onClick={() => onActionClick()}
    >
      {actionText}
    </Button>
  </Container>
);

export default EmptyRaceList;
