export const alertInlineContentMap = {
  success: {
    icon: {
      name: "success",
      lineColor: "--fd-colors-system-positive-content-accent",
      backgroundColor: "transparent"
    },
    text: "--fd-colors-system-positive-content-on-subtle",
    link: "--fd-colors-system-positive-link-on-subtle"
  },
  informational: {
    icon: {
      name: "info",
      lineColor: "--fd-colors-system-info-content-accent",
      backgroundColor: "transparent"
    },
    text: "--fd-colors-system-info-content-on-subtle",
    link: "--fd-colors-system-info-link-on-subtle"
  },
  error: {
    icon: {
      name: "exclamation",
      lineColor: "--fd-colors-system-important-content-accent",
      backgroundColor: "transparent"
    },
    text: "--fd-colors-system-important-content-on-subtle",
    link: "--fd-colors-system-important-link-on-subtle"
  },
  warning: {
    icon: {
      name: "warning",
      lineColor: "--fd-colors-system-warning-content-accent",
      backgroundColor: "transparent"
    },
    text: "--fd-colors-system-warning-content-on-subtle",
    link: "--fd-colors-system-warning-link-on-subtle"
  },
  promo: {
    icon: {
      name: "promo",
      lineColor: "--fd-colors-system-alert-content-accent",
      backgroundColor: "transparent"
    },
    text: "--fd-colors-system-alert-content-on-subtle",
    link: "--fd-colors-system-alert-link-on-subtle"
  }
};
