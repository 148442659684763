import styled, { css } from "styled-components";
import { rgba } from "polished";
import {
  background,
  borderRadius,
  color,
  compose,
  space,
  width
} from "styled-system";

const styledUnits = compose(color, background, borderRadius, width);

export const Container = styled.div`
  display: flex;
`;

export const ContentContainer = styled.div<{ layer: number }>`
  position: absolute;
  z-index: ${({ layer }) => layer};
  display: flex;
  flex-direction: column;
  ${space}
`;

export const DropdownDiv = styled.div`
  border-width: 1px;
  background-color: var(--fd-colors-background-surface);
  border-color: var(--fd-colors-border-default);
  border-radius: var(--fd-radii-border-radius-020);
  ${({ theme }) => css`
    filter: drop-shadow(
      0 1px 3px ${rgba(theme.colorTokens.background.secondary, 0.14)}
    );
    will-change: filter;
  `}
  ${styledUnits}
`;
