import styled, { css } from "styled-components";
import buildInput from "../../_atom/Input";
import { buildText } from "../../_atom/Text";
import Button from "../../_atom/Buttons/button";
import { fontNormal, fontMedium } from "../../_static/Typography";
import Icon from "../../_static/Icons";
import buildColor from "../../_static/ColorPalette";

const buildLoginInput = (isPasswordType) => styled(
  buildInput({
    type: isPasswordType ? "password" : "text"
  })
)`
  border-bottom: ${(props) =>
    props.isError && `2px solid ${buildColor("red", "500")}`};
  padding-right: ${(props) => props.isPassword && "64px"};
  padding-left: 0;
  appearance: none;
  box-shadow: 0 2px 2px rgb(17 43 68 / 15%);
  ${({ isError }) => css`
    border: ${(isError && `2px solid ${buildColor("orange", "600")}`) ||
    `1px solid ${buildColor("blue", "100")}`};
  `}
  height: 42px;
  padding: 12px 12px 12px 5px;
  color: ${buildColor("grey", "900")} !important;

  &::placeholder {
    color: ${(props) =>
      props.opacityPlaceholder
        ? buildColor("blue", "100")
        : buildColor("grey", "700")};
    font-size: 14px;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:focus {
    border: solid 2px ${buildColor("blue_accent", "500")};
  }

  &:-webkit-autofill {
    &:hover,
    &:focus,
    &:active,
    &:not(:focus) {
      box-shadow: 0 0 0 30px ${buildColor("white", "100")} inset !important;
    }
  }
`;

export const TextInput = buildLoginInput(false);
export const PasswordInput = buildLoginInput(true);

export const InputWrapper = styled.div`
  position: relative;
`;

export const WarningMessage = styled.p`
  color: ${buildColor("orange", "600")};
  font-size: 14px;
  font-weight: normal;
  margin-top: 4px;
  font-family: ${fontNormal};
`;

export const InputContainer = styled.fieldset`
  position: relative;
  margin-bottom: 20px;

  &:first-of-type {
    margin-top: 4px;
  }
`;

export const ButtonShowHide = styled(Button)`
  display: block;
  width: 32px;
  height: 32px;
  background: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 11px;
  font-weight: 700;
  color: ${buildColor("blue_accent", "500")};
  text-transform: uppercase;
  border: none;
  outline: none;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

export const StatusIcon = styled(Icon)`
  vertical-align: bottom;
  margin-left: 3px;
`;

export const InputLabel = styled(
  buildText({
    tag: "label",
    bold: false,
    color: buildColor("grey", "900")
  })
)`
  font-size: 14px;
  position: relative;
  font-weight: 500;
  margin-bottom: 8px;
  font-family: ${fontMedium};
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
