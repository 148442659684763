import React, { Fragment, PureComponent } from "react";
import { get } from "lodash";

import { formatSequencial } from "@tvg/formatter";
import RunnerNumber from "../../_atom/RunnerNumber";
import RunnerBio from "../RunnerBio";

import {
  RunnerRow,
  PositionCell,
  Position,
  Number,
  Runner,
  OtherInfo,
  DNF
} from "./styled-components";

export default class RaceResultsRunner extends PureComponent {
  static defaultProps = {
    runnerId: "0",
    runnerName: "",
    trainer: "",
    jockey: "",
    scratched: false,
    finishPosition: 0,
    accBeatenDistance: "",
    postRaceReport: "",
    odds: null,
    isHighlighted: false,
    hasTimeformData: true,
    raceTypeCode: "T"
  };

  renderRunner() {
    const {
      runnerId,
      runnerName,
      finishPosition,
      odds,
      isHighlighted,
      raceTypeCode,
      hasTimeformData
    } = this.props;

    const postRaceReport = get(this.props, "postRaceReport");
    const accBeatenDistance = get(this.props, "accBeatenDistance");
    const jockey = get(this.props, "jockey");
    const trainer = get(this.props, "trainer");
    const isDnf = finishPosition === 99;

    return (
      <RunnerRow
        key={`position-runner-${+runnerId}`}
        data-qa-label="raceResultsRunner-runner"
      >
        <PositionCell data-qa-label="raceResultsRunner-runner-position">
          <Position isHighlighted={isHighlighted}>
            {isDnf ? (
              <DNF />
            ) : (
              <Fragment>
                {finishPosition}
                <sup>
                  {formatSequencial(+finishPosition).replace(
                    (+finishPosition).toString(),
                    ""
                  )}
                </sup>
              </Fragment>
            )}
          </Position>
        </PositionCell>
        <Number isDnf={isDnf}>
          <RunnerNumber runnerId={runnerId} raceTypeCode={raceTypeCode} />
        </Number>
        <Runner
          {...(hasTimeformData ? { colSpan: 3 } : {})}
          isBasic={!hasTimeformData}
          isDnf={isDnf}
        >
          <RunnerBio
            runnerName={runnerName}
            handicappingData={[jockey, trainer]}
            removeVerticalPadding
            firstHandicapBold
            resultsData={
              hasTimeformData && !isDnf
                ? {
                    accBeatenDistance,
                    odds
                  }
                : null
            }
            isScratched={isDnf}
          />
          {hasTimeformData && !!postRaceReport && (
            <OtherInfo isDnf={isDnf}>{`"${postRaceReport}"`}</OtherInfo>
          )}
        </Runner>
      </RunnerRow>
    );
  }

  render() {
    return <Fragment>{this.renderRunner()}</Fragment>;
  }
}
