import React from "react";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";

import tvgConf from "@tvg/conf";
import { get } from "lodash";
import { Container, ContentContainer, Title, Text } from "./styled-components";

const WagerCancellationRulesModal = ({
  isOpen = false,
  onClose,
  messages,
  userLocation,
  onBack,
  hasBack = false
}) => {
  const content = get(messages, userLocation, messages.DEFAULT);
  const { device } = tvgConf();

  const baseModalProps = {
    title: "Wager cancellations",
    titleType: "ipp",
    isOpen,
    onClose,
    onBack: hasBack ? onBack : null,
    qaLabel: "bet-cancel-rules",
    hasShadow: true,
    hasOverlay: true,
    isFullWidth: false,
    isContentTransparent: false,
    isFullHeight: false,
    layerOffset: 1
  };

  const modalProps =
    device === "mobile"
      ? {
          ...baseModalProps,
          animation: "bottomFloating",
          offsetTop: 200,
          offsetBottom: 200,
          offsetLeft: 8,
          offsetRight: 8
        }
      : {
          ...baseModalProps,
          animation: "fade",
          offsetTop: 40,
          offsetBottom: 0,
          offsetLeft: 40,
          offsetRight: 40
        };

  return (
    <ModalV2 {...modalProps}>
      {() => (
        <Container>
          {content &&
            content.map(({ title, text }, index) => (
              <ContentContainer key={index.toString()}>
                {title && <Title>{title}</Title>}
                {text && <Text>{text}</Text>}
              </ContentContainer>
            ))}
        </Container>
      )}
    </ModalV2>
  );
};

export default WagerCancellationRulesModal;
