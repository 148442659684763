import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

const NotificationBubbleContainer = styled(Link)`
  display: flex;
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  justify-content: center;
  align-items: center;
  background-color: ${({ $isDesktop }) =>
    $isDesktop ? "transparent" : buildColor("white", "10")};
  border-radius: 50%;
  ${({ $tvgHeaderV2FeatureToggle }) =>
    $tvgHeaderV2FeatureToggle &&
    css`
      svg > path {
        transform: translate(1px, 3px);
      }
    `};
  ${({ $isDesktop }) =>
    !$isDesktop &&
    css`
      svg {
        margin-top: 10px;
        margin-left: 6.5px;
      }
    `}

  &::after {
    ${(props) =>
      props.$isCounterShown &&
      css`
        content: "${props.counter}";
        position: absolute;
        top: 0;
        left: calc(50% + 3px);
        border-radius: 17px;
        height: 17px;
        line-height: 17px;
        text-align: center;
        font-family: ${fontNormal};
        font-weight: bold;
        font-size: 12px;
        color: ${buildColor("white", "100")};
        background-color: ${buildColor("red", "500")};
        padding: 0 5px;
      `};
  }
`;

export default NotificationBubbleContainer;
