import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { PopperContainer, PopperContent, Trigger } from "./styled-components";
import type { PopperProps } from "./types";
import useOnClickOutside from "../../hooks/useOnClickOutside";

export const Popper: React.FC<PopperProps> = ({
  placement = "top-right",
  on = "hover",
  isDisabled = true,
  content,
  layer = 1999,
  isLeftSideRendered = false,
  children,
  forceClose = false,
  closeDelay
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [triggerLayout, setTriggerLayout] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
  } | null>(null);
  const [contentLayout, setContentLayout] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0
  });

  useOnClickOutside(
    contentRef,
    () => {
      if (on === "click") {
        setIsVisible(false);
      }
    },
    triggerRef
  );

  const measureTriggerLayout = () => {
    if (!triggerRef.current) return;

    const rect = triggerRef.current.getBoundingClientRect();
    const containerRect =
      triggerRef.current.offsetParent?.getBoundingClientRect();
    const offsetX = containerRect?.left || 0;
    const offsetY = containerRect?.top || 0;

    setTriggerLayout({
      x: rect.left - offsetX,
      y: rect.top - offsetY,
      width: rect.width,
      height: rect.height
    });
  };

  const measureContentLayout = () => {
    if (!contentRef.current) return;

    const rect = contentRef.current.getBoundingClientRect();

    setContentLayout({
      width: rect.width,
      height: rect.height
    });
  };

  useLayoutEffect(() => {
    measureTriggerLayout();
    measureContentLayout();
  }, [isVisible]);

  const handleMouseEnter = () => {
    if (on === "hover" && !isDisabled) {
      setIsVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (on === "hover" && !isDisabled) {
      setIsVisible(false);
    }
  };

  const handleClick = () => {
    if (on === "click" && !isDisabled) {
      setIsVisible((prev) => !prev);
    }
  };

  useLayoutEffect(() => {
    const handlePosition = () => {
      measureTriggerLayout();
      measureContentLayout();
    };

    window.addEventListener("scroll", handlePosition, true);
    window.addEventListener("resize", handlePosition, true);
    return () => {
      window.removeEventListener("scroll", handlePosition, true);
      window.removeEventListener("resize", handlePosition, true);
    };
  }, [isVisible]);

  useEffect(() => {
    const timer = setTimeout(() => {
      contentRef.current?.removeAttribute("data-hidden");
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (forceClose) {
      setIsVisible(false);
    }
  }, [forceClose]);

  useEffect(() => {
    if (closeDelay && isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, closeDelay);

      return () => clearTimeout(timer);
    }
    return undefined;
  }, [closeDelay, isVisible]);

  return (
    <PopperContainer ref={containerRef}>
      <Trigger
        ref={triggerRef}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-qa-label="popper-trigger"
      >
        {children}
      </Trigger>
      {triggerLayout && (
        <PopperContent
          ref={contentRef}
          placement={placement}
          triggerLayout={triggerLayout}
          contentLayout={contentLayout}
          isVisible={isVisible}
          layer={layer}
          isLeftSideRendered={isLeftSideRendered}
          data-hidden="true"
        >
          {content}
        </PopperContent>
      )}
    </PopperContainer>
  );
};

export type { PopperProps };
