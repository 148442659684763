import mediator from "@tvg/mediator";
import tracksFilterService from "@tvg/tracks-header/service";
import {
  setUserPrefs,
  userLogout
} from "@tvg/sh-lib-account-actions/src/actions/login";
import { clearUserFavoriteTracks } from "@tvg/sh-lib-account-actions/src/actions/favorites";
import { removeTimeout } from "./userLocation";

const handleLogout = (user, manageEquibaseId, dispatch) => {
  // In case user logs out and greyhound filter is there, remove it
  const raceFilters = tracksFilterService.getUserRaceFilters();
  if (raceFilters.indexOf("G") > -1) {
    raceFilters.splice(raceFilters.indexOf("G"), 1);
    tracksFilterService.setRaceFilterPreference(raceFilters);
  }
  dispatch(clearUserFavoriteTracks());
  dispatch(setUserPrefs({}, false));
  dispatch(userLogout());
  // if user logs out should remove equibaseId
  manageEquibaseId(false);
  if (user.userLogout) {
    removeTimeout();
    // User triggered logout
    // Send event to iOS app when logout is successful
    mediator.ios.dispatch({
      type: "LOGOUT_SUCCESS"
    });
  }
};

export default handleLogout;
