import React from "react";
import type { ButtonProps, ButtonIcon, ButtonVariant } from "./types";
import { Icon, type IconNames } from "../Icon";
import { LoadingSpinner } from "../LoadingSpinner";
import { StyledButton } from "./styled-components";
import { createPalette } from "./utils";
import { buttonsColorPalette } from "./buttonsColorPalette";

const getButtonIcon = (icon: ButtonIcon) =>
  React.isValidElement(icon) ? (
    icon
  ) : (
    <Icon
      name={icon as IconNames}
      size="s"
      lineColor="--fd-colors-component-button-secondary-content-base"
      backgroundColor="transparent"
      qaLabel="button-icon"
    />
  );

export const Button = ({
  variant = "primary",
  size = "l",
  children,
  onClick,
  isLoading = false,
  iconPosition = "start",
  icon,
  overrideColors,
  isStretched = false,
  isDisabled = false,
  qaLabel = "button",
  ...rest
}: ButtonProps) => {
  const colorPalette = createPalette(variant, overrideColors);

  return (
    <StyledButton
      size={size}
      disabled={isDisabled}
      colorPalette={colorPalette}
      isStretched={isStretched}
      isLoading={isLoading}
      iconPosition={iconPosition}
      onClick={onClick}
      hasIcon={!!icon}
      data-qa-label={qaLabel}
      {...rest}
    >
      {isLoading ? (
        <LoadingSpinner
          size="s"
          color="--fd-colors-component-button-tertiary-content-active"
          bgColor="--fd-colors-core-white-tint-020"
        />
      ) : (
        <>
          {icon && getButtonIcon(icon)}
          {children}
        </>
      )}
    </StyledButton>
  );
};

export { type ButtonProps, type ButtonVariant, buttonsColorPalette };
