import React, { Component } from "react";
import { connect } from "react-redux";

import { get, isEqual } from "lodash";

import { Containers } from "@tvg/atomic-ui/_static/BaseComponents";
import PromosComponent from "@tvg/promos";
import tvgConf from "@tvg/conf";

import { getAccountNumber, getResidenceState } from "@urp/store-selectors";

import sendGTMPageView from "../utils/gtm";

export class Promos extends Component {
  static defaultProps = {
    isLogged: false,
    currentPath: "",
    promoPage: "promos",
    returningUser: false,
    accountNumber: "",
    homeState: "",
    optedInPromos: {},
    initialRenderComplete: false,
    optinInfo: {
      promoId: "",
      state: ""
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pageViewSet: false
    };
    this.tvg = tvgConf();
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.isLogged !== this.props.isLogged ||
      !isEqual(nextProps.optedInPromos, this.props.optedInPromos) ||
      nextProps.currentPath !== this.props.currentPath ||
      nextProps.initialRenderComplete !== this.props.initialRenderComplete
    );
  }

  componentDidUpdate() {
    this.setPageView();
  }

  setPageView = () => {
    if (typeof window !== "undefined" && !this.state.pageViewSet) {
      sendGTMPageView(this.props);
      this.setState({ pageViewSet: true });
    }
  };

  setStoryblokSlug(location) {
    let slug = "promos";
    const displayFullPage = location.pathname.includes("/promos");
    const displayModal = location.hash === "#promos";
    const { pathname } = location;

    if (displayModal) {
      const { promoPage } = this.props;

      slug = promoPage === "promos" ? "promos" : promoPage;
    }

    if (displayFullPage) {
      slug =
        pathname.substr(-1) === "/" ? pathname.slice(1, -1) : pathname.slice(1);
    }

    return slug;
  }

  verifyPriorLogin() {
    let { returningUser } = this.props;

    if (typeof window === "object" && !returningUser) {
      returningUser = window.document.cookie
        .split(";")
        .some((item) => item.includes("hasLoginOnce=true"));
    }

    return returningUser;
  }

  tvg;

  renderStoryblokPromos() {
    const user = {
      isLogged: get(this.props, "isLogged", false),
      returningUser: this.verifyPriorLogin(),
      accountNumber: get(this.props, "accountNumber", ""),
      optedInPromos: get(this.props, "optedInPromos", {}),
      homeState: get(this.props, "homeState", "")
    };
    const { history } = this.props.router;
    const { location } = history;
    const storyBlokSlug = this.setStoryblokSlug(location);

    let isDesktop = true;
    if (typeof window !== "undefined" && get(window, "isReactNative", false)) {
      isDesktop = false;
    }

    return (
      <Containers.Page
        isFixed={false}
        data-qa-label="promosPage"
        style={{ flex: 1 }}
      >
        <PromosComponent
          slug={storyBlokSlug}
          user={user}
          history={history}
          isDesktop={isDesktop}
          optinInfo={get(this.props, "optinInfo", {})}
        />
      </Containers.Page>
    );
  }

  render() {
    return this.renderStoryblokPromos();
  }
}

const mapStateToProps = (store) => ({
  accountNumber: getAccountNumber(store),
  optedInPromos: get(store, "userData.optedInPromos", ""),
  homeState: getResidenceState(store),
  returningUser: get(store, "userData.returningUser", false),
  optinInfo: get(store, "app.optinInfo", {})
});

export default connect(mapStateToProps)(Promos);
