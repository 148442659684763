import _ from "lodash";

const parseElement = (value) => {
  let result = _.attempt(JSON.parse, value);

  if (_.isError(result)) {
    result = [];
  }
  return result;
};

export function toggleSideMenu(val) {
  return {
    type: "TOGGLE_SIDE_MENU",
    payload: val
  };
}
export function showBalanceDeposit() {
  return {
    type: "SHOW_BALANCE_DEPOSIT",
    payload: true
  };
}

export function hideBalanceDeposit() {
  return {
    type: "HIDE_BALANCE_DEPOSIT",
    payload: false
  };
}

/* this only request greyhounds for now */
export function successCmsRequest(cmsData) {
  return {
    type: "CMS_REQUEST_SUCCESS",
    payload: {
      profiles: _.has(cmsData, "greyhoundsProfiles")
        ? parseElement(cmsData.greyhoundsProfiles).profilesList
        : [],
      supportLink: _.get(cmsData, "globalTVGSupportLink", ""),
      supportMessage: _.get(cmsData, "globalTVG4SupportMessage", ""),
      menuItemsConfig: _.get(cmsData, "globalTVG4MenuItems", []),
      accountMenuTitles: _.get(cmsData, "globalTVG4AccountMenuTitles"),
      brazeMessages: _.get(cmsData, "brazeMessages", {}),
      loginErrorTitles: _.get(cmsData, "loginErrorTitles", {}),
      robotMessage: _.get(cmsData, "robotMessage", ""),
      credentialsRecoveryModal: _.get(cmsData, "credentialsRecoveryModal", {}),
      emailRecoveryModal: _.get(cmsData, "emailRecoveryModal", {}),
      pawsSubWalletsContent: _.get(cmsData, "pawsSubWalletsContent", [])
    }
  };
}

export function failCmsRequest(err) {
  return {
    type: "CMS_REQUEST_FAIL",
    payload: err
  };
}

export function successFeaturesRequest(cmsData) {
  const activeFeatures = {};
  cmsData.featureToggles.map((feature) => {
    const random = Math.floor(Math.random() * 100 + 1); // random number between 1 and 100
    const throttle = feature.percentage;
    let returnValue = false;
    if (feature.enabled && (random <= throttle || throttle === "100%")) {
      returnValue = true;
    }

    activeFeatures[feature.name] = returnValue;
    return returnValue;
  });

  return {
    type: "FEATURES_REQUEST_SUCCESS",
    payload: {
      features: activeFeatures
    }
  };
}

export function failFeaturesRequest(err) {
  return {
    type: "FEATURES_REQUEST_FAIL",
    payload: err
  };
}

export const abTestingChange = (feature, enabled) => ({
  type: "SET_ABTESTING_TOGGLE",
  payload: {
    featureName: feature,
    enabled
  }
});
