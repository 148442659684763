import GeoComply from "../GeoComply";

const geoComply = new GeoComply("tvg4");

export default (payload, brand, fn) => {
  const newfn = (geo) => fn(payload, geo);

  if (brand === "4njbets") {
    return geoComply.getGeoPacket(payload.account).then(newfn);
  }

  return fn(payload, {});
};
