import React from "react";
import { get, noop } from "lodash";
import formatCurrency from "@tvg/formatter/currency";

import Icon from "../../_static/Icons";
import buildColor from "../../_static/ColorPalette";
import { eyeHide, eyeShow } from "../../_static/Icons/icons";

import {
  BetCardHeader,
  HeaderColumn,
  HeaderText,
  HeaderTitle,
  HideShowContainer,
  HideShowLabel
} from "./styled-components";
import WagerHeaderStatus from "../WagerHeaderStatus";

const MyBetsWagerHeader = (props) => {
  const {
    isOptedIn,
    betStatus,
    isCanceled,
    wager,
    probableValue,
    onApproxPayout,
    onHideShowLegsGTM,
    showContent,
    setShowContent,
    betRefund,
    isBetWinner,
    shouldShowPotentialReturn,
    shouldShowHideLegButton,
    shouldShowRebet,
    qaLabel,
    betsNumber,
    showMinMaxWillPays,
    minWillPays,
    maxWillPays,
    isFDR
  } = props;

  return (
    <BetCardHeader
      data-qa-label={qaLabel}
      hasBorderBottom={showContent && (!isOptedIn || !shouldShowRebet)}
      hasRoundBorderBottom={!showContent && !shouldShowRebet}
      isBigHeader={!!betRefund && betStatus === "winner"}
      isCenter={!betRefund || betStatus !== "winner"}
    >
      <HeaderColumn data-qa-label={`${qaLabel}-info`} betStatus={betStatus}>
        <HeaderText qalabel={`${qaLabel}-text`} isCanceled={isCanceled}>
          {formatCurrency(wager.wagerAmount || 0)}{" "}
          {get(wager, "wagerType.name", "")}
          {betsNumber > 1 && ` (x${betsNumber})`}
        </HeaderText>
        <HeaderTitle qaLabel={`${qaLabel}-title`} isCanceled={isCanceled}>
          {formatCurrency((wager.betTotal || 0) * betsNumber)} ticket
        </HeaderTitle>
      </HeaderColumn>
      <HeaderColumn data-qa-label={`${qaLabel}-status-wrapper`}>
        <WagerHeaderStatus
          betStatus={get(wager, "betStatus.name", "")}
          winningsAmount={get(wager, "winningsAmount", 0)}
          probableValue={probableValue}
          onApproxPayout={onApproxPayout}
          betRefund={betRefund}
          isBetWinner={isBetWinner}
          shouldShowPotentialReturn={shouldShowPotentialReturn}
          showMinMaxWillPays={showMinMaxWillPays}
          minWillPays={minWillPays}
          maxWillPays={maxWillPays}
          isFDR={isFDR}
        />
        {shouldShowHideLegButton && (
          <HideShowContainer
            onClick={() => {
              onHideShowLegsGTM(!showContent);
              setShowContent(!showContent, get(wager, "id"));
            }}
          >
            <Icon
              icon={showContent ? eyeHide : eyeShow}
              size={16}
              color={buildColor("blue_accent", "100")}
              stroke={buildColor("blue_accent", "500")}
            />
            <HideShowLabel>{showContent ? "Hide" : "Show"} legs</HideShowLabel>
          </HideShowContainer>
        )}
      </HeaderColumn>
    </BetCardHeader>
  );
};

MyBetsWagerHeader.defaultProps = {
  isOptedIn: false,
  betStatus: "active",
  isCanceled: false,
  wager: {},
  probableValue: "",
  onApproxPayout: noop,
  onHideShowLegsGTM: noop,
  showContent: true,
  setShowContent: noop,
  betRefund: 0,
  isBetWinner: false,
  shouldShowPotentialReturn: false,
  shouldShowHideLegButton: false,
  qaLabel: "my-bets-wager-header",
  betsNumber: 1,
  isFDR: false
};
export default MyBetsWagerHeader;
