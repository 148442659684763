import styled, { css } from "styled-components";
import type { StyledProps } from "./types";

export const BetTypesButton = styled.button<StyledProps>`
  height: 100%;
  padding: var(--fd-space-space-5) var(--fd-space-space-3);
  background-color: var(--fd-colors-background-surface);
  border-bottom: var(--fd-border-widths-border-width-020) solid
    var(--fd-colors-background-surface);

  &:hover {
    background-color: var(--fd-colors-background-hover);
    border-bottom-color: var(--fd-colors-background-hover);
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-bottom-color: var(--fd-colors-content-link);
    `};
`;

export const BetTypesText = styled.p<StyledProps>`
  color: var(--fd-colors-content-default);
  font-family: Roboto-Medium, sans-serif;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: var(--fd-colors-content-link);
    `};
`;
