import { find, get } from "lodash";

export const hasWagerProfileException = (tvgMenus, userProfileType) => {
  try {
    let wagerMenu;
    tvgMenus.forEach((menu) => {
      if (!wagerMenu) {
        wagerMenu = find(menu.subMenus, { subQaLabel: "wagerRewardsButton" });
      }
    });

    const profileTypeException = get(
      wagerMenu,
      "options.profileTypeException",
      []
    );
    return profileTypeException.includes(userProfileType);
  } catch (error) {
    return false;
  }
};

export default hasWagerProfileException;
