import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  > div > button {
    border: 1px solid
      var(--fd-colors-component-selection-control-background-active);
  }
`;

export const ButtonGroup = styled.div<{
  hasMaxWidth?: boolean;
  isSimpleBetButtons?: boolean;
}>`
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  box-shadow: var(--fd-shadows-elevations-bottom-low);
  overflow: hidden;
  ${({ hasMaxWidth, isSimpleBetButtons }) =>
    hasMaxWidth &&
    css`
      width: ${isSimpleBetButtons ? "151px" : "220px"};
    `}

  button {
    border-top: 1px solid
      var(--fd-colors-component-selection-control-background-active);
    border-bottom: 1px solid
      var(--fd-colors-component-selection-control-background-active);
    border-right: 1px solid
      var(--fd-colors-component-selection-control-background-active);
  }

  &:first-child {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    border-left: 1px solid
      var(--fd-colors-component-selection-control-background-active);
  }
`;
