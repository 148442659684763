import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { get, isEqual, flowRight as compose } from "lodash";
import { Helmet } from "react-helmet";
import StoryblokService from "@tvg-mar/storyblok-bridge";
import gtmUtils from "@tvg-mar/utils/gtmEventHandlers";
import Poller from "@tvg/poller";
import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import { graphql } from "@apollo/client/react/hoc";

import Page from "@tvg-mar/tvg-promos-atomic-ui/_templates/Page";
import HomePage from "@tvg-mar/tvg-promos-atomic-ui/_templates/HomePage";
import SeoPage from "@tvg-mar/tvg-promos-atomic-ui/_templates/SeoPage";
import LeaderboardPage from "@tvg-mar/tvg-promos-atomic-ui/_templates/LeaderboardPage";
import Error from "@tvg-mar/tvg-promos-atomic-ui/_molecules/Error";
import Context from "@tvg-mar/promos-context";

import { isFDR } from "@tvg/utils/generalUtils";
import CPPService from "@tvg/sh-cpp";

import ErrorPageContainer from "./styled-components";

import ComingUpRacesQuery from "./graphql/queries/ComingUpRaces";
import ApolloOptions from "./graphql/options.graph";

import {
  isPromoAlreadyLoaded,
  parsePromosToRedux,
  shouldUpdatePromos
} from "./utils";
import {
  getAllLeaderBoardStandings,
  getLeaderBoardStandingsByPagination,
  getUserLeaderBoardStandings,
  fetchLeaderboardAllRaces
} from "./service";
import { setPromosAction, setSinglePromoAction } from "./actions";

const { PathContext } = Context;

export class PromosComponent extends Component {
  editorPoller;

  editorPollerInterval;

  bridge;

  cppService;

  isCPPEnabled;

  tvg;

  SEO_TEMPLATE_NAME;

  LEADERBOARD_TEMPLATE_NAME;

  constructor(props) {
    super(props);

    this.bridge = new StoryblokService();
    this.cppService = new CPPService();
    this.tvg = tvgConf();
    this.editorPoller = new Poller();
    this.editorPollerInterval = 1000; // 1 second
    this.SEO_TEMPLATE_NAME = "seo_template";
    this.LEADERBOARD_TEMPLATE_NAME = "leaderboard_template";
    this.isCPPEnabled = isFDR() && props.enableAWFlows && props.enableCPPPromos;

    const { promos, slug } = this.props;

    if (isPromoAlreadyLoaded(slug, promos) && !this.isCPPEnabled) {
      this.state = {
        content: promos[slug].content,
        currentSlug: slug,
        showErrorPage: false,
        isLoading: false,
        storyExists: true,
        isLoadingStandingsError: false,
        isLoadingStandingsPageError: false,
        isFetching: false
      };
    } else {
      this.state = {
        content: {
          body: [],
          component: "page",
          _uid: "12345",
          promo_type: {
            _uid: "67890",
            promo_id: 0,
            segment: "all",
            promoCode: "",
            enablePromoCode: false
          },
          seo_meta_data: {
            _uid: "12345",
            title: "",
            description: ""
          }
        },
        currentSlug: slug,
        showErrorPage: false,
        isLoading: true,
        storyExists: true,
        isLoadingStandingsError: false,
        isLoadingStandingsPageError: false,
        isFetching: false
      };
    }
  }

  updatePromosContent = (data) => {
    const { slug } = this.props;
    this.setContent(data.payload?.slug ?? slug, true);
  };

  componentDidMount() {
    const { slug, promos, dispatch, user } = this.props;
    const { accountNumber, isLogged } = user;
    const isTvgDesktop =
      get(this.tvg, "product") === "tvg4" ||
      get(this.tvg, "product") === "tvg5";
    const isPromoDataStoreSet = isPromoAlreadyLoaded(slug, promos);

    mediator.base.subscribe("UPDATE_PROMOS_CONTENT", this.updatePromosContent);

    mediator.base.dispatch({
      type: "TVG_LOGIN:GET_USER_PROMOS"
    });

    if (typeof window !== "undefined") {
      if (tvgConf().environment !== "production") {
        this.editorPoller.start(this.initEditor, this.editorPollerInterval);
      }

      // Required to display new content to user else cached MEP bundle content will be displayed
      if (!isPromoDataStoreSet || !isTvgDesktop || this.isCPPEnabled) {
        this.getContent(slug);
      }

      if (isPromoDataStoreSet) {
        const isLeaderBoard =
          get(promos[slug], `content.component`) ===
          this.LEADERBOARD_TEMPLATE_NAME;

        if (isLeaderBoard) {
          const content = get(promos[slug], `content`);
          getAllLeaderBoardStandings(
            content,
            dispatch,
            this.setState.bind(this)
          );
          fetchLeaderboardAllRaces(content, dispatch);

          if (isLogged && accountNumber !== "") {
            getUserLeaderBoardStandings(
              user,
              content,
              dispatch,
              this.setState.bind(this)
            );
          }
        }
      }
    }
  }

  componentWillUnmount() {
    mediator.base.unsubscribe("UPDATE_PROMO_CONTENT", this.updatePromosContent);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props.user, nextProps.user) ||
      !isEqual(this.state.content, nextState.content) ||
      !isEqual(this.props.promos, nextProps.promos) ||
      !isEqual(
        this.props.allLeaderBoardStandings,
        nextProps.allLeaderBoardStandings
      ) ||
      !isEqual(
        this.props.allLeaderBoardStandingsPages,
        nextProps.allLeaderBoardStandingsPages
      ) ||
      !isEqual(
        this.props.userLeaderBoardStandings,
        nextProps.userLeaderBoardStandings
      ) ||
      this.state.currentSlug !== nextState.currentSlug ||
      this.props.slug !== nextProps.slug ||
      this.state.showErrorPage !== nextState.showErrorPage ||
      this.state.isLoading !== nextState.isLoading ||
      !isEqual(this.props.comingUpRaces, nextProps.comingUpRaces) ||
      this.props.comingUpRacesLoading !== nextProps.comingUpRacesLoading ||
      this.state.isLoadingStandingsError !==
        nextState.isLoadingStandingsError ||
      this.state.isLoadingStandingsPageError !==
        nextState.isLoadingStandingsPageError ||
      !isEqual(this.props.allRaces, nextProps.allRaces)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { slug, promos, history, user, dispatch } = this.props;
    const { accountNumber, isLogged } = user;
    const { currentSlug, content } = this.state;
    const { hash } = history.location;
    const isTvgDesktop =
      get(this.tvg, "product") === "tvg4" ||
      get(this.tvg, "product") === "tvg5";
    const isNative = get(this.tvg, "product") === "iosnative";
    const isLeaderBoard =
      get(content, `component`) === this.LEADERBOARD_TEMPLATE_NAME;

    if (slug !== prevState.currentSlug && (isTvgDesktop || isNative)) {
      this.setContent(slug);
    }

    // Execute on tvg4 or MEP modal to avoid duplicate page loads events on content updates
    if (
      currentSlug !== prevState.currentSlug &&
      (isTvgDesktop || hash === "#promos" || isNative)
    ) {
      this.setGTMPageViewEvent(currentSlug);
    }

    const promoContent = get(promos[currentSlug], `content`);

    if (
      !isEqual(this.state.content, prevState.content) ||
      !isEqual(this.props.promos, prevProps.promos) ||
      !isEqual(this.props.comingUpRaces, prevProps.comingUpRaces) ||
      !isEqual(this.props.allRaces, prevProps.allRaces)
    ) {
      fetchLeaderboardAllRaces(promoContent, dispatch);
    }

    if (
      !isEqual(this.state.content, prevState.content) ||
      !isEqual(this.props.promos, prevProps.promos) ||
      !isEqual(
        this.props.allLeaderBoardStandings,
        prevProps.allLeaderBoardStandings
      )
    ) {
      getAllLeaderBoardStandings(
        promoContent,
        dispatch,
        this.setState.bind(this)
      );
    }

    if (
      prevProps.user.accountNumber !== this.props.user.accountNumber &&
      isLeaderBoard &&
      isLogged &&
      accountNumber !== ""
    ) {
      getUserLeaderBoardStandings(
        user,
        content,
        dispatch,
        this.setState.bind(this)
      );
    }
  }

  setGTMPageViewEvent(slug) {
    const { user, appVersion } = this.props;
    let platform =
      get(this.tvg, "product") === "ios2" ||
      get(this.tvg, "product") === "iosnative"
        ? "ios"
        : "web";
    const mobile = get(this.tvg, "device") === "mobile";
    const loginStatus = get(user, "isLogged", false)
      ? "Logged In"
      : "Logged Out";
    const registrationStatus = get(user, "returningUser", false)
      ? "Registered"
      : "Unregistered";
    let siteVersion;

    if (
      this.tvg.product === "androidwrapper" ||
      this.tvg.product === "tvgandroid"
    ) {
      platform = "android";
    }

    if (mobile && platform !== "ios" && platform !== "android") {
      siteVersion = "v2-revamp";
    } else if (mobile && platform === "ios") {
      siteVersion = "iOS";
    } else if (mobile && platform === "android") {
      if (this.tvg.product === "androidwrapper") {
        siteVersion = "tvg-android-gps";
      } else {
        siteVersion = "tvg-android";
      }
    } else {
      siteVersion = this.tvg.product === "tvg4" ? "TVG4" : "TVG5";
    }

    mediator.base.dispatch({
      type: "PROMOS_PAGEVIEW",
      payload: {
        siteVersion,
        appVersion,
        productVersion: "Promos",
        accountId: get(user, "accountNumber", undefined),
        residenceState: get(user, "homeState", undefined),
        loginStatus,
        registrationStatus,
        page: slug
      }
    });
  }

  setContent(slug, forceRequest = false) {
    const { promos } = this.props;

    if (isPromoAlreadyLoaded(slug, promos) && !this.isCPPEnabled) {
      this.setState({
        content: promos[slug].content,
        currentSlug: slug,
        isLoading: false
      });
    } else {
      this.setState({
        isLoading: true,
        storyExists: true
      });
      this.getContent(slug, forceRequest);
    }
  }

  async getContent(slug, forceRequest = false) {
    const isPromoHomepage = slug === "promos";

    let updatedSlug = slug;
    let promoContent = this.state.content;

    if (isFDR() && !isPromoHomepage) {
      updatedSlug = slug.replace("promos/", "");
    }

    try {
      if (isPromoHomepage && !this.state.isFetching) {
        this.setState({ isFetching: true });
        const [storyblokPromos, cppPromos] = await Promise.allSettled([
          this.bridge.get(`cdn/stories/${updatedSlug}`, {
            resolve_relations: "global_ref.reference"
          }),
          this.isCPPEnabled ? this.cppService.getPromos() : []
        ]);

        const { content } = storyblokPromos.value.data.story;

        const bodyContent = content.body.map((promo) => {
          if (promo.component === "all_promos") {
            return {
              ...promo,
              slots: [...(cppPromos.value ?? []), ...(promo?.slots ?? [])]
            };
          }

          return promo;
        });

        promoContent = {
          ...content,
          body: bodyContent
        };

        this.bridge.initEditor(this);
        this.props.dispatch(
          setSinglePromoAction(storyblokPromos.value.data.story)
        );

        return;
      }

      if (
        this.isCPPEnabled &&
        !this.state.isFetching &&
        (!this.cppService.servicePromos.length || forceRequest)
      ) {
        await this.cppService.getPromos().catch(console.error);
      }

      const isCPPPromo =
        this.isCPPEnabled && this.cppService.hasPromoSlug(updatedSlug);

      if (isCPPPromo) {
        promoContent = this.cppService.getMappedContentForPromo(updatedSlug);
      } else {
        promoContent = await this.bridge
          .get(`cdn/stories/${updatedSlug}`, {
            resolve_relations: "global_ref.reference"
          })
          .then((resp) => resp.data.story.content);

        this.bridge.initEditor(this);
      }

      // eslint-disable-next-line consistent-return
      return promoContent;
    } catch (error) {
      const status = get(error, "response.status", 400);

      if (status === 404) {
        this.setState({ storyExists: false });
      }

      this.setState({
        showErrorPage: true,
        isLoading: false
      });
    } finally {
      this.setState({
        content: promoContent,
        currentSlug: slug,
        isLoading: false,
        isFetching: false
      });
    }
  }

  setMetaData = () => {
    const { content } = this.state;
    const isDesktop = get(this.tvg, "device") === "desktop";
    const isMobile =
      get(this.tvg, "device") === "mobile" ||
      get(this.tvg, "device") === "tablet";
    const isOnSSR = typeof window === "undefined";
    const isSeoTemplate = content.component === this.SEO_TEMPLATE_NAME;

    if (!isSeoTemplate && !isOnSSR && (isMobile || isDesktop)) {
      return (
        <Helmet>
          <title>{get(content, "seo_meta_data.title", "")}</title>
          <meta
            name="description"
            content={get(content, "seo_meta_data.description", "")}
          />
        </Helmet>
      );
    }

    return null;
  };

  initEditor = () => {
    if (get(window, "storyblok")) {
      this.bridge.initEditor(this);
      this.editorPoller.stop();
    }
  };

  // Leaving this method if in any case in the future is need to be inserted inside a poller or so
  updateClientPromos = () =>
    this.bridge.get(`cdn/stories`).then((resp) => {
      const { stories } = resp.data;
      const { promos, dispatch } = this.props;
      const { currentSlug } = this.state;

      if (shouldUpdatePromos(stories, promos)) {
        const parsedPromos = parsePromosToRedux(stories);

        // Update current content and update redux store
        this.setState({
          content: parsedPromos[currentSlug].content
        });
        dispatch(setPromosAction(parsedPromos));
      }
    });

  handleGTMEvents = (urlLink, linkLabel, pageOrigin) => {
    gtmUtils.handleNavigationDataLayerUpdate(linkLabel, pageOrigin, urlLink);
    gtmUtils.handleSiteClickDataLayerUpdate({
      linkLabel,
      urlLink
    });
  };

  handlePromosError = (isModal, isDesktop = false, isNative = false) => {
    let urlLink;
    const linkLabel = get(this.props, "errorBtnTextPromos");
    const pageOrigin = "promo offer page";

    this.setContent("promos");
    this.setState({
      showErrorPage: false,
      isLoading: true
    });

    if (isDesktop) {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: "/promos" }
      });
      urlLink = "/promos";
    } else {
      if (!isModal || isNative) {
        this.props.history.push("/promos");
        urlLink = "/promos";
      } else {
        this.props.history.push("/more#promos");
        urlLink = "/more#promos";
      }
      this.setContent("promos");
    }
    this.handleGTMEvents(urlLink, linkLabel, pageOrigin);
  };

  handleStoryblokError = (isModal, isDesktop = false) => {
    const urlLink = "/";
    const linkLabel = get(this.props, "errorBtnTextHome");
    const pageOrigin = "promo hub landing";

    if (isModal) {
      mediator.base.dispatch({
        type: "CLOSE_PROMOS_MODAL"
      });
    }

    this.handleGTMEvents(urlLink, linkLabel, pageOrigin);
    this.setState({
      showErrorPage: false,
      isLoading: true
    });

    if (isDesktop) {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: urlLink }
      });
    } else {
      this.props.history.push(urlLink);
    }
  };

  handleErrorBtnClick = () => {
    const { storyExists } = this.state;
    const hashValue = get(this.props.history, "location.hash", "");
    const isNative = this.tvg.product === "iosnative";
    const isModal = hashValue === "#promos" || isNative;
    const { isDesktop } = this.props;

    return !storyExists
      ? this.handlePromosError(isModal, isDesktop, isNative)
      : this.handleStoryblokError(isModal, isDesktop);
  };

  getContestPageData = (round, page) => {
    const { promos, dispatch, allLeaderBoardStandingsPages } = this.props;
    const { currentSlug } = this.state;

    if (allLeaderBoardStandingsPages[round][page] === undefined) {
      getLeaderBoardStandingsByPagination(
        get(promos[currentSlug], "content"),
        dispatch,
        round,
        page,
        this.setState.bind(this)
      );
    }
  };

  renderPromosPage = () => {
    const {
      user,
      history,
      allLeaderBoardStandings,
      allLeaderBoardStandingsDates,
      allLeaderBoardStandingsPages,
      userLeaderBoardStandings,
      leaderBoardCurrentPage,
      leaderBoardCurrentContestRound,
      isLoadingStandings,
      comingUpRaces,
      comingUpRacesLoading,
      allRaces,
      allRacesLoading,
      optinInfo,
      isMTPNewRules
    } = this.props;

    const {
      content,
      currentSlug,
      isLoading,
      isLoadingStandingsError,
      isLoadingStandingsPageError
    } = this.state;
    // this.props.isDesktop comes from the promos-standalone and it's false if
    // the app is being used in react native
    const isMobile =
      get(this.tvg, "device") === "mobile" ||
      !get(this.props, "isDesktop", true);
    const isDesktop =
      get(this.tvg, "device") === "desktop" &&
      get(this.props, "isDesktop", true);

    const isSeoTemplate = content.component === this.SEO_TEMPLATE_NAME;
    const isLeaderboardTemplate =
      content.component === this.LEADERBOARD_TEMPLATE_NAME;

    return (
      <Fragment>
        <PathContext.Provider
          value={{
            currentSlug,
            setContent: this.setContent
          }}
        >
          {isSeoTemplate && (
            <SeoPage
              content={content}
              user={user}
              isMobile={isMobile}
              isLoading={isLoading}
            />
          )}
          {isLeaderboardTemplate && (
            <LeaderboardPage
              content={content}
              user={user}
              isDesktop={isDesktop}
              history={history}
              isLoading={isLoading}
              isLoadingStandings={
                isLoadingStandings && !isLoadingStandingsError
              }
              isLoadingStandingsError={isLoadingStandingsError}
              isLoadingStandingsPageError={isLoadingStandingsPageError}
              contestStandingsDates={allLeaderBoardStandingsDates}
              contestStandings={allLeaderBoardStandings}
              contestStandingsPages={allLeaderBoardStandingsPages}
              userStandings={userLeaderBoardStandings}
              currentPage={leaderBoardCurrentPage}
              currentContestEvent={leaderBoardCurrentContestRound}
              isOpted={get(
                user.optedInPromos,
                content.promo_type.promo_id,
                false
              )}
              optinInfo={optinInfo}
              comingUpRaces={comingUpRaces}
              comingUpRacesLoading={comingUpRacesLoading}
              allRaces={allRaces}
              allRacesLoading={allRacesLoading}
              getContestPageData={this.getContestPageData}
              isMTPNewRules={isMTPNewRules}
            />
          )}
          {!isSeoTemplate &&
            !isLeaderboardTemplate &&
            (currentSlug !== "promos" ? (
              <Page
                content={content}
                user={user}
                isDesktop={isDesktop}
                mobile={isMobile}
                promoId={content.promo_type.promo_id}
                promoCode={content.promo_type.promoCode}
                promoType={content.promo_type.segment}
                isOpted={
                  get(user.optedInPromos, content.promo_type.promo_id, false) ||
                  get(content, "isOptedIn", false)
                }
                history={history}
                isLoading={isLoading}
                oldPromosURLs={this.props.oldPromosURLs}
                optinInfo={this.props.optinInfo}
                cppService={this.cppService}
                onOptIn={() => {
                  if (this.props.enableCPPPromos) {
                    this.getContent(this.state.currentSlug);
                  }
                }}
                getPromos={async (slug, forceRequest) =>
                  this.getContent(slug, forceRequest)
                }
              />
            ) : (
              <HomePage
                content={content}
                user={user}
                mobile={isMobile}
                isLoading={isLoading}
              />
            ))}
        </PathContext.Provider>
      </Fragment>
    );
  };

  renderErrorPage = () => {
    const {
      isDesktop,
      errorTitle,
      errorDescription,
      errorBtnTextHome,
      errorBtnTextPromos,
      history
    } = this.props;
    const hashValue = get(history, "location.hash", "");
    const isModal =
      hashValue === "#promos" || tvgConf()?.product === "iosnative";

    return (
      <ErrorPageContainer isModal={isModal} isDesktop={isDesktop} centerItems>
        <Error
          title={errorTitle}
          description={errorDescription}
          buttonText={
            this.state.storyExists ? errorBtnTextHome : errorBtnTextPromos
          }
          onClick={this.handleErrorBtnClick}
        />
      </ErrorPageContainer>
    );
  };

  render() {
    const { showErrorPage } = this.state;
    const { history } = this.props;
    const hashValue = get(history, "location.hash", "");
    const isModal =
      hashValue === "#promos" || tvgConf()?.product === "iosnative";

    return (
      <Fragment>
        {!showErrorPage && !isModal && this.setMetaData()}
        {!showErrorPage && this.renderPromosPage()}
        {showErrorPage && this.renderErrorPage()}
      </Fragment>
    );
  }
}

PromosComponent.defaultProps = {
  errorTitle: "",
  errorDescription: "",
  errorBtnTextPromos: "",
  errorBtnTextHome: "",
  appVersion: ""
};

const mapStateToProps = (store) => ({
  promos: get(store, "promos.stories", {}),
  appVersion: get(store, "ios.init.appVersion", ""),
  oldPromosURLs: get(store, "capi.messages.oldPromosURLs", []),
  errorTitle: get(store, "capi.messages.promosErrorPageTitle", ""),
  errorDescription: get(store, "capi.messages.promosErrorPageDescription", ""),
  errorBtnTextPromos: get(
    store,
    "capi.messages.promosErrorPageButtonTextToPromos",
    ""
  ),
  errorBtnTextHome: get(
    store,
    "capi.messages.promosErrorPageButtonTextToHome",
    ""
  ),
  allLeaderBoardStandingsDates: get(
    store,
    "promos.leaderBoardData.contestStandingsDates",
    []
  ),
  allLeaderBoardStandings: get(
    store,
    "promos.leaderBoardData.contestStandings",
    []
  ),
  allLeaderBoardStandingsPages: get(
    store,
    "promos.leaderBoardData.contestStandingsPages",
    []
  ),
  userLeaderBoardStandings: get(
    store,
    "promos.leaderBoardData.userStandings",
    []
  ),
  isLoadingStandings: get(
    store,
    "promos.leaderBoardData.isLoadingStandings",
    true
  ),
  allRaces: get(store, "promos.leaderBoardData.allRaces"),
  allRacesLoading: get(store, "promos.leaderBoardData.allRacesLoading", true),
  isMTPNewRules: get(store, "capi.featureToggles.MTPColoursRules", false),
  enableCPPPromos: get(store, "capi.featureToggles.enableCPPPromos", false),
  enableAWFlows: get(store, "capi.featureToggles.enableAWFlows", false)
});

export default compose(
  connect(mapStateToProps),
  graphql(ComingUpRacesQuery, {
    ...ApolloOptions
  })
)(PromosComponent);
