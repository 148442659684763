export const openLocationRevalidate = () => ({
  type: "OPEN_LOCATION_REVALIDATE"
});

export const setLocationRevalidateFailure = () => ({
  type: "SET_LOCATION_REVALIDATE_FAILURE"
});

export const closeLocationRevalidate = () => ({
  type: "CLOSE_LOCATION_REVALIDATE"
});
