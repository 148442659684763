import styled, { css } from "styled-components";
import { space } from "styled-system";
import type { ButtonContainerProps } from "./types";

export const ButtonContainer = styled.div<ButtonContainerProps>`
  ${space};
  display: flex;
  position: relative;
  flex-direction: row;
  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      flex-grow: 1;
      flex-basis: 0;
      flex-shrink: 0;
    `}
`;

export const StyledButton = styled.button<{
  hasBorderRadius: boolean;
  hasShadow: boolean;
  isFullWidth: boolean;
}>`
  ${space};
  display: flex;
  align-self: flex-start;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  height: 42px;
  background-color: var(
    --fd-colors-component-selection-control-background-base
  );
  cursor: pointer;
  border: inherit;
  color: var(--fd-colors-link-default-base);
  padding: 0 16px;
  ${({ hasBorderRadius }) =>
    hasBorderRadius &&
    css`
      border-radius: 2px;
    `}
  ${({ hasShadow }) =>
    hasShadow &&
    css`
      box-shadow: var(--fd-shadows-elevations-bottom-low);
    `}
  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      flex-grow: 1;
      flex-basis: 0;
      flex-shrink: 0;
      padding: 0;
    `}

  @media (hover: hover) {
    &:hover {
      background-color: var(
        --fd-colors-component-selection-control-background-hover
      );
    }

    &[data-force-hover="true"] {
      background-color: var(
        --fd-colors-component-selection-control-background-hover
      );
    }
  }

  &[data-active="true"] {
    background-color: var(
      --fd-colors-component-selection-control-background-selected
    );
    color: var(--fd-colors-link-inverse-default-base);

    > div > p {
      color: var(--fd-colors-link-inverse-default-base);
    }

    svg {
      path {
        stroke: var(--fd-colors-link-inverse-default-base);
      }
    }

    @media (hover: hover) {
      &:hover {
        background: var(
          --fd-colors-component-selection-control-background-active
        );
      }
    }

    &::after {
      position: absolute;
      right: 0;
      content: "";
      height: 100%;
      width: 0.5px;
      background: var(--fd-colors-component-selection-control-background-base);
    }
  }

  &:active {
    background-color: var(
      --fd-colors-component-selection-control-background-active
    );

    > div > p {
      color: var(--fd-colors-link-inverse-default-base);
    }

    svg {
      path {
        stroke: var(--fd-colors-link-inverse-default-base);
      }
    }
  }
`;

export const ButtonText = styled.p`
  font-family: "Roboto-medium", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 125%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
