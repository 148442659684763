import React from "react";
import { noop } from "lodash";
import Icon from "../../_static/Icons";
import { infoOutline } from "../../_static/Icons/icons";
import { close } from "../../_static/Icons/iconsDuotone";

import {
  CloseNotification,
  Notification,
  NotificationText,
  NotificationTitle,
  NotificationWrapper,
  RulesButton,
  CancelBetRulesContainer,
  NotificationTopBorder,
  NotificationLeftBorder,
  NotificationRightBorder
} from "./styled-components";

function BetCancelNotification(props) {
  const {
    qaLabel,
    color,
    colorBackground,
    colorBorder,
    colorList,
    iconElement,
    viewBoxSize,
    iconColor,
    strokeColor,
    title,
    text,
    textFontSize,
    showCloseButton,
    showNotificationFn,
    showRulesLink,
    showRulesFn,
    useLargerWidth,
    showCancelRulesFn,
    showCancelRules,
    hasTopBorder,
    titleIsBold
  } = props;

  return (
    <Notification
      data-qa-label={qaLabel}
      color={color}
      colorBackground={colorBackground}
      colorBorder={colorBorder}
    >
      {useLargerWidth && <NotificationLeftBorder colorBorder={colorBorder} />}
      {useLargerWidth && <NotificationRightBorder colorBorder={colorBorder} />}
      {(useLargerWidth || hasTopBorder) && (
        <NotificationTopBorder colorBorder={colorBorder} />
      )}

      <NotificationWrapper data-qa-label={`${qaLabel}-wrapper`}>
        <NotificationTitle
          data-qa-label={`${qaLabel}-title`}
          titleIsBold={titleIsBold}
        >
          {colorList && colorList.length > 0 ? (
            <Icon
              icon={iconElement}
              colorList={colorList}
              size={14}
              viewBoxSize={viewBoxSize}
            />
          ) : (
            <Icon
              icon={iconElement}
              color={iconColor || color}
              size={14}
              viewBoxSize={viewBoxSize}
              stroke={strokeColor}
            />
          )}
          <div data-qa-label={`${qaLabel}-rules-title-text`}>{title}</div>
        </NotificationTitle>
        {typeof text === "string" && (
          <NotificationText
            data-qa-label={`${qaLabel}-text`}
            textFontSize={textFontSize}
          >
            {text}
          </NotificationText>
        )}
        {Array.isArray(text) &&
          text.map((elemText, index) => (
            <NotificationText
              data-qa-label={`${qaLabel + index}-text`}
              textFontSize={textFontSize}
            >
              {elemText}
            </NotificationText>
          ))}
        {showRulesLink && showRulesFn && (
          <RulesButton
            data-qa-label={`${qaLabel}-rules-link`}
            onClick={() => showRulesFn(true)}
            colorBackground={colorBackground}
          >
            See track rules
          </RulesButton>
        )}
        {showCancelRules && showCancelRulesFn && (
          <CancelBetRulesContainer>
            <RulesButton
              data-qa-label={`${qaLabel}-rules-link`}
              onClick={() => showCancelRulesFn(true)}
              colorBackground="transparent"
              fontFamily="inherit"
              color={color}
            >
              See rules
            </RulesButton>
            <span data-qa-label={`${qaLabel}-rules-link-text`}>
              &nbsp;for more details
            </span>
          </CancelBetRulesContainer>
        )}
      </NotificationWrapper>
      {showCloseButton && showNotificationFn && (
        <CloseNotification
          data-qa-label={`${qaLabel}-close`}
          onClick={() => showNotificationFn(false)}
          colorBackground={colorBackground}
        >
          <Icon icon={close} stroke={color} size={16} viewBoxSize={16} />
        </CloseNotification>
      )}
    </Notification>
  );
}

BetCancelNotification.defaultProps = {
  colorList: [],
  viewBoxSize: 1024,
  iconElement: infoOutline,
  strokeColor: "",
  iconColor: "",
  qaLabel: "bet-notification",
  showNotificationFn: noop,
  showCloseButton: false,
  showRulesLink: false,
  showRulesFn: noop,
  textFontSize: "large",
  useLargerWidth: false,
  hasTopBorder: false,
  titleIsBold: true
};

export default BetCancelNotification;
