export const setPromosAction = (stories) => ({
  type: "SET_PROMOS",
  payload: stories
});

export const setSinglePromoAction = (story) => {
  const promo = story;
  // since we are mapping homepage as just "promos" this is just a fallback to make sure it handles as it should
  if (promo.full_slug === "promos/") {
    promo.full_slug = "promos";
  }

  return {
    type: "SET_PROMO",
    payload: promo
  };
};

export const setOverallLeaderBoardStandingsAction = ({
  beginDate,
  endDate,
  optInBeginDate,
  optInEndDate,
  leaderBoardDataResponses
}) => {
  return {
    type: "SET_OVERALL_LEADERBOARD_STANDINGS",
    payload: {
      beginDate,
      endDate,
      optInBeginDate,
      optInEndDate,
      leaderBoardDataResponses
    }
  };
};

export const setLeaderBoardStandingsPageAction = (
  round,
  page,
  standingsData
) => ({
  type: "SET_LEADERBOARD_STANDINGS_PAGE",
  payload: {
    round,
    page,
    standingsData
  }
});

export const setUserLeaderBoardStandingsAction = (standingsData) => ({
  type: "SET_USER_LEADERBOARD_STANDINGS",
  payload: standingsData
});

export const setLeaderBoardAllRacesAction = (races) => ({
  type: "SET_LEADERBOARD_ALL_RACES",
  payload: races
});
