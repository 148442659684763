import React, { Component } from "react";
import {
  unmountComponentAtNode,
  // eslint-disable-next-line
  unstable_renderSubtreeIntoContainer
} from "react-dom";
import Modal from "./Modal";

/**
 * TVG Modal Component
 *
 * This component Allow to show a modal with custom content.
 */
class ModalPortal extends Component {
  static defaultProps = {
    autoOpen: false,
    alwaysOpen: false,
    type: "regular",
    qaLabel: ""
  };

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: props.autoOpen || props.alwaysOpen || false,
      isClosing: false
    };
  }
  state;

  componentDidMount() {
    this.portal = document.getElementById(
      `REACT_MODAL_ELEMENT_${this.props.modalIdentifier}`
    );

    const modalParent =
      typeof this.props.modalParent === "string"
        ? document.querySelector(this.props.modalParent)
        : this.props.modalParent;

    this.modalParent = modalParent || document.body;

    if (!this.portal) {
      this.portal = this.createElement();

      this.modalParent.appendChild(this.portal);
    }

    if (this.state.isModalOpen) {
      unstable_renderSubtreeIntoContainer(
        this,
        this.renderModal(this.props),
        this.portal
      );
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isModalOpen) {
      if (!this.state.isModalOpen) {
        this.setHandlers();
      }

      if (nextProps !== this.props) {
        unstable_renderSubtreeIntoContainer(
          this,
          this.renderModal(nextProps),
          this.portal
        );
      } else {
        unstable_renderSubtreeIntoContainer(
          this,
          this.renderModal(this.props),
          this.portal
        );
      }
    }

    if (this.state.isModalOpen && !nextState.isModalOpen) {
      this.removeHandlers();
      unmountComponentAtNode(this.portal);
    }
  }

  componentWillUnmount() {
    if (this.portal) {
      this.modalParent.removeChild(this.portal);
    }
  }

  onEscapePress;
  onEscapePress =(e) => {
    if (e.keyCode === 27) {
      this.close();
    }

    return this;
  }

  setHandlers;
  setHandlers = () => {
    window.addEventListener("keyup", this.onEscapePress);

    return this;
  }

  close;
  close = () => {
    if (
      !this.state.isClosing &&
      this.state.isModalOpen &&
      !this.props.alwaysOpen
    ) {
      // I'm sorry, this is messy.
      this.modal.setCloseAnimation(() =>
        this.setState(
          {
            isModalOpen: false,
            isClosing: false
          },
          () => this.props.onClose && this.props.onClose()
        )
      );
    }

    if (this.props.alwaysOpen && this.props.closeModal) {
      this.props.closeModal();
    }
    return this;
  }

  createElement;
  createElement = () => {
    const portal = document.createElement("div");
    portal.id = `REACT_MODAL_ELEMENT_${this.props.modalIdentifier}`;
    return portal;
  }

  open;
  open() {
    this.setState(() => ({ isModalOpen: true }));
    return this;
  }

  props;

  removeHandlers;
  removeHandlers = () => {
    window.removeEventListener("keyup", this.onEscapePress);

    return this;
  }

  portal;
  modal;

  renderModal;
  renderModal = (props) => {
    return (
      <Modal
        ref={(c) => {
          this.modal = c;
        }}
        type={this.props.type}
        closeModal={this.close}
        classnames={this.props.classnames}
        qaLabel={this.props.qaLabel}
      >
        {props.children}
      </Modal>
    );
  }

  render() {
    return null;
  }
}

export default ModalPortal;
