export const getUserData = (userData) => ({
  type: "USER_DATA_UPDATE",
  payload: userData
});

export const setUserLoggedState = (isLogged) => ({
  type: "USER_LOGGED_STATE",
  payload: {
    isLogged
  }
});

export const setUserAccountNumber = (accountNumber) => ({
  type: "USER_ACCOUNT_NUMBER",
  payload: {
    accountNumber
  }
});
