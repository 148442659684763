export const primary = {
  default: {
    background: "var(--fd-colors-component-button-secondary-background-base)",
    border:
      "1px solid var(--fd-colors-component-button-secondary-background-base)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-secondary-content-base)"
  },
  hovered: {
    background: "var(--fd-colors-component-button-secondary-background-hover)",
    border:
      "1px solid var(--fd-colors-component-button-secondary-background-hover)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-secondary-content-hover)"
  },
  pressed: {
    background: "var(--fd-colors-component-button-secondary-background-active)",
    border:
      "1px solid var(--fd-colors-component-button-secondary-background-active)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-secondary-content-active)"
  },
  disabled: {
    background:
      "var(--fd-colors-component-button-secondary-background-disabled)",
    border:
      "1px solid var(--fd-colors-component-button-secondary-background-disabled)",
    boxShadow: "none",
    color: "var(--fd-colors-component-button-secondary-content-disabled)"
  }
};

export const primaryDark = {
  default: {
    background: "var(--fd-colors-component-button-secondary-background-base)",
    border:
      "1px solid var(--fd-colors-component-button-secondary-background-base)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-secondary-content-base)"
  },
  hovered: {
    background: "var(--fd-colors-component-button-secondary-background-hover)",
    border:
      "1px solid var(--fd-colors-component-button-secondary-background-hover)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-secondary-content-hover)"
  },
  pressed: {
    background: "var(--fd-colors-component-button-secondary-background-active)",
    border:
      "1px solid var(--fd-colors-component-button-secondary-background-active)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-secondary-content-active)"
  },
  disabled: {
    background: "var(--fd-colors-core-white-tint-005)",
    border: "1px solid var(--fd-colors-core-white-tint-005)",
    boxShadow: "none",
    color: "var(--fd-colors-component-button-secondary-content-disabled)"
  }
};

export const secondary = {
  default: {
    background: "var(--fd-colors-background-surface)",
    border: `1px solid var(--fd-colors-component-button-tertiary-border-base)`,
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-tertiary-content-base)"
  },
  hovered: {
    background: "var(--fd-colors-component-button-tertiary-background-hover)",
    border: `1px solid var(--fd-colors-component-button-tertiary-border-hover)`,
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-tertiary-content-hover)"
  },
  pressed: {
    background: "var(--fd-colors-component-button-tertiary-background-active)",
    border: `1px solid var(--fd-colors-component-button-tertiary-border-active)`,
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-tertiary-content-active)"
  },
  disabled: {
    background:
      "var(--fd-colors-component-button-tertiary-background-disabled)",
    border: `1px solid var(--fd-colors-component-button-tertiary-border-disabled)`,
    boxShadow: "none",
    color: "var(--fd-colors-component-button-tertiary-content-disabled)"
  }
};

export const secondaryDark = {
  default: {
    background:
      "var(--fd-colors-component-button-transparent-background-hover)",
    border:
      "1px solid var(--fd-colors-component-button-transparent-background-hover)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-transparent-content-base)"
  },
  hovered: {
    background: "var(--fd-colors-core-white-tint-020)",
    border: "1px solid var(--fd-colors-core-white-tint-020)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-transparent-content-hover)"
  },
  pressed: {
    background: "var(--fd-colors-core-white-tint-030)",
    border: "1px solid var(--fd-colors-core-white-tint-030)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-transparent-content-active)"
  },
  disabled: {
    background:
      "var(--fd-colors-component-button-transparent-background-disabled)",
    border:
      "1px solid var(--fd-colors-component-button-transparent-background-disabled)",
    boxShadow: "none",
    color: "var(--fd-colors-component-button-transparent-content-disabled)"
  }
};

export const tertiary = {
  default: {
    background: "var(--fd-colors-component-button-button-link-background-base)",
    border:
      "1px solid var(--fd-colors-component-button-button-link-background-base)",
    boxShadow: "none",
    color: "var(--fd-colors-component-button-tertiary-content-base)"
  },
  hovered: {
    background: "var(--fd-colors-component-button-tertiary-background-hover)",
    border:
      "1px solid var(--fd-colors-component-button-tertiary-background-hover)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-tertiary-content-hover)"
  },
  pressed: {
    background:
      "var(--fd-colors-component-button-button-link-background-active)",
    border:
      "1px solid var(--fd-colors-component-button-button-link-background-active)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-tertiary-content-active)"
  },
  disabled: {
    background:
      "var(--fd-colors-component-button-button-link-background-disabled)",
    border:
      "1px solid var(--fd-colors-component-button-button-link-background-disabled)",
    boxShadow: "none",
    color: "var(--fd-colors-component-button-tertiary-content-disabled)"
  }
};

export const tertiaryDark = {
  default: {
    background: "var(--fd-colors-component-button-transparent-background-base)",
    border:
      "1px solid var(--fd-colors-component-button-transparent-background-base)",
    boxShadow: "none",
    color: "var(--fd-colors-component-button-transparent-content-base)"
  },
  hovered: {
    background:
      "var(--fd-colors-component-button-transparent-background-hover)",
    border:
      "1px solid var(--fd-colors-component-button-transparent-background-hover)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-transparent-content-hover)"
  },
  pressed: {
    background: "var(--fd-colors-core-white-tint-030)",
    border: "1px solid var(--fd-colors-core-white-tint-030)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-transparent-content-active)"
  },
  disabled: {
    background:
      "var(--fd-colors-component-button-transparent-background-disabled)",
    border:
      "1px solid var(--fd-colors-component-button-transparent-background-disabled)",
    boxShadow: "none",
    color: "var(--fd-colors-component-button-transparent-content-disabled)"
  }
};

export const betting = {
  default: {
    background: "var(--fd-colors-component-button-primary-background-base)",
    border:
      "1px solid var(--fd-colors-component-button-primary-background-base)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-primary-content-base)"
  },
  hovered: {
    background: "var(--fd-colors-component-button-primary-background-hover)",
    border:
      "1px solid var(--fd-colors-component-button-primary-background-hover)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-primary-content-hover)"
  },
  pressed: {
    background: "var(--fd-colors-component-button-primary-background-active)",
    border:
      "1px solid var(--fd-colors-component-button-primary-background-active)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-primary-content-active)"
  },
  disabled: {
    background: "var(--fd-colors-component-button-primary-background-disabled)",
    border:
      "1px solid var(--fd-colors-component-button-primary-background-disabled)",
    boxShadow: "none",
    color: "var(--fd-colors-component-button-primary-content-disabled)"
  }
};

export const promo = {
  default: {
    background: "var(--fd-colors-brand-tertiary-default)",
    border: "1px solid var(--fd-colors-brand-tertiary-default)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-content-on-light)"
  },
  hovered: {
    background: "var(--fd-colors-core-yellow-l-3)",
    border: "1px solid var(--fd-colors-core-yellow-l-3)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-content-on-light)"
  },
  pressed: {
    background: "var(--fd-colors-core-yellow-l-2)",
    border: "1px solid var(--fd-colors-core-yellow-l-2)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-content-on-light)"
  },
  disabled: {
    background: "var(--fd-colors-component-button-primary-background-disabled)",
    border:
      "1px solid var(--fd-colors-component-button-primary-background-disabled)",
    boxShadow: "none",
    color: "var(--fd-colors-component-button-primary-content-disabled)"
  }
};

export const destructive = {
  default: {
    background: "var(--fd-colors-component-button-destructive-background-base)",
    border:
      "1px solid var(--fd-colors-component-button-destructive-background-base)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-destructive-content-base)"
  },
  hovered: {
    background:
      "var(--fd-colors-component-button-destructive-background-hover)",
    border:
      "1px solid var(--fd-colors-component-button-destructive-background-hover)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-destructive-content-hover)"
  },
  pressed: {
    background:
      "var(--fd-colors-component-button-destructive-background-active)",
    border:
      "1px solid var(--fd-colors-component-button-destructive-background-active)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-destructive-content-active)"
  },
  disabled: {
    background:
      "var(--fd-colors-component-button-destructive-background-disabled)",
    border:
      "1px solid var(--fd-colors-component-button-destructive-background-disabled)",
    boxShadow: "none",
    color: "var(--fd-colors-component-button-destructive-content-disabled)"
  }
};

export const tertiaryDestructive = {
  default: {
    background: "var(--fd-colors-component-button-button-link-background-base)",
    border:
      "1px solid var(--fd-colors-component-button-button-link-background-base)",
    boxShadow: "none",
    color: "var(--fd-colors-link-destructive-base)"
  },
  hovered: {
    background: "var(--fd-colors-core-red-l-5)",
    border: "1px solid var(--fd-colors-core-red-l-5)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-link-destructive-hover)"
  },
  pressed: {
    background:
      "var(--fd-colors-component-button-destructive-background-active)",
    border:
      "1px solid var(--fd-colors-component-button-destructive-background-active)",
    boxShadow: "var(--fd-shadows-elevations-bottom-low)",
    color: "var(--fd-colors-component-button-tertiary-content-active)"
  },
  disabled: {
    background:
      "var(--fd-colors-component-button-button-link-background-disabled)",
    border:
      "1px solid var(--fd-colors-component-button-button-link-background-disabled)",
    boxShadow: "none",
    color: "var(--fd-colors-component-button-tertiary-content-disabled)"
  }
};

export const buttonsColorPalette = {
  primary,
  secondary,
  tertiary,
  betting,
  promo,
  destructive,
  tertiaryDestructive,
  primaryDark,
  secondaryDark,
  tertiaryDark
};
