import { format, parseISO } from "date-fns";
import uwt from "@tvg/api/uwt";

const formatPendingWithdrawals = (uwtWithdrawals) =>
  uwtWithdrawals.map((uwtW) => ({
    id: uwtW.withdrawalId,
    date: format(parseISO(uwtW.transactionDate), "MM-dd-yyyy"),
    type: uwtW.paymentType,
    amount: uwtW.amount,
    onCancel: () => {}
  }));

const getWithdrawals = (accountNumber) =>
  uwt
    .getPendingWithdrawals(accountNumber)
    .then((response) =>
      formatPendingWithdrawals(response.data.pendingWithdrawalsList)
    );

const cancelWithdrawal = (accountNumber, withdrawalId) =>
  uwt.cancelWithdrawal(accountNumber, withdrawalId);

export default {
  getWithdrawals,
  cancelWithdrawal
};
