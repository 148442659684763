import React, { Component, Fragment } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import _, { get } from "lodash";
import * as mediator from "@tvg/mediator-classic/src";
import { connect } from "react-redux";
import classNames from "classnames";
import MenuItem from "../MenuItem/index";
import style from "./style.css";
import { toggleSideMenu } from "../../actions/header";

const stopEvent = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const showGreyhounds = (user, greyhoundsProfiles) => {
  if (_.has(user, "profile")) {
    const show = _.filter(
      greyhoundsProfiles,
      (profile) => profile === user.profile
    );

    return show.length;
  }
  return false;
};

export class MenuNav extends Component {
  constructor(props) {
    super(props);
    _.bindAll(this, [
      "toggleSideMenu",
      "showSideMenu",
      "filterGreyhounds",
      "showGreyNames"
    ]);
    this.state = {
      showGreyhounds: showGreyhounds(props.user, props.greyhoundsProfiles)
    };
  }

  componentWillMount() {
    // we need this to coverage. There is no way for now to put window undefined
    /* istanbul ignore next */
    if (typeof window !== "undefined") {
      this.el = document.createElement("div");
      this.root = document.body;
    }
  }

  componentDidMount() {
    this.root.appendChild(this.el);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      showGreyhounds: showGreyhounds(newProps.user, newProps.greyhoundsProfiles)
    });
  }

  showSideMenu() {
    return this.props.sideMenuToggled ? style.opened : style.collapsed;
  }

  showGreyNames(menuItem) {
    return this.state.showGreyhounds &&
      _.has(menuItem, "options.greyhoundsName")
      ? menuItem.options.greyhoundsName
      : menuItem.name;
  }

  toggleSideMenu(val) {
    const toggleMenu = val !== undefined ? val : !this.props.sideMenuToggled;
    mediator.dispatch("OPEN_SIDE_MENU", { open: toggleMenu });
    this.props.dispatch(toggleSideMenu(toggleMenu));
  }

  filterGreyhounds(menuList) {
    if (this.state.showGreyhounds && this.props.nextGreyhoundRaceLink) {
      let newMenuList = [];
      const racingMenu = _.find(
        menuList,
        (menuItem) => menuItem.name === "Racing"
      );

      if (racingMenu) {
        newMenuList = _.map(racingMenu.subMenus || [], (subMenu) => {
          if (subMenu.route === "/greyhounds") {
            return { ...subMenu, route: this.props.nextGreyhoundRaceLink };
          }
          return { ...subMenu };
        });
      }

      return [
        { ...racingMenu, subMenus: newMenuList },
        ...menuList.filter((menu) => menu.name !== "Racing")
      ];
    }

    return menuList.map((menu) => ({
      ...menu,
      subMenus: (menu.subMenus || []).filter(
        (submenu) => submenu.route !== "/greyhounds"
      )
    }));
  }

  buildMenuList = (fixedHeader) => {
    const {
      menuItems,
      newHeaderExperienceMenus,
      tvgHeaderV2FeatureToggle,
      isLogged
    } = this.props;

    const menuList = this.filterGreyhounds(menuItems);
    return tvgHeaderV2FeatureToggle
      ? menuList.map(
          (menuItem) =>
            (menuItem.qaLabel !== "watchLiveButton" || !isLogged) && (
              <MenuItem
                key={`menuItem${menuItem.name}`}
                isFixedHeader={fixedHeader}
                menuItemName={this.showGreyNames(menuItem)}
                menuItemRoute={menuItem.route}
                menuItemClasses={menuItem.classes}
                menuItemOptions={menuItem.options}
                closeMenu={this.toggleSideMenu}
                menuToggled={this.props.sideMenuToggled}
                subMenus={menuItem.subMenus}
                menuItemQaLabel={menuItem.qaLabel}
                menuItemIcon={menuItem.icon}
                newHeaderExperienceMenus={newHeaderExperienceMenus}
              />
            )
        )
      : menuList.map((menuItem) => (
          <MenuItem
            key={`menuItem${menuItem.name}`}
            isFixedHeader={fixedHeader}
            menuItemName={this.showGreyNames(menuItem)}
            menuItemRoute={menuItem.route}
            menuItemClasses={menuItem.classes}
            menuItemOptions={menuItem.options}
            closeMenu={this.toggleSideMenu}
            menuToggled={this.props.sideMenuToggled}
            subMenus={menuItem.subMenus}
            menuItemQaLabel={menuItem.qaLabel}
            menuItemIcon={menuItem.icon}
            newHeaderExperienceMenus={newHeaderExperienceMenus}
          />
        ));
  };

  render() {
    /* eslint-disable */
    return (
      <Fragment>
        <div className={classNames(this.showSideMenu())}>
          {typeof window !== "undefined"
            ? createPortal(
                <div
                  className={classNames(style.overlay, this.showSideMenu())}
                  onClick={() => this.toggleSideMenu(false)}
                />,
                this.el
              )
            : ""}
          <nav className={style.menuItemNav} onClick={stopEvent}>
            <ul className={style.menuItemList}>{this.buildMenuList()}</ul>
          </nav>
        </div>
        <nav className={style.menuItemHeader} onClick={stopEvent}>
          <ul className={style.menuItemListHeader}>
            {this.buildMenuList(true)}
          </ul>
        </nav>
      </Fragment>
    );
    /* eslint-enable */
  }
}

MenuNav.propTypes = {
  dispatch: PropTypes.func,
  menuItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
        route: PropTypes.string
      }),
      PropTypes.string
    ])
  ),
  user: PropTypes.shape({
    profile: PropTypes.string
  }),
  greyhoundsProfiles: PropTypes.arrayOf(PropTypes.string),
  nextGreyhoundRaceLink: PropTypes.string,
  sideMenuToggled: PropTypes.bool
};

MenuNav.defaultProps = {
  dispatch: () => null,
  menuItems: [],
  sideMenuToggled: false,
  user: {},
  greyhoundsProfiles: [],
  nextGreyhoundRaceLink: null
};

export default connect((store) => ({
  user: store.userData.user,
  isLogged: get(store, "userData.logged"),
  sideMenuToggled: store.header.sideMenuToggled,
  greyhoundsProfiles: store.header.greyhoundsProfiles,
  nextGreyhoundRace: store.nextRace.nextGreyhoundRace,
  nextGreyhoundRaceLink: store.nextRace.nextGreyhoundRaceLink,
  tvgHeaderV2FeatureToggle: get(
    store,
    "header.features.tvgHeaderV2FeatureToggle"
  )
}))(MenuNav);
