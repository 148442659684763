import styled from "styled-components";

export const BetTypeSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  z-index: 1;
  width: 100%;
  background-color: var(--fd-colors-background-surface);
`;

export const InfoContainer = styled.div`
  width: 94px;
  flex-grow: 0;
  padding: var(--fd-space-space-2) var(--fd-space-space-3);
  background-color: var(--fd-colors-background-layer);
  border-bottom: 1px solid var(--fd-colors-border-default);
  border-top: 1px solid var(--fd-colors-border-default);
  border-right: 1px solid var(--fd-colors-border-default);
  border-top-left-radius: 4px;
`;

export const InfoText = styled.p`
  font-size: 16px;
  font-family: Roboto-Bold, sans-serif;
  color: var(--fd-colors-content-default);
  text-align: center;
  width: 70px;
  height: 40px;
  line-height: 20px;
`;

export const BetTypesContainer = styled.div`
  flex: auto;
  height: 60px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--fd-colors-border-default);
  background-color: var(--fd-colors-background-surface);
  overflow-x: hidden;
  border-top-right-radius: 4px;
`;

export const FadeLayer = styled.div<{
  orientation: "left" | "right";
  width: number;
}>`
  width: ${({ width }) => width}px;
  position: absolute;
  height: 55px;
  left: 94px;
  pointer-events: none;
  background-image: linear-gradient(
    to ${({ orientation }) => orientation},
    rgba(255 255 255 / 100%) 0%,
    rgba(255 255 255 / 0%) 5%
  );
`;

export const Navigation = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-bottom: 1px solid var(--fd-colors-border-default);
  background-color: var(--fd-colors-background-surface);
`;
