/* eslint-disable react/no-danger */
import React, { memo, FC, useState, useCallback } from "react";
import { useSpring } from "@react-spring/web";
import { Icon } from "../Icon";
import {
  AnimatedWrapper,
  AlertInlineContainer,
  AlertInlineInfo,
  AlertInlineCloseButton,
  Link,
  LinkWrapper,
  TitleWrapper
} from "./styled-components";
import { IconNames } from "../Icon/types";
import type { AlertInlineProps, AlertInlineVariants } from "./types";
import { alertInlineContentMap } from "./constants";
import { Paragraph } from "../Typography";

export const AlertInLine: FC<AlertInlineProps> = memo(
  ({
    variant,
    title,
    message = "",
    hasDismissButton,
    hasDismissAnimation,
    onDismissPress,
    type = "floating",
    linkLabel,
    onLinkClick,
    linkExtraInfo = "",
    qaLabel,
    children,
    ...rest
  }) => {
    const [isVisible, setIsVisible] = useState(true);
    const { name, lineColor, backgroundColor } =
      alertInlineContentMap[variant as AlertInlineVariants].icon;

    const [animatedStyle, api] = useSpring(() => ({
      from: {
        opacity: 1
      }
    }));

    const handleDismiss = useCallback((e) => {
      onDismissPress?.(e);

      //  We need to maintain this with js since it has interaction
      //  depending on the animation
      if (hasDismissAnimation) {
        api.start({
          from: { opacity: 1 },
          to: {
            opacity: 0
          },
          onRest: () => {
            setIsVisible(false);
          }
        });
      } else {
        setIsVisible(false);
      }
    }, []);

    return isVisible ? (
      <AnimatedWrapper style={{ ...animatedStyle }}>
        <AlertInlineContainer
          variant={variant}
          type={type}
          data-qa-label={qaLabel}
          data-status={isVisible ? "visible" : "hidden"}
          {...rest}
        >
          <Icon
            name={name as IconNames}
            lineColor={lineColor}
            backgroundColor={backgroundColor}
            size="s"
            qaLabel={`alert-inline-icon-${name}`}
          />
          <AlertInlineInfo flexDirection={title ? "column" : "row"}>
            {title || message ? (
              <TitleWrapper>
                <Paragraph
                  qaLabel={
                    title ? "alert-inline-title" : "alert-inline-message"
                  }
                  color={
                    alertInlineContentMap[variant as AlertInlineVariants].text
                  }
                  fontSize="14px"
                  fontWeight={!title ? 400 : 500}
                  lineHeight="125%"
                  margin={0}
                  fontFamily={!title ? "regular" : "medium"}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: title || message || ""
                    }}
                  />
                </Paragraph>

                {(!message || !title) && linkLabel && (
                  <LinkWrapper>
                    <Link
                      role="link"
                      data-qa-label="alert-inline-link"
                      onClick={(e) => {
                        onLinkClick?.(e);
                      }}
                      color={
                        alertInlineContentMap[variant as AlertInlineVariants]
                          .link
                      }
                    >
                      {linkLabel}
                    </Link>
                    {linkExtraInfo.length ? (
                      <Paragraph
                        color={
                          alertInlineContentMap[variant as AlertInlineVariants]
                            .link
                        }
                        fontSize="14px"
                        fontWeight={400}
                        lineHeight="135%"
                        fontFamily="regular"
                        margin={0}
                      >
                        &nbsp;
                        {linkExtraInfo}
                      </Paragraph>
                    ) : (
                      <></>
                    )}
                  </LinkWrapper>
                )}
              </TitleWrapper>
            ) : null}

            {((title && message) || children) && (
              <Paragraph
                qaLabel="alert-inline-message"
                color={
                  alertInlineContentMap[variant as AlertInlineVariants].text
                }
                fontSize="14px"
                fontWeight={400}
                lineHeight="125%"
                fontFamily="regular"
                margin={0}
              >
                {children}
                <span
                  dangerouslySetInnerHTML={{
                    __html: message
                  }}
                />
              </Paragraph>
            )}
            {linkLabel && title && message && (
              <LinkWrapper>
                <Link
                  role="link"
                  data-qa-label="alert-inline-link"
                  onClick={(e) => {
                    onLinkClick?.(e);
                  }}
                  color={
                    alertInlineContentMap[variant as AlertInlineVariants].link
                  }
                >
                  {linkLabel}
                </Link>
                {linkExtraInfo.length ? (
                  <Paragraph
                    color={
                      alertInlineContentMap[variant as AlertInlineVariants].link
                    }
                    fontSize="14px"
                    fontWeight={400}
                    lineHeight="135%"
                    fontFamily="regular"
                    margin={0}
                  >
                    &nbsp;
                    {linkExtraInfo}
                  </Paragraph>
                ) : (
                  <></>
                )}
              </LinkWrapper>
            )}
          </AlertInlineInfo>
          {hasDismissButton && (
            <AlertInlineCloseButton
              onClick={handleDismiss}
              data-qa-label="alert-inline-close-button"
            >
              <Icon
                name="close"
                lineColor={
                  alertInlineContentMap[variant as AlertInlineVariants].text
                }
                backgroundColor="transparent"
                size="s"
                qaLabel="alert-inline-icon-close"
              />
            </AlertInlineCloseButton>
          )}
        </AlertInlineContainer>
      </AnimatedWrapper>
    ) : null;
  }
);

export { type AlertInlineProps };
