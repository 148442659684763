import { Race } from "@tvg/ts-types/Race";
import React from "react";
import { Container } from "./styled-components";
import { Mtp } from "../../../Mtp";
import { renderIcon } from "./renderer";

const RaceDetail = ({
  mtp,
  postTime,
  video,
  status
}: Pick<Race, "mtp" | "postTime" | "status" | "video">) => (
  <Container>
    <Mtp
      qaLabel="mtp"
      mtp={mtp}
      postTime={postTime}
      status={status.code}
      fontWeight="medium"
      whiteSpace="nowrap"
      size="xs"
    />
    {renderIcon(video, status)}
  </Container>
);

export default RaceDetail;
