import React from "react";
import { noop } from "lodash";

import { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import { openLiveChat } from "@tvg/utils/liveChatUtils";

import mediator from "@tvg/mediator";
import { email, account } from "../../_static/Icons/icons";
import MessageBox from "../../_molecule/MessageBoxV2";
import ButtonLink from "../../_atom/Buttons/buttonLink";
import Info from "../../_molecule/Info";
import ProcessingOverlay from "../../_static/ProcessingOverlay";

import { Container, GoToHomeLink, FooterError } from "./styled-components";
import { MessageNote } from "../RecoverCredentials/styled-components";

export const messagesDefault = {
  info: "",
  form: {
    dateOfBirth: "",
    dateOfBirthErrorAge: "",
    dateOfBirthErrorInvalid: "",
    last4Ssn: "",
    last4SsnDescription: "",
    last4SsnErrorInvalid: "",
    submit: ""
  },
  success: {
    title: "",
    footer: "",
    message: "",
    messageAlt: ""
  },
  warning: {
    title: "",
    message: ""
  },
  failure: {
    title: "",
    message: ""
  },
  tryAgain: {
    title: "",
    message: ""
  },
  error: {
    title: "",
    message: ""
  },
  contact: ""
};

const renderSuccess = (device, isMobile, info, message) => (
  <Container
    data-qa-label="loginRecoverEmailSuccess"
    isMobile={isMobile}
    hasPadding
    device={device}
  >
    <Info
      qaLabel="recover-email-info-success"
      mainIcon={email}
      hasOverlayIcon
      messageType="success"
      title={info.title}
      message={info.message}
      full
    />
    <MessageNote data-qa-label="recover-email-message-note-success" isFooter>
      <span dangerouslySetInnerHTML={{ __html: message }} />
    </MessageNote>
  </Container>
);

const renderError = (
  device,
  isMobile,
  info,
  support,
  showTvgLink = false,
  onCloseModalCallback = noop,
  hasTryAgainLink = false,
  onResubmitHandler = noop
) => (
  <Container
    data-qa-label="loginRecoverEmailError"
    isMobile={isMobile}
    hasPadding
    device={device}
  >
    <Info
      qaLabel="recover-email-info-failure"
      mainIcon={account}
      hasOverlayIcon
      messageType="error"
      title={info.title}
      message={info.message}
      full
    />
    <FooterError>
      <ButtonLink
        qaLabel="recover-email-cta-button"
        isStretched
        size="huge"
        url="#"
        isUppercase={false}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          mediator.base.dispatch({
            type: "FORGOT_CREDENTIALS_ERROR_PAGE_CTA",
            payload: {
              label: hasTryAgainLink ? "Try Again" : support,
              module: "Something went wrong!"
            }
          });
          hasTryAgainLink ? onResubmitHandler() : openLiveChat();
        }}
      >
        {hasTryAgainLink ? "Try again" : support}
      </ButtonLink>
      {showTvgLink && (
        <GoToHomeLink>
          <ButtonLink
            isStretched
            type="tertiary"
            size="bigger"
            url="/"
            isUppercase={false}
            qaLabel="goto-tvg-home"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (hasTryAgainLink) {
                mediator.base.dispatch({
                  type: "FORGOT_CREDENTIALS_ERROR_PAGE_CTA",
                  payload: {
                    label: support,
                    module: "Something went wrong!"
                  }
                });
                openLiveChat();
              } else {
                mediator.base.dispatch({
                  type: "FORGOT_CREDENTIALS_RETURN_TO_TVG_COM",
                  payload: {
                    module: "Something went wrong!"
                  }
                });
                onCloseModalCallback();
              }
            }}
          >
            {hasTryAgainLink ? support : "Return to Homepage"}
          </ButtonLink>
        </GoToHomeLink>
      )}
    </FooterError>
  </Container>
);

const RecoverEmail = (props) => {
  const {
    children,
    device,
    onCloseModalCallback,
    info,
    isLoading,
    hasSentEmail,
    hasError,
    hasErrorAttempt,
    attempts,
    attemptsMax,
    messages,
    maskedEmail,
    hasTryAgainLink,
    onResubmitHandler,
    dateOfBirthPlaceholder,
    dateOfBirthValue
  } = props;
  const isMobile = device === "mobile";

  if (!isLoading && hasError && attempts >= attemptsMax)
    return renderError(
      device,
      isMobile,
      {
        title: messages.failure.title,
        message: messages.failure.message
      },
      messages.contact
    );

  if (!isLoading && hasError && hasErrorAttempt)
    return renderError(
      device,
      isMobile,
      hasTryAgainLink
        ? {
            title: messages.tryAgain.title,
            message: messages.tryAgain.message
          }
        : {
            title: messages.error.title,
            message: messages.error.message
          },
      messages.contact,
      true,
      onCloseModalCallback,
      hasTryAgainLink,
      onResubmitHandler
    );

  if (!isLoading && hasSentEmail)
    return renderSuccess(
      device,
      isMobile,
      {
        title: messages.success.title,
        message: maskedEmail
          ? replaceCAPIVariables(messages.success.message, {
              maskedEmail
            })
          : messages.success.messageAlt
      },
      messages.success.footer
    );

  const isDateOfBirthPlaceholder =
    dateOfBirthValue === "" || dateOfBirthValue === dateOfBirthPlaceholder;

  return (
    <Container
      data-qa-label="recover-email-container"
      isMobile={isMobile}
      device={device}
    >
      {isLoading && <ProcessingOverlay />}
      <Container.Header hasPadding={!hasError} device={device}>
        {hasError ? (
          <MessageBox
            qaLabel="recover-email-message-error"
            type="warning"
            subtype={device === "mobile" ? "fullWith" : "floating"}
            title={messages.warning.title}
            message={replaceCAPIVariables(messages.warning.message, {
              attempts: attemptsMax - attempts
            })}
            className="message-warning attempts"
          />
        ) : (
          <MessageBox
            qaLabel="recover-email-message-success"
            type="info"
            subtype="floating"
            message={info}
          />
        )}
      </Container.Header>
      <Container.Main
        device={device}
        isDateOfBirthPlaceholder={isDateOfBirthPlaceholder}
      >
        {children}
      </Container.Main>
    </Container>
  );
};
RecoverEmail.defaultProps = {
  isLoading: false,
  hasSentEmail: false,
  onCloseModalCallback: noop
};

export default RecoverEmail;
