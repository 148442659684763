/* eslint-disable no-unused-expressions */
import styled from "styled-components";
import { fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

// eslint-disable-next-line import/prefer-default-export
export const InputElement = (props) => {
  const defaultProp = {
    type: "text",
    qaLabel: ""
  };
  const inputData = { ...defaultProp, ...props };

  return styled.input.attrs({
    ...(inputData.qaLabel !== ""
      ? {
          "data-qa-label": inputData.qaLabel
        }
      : {}),
    type: inputData.type
  })`
    font-family: ${fontNormal};
    height: 40px;
    width: 100%;
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid ${buildColor("grey", "300")};
    text-indent: 8px;
    box-sizing: border-box;
    background-color: transparent;
  `;
};
