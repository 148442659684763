import styled, { css } from "styled-components";

import buildColor from "../../_static/ColorPalette";
import { fontBold } from "../../_static/Typography";

export const MenuBoxContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (width >= 1024px) {
    border-radius: 4px;
    width: 325px;
  }

  @media only screen and (width <= 1023px) {
    border-radius: 4px 4px 0 0;
  }

  @media only screen and (width >= 651px) and (width <= 1023px) {
    width: 375px;
  }

  @media only screen and (width <= 414px) {
    width: 100%;
  }
`;

export const MenuBoxTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
  height: 48px;
  background-color: ${buildColor("white", "100")};
  border-radius: 4px 4px 0 0;

  span {
    line-height: 24px;
    max-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 38ch;
    font-family: ${fontBold};
  }

  @media only screen and (width >= 415px) {
    justify-content: flex-start;
  }

  @media only screen and (width <= 414px) {
    justify-content: space-between;
  }
`;

const expandedMenu = css`
  max-height: ${({ menuItemsQty }) => menuItemsQty * 48};
`;

const closedMenu = css`
  max-height: 0;
`;

export const MenuBoxLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: max-height 0.5s ease;
  ${({ isOpen }) => (isOpen ? expandedMenu : closedMenu)}

  @media only screen and (width >= 1024px) {
    border-radius: 0 0 4px 4px;
  }
`;

export const MenuBoxIcon = styled.div`
  @media only screen and (width >= 1024px) {
    display: none;
  }

  @media only screen and (width <= 1023px) {
    display: block;
  }
`;
