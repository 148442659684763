// todo: replace RaceResultsHeader with this
// legacy component: RaceResultsHeader
// feature toggle: enableRaceResultsReDesign

import React from "react";
import { HeaderContainer, HeaderColumn } from "./styled-components";

const getGridPosition = (hasPlace, hasShow) => {
  if (!hasPlace && !hasShow) {
    return 4;
  }

  if ((!hasShow && hasPlace) || (!hasPlace && hasShow)) {
    return 3;
  }

  return false;
};

const DEFAULT_GRID_POSITION = 2;

const RaceResultsTop = ({
  betAmount,
  hasWin,
  hasPlace,
  hasShow,
  racePayoffsShift
}) => (
  <HeaderContainer data-qa-label="RaceResultsTop">
    <div>Position</div>

    {hasWin && betAmount && (
      <HeaderColumn
        gridPosition={
          racePayoffsShift
            ? getGridPosition(hasPlace, hasShow)
            : DEFAULT_GRID_POSITION
        }
      >
        ${betAmount} Win
      </HeaderColumn>
    )}
    {hasPlace && betAmount && <HeaderColumn>${betAmount} Place </HeaderColumn>}
    {hasShow && betAmount && <HeaderColumn>${betAmount} Show</HeaderColumn>}
  </HeaderContainer>
);

RaceResultsTop.defaultProps = {
  betAmount: 2,
  hasWin: true,
  hasPlace: true,
  hasShow: true,
  racePayoffsShift: false
};

export default RaceResultsTop;
