import styled from "styled-components";
import { animated } from "@react-spring/web";
import type { GeoToastType } from "./types";

const DEFAULT_STYLING_TOP = "98%";

const toastBgColor = (type: GeoToastType) => {
  switch (type) {
    case "success":
      return "background-color: var(--fd-colors-core-green);";
    case "pending":
      return "background-color: var(--fd-colors-core-purple-d-1);";
    default:
      return "background-color: var(--fd-colors-core-purple-d-1);";
  }
};

const calculateTop = (
  offset: number,
  isBetSlipOpen: boolean,
  overrideTopPositioning: boolean
) => {
  if (overrideTopPositioning) return "0";

  return isBetSlipOpen
    ? DEFAULT_STYLING_TOP
    : `calc(${DEFAULT_STYLING_TOP} - ${offset}px)`;
};

export const Wrapper = styled(animated.div)<{
  visibility: string;
  offset: number;
  isBetSlipOpen: boolean;
  overrideTopPositioning: boolean;
}>`
  display: flex;
  position: fixed;
  visibility: ${({ visibility }) => visibility};
  top: ${({ offset, isBetSlipOpen, overrideTopPositioning }) =>
    calculateTop(offset, isBetSlipOpen, overrideTopPositioning)};
  justify-content: center;
  color: ${({ theme }) => theme.colorTokens.content.onDark};
  width: 100%;
  z-index: 1100;
  pointer-events: none;
`;

export const ContentWrapper = styled.div<{ type?: GeoToastType }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) =>
    `${theme.space["space-4"]} ${theme.space["space-5"]}`};
  border-radius: 100px;
  max-width: 312px;
  ${({ type }) => type && toastBgColor(type)};
  width: fit-content;
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: ${({ theme }) => `0 ${theme.space["space-1"]}`};
`;

export const Title = styled.span`
  font-family: "Proxima nova", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;
`;

export const SubTitle = styled.span`
  font-family: "Proxima nova", sans-serif;
  font-size: ${({ theme }) => theme.space["space-4"]};
  line-height: 125%;
  font-weight: 400;
`;
