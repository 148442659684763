import React, { Component } from "react";
import { Link } from "react-router-dom";
import { noop } from "lodash";
import mediator from "@tvg/mediator";

import { pluralize } from "@tvg/utils/generalUtils";
import UserAccount from "@tvg/atomic-ui/_organism/UserAccount";
import uwtService from "@tvg/api/uwt";
import { events as AlchemerEvents } from "@urp/alchemer";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import {
  talent,
  home,
  tracks,
  results,
  bets,
  promo,
  settings,
  withdraw,
  liveVideo20,
  medal,
  cup,
  lock,
  referFriend,
  accountTransactions
} from "../../_static/Icons/icons";
import {
  AccountSection,
  AccountContainer,
  AccountLink,
  AccountExternalLink,
  AccountLinkRow,
  AccountLinkGroup,
  AccountHighlightedText,
  LinkText,
  LoginContainer,
  LoginTitle,
  LoginText,
  LoginButtons,
  PreferenceItem,
  PreferencesTitleContainer,
  AccountPreferencesSection,
  ExpandableBetPreferences,
  AccountAppVersion,
  ButtonSection,
  ButtonTransparent,
  ButtonLinkStyled,
  SupportButton,
  LogoutButton,
  DisclaimerText,
  DisclaimerContainer,
  MessageBoxLink
} from "./styled-components";
import Toggle from "../../_atom/Toggle";
import NotificationBox from "../../_molecule/NotificationBox";
import { SubWalletContent } from "../../_molecule/AccountSubWallets";

export default class Account extends Component {
  static defaultProps = {
    userName: "",
    userEmail: "",
    accountNumber: "",
    showUserInfo: false,
    showBalanceInfo: false,
    showLoginMessage: false,
    showLogoutButton: false,
    showBetConfirmationToggle: false,
    hideBalance: true,
    onHideBalance: noop,
    isBalanceButtonHighlighted: false,
    balance: 0,
    promoFunds: 0,
    talentPickAppUrl: "",
    depositUrl: "#deposit",
    withdrawUrl: "#withdraw",
    homeUrl: "/home",
    picksUrl: "/picks",
    handicappingStoreUrl: "#handicap-store",
    isTalentPicksPageEnabled: false,
    isHandicapStoreModalEnabled: false,
    isSeoTrackListEnabled: false,
    userBetPreferences: false,
    tracksUrl: "/tracks",
    resultsUrl: "/results",
    betsUrl: "#my-bets",
    accountSummaryUrl: "/my-account-summary",
    accountSummaryLinkNew: false,
    showAccountSummary: false,
    usePrefsUrl: "#bet-prefs",
    promotionsUrl: "#promos",
    wagerRewardsUrl: "/wager-rewards",
    trackListUrl: "/racetracks",
    wagerRewardsLinkNew: false,
    handicappingStoreLinkNew: false,
    tutorialsLinkNew: false,
    feedbackUrl: "",
    careersUrl: "",
    desktopUrl: "",
    privacypolicyUrl: "",
    californiaPolicy: "",
    termsConditionsUrl: "",
    showWagerResponsibly: true,
    wagerResponsiblyUrl: "",
    showResponsibleGaming: false,
    californiaPolicyUrl: "",
    responsibleGamingUrl: "/responsible-gaming",
    showReferFriend: false,
    referFriendUrl: "/referral",
    tutorialsUrl: "",
    watchTvgFaqUrl: "",
    phoneUrl: "",
    emailUrl: "",
    facebookUrl: "",
    twitterUrl: "",
    onLogout: noop,
    navigationCallBack: noop,
    onExternalLinkCallback: noop,
    contentNavigationCallBack: noop,
    onPreferencesExpandCallBack: noop,
    showEnableTouchId: false,
    isTouchIdEnabled: false,
    isBetConfirmationEnabled: false,
    onTouchIdToggle: noop,
    onBetConfirmationToggle: noop,
    isFaceId: false,
    hasPendingWithdrawals: false,
    pendingWithdrawalsUrl: "#withdrawals",
    onPendingWithdrawalsClick: noop,
    onWatchLiveClick: noop,
    watchLiveUrl: "/watch-live",
    externalDeposit: false,
    device: "mobile",
    hasTutorials: false,
    appVersion: "",
    bettingGuideLink: "",
    bettingGuideLinkName: "",
    bettingGuideLinkNew: false,
    children: [],
    supportGtm: noop,
    supportUrl: "#support",
    doNotSellLabel: "",
    doNotSellUrl: "",
    referFriendLabel: "",
    brazeContentCards: {
      unviewed: 0,
      totalCards: 0,
      cards: []
    },
    signUpUrl: "/",
    loginUrl: "/",
    headerContentCardsInboxToggle: false,
    loginMessageTitle: "",
    loginMessageDescription: "",
    enableSeoMEPMenuItems: false,
    seoMEPMenuItems: [
      {
        name: "",
        route: "/",
        subQaLabel: ""
      }
    ],
    hasWagerProfileException: false,
    showPrefReact: false,
    preferencesUrl: "/preferences",
    pendingWithdrawals: 0,
    showConnecticutDisclaimer: false,
    connecticutDisclaimer: "",
    enableAccessibility: false,
    accessibilityUrl: "#accessibility",
    showPendingWithdrawalsV2: false,
    hasQuickWithdraw: false,
    getAvailablePaymentMethods: noop,
    enablePawsQuickDeposits: false,
    shouldShowPromoOnboarding: false,
    renderPromoOnboarding: null,
    isPawsSubWalletsAvailable: false,
    subWalletsContent: [],
    onSendFeedback: noop
  };

  constructor(props) {
    super(props);
    this.state = { clickNumber: 0, withdrawFunds: 0 };
  }

  componentDidMount() {
    if (this.props.accountNumber && this.props.isPawsSubWalletsAvailable) {
      this.getWithdrawalFunds();
    }
  }

  onVersionClick = () => {
    this.setState({
      clickNumber: this.incrementClickNumber()
    });
  };

  incrementClickNumber = () => {
    return this.state.clickNumber + 1;
  };

  gtmAndNavigationHandler = (
    destinationUrl,
    eventLabel,
    isEvenTimeout = true
  ) => {
    mediator.base.dispatch({
      type: "MORE_PAGE:WITHDRAWAL_AND_DEPOSIT_BUTTON",
      payload: {
        destinationUrl,
        eventLabel,
        isEvenTimeout
      }
    });
  };

  getWithdrawalFunds() {
    uwtService.getWithdrawalLimit(this.props.accountNumber).then(({ data }) => {
      this.setState({
        withdrawFunds: data.availableBalance
      });
    });
  }

  getInboxCounter = () =>
    this.props.brazeContentCards.cards.filter(
      (card) => card.sc !== "ab-control-card" && !card.viewed
    ).length;

  renderSeoLinks = () =>
    this.props.seoMEPMenuItems.map((item, key) => (
      <li key={`account-nav-seo-${item.subQaLabel}-${key + 1}`}>
        <AccountExternalLink
          data-qa-label={item.subQaLabel}
          href={item.route}
          onClick={(e) => {
            this.props.onExternalLinkCallback(item.route, e);
            this.props.navigationCallBack(item.route, item.name);
          }}
        >
          <Icon icon={cup} color={buildColor("grey", "900")} />
          <LinkText>{item.name}</LinkText>
        </AccountExternalLink>
      </li>
    ));

  render() {
    return (
      <AccountSection data-qa-label="account">
        <AccountContainer>
          {this.props.showLoginMessage && (
            <LoginContainer>
              <LoginTitle data-qa-label="logged-out-info-title">
                {this.props.loginMessageTitle}
              </LoginTitle>
              <LoginText data-qa-label="logged-out-info-title">
                {this.props.loginMessageDescription}
              </LoginText>
              <LoginButtons>
                <ButtonTransparent
                  tag="link"
                  qaLabel="account-balance-loginBtn"
                  type="secondary"
                  size="huge"
                  url={this.props.loginUrl}
                  isStretched
                >
                  <Icon icon={lock} color={buildColor("white", "100")} />
                  Log In
                </ButtonTransparent>
                <ButtonLinkStyled
                  tag="link"
                  qaLabel="account-balance-joinBtn"
                  type="primary"
                  size="huge"
                  url={this.props.signUpUrl}
                  isStretched
                >
                  <span>Join Now</span>
                </ButtonLinkStyled>
              </LoginButtons>
            </LoginContainer>
          )}
          {(this.props.showUserInfo || this.props.showBalanceInfo) && (
            <UserAccount
              accountNumber={this.props.accountNumber}
              accountName={this.props.userName}
              balance={this.props.balance}
              promoFunds={this.props.promoFunds}
              withdrawFunds={this.state.withdrawFunds}
              subWalletsContent={this.props.subWalletsContent}
              hideBalance={this.props.hideBalance}
              onClickHideBalance={this.props.onHideBalance}
              showUserInfo={this.props.showUserInfo}
              showBalanceInfo={this.props.showBalanceInfo}
              isExternalDeposit={this.props.externalDeposit}
              hasPendingWithdrawals={this.props.hasPendingWithdrawals}
              pendingWithdrawals={this.props.pendingWithdrawals}
              pendingWithdrawalsUrl={this.props.pendingWithdrawalsUrl}
              inboxCounter={this.getInboxCounter()}
              depositUrl={this.props.depositUrl}
              depositOnClick={() => {
                this.gtmAndNavigationHandler("/deposit", "Deposit", false);
              }}
              withdrawUrl={this.props.withdrawUrl}
              withdrawOnClick={() => {
                this.gtmAndNavigationHandler("/withdraw", "Withdraw", false);
              }}
              walletOnClick={() => {
                AlchemerEvents.selectWallet();
                this.gtmAndNavigationHandler("/wallet", "Wallet", false);
              }}
              inboxOnClick={() => {
                mediator.base.dispatch({
                  type: "MORE_PAGE:HEADER_SECTION_NOTIFICATIONS_BUTTON"
                });
              }}
              showWalletButton
              hasQuickWithdraw={this.props.hasQuickWithdraw}
              enablePawsQuickDeposits={this.props.enablePawsQuickDeposits}
              shouldShowPromoOnboarding={this.props.shouldShowPromoOnboarding}
              renderPromoOnboarding={this.props.renderPromoOnboarding}
              isSubWalletsAvailable={this.props.isPawsSubWalletsAvailable}
            />
          )}
          {this.props.hasPendingWithdrawals && this.props.showUserInfo && (
            <MessageBoxLink
              device={this.props.device}
              href={this.props.pendingWithdrawalsUrl}
              onClick={() => {
                mediator.base.dispatch({
                  type: "PENDING_WITHDRAWALS_OPEN",
                  payload: {
                    accountId: this.props.accountNumber
                  }
                });
              }}
            >
              <NotificationBox
                text={`You have ${pluralize(
                  this.props.pendingWithdrawals,
                  "pending withdrawal"
                )}`}
                variant="warning"
                hasRoundCorners={this.props.device !== "mobile"}
              />
            </MessageBoxLink>
          )}
          {this.props.talentPickAppUrl && (
            <AccountLinkGroup device={this.props.device}>
              <li>
                <AccountExternalLink
                  data-qa-label="account-nav-talentpick"
                  onClick={(e) => {
                    this.props.onExternalLinkCallback(
                      this.props.talentPickAppUrl,
                      e
                    );
                    this.props.navigationCallBack(
                      this.props.talentPickAppUrl,
                      "TalentPickTool"
                    );
                  }}
                >
                  <LinkText>Talent Picks Tool</LinkText>
                </AccountExternalLink>
              </li>
            </AccountLinkGroup>
          )}
          {!this.props.showPrefReact &&
            this.props.userBetPreferences &&
            (this.props.showBetConfirmationToggle ||
              this.props.showEnableTouchId) && (
              <ExpandableBetPreferences
                data-qa-label="expandable-bet-preferences"
                header={
                  <PreferencesTitleContainer data-qa-label="preferences-title-container">
                    <Icon
                      icon={settings}
                      color={buildColor("grey", "900")}
                      dataQaLabel="preferences-title-icon"
                    />
                    <PreferenceItem data-qa-label="preferences-item-preferences">
                      Preferences
                    </PreferenceItem>
                  </PreferencesTitleContainer>
                }
                onExpand={this.props.onPreferencesExpandCallBack}
              >
                <AccountPreferencesSection device={this.props.device}>
                  <li>
                    <AccountLink
                      data-qa-label="account-nav-betPrefs"
                      to={this.props.usePrefsUrl}
                      onClick={() => {
                        this.props.navigationCallBack(
                          this.props.usePrefsUrl,
                          "Default bet type and amount",
                          "Preferences"
                        );
                      }}
                    >
                      <PreferenceItem data-qa-label="preferences-item-default-bet-type-amount">
                        Default Bet Type And Amount
                      </PreferenceItem>
                    </AccountLink>
                  </li>
                  {this.props.showBetConfirmationToggle && (
                    <li>
                      <AccountLinkRow data-qa-label="account-nav-betConfirmation">
                        <LinkText>Bet Confirmation</LinkText>
                        <Toggle
                          on={this.props.isBetConfirmationEnabled}
                          onClick={this.props.onBetConfirmationToggle}
                          qaLabel="account-betConfirm-toggle"
                        />
                      </AccountLinkRow>
                    </li>
                  )}
                  {this.props.showEnableTouchId && (
                    <li>
                      <AccountLinkRow data-qa-label="account-nav-touchId">
                        <LinkText>
                          Enable {this.props.isFaceId ? "Face" : "Touch"} ID
                        </LinkText>
                        <Toggle
                          on={this.props.isTouchIdEnabled}
                          onClick={this.props.onTouchIdToggle}
                        />
                      </AccountLinkRow>
                    </li>
                  )}
                </AccountPreferencesSection>
              </ExpandableBetPreferences>
            )}
          {this.props.showPrefReact && (
            <AccountLinkGroup device={this.props.device}>
              <li>
                <AccountLink
                  onTouchStart={noop}
                  data-qa-label="account-nav-preferences"
                  to={this.props.preferencesUrl}
                  onClick={() =>
                    this.props.navigationCallBack(
                      this.props.preferencesUrl,
                      "Preferences"
                    )
                  }
                >
                  <Icon
                    icon={settings}
                    color={buildColor("grey", "900")}
                    dataQaLabel="preferences-title-icon"
                  />
                  <LinkText>Preferences</LinkText>
                </AccountLink>
              </li>
            </AccountLinkGroup>
          )}
          <AccountLinkGroup
            device={this.props.device}
            dontRoundTopBorders={this.props.showLoginMessage}
          >
            <li>
              <AccountLink
                onTouchStart={noop}
                data-qa-label="account-nav-home"
                to={this.props.homeUrl}
                onClick={() =>
                  this.props.navigationCallBack(this.props.homeUrl, "Home")
                }
              >
                <Icon icon={home} color={buildColor("grey", "900")} />
                <LinkText>Home</LinkText>
              </AccountLink>
            </li>
            <li>
              <AccountLink
                onTouchStart={noop}
                data-qa-label="account-nav-tracks"
                to={this.props.tracksUrl}
                onClick={() =>
                  this.props.navigationCallBack(this.props.tracksUrl, "Tracks")
                }
              >
                <Icon icon={tracks} color={buildColor("grey", "900")} />
                <LinkText>Today&apos;s Racing Schedule</LinkText>
              </AccountLink>
            </li>
            <li>
              <AccountLink
                onTouchStart={noop}
                data-qa-label="account-nav-results"
                to={this.props.resultsUrl}
                onClick={() =>
                  this.props.navigationCallBack(
                    this.props.resultsUrl,
                    "Results"
                  )
                }
              >
                <Icon icon={results} color={buildColor("grey", "900")} />
                <LinkText>Results</LinkText>
              </AccountLink>
            </li>
            <li>
              <AccountLink
                onTouchStart={noop}
                data-qa-label="account-nav-bets"
                to={this.props.betsUrl}
                onClick={() =>
                  this.props.navigationCallBack(
                    this.props.betsUrl,
                    "My Bets Modal"
                  )
                }
              >
                <Icon icon={bets} color={buildColor("grey", "900")} />
                <LinkText>Bets</LinkText>
              </AccountLink>
            </li>
            {this.props.showAccountSummary && (
              <li>
                <AccountLink
                  onTouchStart={noop}
                  data-qa-label="account-nav-account-summary"
                  to={this.props.accountSummaryUrl}
                  onClick={() =>
                    this.props.navigationCallBack(
                      this.props.accountSummaryUrl,
                      "Account Summary"
                    )
                  }
                >
                  <Icon
                    icon={accountTransactions}
                    color={buildColor("grey", "900")}
                  />
                  <LinkText>
                    Account Summary
                    {this.props.accountSummaryLinkNew && (
                      <AccountHighlightedText>New</AccountHighlightedText>
                    )}
                  </LinkText>
                </AccountLink>
              </li>
            )}
            <li>
              <AccountLink
                onTouchStart={noop}
                data-qa-label="account-nav-live"
                to={this.props.watchLiveUrl}
                onClick={this.props.onWatchLiveClick}
              >
                <Icon icon={liveVideo20} color={buildColor("grey", "900")} />
                <LinkText>Watch TV</LinkText>
              </AccountLink>
            </li>
            {this.props.isTalentPicksPageEnabled && (
              <li>
                <AccountLink
                  onTouchStart={noop}
                  data-qa-label="account-nav-picks"
                  to={this.props.picksUrl}
                  onClick={() =>
                    this.props.navigationCallBack(
                      this.props.picksUrl,
                      "Talent Picks"
                    )
                  }
                >
                  <Icon icon={talent} color={buildColor("grey", "900")} />
                  <LinkText>TVG Picks</LinkText>
                </AccountLink>
              </li>
            )}
            <li>
              <AccountLink
                onTouchStart={noop}
                data-qa-label="account-nav-promotions"
                to={this.props.promotionsUrl}
                onClick={() =>
                  this.props.navigationCallBack(
                    this.props.promotionsUrl,
                    "Promotions Modal"
                  )
                }
              >
                <Icon icon={promo} color={buildColor("grey", "900")} />
                <LinkText>Promotions</LinkText>
              </AccountLink>
            </li>
            {this.props.showReferFriend && (
              <li>
                <AccountLink
                  onTouchStart={noop}
                  data-qa-label="account-nav-referFriend"
                  to={this.props.referFriendUrl}
                  onClick={() =>
                    this.props.navigationCallBack(
                      this.props.referFriendUrl,
                      "Refer a Friend"
                    )
                  }
                >
                  <Icon icon={referFriend} color={buildColor("grey", "900")} />
                  <LinkText>{this.props.referFriendLabel}</LinkText>
                </AccountLink>
              </li>
            )}
            {this.props.wagerRewardsUrl &&
              !this.props.hasWagerProfileException && (
                <li>
                  <AccountLink
                    onTouchStart={noop}
                    data-qa-label="account-nav-wagerRewards"
                    to={this.props.wagerRewardsUrl}
                    onClick={() =>
                      this.props.navigationCallBack(
                        this.props.wagerRewardsUrl,
                        "Wager Rewards"
                      )
                    }
                  >
                    <Icon icon={medal} color={buildColor("grey", "900")} />
                    <LinkText>
                      Wager Rewards
                      {this.props.wagerRewardsLinkNew}
                    </LinkText>
                  </AccountLink>
                </li>
              )}
            {this.props.isHandicapStoreModalEnabled && (
              <li>
                <AccountLink
                  onTouchStart={noop}
                  data-qa-label="account-nav-handicapping-store"
                  to={this.props.handicappingStoreUrl}
                  onClick={() =>
                    this.props.navigationCallBack(
                      this.props.handicappingStoreUrl,
                      "Handicapping Store"
                    )
                  }
                >
                  <Icon icon={withdraw} color={buildColor("grey", "900")} />
                  <LinkText>
                    Handicapping Store
                    {this.props.handicappingStoreLinkNew && (
                      <AccountHighlightedText>New</AccountHighlightedText>
                    )}
                  </LinkText>
                </AccountLink>
              </li>
            )}
            {this.props.bettingGuideLinkName && this.props.bettingGuideLink && (
              <li>
                <AccountLink
                  onTouchStart={noop}
                  data-qa-label="account-nav-betting-guide"
                  to={this.props.bettingGuideLink}
                  onClick={() =>
                    this.props.navigationCallBack(
                      this.props.bettingGuideLink,
                      this.props.bettingGuideLinkName
                    )
                  }
                >
                  <Icon icon={cup} color={buildColor("grey", "900")} />
                  <LinkText>
                    {this.props.bettingGuideLinkName}
                    {this.props.bettingGuideLinkNew && (
                      <AccountHighlightedText>New</AccountHighlightedText>
                    )}
                  </LinkText>
                </AccountLink>
              </li>
            )}
            {this.props.isSeoTrackListEnabled && (
              <li>
                <AccountLink
                  onTouchStart={noop}
                  data-qa-label="account-nav-track-list"
                  to={this.props.trackListUrl}
                  onClick={() =>
                    this.props.navigationCallBack(
                      this.props.trackListUrl,
                      "Track List"
                    )
                  }
                >
                  <Icon icon={tracks} color={buildColor("grey", "900")} />
                  <LinkText>Track Information List</LinkText>
                </AccountLink>
              </li>
            )}
            {this.props.enableSeoMEPMenuItems &&
              this.props.seoMEPMenuItems.length > 0 &&
              this.renderSeoLinks()}
          </AccountLinkGroup>
          {!this.props.userBetPreferences &&
            (this.props.showBetConfirmationToggle ||
              this.props.showEnableTouchId) && (
              <AccountLinkGroup device={this.props.device}>
                {this.props.showBetConfirmationToggle && (
                  <li>
                    <AccountLinkRow data-qa-label="account-nav-betConfirmation">
                      <LinkText>Bet confirmation</LinkText>
                      <Toggle
                        on={this.props.isBetConfirmationEnabled}
                        onClick={this.props.onBetConfirmationToggle}
                      />
                    </AccountLinkRow>
                  </li>
                )}
                {this.props.showEnableTouchId && (
                  <li>
                    <AccountLinkRow data-qa-label="account-nav-touchId">
                      <LinkText>
                        Enable {this.props.isFaceId ? "Face" : "Touch"} ID
                      </LinkText>
                      <Toggle
                        on={this.props.isTouchIdEnabled}
                        onClick={this.props.onTouchIdToggle}
                      />
                    </AccountLinkRow>
                  </li>
                )}
              </AccountLinkGroup>
            )}
          <AccountLinkGroup device={this.props.device}>
            {this.props.hasTutorials && (
              <li>
                <AccountLink
                  onTouchStart={noop}
                  data-qa-label="account-nav-tutorials"
                  to={this.props.tutorialsUrl}
                  onClick={() =>
                    this.props.contentNavigationCallBack("Tutorials")
                  }
                >
                  <LinkText>
                    Tutorials
                    {this.props.tutorialsLinkNew && (
                      <AccountHighlightedText>New</AccountHighlightedText>
                    )}
                  </LinkText>
                </AccountLink>
              </li>
            )}
            <li>
              <AccountLink
                onTouchStart={noop}
                data-qa-label="account-nav-privacyPolicy"
                to={this.props.privacypolicyUrl}
                onClick={() =>
                  this.props.contentNavigationCallBack("Privacy Policy")
                }
              >
                <LinkText>Privacy Policy</LinkText>
              </AccountLink>
            </li>
            <li>
              <AccountLink
                onTouchStart={noop}
                data-qa-label="account-nav-privacyPolicy"
                to={this.props.californiaPolicyUrl}
                onClick={() =>
                  this.props.contentNavigationCallBack("Privacy Policy")
                }
              >
                <LinkText>California Privacy Policy</LinkText>
              </AccountLink>
            </li>
            {this.props.doNotSellUrl && (
              <li>
                <AccountExternalLink
                  data-qa-label="account-do-not-sell"
                  onClick={(e) => {
                    this.props.onExternalLinkCallback(
                      this.props.doNotSellUrl,
                      e
                    );
                    this.props.navigationCallBack(
                      this.props.doNotSellUrl,
                      "Do not sell Navigation"
                    );
                  }}
                >
                  <LinkText>{this.props.doNotSellLabel}</LinkText>
                </AccountExternalLink>
              </li>
            )}
            <li>
              <AccountLink
                onTouchStart={noop}
                data-qa-label="account-nav-TermsConditions"
                to={this.props.termsConditionsUrl}
                onClick={() =>
                  this.props.contentNavigationCallBack("Terms and Conditions")
                }
              >
                <LinkText>Terms & Conditions</LinkText>
              </AccountLink>
            </li>
            {this.props.showWagerResponsibly && (
              <li>
                <AccountLink
                  onTouchStart={noop}
                  data-qa-label="account-nav-WagerResponsibly"
                  to={this.props.wagerResponsiblyUrl}
                  onClick={() =>
                    this.props.contentNavigationCallBack("Wager Responsibly")
                  }
                >
                  <LinkText>Wager Responsibly</LinkText>
                </AccountLink>
              </li>
            )}
            {this.props.showResponsibleGaming && (
              <li>
                <AccountLink
                  onTouchStart={noop}
                  data-qa-label="account-nav-responsibleGaming"
                  to={this.props.responsibleGamingUrl}
                  onClick={() => {
                    AlchemerEvents.selectResponsibleGaming();
                    this.props.navigationCallBack(
                      this.props.responsibleGamingUrl,
                      "responsible gambling"
                    );
                  }}
                >
                  <LinkText>Responsible Gaming</LinkText>
                </AccountLink>
              </li>
            )}
            {this.props.enableAccessibility && (
              <li>
                <AccountLink
                  onTouchStart={noop}
                  data-qa-label="account-nav-accessibility"
                  to={this.props.accessibilityUrl}
                  onClick={() =>
                    this.props.contentNavigationCallBack("Accessibility")
                  }
                >
                  <LinkText>Accessibility</LinkText>
                </AccountLink>
              </li>
            )}
            {!this.props.showLoginMessage && (
              <li>
                <AccountExternalLink
                  data-qa-label="account-nav-feedback"
                  onClick={() => {
                    this.props.onSendFeedback();
                    this.props.navigationCallBack(
                      this.props.feedbackUrl,
                      "Send Feedback"
                    );
                  }}
                >
                  <LinkText>Send Feedback</LinkText>
                </AccountExternalLink>
              </li>
            )}
            <li>
              <AccountExternalLink
                data-qa-label="account-nav-careers"
                onClick={(e) => {
                  this.props.onExternalLinkCallback(this.props.careersUrl, e);
                  this.props.navigationCallBack(
                    this.props.careersUrl,
                    "Careers"
                  );
                }}
              >
                <LinkText>Careers</LinkText>
              </AccountExternalLink>
            </li>
            <li>
              <AccountExternalLink
                data-qa-label="account-nav-go-desktop"
                onClick={(e) => {
                  this.props.onExternalLinkCallback(this.props.desktopUrl, e);
                  this.props.navigationCallBack(
                    this.props.desktopUrl,
                    "Go to Desktop"
                  );
                }}
              >
                <LinkText>Go To Desktop</LinkText>
              </AccountExternalLink>
            </li>
            <li>
              <AccountExternalLink
                data-qa-label="account-nav-FAQ"
                onClick={(e) => {
                  this.props.onExternalLinkCallback(
                    this.props.watchTvgFaqUrl,
                    e
                  );
                  this.props.navigationCallBack(
                    this.props.watchTvgFaqUrl,
                    "TVG FAQ"
                  );
                }}
              >
                <LinkText>TVG FAQ</LinkText>
              </AccountExternalLink>
            </li>
            <li>
              <AccountAppVersion
                onClick={() => this.onVersionClick()}
                data-qa-label="account-app-version"
              >
                <LinkText data-qa-label="account-app-version-text">
                  App Version ({this.props.appVersion})
                </LinkText>
              </AccountAppVersion>
            </li>
            {this.props.showConnecticutDisclaimer && (
              <li className="disclaimer">
                <DisclaimerContainer>
                  <DisclaimerText>
                    {this.props.connecticutDisclaimer}
                  </DisclaimerText>
                </DisclaimerContainer>
              </li>
            )}
          </AccountLinkGroup>
          <ButtonSection device={this.props.device}>
            <SupportButton
              qaLabel="account-supportBtn"
              type="secondary"
              size="huge"
              url={this.props.supportUrl}
              onClick={() => this.props.supportGtm()}
            >
              Support
            </SupportButton>
            {this.props.showLogoutButton && (
              <Link to="/" state={{ isLogout: true }}>
                <LogoutButton
                  qaLabel="account-logoutBtn"
                  type="secondary"
                  size="huge"
                  isBold={false}
                  isStretched
                  isUppercase={false}
                  onClick={this.props.onLogout}
                >
                  log out
                </LogoutButton>
              </Link>
            )}
          </ButtonSection>
        </AccountContainer>
      </AccountSection>
    );
  }
}
