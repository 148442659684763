import { string } from "yup";
import { get } from "lodash";

import { getZipValidationMemo } from "./utils";

export const invertDate = (date) => {
  const [month, day, year] = date.split("-");
  return `${year}-${month}-${day}`;
};

export const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

export const cityValidation = ({ scope, labels }) =>
  string()
    .required(get(labels, `${scope}.errors.cityEmpty`))
    .test("no-numbers", get(labels, `${scope}.errors.cityEmpty`), (value) => {
      return value.match(/^[a-zA-Z\s]+$/);
    });

export const streetValidation = ({ scope, labels }) =>
  string()
    .required(get(labels, `${scope}.errors.streetEmpty`))
    .test("po-box-error", get(labels, `${scope}.errors.pobox`), (value) => {
      const valueLowerCase = value.toLowerCase();
      return !(
        valueLowerCase.includes("p.o. box") || valueLowerCase.includes("po box")
      );
    })
    .test(
      "starts-with-number",
      get(labels, `${scope}.errors.streetMustStartWithNumber`),
      (value) => {
        return value === "" || value.match(/^\d+(-\d+)?\s+.+$/);
      }
    )
    .test("not-empty", get(labels, `${scope}.errors.streetEmpty`), (value) => {
      return value !== "";
    });

export const stateValidation = ({ scope, labels }) =>
  string().required(get(labels, `${scope}.errors.stateEmpty`));

export const zipValidation = ({ scope, labels }) =>
  string()
    .required(get(labels, `${scope}.errors.zipCodeEmpty`))
    .test(
      "incomplete-error",
      get(labels, `${scope}.errors.zipCodeEmpty`),
      (value) => {
        return !value.includes("_");
      }
    )
    .test(
      "invalid-error",
      get(labels, `${scope}.errors.zipCodeEmpty`),
      (value) => {
        if (value && !value.includes("_")) {
          return getZipValidationMemo(value)
            .then((result) => {
              const status = get(result, "data.status");
              if (status === "success") {
                return true;
              }
              return false;
            })
            .catch(() => {
              return true;
            });
        }
        return false;
      }
    );
