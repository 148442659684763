import TvgConfig from "@tvg/conf";
import mediator from "@tvg/mediator";
import * as mediatorClassic from "@tvg/mediator-classic/src";

const FULL_URL_REGEX = /^https?:\/\//;
const RELATIVE_PATH_REGEX = /^\/+(.*)/;

const NATIVE_PRODUCTS = ["iosnative", "androidnative"];
const EXTERNAL_APP_PRODUCTS = [
  "ios2",
  "androidwrapper",
  "fdrandroid",
  "tvgandroid"
];

const isFullUrl = (url) => FULL_URL_REGEX.test(url);
const isRelativePath = (url) => RELATIVE_PATH_REGEX.test(url);

export const inferUrl = (url) => {
  let inferredUrl;
  if (isFullUrl(url)) {
    inferredUrl = url;
  } else if (isRelativePath(url)) {
    inferredUrl = `${window.location.origin}${url}`;
  } else {
    inferredUrl = `http://${url}`;
  }
  return inferredUrl;
};

export const openExternalLinkIos = (url) => {
  const openExternalApp = inferUrl(url);

  mediator.ios.dispatch({
    type: "OPEN_EXTERNAL_APP",
    payload: {
      openExternalApp
    }
  });
};

export const openExternalLinkNative = (url) => {
  const confInstance = TvgConfig();
  let absoluteUrl = url;

  if (isRelativePath(url)) {
    absoluteUrl = confInstance.buildUrl({
      app: confInstance.product,
      path: url
    });
  }

  window.handleNativeMessages("OPEN_EXTERNAL_URL", {
    url: absoluteUrl
  });
};

export const openExternalApp = (url) => {
  const confInstance = TvgConfig();
  if (NATIVE_PRODUCTS.includes(confInstance.product)) {
    openExternalLinkNative(url);
  } else if (EXTERNAL_APP_PRODUCTS.includes(confInstance.product)) {
    openExternalLinkIos(url);
  } else if (window) {
    const openExternalLink = inferUrl(url);
    const tab = window.open(openExternalLink, "_blank");
    tab.focus();
  }
};

export const openQuickDepositDesktop = () => {
  const confInstance = TvgConfig();
  if (confInstance.device !== "desktop") return;

  mediatorClassic.dispatch("HEADER_DESKTOP_OPEN_SECTION", {
    section: "Quick Deposit"
  });
  mediatorClassic.dispatch("OPEN_QUICK_DEPOSIT", {});
};

export const generateLinkString = (url, text, callback) => {
  if (isRelativePath(url))
    return `<a href='${url}' onclick='${callback}'>${text}</a>`;

  const confInstance = TvgConfig();
  if (confInstance.device === "mobile" && confInstance.product === "ios2") {
    return `<a href='#' onclick='window.webkit && window.webkit.messageHandlers["OPEN_EXTERNAL_APP"].postMessage({ type: "OPEN_EXTERNAL_APP", payload: { openExternalApp: "${inferUrl(
      url
    )}"}})'>${text}</a>`;
  }
  return `<a target='_blank' href='${inferUrl(url)}'>${text}</a>`;
};

export default openExternalLinkIos;
