import React from "react";

import Text from "@tvg/atomic-ui/_atom/Text";

import InternalLink from "../../_atoms/InternalLink";
import ExternalLink from "../../_atoms/ExternalLink";
import Icon from "../../_static/Icons";
import buildColor from "../../_static/ColorPalette";
import { arrowRight, popOut } from "../../_static/Icons/icons";
import MenuItemLinkContainer from "./styled-components";

const MenuLinkItem = ({ text, path, slug, qaLabel }) => {
  const renderExternalLink = () => (
    <ExternalLink url={path.url} qaLabel={`${qaLabel}ExternalLink`}>
      <Text
        tag="span"
        normal
        fontSize={14}
        color={buildColor("grey", "900")}
        qaLabel={`${qaLabel}ExternalLinkText`}
      >
        {text}
      </Text>
      <Icon
        icon={popOut}
        size={16}
        view="0 0 16 16"
        qaLabel={`${qaLabel}ExternalLinkIcon`}
      />
    </ExternalLink>
  );

  const renderInternalLink = () => (
    <InternalLink
      pathName={path.linktype === "story" ? path.cached_url : path.url}
      linkType={path.linktype}
      target="_self"
      qaLabel={`${qaLabel}InternalLink`}
    >
      <Text
        tag="span"
        normal
        fontSize={14}
        color={buildColor("grey", "900")}
        qaLabel={`${qaLabel}InternalLinkText`}
      >
        {text}
      </Text>
      <Icon
        icon={arrowRight}
        size={16}
        view="0 0 16 16"
        qaLabel={`${qaLabel}InternalLinkIcon`}
      />
    </InternalLink>
  );

  return (
    <MenuItemLinkContainer
      focusOn={path.cached_url === slug}
      data-qa-label={qaLabel}
    >
      {path.linktype === "story" ||
      (path.url.indexOf("http") < 0 && path.linktype === "url")
        ? renderInternalLink()
        : renderExternalLink()}
    </MenuItemLinkContainer>
  );
};

MenuLinkItem.defaultProps = {
  text: "",
  path: {
    url: "",
    cached_url: "",
    linktype: "story"
  },
  slug: "",
  qaLabel: "menuLinkItem"
};

export default MenuLinkItem;
