import styled from "styled-components";
import Button from "../../_atom/Buttons/button";
import buildColor from "../../_static/ColorPalette";

export const ResultsTable = styled.table`
  width: 100%;
`;

export const SeeAllRunnersSection = styled.div`
  background-color: ${buildColor("blue", "000")};
  border-bottom: 1px solid ${buildColor("grey", "300")};
`;

export const AllRunnersButton = styled(Button)`
  width: 100%;
`;

export const IconContainer = styled.div`
  padding-left: 3px;
  height: 16px;
`;

export const OtherInfo = styled.div`
  display: flex;
  flex-direction: column;
  background: ${buildColor("white", "100")};
  font-size: 12px;
  padding: 16px 8px;
  border-bottom: 1px solid ${buildColor("grey", "300")};

  & > :nth-child(2) {
    margin-top: 8px;
  }
`;

export const WinningTimeLabel = styled.strong`
  padding-right: 16px;
  padding-left: 3px;
  color: ${buildColor("grey", "900")};
`;

export const WinningTime = styled.div`
  display: flex;
  color: ${buildColor("grey", "800")};
`;

export const ScratchesLabel = styled.strong`
  padding-right: 34px;
  padding-left: 3px;
  color: ${buildColor("grey", "900")};
`;

export const Scratches = styled.div`
  display: flex;
  color: ${buildColor("grey", "800")};
`;

export const Number = styled.div`
  width: 16px;
  text-align: center;
  margin-right: 2px;
`;
