import styled from "styled-components";
import { margin } from "styled-system";

export const StyledRadio = styled.input`
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;
  margin: 0;
  cursor: pointer;
  appearance: none;
  accent-color: var(
    --fd-colors-component-selection-control-background-selected
  );
  background-color: var(
    --fd-colors-component-selection-control-background-base
  );
  box-shadow: var(--fd-shadows-elevations-bottom-low);
  border: 1px solid var(--fd-colors-component-selection-control-border-base);
  border-radius: 100px;
  padding: 10px;

  &:hover {
    background-color: var(
      --fd-colors-component-selection-control-background-hover
    );
    border: 1px solid var(--fd-colors-component-selection-control-border-hover);
  }

  &:checked {
    justify-content: center;
    align-items: center;
    border: 1px solid
      var(--fd-colors-component-selection-control-border-selected);
    background-color: var(
      --fd-colors-component-selection-control-background-selected
    );
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' r='4' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
  }

  &:disabled {
    background-color: var(
      --fd-colors-component-selection-control-background-disabled
    );
    border: 1px solid
      var(--fd-colors-component-selection-control-border-disabled);
    cursor: default;
  }

  &:checked:disabled {
    background-color: var(
      --fd-colors-component-selection-control-background-disabled
    );
    border: 2px solid var(--fd-colors-content-disabled);
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' r='4' fill='%23CED4DB'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    cursor: default;
  }

  &[data-invalid="true"] {
    border: 1px solid var(--fd-colors-system-warning-border-default);
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  ${margin}
`;

export const StyledLabelContainer = styled.div`
  cursor: pointer;
  flex-shrink: 1;
`;
