import React, { PureComponent } from "react";

import { noop, bindAll, get } from "lodash";
import { success, warning, arrowDown } from "../../_static/Icons/icons";

import Icon from "../../_static/Icons";
import buildColor from "../../_static/ColorPalette";
import {
  SelectWrapper,
  Select,
  SelectContainer,
  SelectLabel,
  StatusIcon,
  WarningMessage,
  IconWrapper
} from "./styled-components";

const getIcon = (status, qaLabel) => {
  const iconProps =
    status === "success"
      ? { icon: success, color: buildColor("green", "600") }
      : {
          icon: warning,
          color: buildColor("orange", "600")
        };
  return (
    <StatusIcon
      icon={iconProps.icon}
      color={iconProps.color}
      size={16}
      qaLabel={`${qaLabel}${status}`}
    />
  );
};

export default class SelectMolecule extends PureComponent {
  static defaultProps = {
    options: [],
    qaLabel: "",
    callback: noop,
    onBlurHandler: noop,
    enterKeyHandler: noop,
    status: null,
    label: "",
    placeholder: ""
  };

  constructor(props) {
    super(props);

    bindAll(this, ["renderLabel", "renderSelect", "renderWarningMsg"]);

    this.state = {
      isStateSelected: false
    };
  }

  renderLabel() {
    const { qaLabel, label } = this.props;
    return (
      <SelectLabel htmlFor="stateSelector" data-qa-label={`${qaLabel}Label`}>
        {label}
      </SelectLabel>
    );
  }

  renderSelect() {
    return (
      <SelectWrapper>
        <Select
          onChange={(e) => {
            this.setState({
              isStateSelected: e.target.value !== "select-state"
            });
            this.props.callback("state", e.target.value);
          }}
          onBlur={(e) => this.props.onBlurHandler(e)}
          isError={this.props.status === "error"}
          id="stateSelector"
          data-qa-label={this.props.qaLabel}
          onKeyPress={this.props.enterKeyHandler}
          defaultValue="select-state"
          className={this.state.isStateSelected && "state-selected"}
        >
          <option value="select-state" disabled>
            Select a State
          </option>

          {this.props.options.map((opt) => (
            <option key={opt.name} value={opt.abbreviation}>
              {opt.name}
            </option>
          ))}
        </Select>
        <IconWrapper className="chevron-icon-wrapper">
          <Icon icon={arrowDown} color={buildColor("blue_accent", "500")} />
        </IconWrapper>
      </SelectWrapper>
    );
  }

  renderWarningMsg() {
    const { qaLabel, label, status } = this.props;
    const txtLabel = React.isValidElement(label)
      ? get(label, "props.text", "")
      : label;
    return (
      status === "error" && (
        <WarningMessage data-qa-label={`${qaLabel}ErrMsg`}>
          {status && getIcon(status, qaLabel)}
          Please select a {txtLabel}
        </WarningMessage>
      )
    );
  }

  render() {
    return (
      <SelectContainer data-qa-label={`${this.props.qaLabel}Section`}>
        {this.renderLabel()}
        {this.renderSelect()}
        {this.renderWarningMsg()}
      </SelectContainer>
    );
  }
}
