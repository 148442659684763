import { get } from "lodash";
import { reach } from "yup";

export const handleOnTheFlyChange = async (
  isTouched,
  value,
  fieldName,
  schema,
  formRef
) => {
  if (isTouched) {
    const fieldSchema = reach(schema, fieldName);
    try {
      await fieldSchema.validate(value, {
        abortEarly: true
      });
      formRef.current.setFieldError(fieldName, "");
    } catch (err) {
      formRef.current.setFieldError(
        fieldName,
        get(err, "errors[0]", "Please fill this field")
      );
    }
  }
};
