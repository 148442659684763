import styled from "styled-components";
import ReactInputMask from "react-input-mask";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontMedium, fontMono } from "../../_static/Typography";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop}px;` : "")}
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}px;` : ""}
  ${({ borderTop }) =>
    borderTop
      ? `border-top: ${borderTop}px solid ${buildColor("blue", "100")};`
      : ""}
  ${({ paddingTop }) => (paddingTop ? `padding-top: ${paddingTop}px ;` : "")}
`;

export const Label = styled.label`
  font-family: ${fontMedium};
  font-weight: 500;
  font-size: 14px;
  color: ${buildColor("grey", "900")};
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : "5px"};
`;

export const OptionalLabel = styled.span`
  font-family: ${fontNormal};
  font-weight: 500;
  font-size: 12px;
  color: ${buildColor("grey", "700")};
  margin-left: 4px;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1;
  min-width: 0;
  outline: none;
  height: 42px;
  border-radius: 2px;
  font-family: ${fontNormal};
  font-size: ${({ type, currentValue }) =>
    type === "password" && currentValue?.length > 0 ? "24px" : "14px"};
  border: 1px solid ${buildColor("blue", "100")};
  box-shadow: 0 2px 2px rgb(17 43 68 / 15%);
  color: ${buildColor("grey", "900")};
  padding: 0 12px;

  ${({ hasIcon }) => hasIcon && `padding-right: 48px;`}
  ${({ error }) =>
    error &&
    `
    box-shadow: none;
    border: 2px solid ${buildColor("orange", "600")};
  `}
  &:focus {
    border: 2px solid ${buildColor("blue_accent", "500")};
  }

  ${({ isLowerCase }) => isLowerCase && `text-transform: lowercase;`}

  &::placeholder {
    font-size: 14px;
  }

  &:disabled {
    background-color: ${buildColor("blue", "000")};
    color: ${buildColor("grey", "800")};
    -webkit-text-fill-color: ${buildColor("grey", "800")};
    opacity: 1;
  }

  /* remove arrows when type is number */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const IconWrapper = styled.button`
  position: absolute;
  top: 9px;
  right: 8px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  outline: none;

  &:hover {
    background-color: ${buildColor("blue_accent", "100")};
    border-radius: 50%;
  }
`;

export const InputMaskStyled = styled(ReactInputMask)`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1;
  outline: none;
  height: 42px;
  border-radius: 2px;
  font-family: ${({ $isMono }) => ($isMono ? fontMono : fontNormal)};
  font-size: 14px;
  min-width: 0;
  letter-spacing: ${({ letterSpacing }) => `${letterSpacing}px`};
  border: 1px solid ${buildColor("blue", "100")};
  box-shadow: 0 2px 2px rgb(17 43 68 / 15%);
  color: ${buildColor("grey", "900")};
  padding: 0 12px;

  ${({ $alignCenter }) => ($alignCenter ? "text-align: center;" : "")}
  ${({ error }) =>
    error &&
    `
    box-shadow: none;
    border: 2px solid ${buildColor("orange", "600")};
  `}
  &:focus {
    border: 2px solid ${buildColor("blue_accent", "500")};
  }
`;

export const ErrorText = styled.p`
  display: flex;
  align-items: flex-start;
  font-family: ${fontNormal};
  font-weight: 400;
  font-size: 14px;
  color: ${buildColor("orange", "600")};
  margin-top: 8px;

  svg {
    margin-right: 6px;
  }
`;
