/* eslint-disable security/detect-object-injection */
import React from "react";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import { MtpProps } from "./types";
import {
  getMoreThanSixMinutesTextColor,
  getMtpColorTextColor,
  getPostTimeLessThanSixtyMinutes,
  getPostTimeMoreThanSixtyMinutes,
  GetRaceOffAndOfficialText,
  getSizeVariantsStyles,
  getWeight
} from "./builder";
import { Paragraph } from "../Typography";
import { MtpWrapper } from "./styled-components";

const Mtp: React.FC<MtpProps> = ({
  status,
  mtp,
  postTime,
  size = "s",
  align = "left",
  qaLabel = "mtp",
  mode = "light",
  isMtpPro = false,
  ...props
}) => {
  const sizeVariantStyles = getSizeVariantsStyles(size, mtp, status);

  if (
    [RaceStatusEnum.RACE_OFF, RaceStatusEnum.RACE_OFFICIAL].includes(status)
  ) {
    return (
      <MtpWrapper {...props}>
        {GetRaceOffAndOfficialText(
          status,
          size,
          postTime,
          align,
          qaLabel,
          mode
        )}
      </MtpWrapper>
    );
  }

  return mtp > 59 ? (
    <MtpWrapper {...props}>
      <Paragraph
        color={getMoreThanSixMinutesTextColor(mode)}
        fontSize={sizeVariantStyles[size].mtpText.fontSize}
        fontWeight={sizeVariantStyles[size].mtpText.fontWeight}
        lineHeight={sizeVariantStyles[size].mtpText.lineHeight}
        fontFamily={sizeVariantStyles[size].mtpText.fontFamily}
        textAlign={align}
        qaLabel={qaLabel}
        margin={0}
      >
        {getPostTimeMoreThanSixtyMinutes(size, postTime)}
      </Paragraph>
    </MtpWrapper>
  ) : (
    <MtpWrapper {...props}>
      <Paragraph
        color={getMtpColorTextColor(mtp, mode)}
        fontFamily={sizeVariantStyles[size].mtpText.fontFamily}
        fontSize={sizeVariantStyles[size].mtpText.fontSize}
        fontWeight={getWeight(mtp, size)}
        lineHeight={sizeVariantStyles[size].mtpText.lineHeight}
        textAlign={align}
        qaLabel={qaLabel}
        margin={0}
        display={isMtpPro ? "flex" : ""}
      >
        {getPostTimeLessThanSixtyMinutes(
          size,
          mtp,
          align,
          getMtpColorTextColor(mtp, mode),
          isMtpPro
        )}
      </Paragraph>
    </MtpWrapper>
  );
};

export default Mtp;
export { MtpProps, Mtp };
