import styled from "styled-components";
import type { CSSProperties } from "react";
import type {
  ContentLayoutProps,
  Placement,
  TriggerLayoutProps
} from "./types";

const calculatePlacement = (
  placement: Placement,
  triggerLayout: TriggerLayoutProps,
  contentLayout: ContentLayoutProps,
  isLeftSideRendered?: boolean
): CSSProperties => {
  const {
    x: triggerX,
    y: triggerY,
    width: triggerWidth,
    height: triggerHeight
  } = triggerLayout;
  const { width: contentWidth, height: contentHeight } = contentLayout;
  const viewportWidth = window.innerWidth; // using window.screen.width will not work for wagerpad
  const viewportHeight = window.screen.height; // using window.innerHeight will not work for elements rendered outside the viewport

  const placementMap: Record<Placement, { top: number; left: number }> = {
    "top-left": {
      top: triggerY - contentHeight,
      left: triggerX
    },
    "top-right": {
      top: triggerY - contentHeight,
      left: triggerX + triggerWidth - contentWidth
    },
    "top-mid": {
      top: triggerY - contentHeight,
      left: triggerX + triggerWidth / 2 - contentWidth / 2
    },
    "bottom-left": {
      top: triggerY + triggerHeight,
      left: triggerX
    },
    "bottom-right": {
      top: triggerY + triggerHeight,
      left: triggerX + triggerWidth - contentWidth
    },
    "bottom-mid": {
      top: triggerY + triggerHeight,
      left: triggerX + triggerWidth / 2 - contentWidth / 2
    },
    left: {
      top: triggerY + triggerHeight / 2 - contentHeight / 2,
      left: triggerX - contentWidth
    },
    right: {
      top: triggerY + triggerHeight / 2 - contentHeight / 2,
      left: triggerX + triggerWidth
    },
    "drop-down": {
      top: triggerY + triggerHeight,
      left: triggerX
    }
  };

  const result = placementMap[placement as keyof typeof placementMap];

  let { left, top } = result;

  if (left + contentWidth > viewportWidth) {
    left = viewportWidth - contentWidth - 10;
  }

  if (top + contentHeight > viewportHeight) {
    top = viewportHeight - contentHeight - 10;
  }

  if (isLeftSideRendered && left < 0) {
    left = 5;
  }

  return { top: `${top}px`, left: `${left}px` };
};

export const PopperContainer = styled.div`
  cursor: default;
`;

export const PopperContent = styled.div.attrs<{
  placement: Placement;
  triggerLayout: TriggerLayoutProps;
  contentLayout: ContentLayoutProps;
  isVisible: boolean;
  isLeftSideRendered?: boolean;
  layer: number;
}>(
  ({ placement, triggerLayout, contentLayout, layer, isLeftSideRendered }) => ({
    style: {
      position: "absolute",
      zIndex: layer || 1999,
      left: 0,
      top: 0,
      ...calculatePlacement(
        placement,
        triggerLayout,
        contentLayout,
        isLeftSideRendered
      )
    }
  })
)<{
  placement: Placement;
  triggerLayout: TriggerLayoutProps;
  contentLayout: ContentLayoutProps;
  isVisible: boolean;
  layer: number;
  isLeftSideRendered?: boolean;
}>`
  z-index: ${({ layer }) => layer || 1999};
  background-color: transparent;
  border-radius: 4px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: none;
  transform: ${({ isVisible }) =>
    isVisible
      ? "scale(1) translateY(0) translateX(0)"
      : "scale(0.95) translateY(16px) translateX(-12px)"};
  transition:
    opacity 200ms ease-in-out,
    transform 200ms cubic-bezier(0.2, 0.8, 0.8, 1);

  &[data-hidden="true"] {
    visibility: hidden;
    opacity: 0;
  }
`;

export const Trigger = styled.div`
  background: none;
  border: none;
`;
