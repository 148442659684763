import type { ColorPropertiesGroups, ButtonVariant } from "./types";
import { buttonsColorPalette } from "./buttonsColorPalette";

export const createPalette = (
  variant: ButtonVariant,
  colorsObject?: ColorPropertiesGroups
) => {
  const baseColors = buttonsColorPalette[variant as ButtonVariant];
  return { ...baseColors, ...colorsObject };
};
