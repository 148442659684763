import React, { useState, useEffect, useRef } from "react";
import { get, find } from "lodash";
import moment from "moment";

import tvgConf from "@tvg/conf";
import ProcessingOverlay from "@tvg/atomic-ui/_static/ProcessingOverlay";
import Context from "@tvg-mar/promos-context";
import {
  handleUserOptinClick,
  handleReactNativeEvents
} from "@tvg-mar/utils/optinHandlingUtils";

import Components from "../../_dictionary";
import Banner from "../../_organisms/Banner";
import OptinFooterButton from "../../_molecules/OptinFooterButton";
import BackButton from "../../_molecules/BackButton";
import LegalsContent from "../../_static/LegalsContent";
import EligibleRaces from "../../_organisms/EligibleRaces";
import { filterComingUpRaces } from "../../_organisms/EligibleRaces/utils";
import ContestNotifications from "../../_organisms/ContestNotifications";
import {
  Separator,
  ProcessingContainer,
  LeaderboardPageContainer,
  LeaderboardContentGrid,
  LeaderboardBodyGrid,
  LeaderboardGlobalGrid,
  LeaderboardContentStacked
} from "./styled-components";

const { OptinContext, UserContext } = Context;

const LeaderboardPage = ({
  isLoading,
  user,
  isDesktop,
  history,
  content,
  isLoadingStandings,
  isLoadingStandingsError,
  isLoadingStandingsPageError,
  contestStandingsDates,
  contestStandings,
  contestStandingsPages,
  userStandings,
  optinInfo,
  comingUpRaces,
  comingUpRacesLoading,
  allRaces,
  allRacesLoading,
  getContestPageData,
  isMTPNewRules,
  qaLabel
}) => {
  const { returningUser, isLogged, accountNumber, optedInPromos } = user;
  const promoId = +get(content, "promo_type.promo_id", 0);
  const nextComingUpRace = filterComingUpRaces(
    comingUpRaces,
    allRaces,
    1,
    1
  ).races;
  const hasComingUpRaces =
    nextComingUpRace.length > 0 && get(nextComingUpRace[0], "postTime", false);

  const showBackBtn =
    get(history, "location.state.showBackBtn", false) ||
    get(history, "location.search", "").includes("showBackBtn");

  const [isOpted, setIsUserOpted] = useState(false);

  const topPageRef = useRef();

  const scrollModalToTop = () => {
    if (topPageRef) {
      topPageRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (!isOpted && get(optedInPromos, `${promoId.toString()}`, false)) {
      setIsUserOpted(true);
    } else {
      setIsUserOpted(false);
    }
    scrollModalToTop();
  }, [JSON.stringify(optedInPromos)]);

  const [optinError, setOptinError] = useState("");
  const [sentOptinEvents, setSentOptinEvents] = useState(false);
  const [showNextRaceButton, setShowNextRaceButton] = useState(false);

  useEffect(() => {
    if (hasComingUpRaces) {
      setShowNextRaceButton(true);
    } else {
      setShowNextRaceButton(false);
    }
  }, [hasComingUpRaces]);

  // TODO: Logic replicated from page template and tranformed into hook. Functionality needs to be reviewed for react native.
  useEffect(() => {
    if (tvgConf.product === "iosnative" && optinInfo.state !== "") {
      if (sentOptinEvents) {
        setSentOptinEvents(false);
      }

      handleReactNativeEvents(
        optinInfo,
        sentOptinEvents,
        promoId,
        setOptinError,
        setSentOptinEvents
      );
    }
  }, [optinInfo.state]);

  const getLocalDate = (date) => {
    const utcDate = moment.utc(date).toDate();
    return moment(utcDate).local().format();
  };

  const contestStartDate = getLocalDate(get(content, "contestStartDate", ""));
  const contestEndDate = getLocalDate(get(content, "contestEndDate", ""));

  const [showNotification, setShowNotification] = useState(false);
  const [isEligible, setIsEligible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowNotification(true);

      if (moment().isBefore(contestEndDate)) {
        setIsEligible(true);
      }
    }, 2000);
  }, []);

  const isOptinError = !!optinError;
  let typeMessage = "info";

  if (isOpted) {
    typeMessage = "success";
  } else if (isOptinError) {
    typeMessage = "error";
  }

  let banner;
  let ctaButton;
  let presentation;
  let prizes;
  let contestRaces;
  let standings;
  let howToPlay;
  let legalContent;

  Object.keys(content).forEach((type) => {
    switch (type) {
      case "banner":
        banner = find(get(content, "banner"), { component: "banner_v2_0" });
        break;
      case "optin_button":
        ctaButton = find(get(content, "optin_button"), {
          component: "optin_button"
        });
        break;
      case "presentation":
        presentation = get(content, "presentation");
        break;
      case "prizes":
        prizes = get(content, "prizes");
        break;
      case "contest_races":
        contestRaces = get(content, "contest_races");
        break;
      case "standings":
        standings = get(content, "standings");
        break;
      case "how_to_play":
        howToPlay = get(content, "how_to_play");
        break;
      case "legal_content":
        legalContent = get(content, "legal_content");
        break;
      default:
        break;
    }
  });

  const renderGrid = () => (
    <LeaderboardContentGrid data-qa-label={`${qaLabel}ContentGrid`}>
      <LeaderboardBodyGrid data-qa-label={`${qaLabel}BodyGrid`}>
        {presentation.map((blok) => Components({ ...blok, isModule: true }))}
        <Separator data-qa-label={`${qaLabel}Separator`} />
        {standings.map((blok) =>
          Components({
            ...blok,
            isModule: true,
            accountNumber,
            contestStartDate,
            isLoadingStandings,
            isLoadingStandingsError,
            isLoadingStandingsPageError,
            contestStandingsDates,
            contestStandings,
            contestStandingsPages,
            userStandings,
            getContestPageData
          })
        )}
      </LeaderboardBodyGrid>
      <LeaderboardGlobalGrid data-qa-label={`${qaLabel}Prizes`}>
        {prizes.map((blok) =>
          Components({
            ...blok,
            isModule: true,
            isWidget: true,
            borderLess: true,
            paddingLess: true,
            fontSize: "14px"
          })
        )}
        {contestRaces && (
          <EligibleRaces
            content={contestRaces[0]}
            comingUpRaces={comingUpRaces}
            comingUpRacesLoading={comingUpRacesLoading}
            allRaces={allRaces}
            allRacesLoading={allRacesLoading}
            isMTPNewRules={isMTPNewRules}
            qaLabel={`${qaLabel}EligibleRaces`}
          />
        )}
        {howToPlay.map((blok) => Components({ ...blok, isModule: true }))}
      </LeaderboardGlobalGrid>
    </LeaderboardContentGrid>
  );

  const renderFooter = () =>
    ctaButton && isEligible ? (
      <OptinFooterButton
        content={{
          ...ctaButton,
          isMobilePersistent: true,
          isUpperCase: false
        }}
        isOpted={isOpted}
        showNextRaceBtn={showNextRaceButton}
        raceNumber={get(nextComingUpRace[0], "number", 0)}
        trackCode={get(nextComingUpRace[0], "trackCode", "")}
        trackName={get(nextComingUpRace[0], "trackName", "")}
        qaLabel={`${qaLabel}OptinFooterButton`}
      />
    ) : null;

  const renderStacked = () => (
    <LeaderboardContentStacked data-qa-label={`${qaLabel}ContentStacked`}>
      {presentation.map((blok) => Components({ ...blok, isModule: false }))}
      <Separator data-qa-label={`${qaLabel}SeparatorPresentation`} />
      {prizes.map((blok) =>
        Components({
          ...blok,
          isModule: false,
          isWidget: false,
          borderLess: true,
          paddingLess: true,
          fontSize: "14px"
        })
      )}
      <Separator data-qa-label={`${qaLabel}SeparatorPrizes`} />
      {contestRaces && (
        <EligibleRaces
          content={contestRaces[0]}
          comingUpRaces={comingUpRaces}
          comingUpRacesLoading={comingUpRacesLoading}
          allRaces={allRaces}
          allRacesLoading={allRacesLoading}
          isMTPNewRules={isMTPNewRules}
          qaLabel={`${qaLabel}EligibleRaces`}
        />
      )}
      <Separator data-qa-label={`${qaLabel}SeparatorEligibleRaces`} />
      {standings.map((blok) =>
        Components({
          ...blok,
          isModule: false,
          accountNumber,
          contestStartDate,
          isLoadingStandings,
          isLoadingStandingsError,
          isLoadingStandingsPageError,
          contestStandingsDates,
          contestStandings,
          contestStandingsPages,
          userStandings,
          getContestPageData
        })
      )}
      <Separator data-qa-label={`${qaLabel}SeparatorStandings`} />
      {howToPlay.map((blok) => Components({ ...blok, isModule: false }))}
      <Separator data-qa-label={`${qaLabel}SeparatorHowToPlay`} />
      {renderFooter()}
    </LeaderboardContentStacked>
  );

  return (
    <OptinContext.Provider
      value={{
        isOpted,
        optedIn: false,
        error: optinError,
        handleOptinClick: () =>
          handleUserOptinClick(user, promoId, setIsUserOpted, setOptinError),
        sentOptinEvents: false
      }}
    >
      <UserContext.Provider value={{ isLogged, returningUser, optedInPromos }}>
        <LeaderboardPageContainer
          data-qa-label={qaLabel}
          addBottomMargin={(returningUser || isLogged) && isEligible}
          ref={topPageRef}
        >
          {isLoading && (
            <ProcessingContainer data-qa-label={`${qaLabel}Processing`}>
              <ProcessingOverlay qaLabel={`${qaLabel}ProcessingOverlay`} />
            </ProcessingContainer>
          )}
          {showBackBtn && !isDesktop && (
            <BackButton qaLabel={`${qaLabel}BackButton`} />
          )}
          {banner && (
            <Banner
              content={{
                ...banner,
                isForReturningCustomers: true,
                isLogged,
                returningUser,
                isOpted,
                isEligible,
                showNextRaceBtn: showNextRaceButton,
                raceNumber: get(nextComingUpRace[0], "number", 0),
                trackCode: get(nextComingUpRace[0], "trackCode", ""),
                trackName: get(nextComingUpRace[0], "trackName", "")
              }}
              qaLabel={`${qaLabel}Banner`}
            />
          )}
          {showNotification && (
            <ContestNotifications
              type={typeMessage}
              isOpted={isOpted}
              errorType={optinError}
              contestStandingsDates={contestStandingsDates}
              showCountdown
              addBottomMargin={!!ctaButton && isEligible}
              borderTopRadius
              qaLabel={`${qaLabel}ContestNotification`}
            />
          )}
          {!isLoading && renderGrid()}
          {!isLoading && renderStacked()}
          {legalContent && (
            <LegalsContent
              content={legalContent[0]}
              qaLabel={`${qaLabel}LegalsContent`}
            />
          )}
        </LeaderboardPageContainer>
      </UserContext.Provider>
    </OptinContext.Provider>
  );
};

LeaderboardPage.defaultProps = {
  isLoading: true,
  user: {},
  isDesktop: false,
  content: {},
  isLoadingStandings: true,
  isLoadingStandingsError: false,
  isLoadingStandingsPageError: false,
  contestStandings: [],
  userStandings: [],
  currentPage: 0,
  currentContestEvent: 1,
  optinInfo: {
    promoId: "",
    state: "",
    error: ""
  },
  isMTPNewRules: false,
  qaLabel: "leaderboardPage"
};

export default LeaderboardPage;
