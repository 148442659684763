import React, { useEffect, useState } from "react";
import { first, get, last, noop, has } from "lodash";
import { connect } from "react-redux";
import MyBetsCard from "@tvg/atomic-ui/_organism/MyBetsCard";
import {
  getFeatureIsMTPNewRules,
  getSelectedSettledTab,
  getSelectedTab,
  getShowSeeResult,
  getTodayActiveCounter,
  getTodaySettledCounter,
  getWatchReplayWithinMyBets,
  getIsMyBetsOpen
} from "@tvg/sh-lib-my-bets/redux/selectors";
import {
  getActiveLegs,
  getRacesInPickBets,
  isMultiRaceBet,
  getRaceProps
} from "@tvg/sh-lib-my-bets/utils/raceDetails";
import {
  getWagerToRender,
  scrollActiveBetIntoView,
  scrollActivePillIntoView,
  shouldRenderWager,
  useIsomorphicLayoutEffect
} from "@tvg/sh-lib-my-bets/utils/general";
import {
  getVideoFeedback,
  onVideoRedirect
} from "@tvg/sh-lib-my-bets/utils/video";
import buildRaceUrl from "@tvg/formatter/url";
import {
  cleanBetCancelResult,
  openMybetsPastPerformance,
  openBetSocialShareModal
} from "@tvg/sh-lib-my-bets/redux/actions";
import {
  handleRaceHeaderGtm,
  onCancelBetModalGtm
} from "@tvg/sh-lib-my-bets/utils/gtm";

import useAllRacesFromTrack from "@tvg/sh-lib-my-bets/hooks/useAllRacesFromTrack";
import MyBetsWager from "./MyBetsWager";

export const MyBetsBody = (props) => {
  const {
    dispatch,
    selectedTab,
    isMyBetsOpen,
    bet,
    index,
    races,
    closeModal,
    activeBetsCounter,
    settledBetsCounter,
    currentRaceDate,
    selectedSettledTab,
    isReplayInsideMyBets,
    isMTPNewRules,
    modalScrollableRef,
    hasWagerFooter,
    hasPastPerformance,
    showLiveVideo,
    showRaceLeg,
    hasWagerDetails,
    selectedWager,
    showSeeResult,
    isCancelModal,
    indexBuffer,
    setIndexBuffer,
    isInsideRaceOfficials,
    isDesktop
  } = props;

  const wagers = get(bet, "wagers", []);

  const [showContentObj, setShowContentObj] = useState(
    wagers.reduce((acc, wager) => {
      acc[get(wager, "id")] = true;
      return acc;
    }, {})
  );

  useIsomorphicLayoutEffect(() => {
    if (indexBuffer) {
      scrollActiveBetIntoView(indexBuffer);
      setIndexBuffer(0);
    }
    if (
      selectedSettledTab === "LAST_MONTH" ||
      selectedSettledTab === "LAST_WEEK"
    ) {
      scrollActivePillIntoView();
    }
  }, []);

  useEffect(() => {
    let updateInfo = false;
    let auxObj = {};

    if (wagers.length > 0) {
      auxObj = wagers.reduce((acc, wager) => {
        const wagerId = get(wager, "id", 0);
        if (!has(showContentObj, wagerId)) {
          acc[wagerId] = true;
          updateInfo = true;
        }
        return acc;
      }, {});
    }

    if (updateInfo) {
      setShowContentObj({ ...showContentObj, ...auxObj });
    }
  }, [wagers]);

  const mainWagerDetails = get(bet, "value", "||0").split("|");
  const [raceDate, trackCode] = mainWagerDetails;
  const raceNumber = +get(mainWagerDetails, 2, "0");
  const biggestSelectionSize = get(
    first(
      wagers.sort(
        (a, b) => b.selections.selection.length - a.selections.selection.length
      )
    ),
    "selections.selection.length",
    0
  );

  const currentRace = races.find(
    (race) =>
      race.id === `${trackCode}-${raceNumber}` && race.raceDate === raceDate
  );

  const trackName = get(bet, "wagers[0].trackName");
  const racePostTime = get(bet, "wagers[0].racePostTime");
  const isBetStatusNotCanceledOrRefunded = wagers.some(
    (wager) => wager.betStatus.code !== "R" && wager.betStatus.code !== "C"
  );

  const allRacesFromTrack = useAllRacesFromTrack({ races, trackCode });

  const activeRaces =
    allRacesFromTrack &&
    allRacesFromTrack.length > 0 &&
    getActiveLegs(allRacesFromTrack, raceNumber, biggestSelectionSize);
  const currentOpenLeg = activeRaces ? first(activeRaces) : null;
  const legRaces = getRacesInPickBets(
    allRacesFromTrack,
    raceNumber,
    biggestSelectionSize
  );

  const bettingInterests = allRacesFromTrack.map(
    (race) => race.bettingInterests
  );
  const dateDiff = Math.round(
    (new Date(racePostTime).getTime() - new Date().getTime()) /
      (1000 * 3600 * 24)
  );

  // Removed greyhounds variation since its not being used elsewhere (ex. tracks page, talent picks)
  const raceUrl = buildRaceUrl(trackCode, trackName, raceNumber);

  const betsNumber = wagers.filter(
    (cur) => cur.betStatus.code !== "C" && cur.betStatus.code !== "R"
  ).length;

  const betAmount = wagers.reduce((acc, cur) => {
    const currentBetTotal =
      cur.betStatus.code !== "C" && cur.betStatus.code !== "R"
        ? cur.betTotal
        : 0;
    return acc + currentBetTotal;
  }, 0);

  const winningsAmount = wagers.reduce(
    (acc, cur) => acc + cur.winningsAmount,
    0
  );

  // Check if at least one of the bets are Pick/MultiRace Bets
  const isMultiRace = !!wagers
    .map((b) => isMultiRaceBet(b.wagerType.code))
    .findIndex((b) => b === false);

  const isAllBetsCancelled =
    !!get(wagers, "length", 0) && wagers.every((b) => b.betStatus.code === "C");

  const isSettledBet = selectedTab === "SETTLED";
  const isFutureBet = selectedTab === "FUTURES";

  let videoFeedback = "NOT_AVAILABLE_NO_REPLAY";

  if (isMultiRace && currentOpenLeg && !isSettledBet) {
    videoFeedback = getVideoFeedback(
      currentOpenLeg,
      first(allRacesFromTrack),
      last(allRacesFromTrack)
    );
  } else if (currentRace) {
    videoFeedback = getVideoFeedback(
      currentRace,
      first(allRacesFromTrack),
      last(allRacesFromTrack)
    );
  }

  const hasRaceLeg =
    isMultiRace && !isSettledBet && currentRace && currentOpenLeg;

  const {
    mtp,
    statusCode,
    legStatusCode,
    legNumber,
    hasLiveVideo,
    currentRaceNumber,
    raceType
  } = getRaceProps({
    isMultiRace,
    legRaces,
    isCancelled: isAllBetsCancelled,
    currentRace,
    currentLeg: currentOpenLeg,
    bet,
    isSettledBet,
    currentRaceDate,
    betDate: raceDate,
    videoFeedback,
    raceNumber
  });
  const hasReplayVideo = videoFeedback.indexOf("NO_REPLAY") === -1;

  return (
    <MyBetsCard
      hasPastPerformance={
        hasPastPerformance &&
        isSettledBet &&
        isBetStatusNotCanceledOrRefunded &&
        statusCode === "RO" &&
        raceType !== "G" &&
        (hasReplayVideo || showSeeResult)
      }
      hasReplayVideo={hasReplayVideo}
      showSeeResult={showSeeResult}
      hasLiveVideo={showLiveVideo && hasLiveVideo}
      legNumber={legNumber}
      onVideoRedirect={() =>
        onVideoRedirect(closeModal, {
          selectedTab,
          settledBets: settledBetsCounter,
          activeBets: activeBetsCounter,
          selectedSettledTab
        })
      }
      showBetInfoHeader={betsNumber > 0 && betAmount > 0}
      isFutureBet={isFutureBet}
      raceDate={get(bet, "wagers[0].raceDate", "")}
      showRaceLeg={showRaceLeg && !!hasRaceLeg}
      mtp={mtp}
      isRaceNear={mtp <= 5}
      dateDiff={dateDiff}
      betIndex={`activebet-${index}`}
      closeModal={closeModal}
      index={index}
      onWatchReplay={(showIppInsideMybets = true) => {
        handleRaceHeaderGtm("MYBETS_USER_CLICKS_WATCH_REPLAY", {
          selectedTab,
          selectedSettledTab,
          activeBets: activeBetsCounter,
          settledBets: settledBetsCounter,
          gaEventLabel: hasReplayVideo ? "Watch Replay" : "See Result"
        });

        if (showSeeResult && showIppInsideMybets) {
          const modalTitle = `${trackName} R${raceNumber} - Replay`;
          setIndexBuffer(index);
          dispatch(
            openMybetsPastPerformance(
              {
                trackCode,
                raceNumber,
                raceDate
              },
              modalTitle
            )
          );
        }
      }}
      isReplayInsideMyBets={isReplayInsideMyBets}
      isMTPNewRules={isMTPNewRules}
      isMyBetsOpen={isMyBetsOpen}
      racePostTime={racePostTime}
      trackName={trackName}
      legStatusCode={legStatusCode}
      statusCode={statusCode}
      raceNumber={raceNumber}
      raceUrl={raceUrl}
      betsNumber={betsNumber}
      betAmount={betAmount}
      winningsAmount={winningsAmount}
      currentRaceNumber={currentRaceNumber}
    >
      {/* Render evert single wager for this specific track */}
      {getWagerToRender(wagers, selectedWager).map((wager, wagerIndex) => {
        const raceStatus = get(currentRace, "status.code");
        const isCancelled =
          get(wager, "betStatus.code") === "C" &&
          raceStatus !== "O" &&
          raceStatus !== "IC";
        return (
          shouldRenderWager(
            selectedTab,
            currentRaceDate,
            get(wager, "raceDate", "")
          ) && (
            <MyBetsWager
              key={`wager-${get(wager, "id", "unk")}-${mainWagerDetails.join(
                "-"
              )}`}
              isDesktop={isDesktop}
              index={wagerIndex}
              wager={wager}
              mainWagerDetails={mainWagerDetails}
              races={races}
              raceNumber={raceNumber}
              statusCode={statusCode}
              legStatusCode={legStatusCode}
              betAmount={betAmount}
              bettingInterests={bettingInterests}
              currentRaceDate={currentRaceDate}
              allRacesFromTrack={allRacesFromTrack}
              modalScrollableRef={modalScrollableRef}
              closeModal={closeModal}
              raceUrl={raceUrl}
              showContentObj={showContentObj}
              setShowContentObj={setShowContentObj}
              currentRace={currentRace}
              currentOpenLeg={currentOpenLeg}
              hasFooter={hasWagerFooter && !isCancelled}
              hasDetails={hasWagerDetails && !isCancelled}
              isMyBetsOpen={isMyBetsOpen}
              hasContentMaxHeight={isCancelModal}
              onCancelWager={() => {
                dispatch(cleanBetCancelResult());
                onCancelBetModalGtm(
                  dispatch,
                  {
                    bet,
                    wager
                  },
                  selectedTab,
                  selectedSettledTab
                );
              }}
              isInsideRaceOfficials={isInsideRaceOfficials}
              onShareWager={(repeatButtonSearch) => {
                dispatch(
                  openBetSocialShareModal({
                    bet,
                    wager,
                    repeatButtonSearch,
                    isMyBets: true
                  })
                );
              }}
            />
          )
        );
      })}
    </MyBetsCard>
  );
};

MyBetsBody.defaultProps = {
  dispatch: noop,
  selectedTab: "",
  isMyBetsOpen: false,
  bet: {},
  index: -1,
  races: [],
  closeModal: noop,
  activeBetsCounter: 0,
  settledBetsCounter: 0,
  currentRaceDate: "",
  selectedSettledTab: "",
  isReplayInsideMyBets: true,
  isMTPNewRules: true,
  modalScrollableRef: null,
  hasWagerFooter: true,
  selectedWager: undefined,
  hasPastPerformance: true,
  showLiveVideo: true,
  showRaceLeg: true,
  hasWagerDetails: true,
  showSeeResult: false,
  isCancelModal: false,
  isInsideRaceOfficials: false,
  isDesktop: false
};

const mapStateToProps = (store) => ({
  selectedTab: getSelectedTab(store),
  isMyBetsOpen: getIsMyBetsOpen(store),
  selectedSettledTab: getSelectedSettledTab(store),
  activeBetsCounter: getTodayActiveCounter(store),
  settledBetsCounter: getTodaySettledCounter(store),
  isMTPNewRules: getFeatureIsMTPNewRules(store),
  isReplayInsideMyBets: getWatchReplayWithinMyBets(store),
  showSeeResult: getShowSeeResult(store)
});

export default connect(mapStateToProps)(MyBetsBody);
