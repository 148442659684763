import React from "react";

import Title from "@tvg/atomic-ui/_atom/Title";

import OfferItem from "../../_molecules/OfferItem";
import {
  OfferItemsContainer,
  OfferItemsWrapper,
  OfferItemsTitle
} from "./styled-components";
import buildColor from "../../_static/ColorPalette";

const OfferItems = ({ items, title, qaLabel }) => {
  const renderOfferItems = () =>
    items.map((item) => (
      <OfferItem key={item._uid} {...item} qaLabel={`${qaLabel}Item`} />
    ));

  return (
    <OfferItemsContainer data-qa-label={qaLabel}>
      <OfferItemsTitle data-qa-label={`${qaLabel}TitleWrapper`}>
        <Title
          tag="h2"
          normal
          fontSize={16}
          color={buildColor("grey", "900")}
          qaLabel={`${qaLabel}Title`}
        >
          {title}
        </Title>
      </OfferItemsTitle>
      <OfferItemsWrapper data-qa-label={`${qaLabel}List`}>
        {items.length > 0 && renderOfferItems()}
      </OfferItemsWrapper>
    </OfferItemsContainer>
  );
};

OfferItems.defaultProps = {
  items: [],
  title: "",
  qaLabel: "offerItems"
};

export default OfferItems;
