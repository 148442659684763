import React from "react";
import { AlertInLine } from "@tvg/design-system/web";
import { ConfigCancelLimitFeedback } from "../../../ConfirmBetModal/hooks/useCancelLimits/types";
import { PlaceBetError } from "../../../../types";

export interface AlertsProps {
  isRaceWagerable: boolean;
  showRaceOffAlert: boolean;
  hasScratches: boolean;
  errorMessages: Array<PlaceBetError>;
  placeBetSuccess: boolean;
  placeBetError: boolean;
  isBlockedByState: boolean;
  userBalance: number;
  totalAmount: number;
  blockedByStateErrorMessage: string;
  feedback: ConfigCancelLimitFeedback | null;
  betConfirmationMessages: { noLongerAvailableForBetting: string };
  confirmModalMessages: { validBet: string; invalidBet: string };
}

const Alerts = ({
  isRaceWagerable,
  showRaceOffAlert,
  hasScratches,
  errorMessages,
  placeBetSuccess,
  placeBetError,
  isBlockedByState,
  userBalance,
  totalAmount,
  blockedByStateErrorMessage,
  feedback,
  betConfirmationMessages,
  confirmModalMessages
}: AlertsProps) => (
  <>
    {!isRaceWagerable && showRaceOffAlert && (
      <AlertInLine
        type="fixed"
        qaLabel="race-off-repeat-bet-alert"
        message={
          betConfirmationMessages?.noLongerAvailableForBetting ||
          "The race has started and you can no longer bet"
        }
        variant="warning"
        mb="space-4"
      />
    )}
    {isRaceWagerable && hasScratches && (
      <AlertInLine
        type="fixed"
        qaLabel="scratches-repeat-bet-alert"
        message={
          +totalAmount > 0
            ? confirmModalMessages?.validBet
            : confirmModalMessages?.invalidBet
        }
        variant="warning"
        mb="space-4"
      />
    )}
    {placeBetError &&
      errorMessages.map((errorMessage) => (
        <AlertInLine
          type="fixed"
          variant="error"
          key={errorMessage.code}
          title={
            errorMessage.message.title
              ? errorMessage.message.title
              : errorMessage.message.text
          }
          message={errorMessage.message.title ? errorMessage.message.text : ""}
          qaLabel="error-messages-alert"
          mb="space-4"
        />
      ))}
    {!(placeBetSuccess || placeBetError) && userBalance < totalAmount && (
      <AlertInLine
        type="fixed"
        qaLabel="insufficient-amount-repeatBet"
        message="Insufficient funds to place the bet"
        variant="warning"
        mb="space-4"
      />
    )}
    {isBlockedByState && (
      <AlertInLine
        type="fixed"
        variant="error"
        message={blockedByStateErrorMessage}
        qaLabel="state-blocked-error"
        mb="space-4"
      />
    )}
    {feedback && (
      <AlertInLine
        type="fixed"
        qaLabel="raceCancelLimits"
        title={feedback.title}
        message={feedback.description || ""}
        variant="informational"
        linkLabel={feedback.link && feedback.link.label}
        onLinkClick={() => feedback.link && window.open(feedback.link.url)}
        mb="space-4"
      />
    )}
  </>
);

export default Alerts;
