import React, { useState, useEffect, memo } from "react";
import { noop } from "lodash";

import { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import { mtpNotificationConfigDefault } from "../../__mocks__/ipp-pastRaces.mock";
import { arrowForward } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";

import {
  MTPNotificationContainer,
  MTPNotificationDetailText
} from "./styled-compontents";

const idleIncrementUntilAnimationFinish = 2;
const statusAllowed = ["IC"];

const MTPNotification = (props) => {
  const {
    currentRace: { mtp = 0, track: { trackCode } = {}, raceNumber, status },
    onModalClose,
    mtpNotificationConfig: {
      mtpBelowThresholdText,
      raceOffText,
      mtpThreshold,
      showNotificationDelay,
      hideNotificationDelay
    }
  } = props;
  const clickHandler = () => onModalClose();
  const [showNotification, setShowNotification] = useState(false);
  const [animation, setAnimation] = useState("");
  const [isRaceOff, setIsRaceOff] = useState(false);

  useEffect(() => {
    if (status && status.code === "SK") {
      setIsRaceOff(true);
    }
    let timerShowId;
    if (
      !showNotification &&
      ((status && statusAllowed.includes(status.code) && mtp <= mtpThreshold) ||
        isRaceOff)
    ) {
      setShowNotification(true);
      timerShowId = setTimeout(() => setAnimation("slide-up"), 0);
    }
    let timerHideId;
    if (isRaceOff) {
      timerHideId = setTimeout(
        () => {
          clearTimeout(timerShowId);
          setAnimation("slide-down");
          setTimeout(
            () => setShowNotification(false),
            (hideNotificationDelay + idleIncrementUntilAnimationFinish) * 1000
          );
        },
        (showNotificationDelay + idleIncrementUntilAnimationFinish) * 1000
      );
    }
    return () => {
      clearTimeout(timerShowId);
      clearTimeout(timerHideId);
    };
  }, [mtp, status, isRaceOff]);

  return (
    showNotification && (
      <MTPNotificationContainer
        onClick={clickHandler}
        className={animation}
        showNotificationDelay={showNotificationDelay}
        hideNotificationDelay={hideNotificationDelay}
        isRaceOff={isRaceOff}
      >
        <MTPNotificationDetailText data-qa-label="notificiation-mtpText">
          {isRaceOff
            ? replaceCAPIVariables(raceOffText, {
                mtp,
                trackCode,
                raceNumber: raceNumber || ""
              })
            : replaceCAPIVariables(mtpBelowThresholdText, {
                mtp,
                trackCode,
                raceNumber: raceNumber || ""
              })}
        </MTPNotificationDetailText>
        <Icon
          icon={arrowForward}
          color={buildColor("white", "100")}
          size={16}
        />
      </MTPNotificationContainer>
    )
  );
};

MTPNotification.defaultProps = {
  currentRace: {},
  onModalClose: noop,
  mtpNotificationConfig: mtpNotificationConfigDefault
};

export default memo(MTPNotification);
