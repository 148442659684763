import { combineReducers } from "redux";

import wagerRewardsReducer, {
  initialState as initialWagerRewardsState
} from "@tvg/wager-rewards/src/reducers";

import capiReducer, {
  initialState as initialCapiState
} from "./App/capiReducer";

import userDataReducer, {
  initialState as initialUserState
} from "./App/userDataReducer";

export const initialState = {
  capi: initialCapiState,
  userData: initialUserState,
  wagerRewards: initialWagerRewardsState
};

export default combineReducers({
  capi: capiReducer,
  userData: userDataReducer,
  wagerRewards: wagerRewardsReducer
});
