import styled from "styled-components";
import { space, position } from "styled-system";

import type { CounterProps } from "./types";

export const CounterWrapper = styled.div<
  Pick<CounterProps, "variant" | "value">
>`
  align-items: center;
  border-radius: 12px;
  display: flex;
  height: 16px;
  justify-content: center;
  max-height: 16px;
  min-width: 16px;
  padding: 0 ${({ value }) => (value > 9 ? "5px" : "")};
  width: max-content;

  &.primary {
    background-color: var(--fd-colors-system-important-background-default);
  }

  &.secondary {
    background-color: var(--fd-colors-system-info-background-default);
  }

  ${space}
  ${position}
`;

export const CounterValue = styled.span`
  color: var(--fd-colors-system-info-content-on-default);
  font-family: "RobotoCondensed-Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  text-transform: uppercase;
`;
