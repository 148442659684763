import styled, { css } from "styled-components";
import { space } from "styled-system";

export const CarouselContainer = styled.div`
  width: 100%;
  padding-bottom: var(--fd-space-space-4);
  ${space};
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 64px;
  padding: var(--fd-space-space-4);
  gap: var(--fd-space-space-4);
  ${space};
`;

export const ButtonsContainer = styled.div<{ isDisabled?: boolean }>`
  margin-left: auto;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  border-radius: var(--fd-radii-border-radius-020);
  background-color: var(--fd-colors-component-button-transparent-content-base);
  overflow: hidden;
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          border: 1px solid
            var(--fd-colors-component-button-tertiary-border-disabled);
        `
      : css`
          box-shadow: var(--fd-shadows-elevations-bottom-low);
          border: 1px solid
            var(--fd-colors-component-button-tertiary-border-base);
        `}
`;

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  height: 30px;
  width: 31px;
  border: none;
  background-color: var(--fd-colors-component-button-transparent-content-base);
  transition:
    background-color 0.1s ease-in-out,
    stroke 0.1s ease-in-out;

  &:hover:not(:disabled) {
    background-color: var(
      --fd-colors-component-button-tertiary-background-hover
    );
  }

  &:active:not(:disabled) {
    background-color: var(
      --fd-colors-component-button-tertiary-background-active
    );

    svg path {
      stroke: var(--fd-colors-component-button-secondary-content-active);
    }
  }

  &:disabled {
    cursor: initial;

    svg path {
      stroke: var(--fd-colors-component-button-tertiary-content-disabled);
    }
  }
`;

export const Embla = styled.div<{ slideSize: number | string }>`
  --slide-size: ${({ slideSize }) =>
    typeof slideSize === "number" ? `${slideSize}px` : slideSize};
`;

export const EmblaViewport = styled.div<{ edgeGap: number | string }>`
  overflow: hidden;
  ${({ edgeGap }) =>
    typeof edgeGap === "number"
      ? css`
          padding: 0 ${edgeGap}px;
        `
      : css`
          padding: 0
            ${edgeGap.startsWith("--") ? `var(${edgeGap}, 16px)` : edgeGap};
        `};
`;

export const EmblaContainer = styled.div<{ gap: number | string }>`
  display: flex;
  backface-visibility: hidden;
  touch-action: pan-y pinch-zoom;
  ${({ gap }) =>
    typeof gap === "number"
      ? css`
          gap: ${gap}px;
        `
      : css`
          gap: ${gap.startsWith("--") ? `var(${gap}, 8px)` : gap};
        `};
`;

export const EmblaSlide = styled.div<{ slideSize: number | string }>`
  min-width: 0;
  flex: 0 0
    ${({ slideSize }) =>
      typeof slideSize === "number" ? `${slideSize}px` : slideSize};
`;
