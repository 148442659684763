import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontBold, defaultSize } from "../../_static/Typography";
import Title from "../../_atom/Title";

import Tag from "../../_atom/Tag";

export const NormalTitle = styled(Title)`
  font-weight: normal;
`;

export const ListItemWrapper = styled.li`
  width: 100%;
  border: 1px solid ${buildColor("blue", "100")};
  border-radius: 2px 2px 0 0;
  background-color: ${buildColor("blue_accent", "000")};
  min-height: 72px;
  display: flex;
  padding: 12px;
  flex-direction: row;
  justify-content: center;

  @media (pointer: fine) {
    &:hover {
      cursor: pointer;
      background-color: ${buildColor("blue_accent", "100")};
    }
  }

  @media (pointer: coarse) {
    &:active {
      background-color: ${buildColor("blue_accent", "100")};
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 4px;
  ${({ isNewTagSLim }) =>
    isNewTagSLim &&
    css`
      justify-content: space-between;
    `}
`;

export const NewTag = styled(Tag)`
  ${({ isNewTagSLim }) =>
    isNewTagSLim
      ? css`
          height: 16px;
          background-color: ${buildColor("yellow", "500")};
          border-radius: 12px;

          & span {
            color: ${buildColor("grey", "900")};
            text-transform: lowercase;
          }
        `
      : css`
          margin-left: 8px;
        `}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  padding-right: 12px;
`;

export const GoArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 12px;
`;

export const ListItemContent = styled.div`
  flex-grow: 1;
  align-items: flex-start;

  > span.new-badge {
    margin-bottom: 8px;
  }

  & > h1 {
    font-family: ${fontBold};
  }

  && > p {
    margin: 0;
    font-size: 12px;
    color: ${buildColor("grey", "900")};
  }
`;

export const ListItemLink = styled(Link)`
  display: flex;
  flex-direction: row;
  text-align: left;
  flex: 1;
  align-items: center;
  outline: none;
  background: none;
`;

export const ListItemRef = styled.a`
  display: flex;
  flex-direction: row;
  text-align: left;
  flex: 1;
  align-items: center;
  outline: none;
  background: none;
`;

export const ListItemContentWrapper = styled.button`
  height: 100%;
  display: flex;
  flex-direction: row;
  text-align: left;
  flex: 1;
  align-items: center;
  outline: none;
  background: none;

  & :hover {
    cursor: pointer;
  }
`;

export const ListItemDescription = styled.span`
  color: ${buildColor("grey", "800")};
  font-size: ${defaultSize};
  font-family: ${fontNormal};
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 18px;
`;
