import React from "react";
import type { TabPanelProps } from "./types";
import { StyledContainer } from "./styled-components";

export const TabPanel = ({
  children,
  qaLabel = "tabPanel",
  isFullHeight,
  ...rest
}: TabPanelProps) => (
  <StyledContainer
    isFullHeight={isFullHeight}
    data-qa-label={qaLabel}
    {...rest}
  >
    {children}
  </StyledContainer>
);

export type { TabPanelProps };
