import { createStore, compose, applyMiddleware } from "redux";

import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import reducers from "./reducers";

let composeEnhancers = compose;

if (typeof DEVELOPMENT !== "undefined" && DEVELOPMENT) {
  /* eslint-disable */
  composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  /* eslint-enable */
}

export default (initialState) => {
  const middlewares = [promise(), thunk];
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  const store = createStore(reducers, initialState, enhancer);

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      return System.import("./reducers").then((reducerModule) =>
        store.replaceReducer(reducerModule.default)
      );
    });
  }

  return store;
};
