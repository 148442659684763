import styled, { css } from "styled-components";
import {
  background,
  borderRadius,
  color,
  compose,
  space,
  width
} from "styled-system";
import type { ButtonSize } from "./types";

const styles = compose(color, background, space, borderRadius, width);

export const SortButton = styled.button<{ size: ButtonSize }>`
  ${styles};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  min-width: 140px;

  ${({ size }) =>
    size === "s" &&
    css`
      padding: var(--fd-space-space-1);
    `}
  ${({ size }) =>
    size === "m" &&
    css`
      padding: var(--fd-space-space-2);
    `}
    ${({ size }) =>
    size === "l" &&
    css`
      padding: var(--fd-space-space-2) var(--fd-space-space-4);
    `}

  &:hover {
    background-color: var(
      --fd-colors-component-button-tertiary-background-hover
    );
    border-radius: var(--fd-radii-border-radius-020);
  }

  &:active {
    background-color: var(
      --fd-colors-component-button-tertiary-background-active
    );
    border-radius: var(--fd-radii-border-radius-020);
  }

  &:active p {
    color: var(--fd-colors-component-button-tertiary-content-active);
  }

  &:active svg path {
    stroke: var(--fd-colors-component-button-tertiary-content-active);
  }

  &:disabled {
    background-color: transparent;
  }

  &:disabled p {
    color: var(--fd-colors-content-disabled);
  }
`;

export const Container = styled.div`
  position: relative;
  margin-left: var(--fd-space-space-1);
`;

export const LabelText = styled.p`
  font-family: "Roboto-Medium", sans-serif;
  font-size: 14px;
  color: var(--fd-colors-content-default);
  margin-right: var(--fd-space-space-1);
`;

export const SelectionText = styled.p`
  font-family: "Roboto-Medium", sans-serif;
  font-size: 14px;
  color: var(--fd-colors-component-button-tertiary-content-base);
  margin-right: var(--fd-space-space-2);
`;
