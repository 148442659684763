import React from "react";
import buildColor from "../../_static/ColorPalette";

export const TVGLogo = () => (
  <svg
    width="41"
    height="11"
    viewBox="0 0 41 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.231 2.86552C31.3785 2.86552 30.0099 3.60725 29.5156 5.49764C29.0647 7.22552 29.9538 8.13688 32.2088 8.13688C33.6917 8.13688 34.5571 7.66807 35.0635 6.96462H33.1912L33.745 4.84272H40.2183L38.8233 10.1849H36.8699L36.5799 9.31893C35.1786 10.1733 33.607 10.501 30.9953 10.501C27.8055 10.501 23.6249 9.85764 24.7533 5.53726C24.9428 4.81245 25.235 4.18737 25.6043 3.64776L20.2934 10.1849H15.5163L13.4845 3.18296H9.51629L7.68791 10.1849H3.13712L4.96506 3.18296H0.515991L1.13307 0.818416H12.798V0.817525H17.8009L18.7658 6.82616H18.798L22.9007 0.817525H24.8362L24.8357 0.816635H30.3491C31.4295 0.587348 32.5467 0.500977 33.5904 0.500977C38.6557 0.500977 40.7659 1.63094 40.4926 4.03777H35.3597C35.357 3.55293 35.0971 3.25197 34.7197 3.08724C34.3285 2.91761 33.8262 2.86552 33.231 2.86552Z"
      fill={buildColor("green", "700")}
    />
  </svg>
);

export const Laurel = ({ direction }) =>
  direction === "left" ? (
    <svg
      width="20"
      height="33"
      viewBox="0 0 20 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.47955 0.617188C0.730745 2.59239 4.74955 8.75319 4.74955 8.75319C4.74955 8.75319 9.52555 7.14159 7.47955 0.617188ZM6.79195 16.7296V16.7272C6.98995 16.6408 11.7071 14.5432 10.0943 9.39399C10.0943 9.39399 5.22355 9.72999 6.15835 14.7988C5.34115 12.9232 3.57835 10.2796 0.016745 9.71079C-0.391255 19.72 6.79195 16.7296 6.79195 16.7296ZM12.2771 15.9856C15.5219 21.0376 11.2043 24.3376 11.1191 24.4012C11.0159 24.4864 5.01115 29.368 2.31235 19.7404C5.63275 19.2868 8.07475 20.9464 9.53515 22.408C7.46755 17.59 12.2771 15.9856 12.2771 15.9856ZM16.4603 21.3244C16.4603 21.3244 12.4691 24.4864 16.2719 28.3492C14.1839 27.6292 10.9463 27.106 7.99075 29.1352C15.2903 36.8344 18.4859 29.3632 18.4859 29.3632L18.4847 29.3608C19.1423 27.9784 20.3723 24.2656 16.4603 21.3244Z"
        fill={buildColor("green", "500")}
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="33"
      viewBox="0 0 20 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9523 0.617188C18.7011 2.59239 14.6811 8.75319 14.6811 8.75319C14.6811 8.75319 9.90629 7.14159 11.9523 0.617188ZM12.6387 16.7296L12.6399 16.7272C12.4419 16.6408 7.72469 14.5432 9.33749 9.39399C9.33749 9.39399 14.2071 9.72999 13.2723 14.7988C14.0895 12.9232 15.8523 10.2796 19.4151 9.71079C19.8231 19.72 12.6387 16.7296 12.6387 16.7296ZM7.15349 15.9844C3.90869 21.0376 8.22749 24.3364 8.31149 24.4012C8.41589 24.4864 14.4207 29.368 17.1183 19.7404C13.7991 19.2868 11.3571 20.9464 9.89669 22.408C11.9631 17.59 7.15349 15.9844 7.15349 15.9844ZM2.97029 21.3244C2.97029 21.3244 6.96269 24.4864 3.15989 28.348C5.24789 27.628 8.48429 27.106 11.4411 29.1352C4.14149 36.8344 0.94469 29.3632 0.94469 29.3632L0.947089 29.3608C0.288289 27.9784 -0.940515 24.2656 2.97029 21.3244Z"
        fill={buildColor("green", "500")}
      />
    </svg>
  );
