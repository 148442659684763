import { get } from "lodash";
import promoService from "@tvg/api/pro";

import {
  setOverallLeaderBoardStandingsAction,
  setLeaderBoardStandingsPageAction,
  setUserLeaderBoardStandingsAction,
  setLeaderBoardAllRacesAction
} from "./actions";

export const getAllLeaderBoardStandings = (content, dispatch, setState) => {
  const promoId = get(content, "promo_type.promo_id");
  const numberVisibleStandings = get(
    content,
    "standings[0].numberVisibleStandings",
    20
  );

  if (promoId === undefined) return;

  promoService
    .fetchAllLeaderBoardStandings(Number(promoId), numberVisibleStandings)
    .then((resp) => {
      const standingsData = get(resp, "data", {});

      dispatch(setOverallLeaderBoardStandingsAction(standingsData));
      setState({ isLoadingStandingsError: false });
    })
    .catch((e) => {
      console.error(e);
      setState({ isLoadingStandingsError: true });
    });
};

export const getUserLeaderBoardStandings = (
  user,
  content,
  dispatch,
  setState
) => {
  const { accountNumber } = user;
  const promoId = +get(content, "promo_type.promo_id", 0);
  const numberNearbyUsers = get(content, "standings[0].numberNearbyUsers", 2);

  promoService
    .fetchUserLeaderBoardStandings(accountNumber, promoId, numberNearbyUsers)
    .then((resp) => {
      const standingsData = get(resp.data, "leaderBoardDataResponses");

      dispatch(setUserLeaderBoardStandingsAction(standingsData));
      setState({ isLoadingStandingsError: false });
    })
    .catch((e) => {
      console.error(e);
      setState({ isLoadingStandingsError: true });
    });
};

export const getLeaderBoardStandingsByPagination = (
  content,
  dispatch,
  round,
  page,
  setState
) => {
  const promoId = +get(content, "promo_type.promo_id", 0);
  const pageSize = get(content, "standings[0].numberVisibleStandings", 20);
  promoService
    .fetchLeaderBoardStandingsByPagination(promoId, round + 1, page, pageSize)
    .then((resp) => {
      const standingsData = get(resp.data, "entries");
      dispatch(setLeaderBoardStandingsPageAction(round, page, standingsData));
      setState({ isLoadingStandingsPageError: false });
    })
    .catch((e) => {
      console.error(e);
      setState({ isLoadingStandingsPageError: true });
    });
};

export const fetchLeaderboardAllRaces = (content, dispatch) => {
  const promoId = get(content, "promo_type.promo_id");

  if (promoId === undefined) return;

  promoService
    .fetchAllRaces(promoId)
    .then((response) => {
      dispatch(setLeaderBoardAllRacesAction(response.data));
    })
    .catch((e) => {
      console.error(e);
      dispatch(setLeaderBoardAllRacesAction([]));
    });
};
