import styled, { css } from "styled-components";
import { buildText } from "../../_atom/Text";
import buildColor from "../../_static/ColorPalette";
import { fontMedium, fontNormal } from "../../_static/Typography";

export const PayoffRow = styled.tr`
  background-color: ${buildColor("white", "100")};
  border-bottom: 1px solid
    ${({ hasNewDesign }) =>
      hasNewDesign ? buildColor("blue", "100") : buildColor("grey", "300")};
  height: 40px;
  font-size: 12px;
`;

export const PayoffCell = styled.td`
  padding: ${({ hasNewDesign }) => (hasNewDesign ? "10px 12px" : "10px 8px")};
  ${({ hasNewDesign }) =>
    hasNewDesign &&
    css`
      & > span > span:last-child {
        text-transform: capitalize;
      }
    `};

  &:last-child {
    width: 1%;
    text-align: right;
    vertical-align: baseline;
  }
`;

export const RacePayoffsDesc = styled(
  buildText({
    color: buildColor("grey", "900")
  })
)`
  font-size: 14px;
`;

export const RacePayoffsPickDetail = styled(
  buildText({
    color: buildColor("grey", "700")
  })
)`
  padding-left: 5px;
`;

export const PayoffsPerSaddleContainer = styled.div`
  display: flex;
  margin-top: 5px;
  ${({ isVertical }) =>
    isVertical &&
    css`
      flex-direction: column;
    `}
`;

export const Selections = styled.section`
  display: flex;
  align-items: baseline;
  margin-right: 5px;
  ${({ isVertical }) =>
    isVertical &&
    css`
      flex-direction: column;
    `}
`;

export const Payoff = buildText({
  tag: "strong",
  color: buildColor("grey", "800")
});

export const NewPayoff = styled.span`
  font-family: ${fontMedium};
  color: ${buildColor("grey", "800")};
  font-size: 14px;
`;

export const PayoffsPerSaddleList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  list-style: none;
`;

export const PayoffsPerSaddleItem = styled.li`
  text-align: center;
  margin-right: 5px;
  margin-bottom: 5px;
`;

export const PayoutText = styled.span`
  margin-right: 5px;
  ${({ hasNewDesign }) =>
    hasNewDesign &&
    css`
      font-family: ${fontNormal};
      font-size: 12px;
      color: ${buildColor("grey", "800")};
    `}
`;

export const RunnerText = styled.span`
  background-color: ${buildColor("blue", "100")};
  border-radius: 1px;
  width: 33px;
  height: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${buildColor("black", "100")};
`;
