import React, { type FC } from "react";

import { CounterWrapper, CounterValue } from "./styled-components";

import type { CounterProps } from "./types";

export const Counter: FC<CounterProps> = ({
  value,
  variant = "primary",
  qaLabel = "counter",
  ...rest
}) => (
  <CounterWrapper
    className={variant}
    value={value}
    data-qa-label={qaLabel}
    {...rest}
  >
    <CounterValue data-qa-label={`${qaLabel}-value`}>
      {value > 99 ? "99+" : value}
    </CounterValue>
  </CounterWrapper>
);

export type { CounterProps };
