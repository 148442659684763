import styled, { css } from "styled-components";

import buildColor from "../../_static/ColorPalette";
import { fontMedium } from "../../_static/Typography";

export const MTPNotificationContainer = styled.div`
  display: flex;
  color: ${buildColor("white", "100")};
  background-color: ${buildColor("orange", "500")};
  transition: transform 1s ease-in
    ${({ showNotificationDelay }) =>
      showNotificationDelay ? `${showNotificationDelay}s` : "0s"};
  transform: translate(-50%, 150%);
  position: absolute;
  bottom: 16px;
  left: 50%;
  align-items: center;
  padding: 14px 16px;
  box-shadow:
    0 2px 4px rgba(17, 43, 68, 0.06),
    0 1px 2px rgba(17, 43, 68, 0.08);
  border-radius: 2px;

  &.slide-up {
    transform: translate(-50%, 0%);
  }

  &.slide-down {
    transition-delay: ${({ hideNotificationDelay }) =>
      hideNotificationDelay ? `${hideNotificationDelay}s` : "0s"};
  }

  ${({ isRaceOff }) =>
    isRaceOff &&
    css`
      background-color: ${buildColor("blue", "500")};
    `}
`;

export const MTPNotificationDetailText = styled.span`
  margin-right: 5px;
  white-space: nowrap;
  font-family: ${fontMedium};
  font-size: 14px;
`;
