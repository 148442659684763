import styled from "styled-components";
import {
  space,
  width,
  layout,
  flexbox,
  border,
  boxShadow,
  compose,
  variant as styledVariant
} from "styled-system";
import type { ColorPropertiesGroups, IconPosition, ButtonSize } from "./types";

export const buttonSizes = {
  s: {
    height: "26px",
    padding: "4px 8px"
  },
  m: {
    height: "32px",
    padding: "7px 8px"
  },
  l: {
    height: "44px",
    padding: "13px 16px"
  }
};

const buttonStyles = compose(
  space,
  width,
  layout,
  flexbox,
  boxShadow,
  border,
  styledVariant({ prop: "size", variants: buttonSizes })
);

export const StyledButton = styled.button<{
  colorPalette: ColorPropertiesGroups;
  iconPosition: IconPosition;
  isLoading: boolean;
  isStretched: boolean;
  size: ButtonSize;
  hasIcon: boolean;
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  gap: 8px;
  flex-shrink: 0;
  font-family: Roboto-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  color: ${({ colorPalette }) => colorPalette.default.color};
  background-color: ${({ colorPalette, isLoading }) =>
    isLoading
      ? colorPalette.pressed.background
      : colorPalette.default.background};
  border: ${({ colorPalette }) => colorPalette.default.border};
  box-shadow: ${({ colorPalette }) => colorPalette.default.boxShadow};
  opacity: 1;
  flex-grow: ${({ isStretched }) => (isStretched ? 1 : 0)};
  ${({ isStretched }) =>
    isStretched ? "width: 100%;" : "align-self: flex-start;"};
  flex-direction: ${({ iconPosition }) =>
    iconPosition === "end" ? "row-reverse" : "row"};
  ${buttonStyles};

  & svg path,
  svg rect {
    stroke: ${({ colorPalette, hasIcon }) =>
      hasIcon && colorPalette.default.color};
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${({ colorPalette }) =>
        colorPalette.hovered.background};
      border: ${({ colorPalette }) => colorPalette.hovered.border};
      box-shadow: ${({ colorPalette }) => colorPalette.hovered.boxShadow};
      color: ${({ colorPalette }) => colorPalette.hovered.color};

      & svg path,
      svg rect {
        stroke: ${({ colorPalette }) => colorPalette.hovered.color};
      }
    }
  }

  &:active {
    background-color: ${({ colorPalette }) => colorPalette.pressed.background};
    border: ${({ colorPalette }) => colorPalette.pressed.border};
    box-shadow: ${({ colorPalette }) => colorPalette.pressed.boxShadow};
    color: ${({ colorPalette }) => colorPalette.pressed.color};

    & svg path,
    svg rect {
      stroke: ${({ colorPalette, hasIcon }) =>
        hasIcon && colorPalette.pressed.color};
    }
  }

  &:disabled {
    background-color: ${({ colorPalette }) => colorPalette.disabled.background};
    border: ${({ colorPalette }) => colorPalette.disabled.border};
    box-shadow: ${({ colorPalette }) => colorPalette.disabled.boxShadow};
    color: ${({ colorPalette }) => colorPalette.disabled.color};
    cursor: default;

    & svg path,
    svg rect {
      stroke: ${({ colorPalette, hasIcon }) =>
        hasIcon && colorPalette.disabled.color};
    }
  }
`;
