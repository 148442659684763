import axios from "axios";
import { attempt, get } from "lodash";
import pxpService from "@tvg/api/pxp";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

const perimeterXService = "service.pxp";

// response interceptor to handle PerimeterX block responses

export const challengeRemoveHandler = () => {
  window._pxOnCaptchaSuccess = () => {
    const humanChallenge = document.querySelector("#px-captcha");
    window.dispatchEvent(new CustomEvent("passedHC"));
    if (humanChallenge) {
      humanChallenge.remove();
    }
  };
};

export const handlePxResponse = (data, account) => {
  // Mandatory window properties
  window._pxAppId = `${data.appId}`; // PerimeterX's application id
  window._pxJsClientSrc = `${pxpService.getPerimeterXInitJs(
    data.appId.replace("PX", "")
  )}`; // PerimeterX's JavaScript sensor url
  window._pxFirstPartyEnabled = `${data.firstPartyEnabled}`; // A boolean flag indicating wether first party is enabled or not
  window._pxVid = `${data.vid}`; // PerimeterX's visitor id
  window._pxUuid = `${data.uuid}`; // PerimeterX's unique user id
  window._pxHostUrl = `${data.hostUrl}`; // PerimeterX's cloud component URL

  // Custom property
  window._pxParam5 = `${account}`;

  // new script element which is used to load the PerimeterX challenge script
  const script = document.createElement("script");
  script.src = `${data.altBlockScript}`;

  // use the blockScript property from the Advanced Blocking Response result.
  // the div in which the PerimeterX challenge script will render the challenge
  const div = document.createElement("div");
  div.setAttribute("id", "px-captcha");

  window.dispatchEvent(new CustomEvent("enableHC"));

  // adding the elements to the page
  const pxElement = document.querySelector("#pxElement");
  if (pxElement) {
    pxElement.appendChild(div);
  }
  document.getElementsByTagName("head")[0].appendChild(script);

  challengeRemoveHandler();
};

const interceptRequests = () =>
  axios.interceptors.response.use(
    (res) => res,
    (error) => {
      if (
        error.response.status === 403 &&
        error.response.data &&
        error.response.data.appId &&
        error.response.data.blockScript
      ) {
        const variables = attempt(() => JSON.parse(error.response.config.data));
        handlePxResponse(error.response.data, get(variables, "account"));
        return Promise.reject({ ...error, isPerimeterX: true });
      }
      return Promise.reject({ ...error });
    }
  );

export const humanChallengeStyle = {
  width: "100%",
  height: 60,
  fontWeight: "bold",
  texSize: 14,
  backgroundColor: buildColor("orange", "500"),
  fillColor: buildColor("orange", "200"),
  textColor: buildColor("white", "100"),
  borderWidth: 0,
  animation: true,
  textTransform: "capitalized"
};

export default interceptRequests;
