import React from "react";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { email } from "../../_static/Icons/icons";
import {
  ContentCardEmpty,
  IconContainer,
  Strong,
  P,
  ContentCardEmptyContainer
} from "./styled-components";

const ContentCardEmptyState = ({ qaLabel, title, message }) => (
  <ContentCardEmpty data-qa-label={qaLabel}>
    <ContentCardEmptyContainer>
      <IconContainer>
        <Icon
          icon={email}
          size={21}
          qaLabel={`${qaLabel}-icon`}
          color={buildColor("grey", "700")}
        />
      </IconContainer>
      {title && <Strong>{title}</Strong>}
      {message && <P>{message}</P>}
    </ContentCardEmptyContainer>
  </ContentCardEmpty>
);

ContentCardEmptyState.defaultProps = {
  qaLabel: "",
  title: "",
  message: ""
};

export default ContentCardEmptyState;
