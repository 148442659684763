import styled from "styled-components";
import { buildText } from "../Text/index";
import buildColor from "../../_static/ColorPalette";

export const Text = styled(buildText())`
  line-height: 1.33;
  padding: 24px 30px 40px;
  border-bottom: 1px solid ${buildColor("grey", "100")};
  display: block;
`;

export const Container = styled.div`
  background-color: ${buildColor("white", "100")};
  font-size: 12px;
  text-align: center;
  padding: 16px;
  height: auto;
`;

export const RedirectLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  font-size: 12px;
  background-color: ${buildColor("blue_accent", "500")};
  color: ${buildColor("white", "100")};
  border: none;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 2px;
`;
