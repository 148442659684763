export const getUserData = (userData) => ({
  type: "USER_DATA_UPDATE",
  payload: userData
});

export const userLogout = () => ({
  type: "USER_LOGOUT",
  payload: { logged: false }
});

export const successBalancePollerRequest = (balancePoller) => ({
  type: "USER_BALANCE_POLLER",
  payload: balancePoller
});

export const failBalanceRequest = (err) => ({
  type: "USER_BALANCE_FAIL",
  payload: err,
  error: true
});

export const setUserPrefs = (userPrefs = {}, gotPreferences = false) => ({
  type: "USER_PREFS_SET",
  payload: {
    preferences: userPrefs,
    gotPreferences
  }
});

export const failUserPrefs = (err) => ({
  type: "USER_PREFS_FAIL",
  payload: err,
  error: true
});

export const successUserPromos = (userPromos) => ({
  type: "USER_PROMOS_SUCCESS",
  payload: { optedInPromos: userPromos }
});

export const clearUserPromos = () => ({
  type: "USER_PROMOS_CLEAR"
});

export const loadingUserPromos = () => ({
  type: "USER_PROMOS_LOADING"
});

export const failUserPromos = (err) => ({
  type: "USER_PROMOS_FAIL",
  payload: err,
  error: true
});

export const setUserAccountNumber = (accountNumber) => ({
  type: "USER_ACCOUNT_NUMBER",
  payload: {
    accountNumber
  }
});

export const setIsLoginErrorPage = (isLoginError) => ({
  type: "LOGIN_ERROR_PAGE",
  payload: {
    isLoginError
  }
});

export const setUserPrefsHomepageTemplateDesktop = (template) => ({
  type: "USER_PREFS_HOMEPAGE_TEMPLATE_DESKTOP",
  payload: template
});

export const setUserPrefsHomepageTemplateMobile = (template) => ({
  type: "USER_PREFS_HOMEPAGE_TEMPLATE_MOBILE",
  payload: template
});
