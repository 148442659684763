import React from "react";
import { formatPeriod, formatPostTime, formatRaceDate } from "@tvg/formatter";

import { LegStatus, RaceStatus } from "./styled-components";
import { PlainMTPText } from "../../_atom/MTP/styled-components";
import MTP from "../../_atom/MTP";

const MyBetsMTP = (props) => {
  const {
    qaLabel,
    showRaceLeg,
    legNumber,
    isFutureBet,
    raceDate,
    currentDate,
    raceMtp,
    racePostTime,
    raceStatusCode,
    raceDateDiff,
    isMTPNewRules,
    device
  } = props;
  return (
    <RaceStatus data-qa-label={`${qaLabel}-wrapper`} device={device}>
      {showRaceLeg && legNumber > 0 && (
        <LegStatus data-qa-label={`${qaLabel}-leg-status`}>{`Leg ${legNumber} ${
          device === "desktop" ? "" : "-"
        }`}</LegStatus>
      )}
      {isFutureBet && raceDate && raceDate !== currentDate ? (
        <PlainMTPText data-qa-label={qaLabel}>
          {`${formatPostTime(racePostTime)} `}
          {formatPeriod(racePostTime)} - {formatRaceDate(raceDate)}
        </PlainMTPText>
      ) : (
        <MTP
          mtp={raceMtp}
          postTime={racePostTime}
          status={raceStatusCode}
          dateDiff={raceDateDiff}
          isPlainMTP
          isMyBets
          isMTPNewRules={isMTPNewRules}
        />
      )}
    </RaceStatus>
  );
};

MyBetsMTP.defaultProps = {
  qaLabel: "mybets-mtp",
  showRaceLeg: false,
  legNumber: 1,
  isFutureBet: false,
  raceDate: "",
  currentDate: "",
  raceMtp: 60,
  racePostTime: "",
  raceStatusCode: "O",
  raceDateDiff: "",
  isMTPNewRules: true,
  device: "mobile"
};
export default MyBetsMTP;
