import React from "react";
import style from "./style.css";

const ItemAdvantage = (props) => (
  <div className={style.advantageBlock}>
    <div className={style.iconAdvantage}>
      <props.icon />
    </div>
    <div className={style.textAdvantage}>{props.text}</div>
  </div>
);

export default ItemAdvantage;
