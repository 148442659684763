import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import buildColor from "../../_static/ColorPalette";
import { buildText } from "../../_atom/Text";
import { buildTitle } from "../../_atom/Title";
import { fontNormal, fontMedium } from "../../_static/Typography";

export const LoginResponsibly = styled(
  buildText({
    tag: "p",
    color: buildColor("grey", "700"),
    fontSize: 12
  })
)`
  text-align: center;
  line-height: 17px;
  padding: 0 4px;
  ${({ hasMarginTop }) =>
    hasMarginTop &&
    css`
      margin-top: auto;
    `}
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;

export const LoginMessageWrapper = styled.div`
  display: ${({ isLoginError }) => isLoginError && "none"};
  padding: 12px;
  background-color: ${buildColor("green", "000")};
  ${({ device }) =>
    device === "mobile"
      ? css`
          border-top: 1px solid ${buildColor("green", "200")};
          border-bottom: 1px solid ${buildColor("green", "200")};
        `
      : css`
          margin: 24px 24px 0;
          border-radius: 4px;
          border: 1px solid ${buildColor("green", "200")};
        `};
`;

export const LoginMessageTitle = styled(
  buildText({
    tag: "p",
    color: buildColor("green", "600"),
    fontSize: 14
  })
)`
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 19px;
  font-family: ${fontMedium};

  svg {
    margin-right: 12px;
    background-color: ${buildColor("green", "100")};
    border-radius: 50%;
  }
`;

export const LoginMessage = styled(
  buildText({
    tag: "p",
    color: buildColor("green", "600"),
    fontSize: 14
  })
)`
  font-weight: 400;
  line-height: 19px;
  font-family: ${fontNormal};
  margin-left: 28px;
`;

export const LoginFooterContainer = styled.div`
  z-index: 2;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  flex-direction: column;
  ${({ device }) =>
    device === "mobile"
      ? css`
          padding: 12px;
        `
      : css`
          padding: 0 20px 20px;
        `};

  & > button {
    background: ${buildColor("blue_accent", "500")};
  }

  & > div#pxElement:empty + button[data-qa-label="loginBtn"] {
    margin-top: -4px;
  }
`;

export const LoginContainer = styled.section`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background: ${buildColor("white", "100")};
`;

export const LoginForm = styled.form`
  padding: 8px;
  overflow-y: scroll;
  flex: 1;
`;

export const Content = styled.div`
  flex: 0 0 auto;
`;

export const Title = buildTitle({
  tag: "h3",
  fontSize: 20,
  bold: true,
  color: buildColor("green", "600"),
  uppercase: true
});

export const Text = buildText({
  fontSize: 12,
  bold: true,
  color: buildColor("white", "100"),
  uppercase: true
});

export const TitleContainer = styled.div`
  padding: 16px 16px 0;
`;

export const ResetCreateLinksContainer = styled.div`
  padding: 24px 0 16px;
  text-align: center;
  display: flex;
`;

export const ResetCredButton = styled(Link)`
  height: 16px;
  font-size: 12px;
  color: ${buildColor("blue_accent", "600")};
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: ${fontMedium};
`;

export const CreateAccountButton = styled.a`
  font-size: 14px;
  color: ${buildColor("blue_accent", "500")};
  text-decoration: none;
  height: 16px;
  display: flex;
  align-items: center;
  background-color: transparent;
  text-transform: none;
  font-weight: normal;
  font-family: ${fontMedium};

  &:active {
    color: ${buildColor("blue_accent", "700")};
  }
`;

export const PerimeterxContainer = styled.div`
  width: 100%;
  margin-top: 4px;
`;

export const RobotMessage = styled.span`
  width: 100%;
  color: ${buildColor("grey", "900")};
  font-family: ${fontNormal};
  line-height: 18px;
`;

export const FooterText = styled.span`
  color: ${buildColor("grey", "900")};
  font-size: 14px;
  font-weight: 500;
  font-family: ${fontMedium};
`;
