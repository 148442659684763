export const setUserFavoriteTracks = (favoriteTracks) => ({
  type: "SET_USER_FAVORITE_TRACKS",
  payload: { favoriteTracks }
});

export const clearUserFavoriteTracks = () => ({
  type: "CLEAR_USER_FAVORITE_TRACKS"
});

export default setUserFavoriteTracks;
