import mediator from "@tvg/mediator";
import { get } from "lodash";
import { GlobalWalletMediatorEventType } from "@tvg/ts-types/Amplitude";
import { EventData, EventProps } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(
    GlobalWalletMediatorEventType.BALANCE_EXPANDED,
    (data: EventData) =>
      track<EventProps>(
        get(data, "payload.isBalanceExpanded", false)
          ? EVENT_NAME.EXPAND
          : EVENT_NAME.COLLAPSE,
        {
          [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
          [BaseEventProps.EVENT_LABEL]: "Balance Expanded",
          [BaseEventProps.MODULE]: "Account"
        }
      )
  );

  mediator.base.subscribe(
    GlobalWalletMediatorEventType.WITHDRAW_DEPOSIT,
    (data: EventData) =>
      track<EventProps>(EVENT_NAME.NAVIGATED, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: get(data, "payload.eventLabel"),
        [BaseEventProps.MODULE]: "Account",
        [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl")
      })
  );

  mediator.base.subscribe(
    GlobalWalletMediatorEventType.VIEW_BALANCE_DETAILS,
    () =>
      track<EventProps>(EVENT_NAME.VIEW_BALANCE_DETAILS, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]:
          "User clicks the “View Balance Details” CTA",
        [BaseEventProps.MODULE]: "Account",
        [BaseEventProps.LINK_URL]: "/account"
      })
  );

  mediator.base.subscribe(
    GlobalWalletMediatorEventType.PLAYABLE_BALANCE_MODAL,
    (data: EventData) =>
      track<EventProps>(
        get(data, "payload.open", false)
          ? EVENT_NAME.PLAYABLE_BALANCE_MODAL_OPEN
          : EVENT_NAME.PLAYABLE_BALANCE_MODAL_CLOSE,
        {
          [BaseEventProps.EVENT_CATEGORY]: "Navigation",
          [BaseEventProps.LINK_TEXT]:
            "User clicks the “View Balance Details” CTA",
          [BaseEventProps.MODULE]: !get(data, "payload.open", false)
            ? "Balances"
            : "Account"
        }
      )
  );

  mediator.base.subscribe(
    GlobalWalletMediatorEventType.ACCOUNT_NAVIGATION,
    (data: EventData) =>
      track<EventProps>(EVENT_NAME.NAVIGATED, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: get(data, "payload.linkName"),
        [BaseEventProps.MODULE]: "Account",
        [BaseEventProps.LINK_URL]: get(data, "payload.url")
      })
  );
};
