import React, { PureComponent } from "react";
import { toString, isFinite } from "lodash";
import formatSequencial from "@tvg/formatter/numeric";

import Position from "./styled-components";

export default class PositionLabel extends PureComponent {
  getNonNumericPosition() {
    let position;
    const { race } = this.props;
    if (isFinite(race.finishPosition)) {
      if (race.finishPosition <= 0) {
        position = <Position nonNumeric>SCR</Position>;
      } else {
        position = <Position nonNumeric>DNF</Position>;
      }
    } else {
      position = <Position>- {`/ ${race.numRunners}`}</Position>;
    }

    return position;
  }

  getPosition() {
    const { race } = this.props;

    return (
      isFinite(race.finishPosition) && (
        <Position>
          {race.finishPosition}
          <sup>
            {formatSequencial(race.finishPosition).replace(
              toString(race.finishPosition),
              ""
            )}
          </sup>
          {`/ ${race.numRunners}`}
        </Position>
      )
    );
  }

  render() {
    const { race } = this.props;

    return typeof race.finishPosition === "number" &&
      !!race.finishPosition &&
      race.finishPosition > 0 &&
      race.finishPosition <= +race.numRunners
      ? this.getPosition()
      : this.getNonNumericPosition();
  }
}
