import { addMessage, removeMessage, removeAllMessages } from "./actions";

const MESSAGE_REMOVE_TIMEOUT = 4000; // 4 seconds

class MessengerService {
  dispatcher;

  messages = [];

  setDispatcher(dispatcher) {
    this.dispatcher = dispatcher;
  }

  create(message) {
    const key = this.messages.length;
    const msg = { ...message, key };

    if (message.showCloseButton) {
      msg.onClose = () => this.dispatcher(removeMessage(key));
    } else {
      setTimeout(
        () => this.dispatcher(removeMessage(key)),
        MESSAGE_REMOVE_TIMEOUT
      );
    }

    this.dispatcher(addMessage(msg));

    return msg;
  }

  clearAllMessages() {
    this.dispatcher(removeAllMessages());
  }
}

const service = new MessengerService();
export default service;
