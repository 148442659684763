import React, { useContext, useLayoutEffect, useRef } from "react";
import { TabsContext } from "../../Tabs";
import {
  StyledViewIconContainer,
  StyledViewTitleContainer,
  StyledTitle,
  StyledDescription,
  StyledButton
} from "./styled-components";
import type { TabProps } from "./types";

export const Tab = ({
  value,
  title,
  description,
  counter,
  isSelected = false,
  isDisabled = false,
  noBorder = false,
  isStretched,
  qaLabel = value
}: TabProps) => {
  const { onChange, selectedValue, setTabsLayout } = useContext(TabsContext);
  const ref = useRef<HTMLDivElement>(null);
  const isCurrentTabSelected = isSelected || selectedValue === value;

  useLayoutEffect(() => {
    if (ref.current && ref.current.parentElement) {
      const parentElementRect =
        ref.current.parentElement.getBoundingClientRect();

      const { x } = ref.current.getBoundingClientRect();

      const newState = {
        [value]: { width: ref.current.clientWidth, x: x - parentElementRect.x }
      };

      setTabsLayout((state: {}) => ({ ...state, ...newState }));
    }
  }, [ref]);

  return (
    <StyledButton
      onClick={() => onChange(value)}
      isStretched={isStretched}
      isDisabled={isDisabled}
      noBorder={noBorder}
      isSelected={isCurrentTabSelected}
      className={isCurrentTabSelected ? "selected" : ""}
      data-qa-label={qaLabel}
      id={value}
      ref={ref}
    >
      <>
        <StyledViewTitleContainer>
          <StyledTitle
            isDisabled={isDisabled}
            isSelected={isCurrentTabSelected}
            data-qa-label={`tab-title-${title}`}
          >
            {title}
          </StyledTitle>
          {!isDisabled && counter && (
            <StyledViewIconContainer>{counter}</StyledViewIconContainer>
          )}
        </StyledViewTitleContainer>
        {description && (
          <StyledDescription
            isDisabled={isDisabled}
            isSelected={isCurrentTabSelected}
            data-qa-label={`tab-description-${description}`}
          >
            {description}
          </StyledDescription>
        )}
      </>
    </StyledButton>
  );
};

export type { TabProps };
