import React, { useRef, useLayoutEffect, useState, type FC } from "react";
import { ScrollContainer, ShadowWrapper } from "./styled-components";
import type { ScrollProps, BorderToShow } from "./types";

export const Scroll: FC<ScrollProps> = ({
  children,
  isDark = false,
  qaLabel = "scroll",
  showBorderTop = false,
  showBorderRight = false,
  showBorderBottom = false,
  showBorderLeft = false,
  hasShadow = false,
  component = "div",
  ...props
}) => {
  const [scrollPosition, setScrollPosition] = useState("top");
  const [hideShadow, setHideShadow] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const borderToShow: BorderToShow = {
    top: showBorderTop,
    right: showBorderRight,
    bottom: showBorderBottom,
    left: showBorderLeft
  };

  useLayoutEffect(() => {
    if (hasShadow && scrollRef.current) {
      setHideShadow(
        scrollRef.current?.clientHeight === scrollRef.current?.scrollHeight
      );
      scrollRef.current?.addEventListener("scroll", handleScroll);

      return () => {
        scrollRef.current?.removeEventListener("scroll", handleScroll);
      };
    }

    return () => scrollRef.current?.removeEventListener("scroll", handleScroll);
  }, [scrollRef, hasShadow]);

  const handleScroll = () => {
    if (scrollRef.current) {
      if (scrollRef.current?.scrollTop === 0) {
        return setScrollPosition("top");
      }

      if (
        scrollRef.current?.scrollTop + scrollRef.current?.clientHeight ===
        scrollRef.current?.scrollHeight
      ) {
        return setScrollPosition("bottom");
      }

      return scrollPosition !== "middle" && setScrollPosition("middle");
    }

    return null;
  };

  return hasShadow ? (
    <ShadowWrapper
      data-scroll-position={scrollPosition}
      data-hide-shadow={hideShadow}
      data-qa-label={`${qaLabel}-shadow`}
      {...props}
    >
      <ScrollContainer
        ref={scrollRef}
        data-mode={isDark ? "dark" : "light"}
        data-qa-label={qaLabel}
        borderToShow={borderToShow}
        hasShadow={hasShadow}
        as={component}
        {...props}
      >
        {children}
      </ScrollContainer>
    </ShadowWrapper>
  ) : (
    <ScrollContainer
      ref={scrollRef}
      data-mode={isDark ? "dark" : "light"}
      data-qa-label={qaLabel}
      borderToShow={borderToShow}
      hasShadow={hasShadow}
      as={component}
      {...props}
    >
      {children}
    </ScrollContainer>
  );
};

export type { ScrollProps };
