import styled, { css } from "styled-components";

import buildColor from "../../_static/ColorPalette";
import { titleFont } from "../../_static/Typography";

export const RunnerRow = styled.tr`
  background-color: ${buildColor("white", "100")};
  font-size: 12px;
  border-bottom: 1px solid ${buildColor("blue", "100")};
  vertical-align: top;
`;

export const PositionCell = styled.td`
  height: 0;
  vertical-align: top;
`;

export const Position = styled.strong`
  font-family: ${titleFont};
  padding: 16px 0 0 8px;
  width: 100%;
  height: 100%;
  font-size: 12px;
  display: flex;
  line-height: 16px;
  box-sizing: content-box;
  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      color: ${buildColor("blue_accent", "500")};
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 4px;
        background-color: ${buildColor("blue_accent", "500")};
      }
    `};

  & > sup {
    font-size: 8px;
    transform: translate(0, -2px);
  }
`;

export const DNF = styled.span`
  width: 30px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  border-radius: 2px;
  border: 1px solid ${buildColor("grey", "700")};
  color: ${buildColor("grey", "700")};

  &::before {
    content: "DNF";
  }
`;

export const Number = styled.td`
  width: 24px;
  vertical-align: top;
  padding-top: 16px;
  ${({ isDnf }) =>
    isDnf
      ? css`
          padding-left: 8px;
        `
      : css`
          padding-right: 8px;
        `};
`;

export const Runner = styled.td`
  font-family: ${titleFont};
  text-align: left;
  ${({ isBasic }) =>
    isBasic
      ? css`
          padding: 8px 8px 8px 0;
        `
      : css`
          padding: 8px 8px 12px 0;
        `};
  max-width: 0;
  ${({ isDnf }) =>
    isDnf &&
    css`
      padding-left: 8px;
    `};
`;

export const OtherInfo = styled.p`
  padding: 0 8px;
  transform: translate(0, -4px);
  ${({ isDnf }) =>
    isDnf
      ? css`
          color: ${buildColor("grey", "700")};
        `
      : css`
          color: ${buildColor("grey", "800")};
        `};
`;
