import React from "react";
import { connect } from "react-redux";
import ApproxPayoutHelp from "@tvg/atomic-ui/_templates/ApproxPayoutHelp";
import parseJSONCapiMessage from "@tvg/utils/capiUtils";

export const ApproxPayoutHelpComp = ({ description }) => (
  <ApproxPayoutHelp description={description} />
);

ApproxPayoutHelpComp.defaultProps = {
  description: ["", "", ""]
};

export default connect((store) => ({
  description: parseJSONCapiMessage(store, "capi.messages.approxPayoutHelp", [])
}))(ApproxPayoutHelpComp);
