import React, { Fragment } from "react";
import { first, noop } from "lodash";
import { TVGThemeProvider, Paragraph } from "@tvg/design-system";
import { Button } from "@tvg/design-system/web";

import {
  PickBetsContainer,
  PickBetsTextWrapper,
  PickBetsLinkContainer
} from "./styled-components";

const PickBetRebet = ({
  raceUrl,
  handleRebetClickEvent,
  rebetWagerTypeName,
  rebetSearch,
  isContentDisplayed,
  bigLayout,
  isTVG4
}) => {
  const PickBetWrapper = isTVG4 ? Fragment : PickBetsLinkContainer;
  return (
    <TVGThemeProvider>
      <PickBetsContainer
        bigLayout={bigLayout}
        hasRoundBottomBorder={!isContentDisplayed}
      >
        <PickBetsTextWrapper bigLayout={bigLayout}>
          <Paragraph
            fontFamily="medium"
            color="blue_accent.800"
            qaLabel="pick-bets-rebet-title"
          >{`${rebetWagerTypeName} Available`}</Paragraph>
          <Paragraph
            qaLabel="pick-bets-rebet-description"
            fontSize="xs"
            marginBottom={bigLayout ? "space-4" : ""}
          >
            Bet using your remaining selections
          </Paragraph>
        </PickBetsTextWrapper>
        <PickBetWrapper
          data-qa-label="myBets-pickbets-link"
          to={{
            pathname: raceUrl && first(raceUrl.split("?")),
            search: rebetSearch,
            state: {
              openVideo: false
            }
          }}
        >
          <Button
            variant="betting"
            iconPosition="end"
            icon="arrowRight"
            onClick={handleRebetClickEvent}
            qaLabel="button"
            size="m"
            isStretched
          >
            Create Bet
          </Button>
        </PickBetWrapper>
      </PickBetsContainer>
    </TVGThemeProvider>
  );
};

PickBetRebet.defaultProps = {
  raceUrl: "",
  handleRebetClickEvent: noop,
  rebetWagerTypeName: "",
  rebetSearch: "",
  isContentDisplayed: true,
  bigLayout: false,
  isTVG4: false
};

export default React.memo(PickBetRebet);
