import { EnvironmentConfig, EventType, Event } from "../types";

const xSellConfig: EnvironmentConfig = {
  [EventType.Trigger]: {
    [Event.Login]: {
      eventName: "x-sell/bridge/to-react/onTriggerLogin"
    },
    [Event.Join]: {
      eventName: "x-sell/bridge/to-react/onTriggerJoin"
    },
    [Event.OnRefreshSession]: {
      eventName: "x-sell/bridge/to-react/onUserTouch"
    },
    [Event.FirstLoadComplete]: {
      eventName: "x-sell/bridge/to-react/firstLoadComplete"
    },
    [Event.AcceptTerms]: {
      eventName: "x-sell/bridge/to-react/onTriggerAcceptTerms"
    },
    [Event.Verification]: {
      eventName: "x-sell/bridge/to-react/onTriggerVerification"
    },
    [Event.AccountSummary]: {
      eventName: "x-sell/bridge/to-react/onTriggerAccountSummary"
    },
    [Event.Deposit]: {
      eventName: "x-sell/bridge/to-react/onTriggerDeposit"
    },
    // TODO: Workaround for logout user when is not sync with native
    [Event.Logout]: {
      eventName: "x-sell/bridge/to-native/logoutFlowComplete"
    }
  },
  [EventType.Subscribe]: {
    [Event.LoginComplete]: {
      eventName: "x-sell/bridge/to-native/loginFlowComplete"
    },
    [Event.LoginCancelled]: {
      eventName: "x-sell/bridge/to-native/loginCancelled"
    },
    [Event.LogoutComplete]: {
      eventName: "x-sell/bridge/to-native/logoutFlowComplete"
    },
    [Event.AuthDetailsUpdated]: {
      eventName: "x-sell/bridge/to-native/authDetailsUpdated"
    },
    [Event.AccountAndWalletClosed]: {
      eventName: "x-sell/bridge/to-native/accountAndWalletClosed"
    }
  }
};

export default xSellConfig;
