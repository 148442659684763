import React, { useContext, Children, cloneElement } from "react";
import { TabsContext } from "../../Tabs";
import { TabSelectionIndicator } from "../TabSelectionIndicator";
import type { TabListProps } from "./types";
import { StyledContainer } from "./styled-components";

export const TabList = ({ children, isStretched }: TabListProps) => {
  const { tabsLayout } = useContext(TabsContext);

  return isStretched ? (
    <div>
      <StyledContainer tabsLayout={tabsLayout} data-qa-label="tablist-wrapper">
        {Children.map(children, (child) =>
          cloneElement(child, { isStretched })
        )}
        <TabSelectionIndicator />
      </StyledContainer>
    </div>
  ) : (
    <>
      <StyledContainer tabsLayout={tabsLayout} data-qa-label="tablist-wrapper">
        {Children.map(children, (child) =>
          cloneElement(child, { isStretched })
        )}
        <TabSelectionIndicator />
      </StyledContainer>
    </>
  );
};

export type { TabListProps };
