export const initialState = {
  isLoading: false,
  hasSentEmail: false,
  hasError: false,
  hasErrorAttempt: false,
  hasErrorInternal: false,
  values: { dateOfBirth: "", last4Ssn: [] },
  touched: { dateOfBirth: false, last4Ssn: false },
  maskedEmail: "",
  attempts: 0
};

export const ACTION = {
  RECOVERY_REQUEST_START: "RECOVERY_REQUEST_START",
  RECOVERY_REQUEST_SUCCESS: "RECOVERY_REQUEST_SUCCESS",
  RECOVERY_REQUEST_FAILURE: "RECOVERY_REQUEST_FAILURE",
  RECOVERY_REQUEST_ERROR: "RECOVERY_REQUEST_ERROR",
  RECOVERY_REQUEST_INTERNAL_ERROR: "RECOVERY_REQUEST_INTERNAL_ERROR",
  SET_VALUES: "SET_VALUES",
  SET_TOUCHED: "SET_TOUCHED"
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.RECOVERY_REQUEST_START:
      return {
        ...state,
        isLoading: true,
        hasSentEmail: false,
        hasError: false
      };
    case ACTION.RECOVERY_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasSentEmail: true,
        hasError: false,
        values: action.payload.values,
        maskedEmail: action.payload.maskedEmail
      };
    case ACTION.RECOVERY_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasSentEmail: false,
        hasError: true,
        attempts: state.attempts + 1
      };
    case ACTION.RECOVERY_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        hasErrorAttempt: true
      };
    case ACTION.RECOVERY_REQUEST_INTERNAL_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        hasErrorAttempt: true,
        hasErrorInternal: true
      };
    case ACTION.SET_VALUES:
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload
        }
      };
    case ACTION.SET_TOUCHED:
      return {
        ...state,
        touched: {
          ...state.touched,
          ...action.payload
        }
      };
    default:
      return state;
  }
}
