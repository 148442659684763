import React, { Component } from "react";
import WarningIcon from "@tvg/ui-bootstrap/assets/svg/warning.svg";
import OkIcon from "@tvg/ui-bootstrap/assets/svg/success.svg";
import classNames from "classnames";
import _ from "lodash";

import style from "./style.css";

class Username extends Component {
  constructor(props) {
    super(props);

    _.bindAll(this, [
      "renderLabel",
      "renderInput",
      "onChangeUsernameHolder",
      "checkUsernamePattern",
      "gtmFieldComplete"
    ]);
  }

  state;

  onChangeUsernameHolder;
  onChangeUsernameHolder(e) {
    const usernameValue = e.target.value;
    this.props.updateMainState({ usernameValue });

    if (
      !this.props.isUsernameValid &&
      this.checkUsernamePattern(usernameValue)
    ) {
      this.props.updateMainState({
        isUsernameValid: true,
        isUsernameCorrected: true,
        usernameValue
      });
    }

    if (
      this.props.isUsernameCorrected &&
      !this.checkUsernamePattern(usernameValue)
    ) {
      this.props.updateMainState({
        isUsernameValid: false,
        isUsernameCorrected: false,
        usernameValue
      });
    }

    if (!this.checkUsernamePattern(usernameValue)) {
      this.props.updateMainState({
        isUsernameValid: false,
        isUsernameCorrected: false,
        usernameValue
      });
    }
  }

  checkUsernamePattern(username) {
    return /^([A-Za-z0-9]+){1,20}/.test(username);
  }

  gtmFieldComplete() {
    this.props.onFieldBlur("username", this.props.usernameValue);
  }

  renderLabel;
  renderLabel() {
    const WarningFeedbackIcon = !this.props.isUsernameValid && (
      <WarningIcon
        className={classNames({
          [style.statusIcon]: true,
          [style.warningIcon]: true
        })}
      />
    );

    const OkFeedbackIcon = this.props.isUsernameCorrected &&
      this.props.isUsernameValid && (
        <OkIcon
          className={classNames({
            [style.statusIcon]: true,
            [style.warningIcon]: true
          })}
        />
      );

    return (
      <label htmlFor="stateSelector" className={style.label}>
        Username or Account #{WarningFeedbackIcon || OkFeedbackIcon}
      </label>
    );
  }

  renderInput;
  renderInput() {
    const inputStyle = classNames({
      [style.inputText]: true,
      [style.inputTextInvalid]: !this.props.isUsernameValid
    });

    const WarningMessage = !this.props.isUsernameValid && (
      <div className={style.warningMessage}>
        Please insert a valid Username/Account #.
      </div>
    );

    return (
      <div className={style.inputWrapper}>
        <input
          type="text"
          className={inputStyle}
          id="usernameInput"
          data-qa-label="usernameInput"
          placeholder="you@example.com"
          value={this.props.usernameValue}
          onChange={this.onChangeUsernameHolder}
          name="username"
          onFocus={this.props.firstTouchForGTM}
          onBlur={_.throttle(this.gtmFieldComplete, 1000, { trailing: false })}
          onKeyPress={this.props.keyIsPressed}
        />
        {WarningMessage}
      </div>
    );
  }

  render() {
    return (
      <div className={style.container}>
        {this.renderLabel()}
        {this.renderInput()}
      </div>
    );
  }
}

export default Username;
