import React, { Component } from "react";
import { noop } from "lodash";

import { openLiveChat } from "@tvg/utils/liveChatUtils";
import mediator from "@tvg/mediator";
import { replaceCAPIVariables } from "@tvg/utils/capiUtils";

import RecoverCredentialsForm from "../../_organism/RecoverCredentialsForm";
import ProcessingOverlay from "../../_static/ProcessingOverlay";
import {
  Container,
  MessageWrapper,
  MessageEmail,
  MessageNote,
  MessageBoxFooter,
  MessageLink,
  CallToActionWrapper,
  StyledButtonLink,
  MessageLinkFooter,
  MessageEmailResent
} from "./styled-components";
import Info from "../../_molecule/Info";
import MessageBox from "../../_molecule/MessageBoxV2";
import { email as emailIcon, account } from "../../_static/Icons/icons";

const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

/* Checks if value is only numbers */
const validateNumbers = (value) => {
  const re = /^\d+$/;
  return re.test(value);
};

const renderMessageHelper = (
  userId,
  onRetype,
  messagesHelper,
  hasResentEmail
) => {
  const isNumber = validateNumbers(userId);
  return (
    <MessageWrapper>
      <p>{messagesHelper.text}</p>
      {!isNumber && <MessageEmail>{` ${userId} `}</MessageEmail>}
      {!hasResentEmail && (
        <MessageNote breakLine={isNumber}>
          <span>{messagesHelper.retypeMessage}</span>
          <MessageLink
            data-qa-label="reset-credentials-retype"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              mediator.base.dispatch({
                type: "FORGOT_CREDENTIALS_WRONG_EMAIL_CTA_LINK_CLICK"
              });
              onRetype();
            }}
          >
            {messagesHelper.retypeAction}
          </MessageLink>
        </MessageNote>
      )}
    </MessageWrapper>
  );
};

const renderSuccessMessage = (userId, onRetype, messages, hasResentEmail) => {
  const isEmail = validateEmail(userId);
  const isNumber = validateNumbers(userId);

  if (isEmail)
    return renderMessageHelper(
      userId,
      onRetype,
      messages.email,
      hasResentEmail
    );

  if (isNumber) {
    const messageAccountText = replaceCAPIVariables(
      messages.accountNumber.text,
      { accountNumber: userId }
    );
    const messageAccount = {
      ...messages.accountNumber,
      text: messageAccountText
    };
    return renderMessageHelper(
      userId,
      onRetype,
      messageAccount,
      hasResentEmail
    );
  }

  return renderMessageHelper(
    userId,
    onRetype,
    messages.username,
    hasResentEmail
  );
};

const renderEmailResentMessage = (message) => (
  <MessageEmailResent dangerouslySetInnerHTML={{ __html: message }} />
);

export const messagesDefault = {
  info: "",
  recover: "",
  error: {
    message: "",
    titleMaxAttempts: "",
    title: "",
    titleError: ""
  },
  success: {
    footer: "",
    resend: "",
    title: "",
    message: "",
    email: {
      text: "",
      retypeMessage: "",
      retypeAction: ""
    },
    accountNumber: {
      text: "",
      retypeMessage: "",
      retypeAction: ""
    },
    username: {
      text: "",
      retypeMessage: "",
      retypeAction: ""
    },
    resent: {
      title: "",
      message: ""
    }
  },
  contact: "",
  return: ""
};

export default class RecoverCredentials extends Component {
  static defaultProps = {
    device: "",
    isProcessing: false,
    mobile: false,
    initialValue: null,
    messages: messagesDefault,
    onResetCallback: noop,
    hasError: false,
    hasSentEmail: false,
    attempts: 0,
    attemptsMax: 3,
    onRetype: noop,
    userIdValue: "",
    onCloseModalCallback: noop,
    hasResentEmail: false,
    onResendCallback: noop,
    redirectToRecoverDetails: noop
  };

  renderSuccess = () => (
    <Container
      device={this.props.device}
      data-qa-label="loginSignUpBlockSuccess"
      isMobile={this.props.mobile}
    >
      <Info
        qaLabel="credentials-recovery-info-success"
        mainIcon={emailIcon}
        hasOverlayIcon
        messageType="success"
        title={this.props.messages.success.title}
        message={renderSuccessMessage(
          this.props.userIdValue,
          this.props.onRetype,
          this.props.messages.success,
          this.props.hasResentEmail
        )}
        full
      />
      {this.props.hasResentEmail ? (
        <MessageBoxFooter
          data-qa-label="recover-credentials-success-message-box-container"
          onClick={(e) => {
            if (e.target.nodeName === "A") {
              mediator.base.dispatch({
                type: "FORGOT_CREDENTIALS_CONTACT_CUSTOMER_SERVICE"
              });
            }
          }}
        >
          <MessageBox
            qaLabel="recover-credentials-success"
            type="success"
            subtype="floating"
            title={this.props.messages.success.resent.title}
            message={renderEmailResentMessage(
              this.props.messages.success.resent.message
            )}
          />
        </MessageBoxFooter>
      ) : (
        <MessageNote qaLabel="reset-credentials-footer" isFooter>
          {this.props.messages.success.footer}
          <MessageLinkFooter
            data-qa-label="reset-credentials-resend"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              mediator.base.dispatch({
                type: "FORGOT_CREDENTIALS_RESEND_SCREEN"
              });
              this.props.onResendCallback();
            }}
          >
            {this.props.messages.success.resend}
          </MessageLinkFooter>
        </MessageNote>
      )}
    </Container>
  );

  renderError = () => (
    <Container
      device={this.props.device}
      data-qa-label="loginSignUpBlockFailure"
      isMobile={this.props.mobile}
    >
      <Info
        qaLabel="credentials-recovery-info-failure"
        mainIcon={account}
        hasOverlayIcon
        messageType="error"
        title={
          this.props.attempts >= this.props.attemptsMax
            ? this.props.messages.error.titleMaxAttempts
            : this.props.messages.error.titleError
        }
        message={this.props.messages.error.message}
        full
      />
      <CallToActionWrapper
        data-qa-label={`recover-credentials-cta-${
          this.props.attempts >= this.props.attemptsMax
            ? "Too May Attempts"
            : "Something went wrong!"
        }`}
      >
        <StyledButtonLink
          qaLabel="recover-credentials-message-cs"
          isStretched
          size="huge"
          url="#"
          isUppercase={false}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            mediator.base.dispatch({
              type: "FORGOT_CREDENTIALS_ERROR_PAGE_CTA",
              payload: {
                label: "Message Customer Service",
                module:
                  this.props.attempts >= this.props.attemptsMax
                    ? "Too May Attempts"
                    : "Something went wrong!"
              }
            });
            openLiveChat();
          }}
        >
          {this.props.messages.contact}
        </StyledButtonLink>
        <StyledButtonLink
          qaLabel="recover-credentials-return-tvg"
          isStretched
          size="huge"
          url="#"
          isUppercase={false}
          type="tertiary"
          withMarginTop
          onClick={(e) => {
            mediator.base.dispatch({
              type: "FORGOT_CREDENTIALS_RETURN_TO_TVG_COM",
              payload: {
                module:
                  this.props.attempts >= this.props.attemptsMax
                    ? "Too Many Attempts"
                    : "Something went wrong!"
              }
            });
            this.props.onCloseModalCallback();
          }}
        >
          {this.props.messages.return}
        </StyledButtonLink>
      </CallToActionWrapper>
    </Container>
  );

  render() {
    if (!this.props.isProcessing && this.props.hasError)
      return this.renderError();
    if (!this.props.isProcessing && this.props.hasSentEmail)
      return this.renderSuccess();
    return (
      <Container
        device={this.props.device}
        data-qa-label={`loginSignUpBlock${
          this.props.isProcessing ? "-loading" : ""
        }`}
        isMobile={this.props.mobile}
      >
        {this.props.isProcessing && <ProcessingOverlay />}
        <RecoverCredentialsForm
          initialEmail={this.props.initialValue}
          info={this.props.messages.info}
          recover={this.props.messages.recover}
          onResetCallback={this.props.onResetCallback}
          redirectToRecoverDetails={this.props.redirectToRecoverDetails}
          isMobile={this.props.mobile}
        />
      </Container>
    );
  }
}
