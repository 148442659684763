import React, { Component } from "react";
import classNames from "classnames";
import Button from "../Buttons";
import styles from "./index.css";

import Close from "../../assets/svg/close.svg";
import Tick from "../../assets/svg/tick.svg";
import Warning from "../../assets/svg/warning.svg";
import Info from "../../assets/svg/info.svg";
import Exclamation from "../../assets/svg/exclamation.svg";

/**
 * TVG Notification Component
 */
class Notification extends Component {
  static defaultProps = {
    type: "success",
    title: "",
    message: "",
    icon: false,
    dismissible: false,
    autodismiss: 0,
    show: false,
    onClose: () => {},
    onShow: () => {},
    scrollTop: false
  };

  static createMarkup(text) {
    return { __html: text };
  }

  constructor(props) {
    super(props);

    this.state = {
      type: props.type,
      title: props.title,
      message: props.message,
      icon: props.icon,
      dismissible: props.dismissible,
      autodismiss: props.autodismiss,
      show: props.show,
      scrollTop: props.scrollTop
    };

  }

  state;

  componentWillMount() {
    const { onClose, onShow, ...props } = this.props;
    this.setState(props);
  }

  componentDidMount() {
    if (this.props.scrollTop && this.notificationEle) {
      this.notificationEle.scrollIntoView(false);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { onClose, onShow, ...props } = nextProps;
    this.setState(props);

    if (!this.props.show && nextProps.show && this.props.onShow) {
      this.props.onShow();
    }
  }

  componentDidUpdate() {
    if (this.props.scrollTop && this.notificationEle) {
      this.notificationEle.scrollIntoView(false);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.dismissTimeout);
  }

  getNotificationProps = () => {
    let propMap;
    const icon = this.state.icon;
    switch (this.state.type) {
      case "success":
        propMap = {
          icon: icon ? (
            <Tick
              fill="#38ab50"
              width="32"
              height="32"
              data-qa-label="successNotificationIcon"
            />
          ) : null,
          className: classNames(styles.alertBox, styles.successBox)
        };
        break;
      case "error":
        propMap = {
          icon: icon ? (
            <Warning
              fill="#de3232"
              width="32"
              height="32"
              data-qa-label="errorNotificationIcon"
            />
          ) : null,
          className: classNames(styles.alertBox, styles.errorBox)
        };
        break;
      case "alert":
        propMap = {
          icon: icon ? (
            <Exclamation
              fill="#ffb80c"
              width="32"
              height="32"
              data-qa-label="alertNotificationIcon"
            />
          ) : null,
          className: classNames(styles.alertBox, styles.warningBox)
        };
        break;
      case "warning":
        propMap = {
          icon: icon ? (
            <Warning
              fill="#ffb80c"
              width="32"
              height="32"
              data-qa-label="warningNotificationIcon"
            />
          ) : null,
          className: classNames(styles.alertBox, styles.warningBox)
        };
        break;
      case "info":
        propMap = {
          icon: icon ? (
            <Info
              fill="#27609b"
              width="32"
              height="32"
              data-qa-label="infoNotificationIcon"
            />
          ) : null,
          className: classNames(styles.alertBox, styles.infoBox)
        };
        break;
      default:
        propMap = {
          icon: icon ? (
            <Tick
              fill="#38ab50"
              width="32"
              height="32"
              data-qa-label="successNotificationIcon"
            />
          ) : null,
          className: classNames(styles.alertBox, styles.successBox)
        };
    }
    return propMap;
  }

  dismissTimeout;
  props;

  removeNotification;
  removeNotification = () => {
    this.setState({ show: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  renderIcon = () => {
    return this.state.icon ? (
      <div
        className={styles.alertBoxIcon}
        data-qa-label={this.state.type.concat("NotificationIcon")}
      >
        {" "}
        {this.getNotificationProps().icon}{" "}
      </div>
    ) : null;
  }

  renderMessage = () => {
    return (
      <div
        className={styles.alertBoxContainer}
        data-qa-label={this.state.type.concat("NotificationMessageContainer")}
      >
        {this.state.title ? (
          <strong data-qa-label={this.state.type.concat("NotificationTitle")}>
            {" "}
            {this.state.title}{" "}
          </strong>
        ) : null}
        <span
          className={styles.alertMessage}
          data-qa-label={this.state.type.concat("NotificationMessage")}
          dangerouslySetInnerHTML={Notification.createMarkup(
            this.state.message
          )}
        />
      </div>
    );
  }

  renderCloseButton = () => {
    return (
      <div className={styles.alertBoxCloseButton}>
        {this.state.dismissible ? (
          <Button
            className={styles.closeButton}
            onClick={this.removeNotification}
            qaLabel={this.state.type.concat("NotificationClose")}
          >
            <Close width="16" height="16" />
          </Button>
        ) : null}
      </div>
    );
  }

  render() {
    if (!this.state.show) {
      return null;
    }

    if (this.state.autodismiss !== 0) {
      this.dismissTimeout = setTimeout(
        this.removeNotification,
        this.state.autodismiss
      );
    }

    return (
      <div
        ref={(c) => {
          this.notificationEle = c;
        }}
        className={this.getNotificationProps().className}
        data-qa-label={this.state.type.concat("Notification")}
      >
        <div className={styles.iconMessageContainer}>
          {this.renderIcon()}
          {this.renderMessage()}
        </div>
        {this.renderCloseButton()}
      </div>
    );
  }
}

export default Notification;
